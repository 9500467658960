<template>
  <div class="row">
    <div class="col-md-12 py-0">
      <div class="card pane border-0 shadow-sm bg-white py-4 px-5">
        <!-- Header -->
        <div class="card-header border-0 bg-transparent pl-3 pb-2">
          <h4 class="text-capitalize">{{ $t("AccountOverview") }}</h4>
        </div>

        <!-- Content -->
        <div class="row px-2">
          <div class="col-md-6">
            <!-- Account details -->
            <div class="card">
              <div
                class="
                  justify-content-between
                  card-header
                  bg-transparent
                  py-1
                  align-items-center
                  d-flex
                "
              >
                <h6 class="my-auto">
                  {{ $t("ACCOUNTDETAILS") }}
                </h6>
                <router-link
                  :to="{ name: 'account-edit' }"
                  class="btn rnd mr-n3"
                >
                  <i class="mdi mdi-pencil color-font"></i>
                </router-link>
              </div>

              <div class="card-body">
                <p class="mb-0">{{ username }}</p>
                <small class="text-muted">{{ email }}</small>
              </div>

              <div class="card-footer bg-transparent border-top-0 pt-0 pl-3">
                <button @click.prevent class="btn btn-font-trans btn-sm">
                  {{ $t("CHANGEPASSWORD") }}
                </button>
              </div>
            </div>

            <!-- Newsletter details -->
            <div class="card mt-5">
              <div
                class="
                  justify-content-between
                  card-header
                  bg-transparent
                  py-4
                  my-1
                  align-items-center
                  d-flex
                "
              >
                <h6 class="my-auto">{{ $t("NEWSLETTERPREFERENCES") }}</h6>
              </div>

              <div class="card-body">
                <p class="mt-2 text-muted" v-if="newsletter">
                  <i class="mdi mdi-check"></i>
                  {{ $t("newsletteractivated") }}
                </p>
                <p class="my-0 text-info" v-else>
                  {{ $t("notSubscribedToNewsletter") }}
                </p>
              </div>

              <div
                class="card-footer bg-transparent border-top-0 pt-0 pl-3"
                v-show="!newsletter"
              >
                <button
                  @click.prevent="$router.push({ name: 'newsletter' })"
                  class="btn btn-font-trans btn-sm"
                >
                  {{ $t("EDITNEWSLETTERPREFERENCES") }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Transactions -->
            <div class="card">
              <div
                class="
                  justify-content-between
                  card-header
                  bg-transparent
                  py-4
                  my-1
                  align-items-center
                  d-flex
                "
              >
                <h6 class="my-auto">{{ $t("TRANSACTIONS") }}</h6>
              </div>

              <div class="card-body">
                <ul class="list-group px-0">
                  <li class="list-group-item border-0 px-0 pt-0 pb-2">
                    <h6 class="mb-0">{{ $t("Purchases") }}</h6>
                    <small class="my-0 text-muted" v-if="orders_no === 0">{{
                      $t("Notransactionregistered")
                    }}</small>
                    <small class="my-0 text-muted" v-else-if="orders_no === 1"
                      >{{ orders_no }} {{ $t("transactionregistered") }}</small
                    >
                    <small
                      class="my-0 text-muted"
                      v-else-if="orders_no > 0 && orders_no > 1"
                      >{{ orders_no }} {{ $t("transactionregistered") }}</small
                    >
                  </li>
                  <li class="list-group-item border-0 px-0 py-2">
                    <h6 class="mb-0">{{ $t("cart_") }}</h6>
                    <small
                      class="my-0 text-muted"
                      v-if="cart_no >= 0 && cart_no > 1"
                      >{{ cart_no }} {{ $t("itemsadded") }}</small
                    >
                    <small class="my-0 text-muted" v-else-if="cart_no === 1"
                      >{{ cart_no }} {{ $t("itemsadded") }}</small
                    >
                    <small class="my-0 text-muted" v-else>{{
                      $t("Noitemadded")
                    }}</small>
                  </li>
                  <li class="list-group-item border-0 px-0 py-2">
                    <h6 class="mb-0">{{ $t("coupons") }}</h6>
                    <small class="my-0 text-muted" v-if="coupons_no < 1">{{
                      $t("Nocouponregistered")
                    }}</small>
                    <small class="my-0 text-muted" v-else
                      >{{ coupons_no }} {{ $t("couponsregistered") }}</small
                    >
                  </li>
                  <li class="list-group-item border-0 px-0 py-2">
                    <h6 class="mb-0">{{ $t("Comments") }}</h6>
                    <small class="my-0 text-muted" v-if="comments_no === 0">{{
                      $t("Nocommentregistered")
                    }}</small>
                    <small class="my-0 text-muted" v-else-if="comments_no === 1"
                      >{{ comments_no }} {{ $t("comment registered") }}</small
                    >
                    <small
                      class="my-0 text-muted"
                      v-else-if="comments_no > 0 && comments_no > 1"
                      >{{ comments_no }} {{ $t("commentsregistered") }}</small
                    >
                  </li>
                </ul>
              </div>

              <div class="card-footer bg-transparent border-top-0 pt-0 pl-3">
                <button
                  @ciick.prevent="$router.push('/')"
                  class="btn btn-font-trans btn-sm"
                >
                  {{ $t("GOSHOPPING") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      newsletter: false,
      username: "",
      email: "",
      orders: null,
      orders_no: 0,
      cart_no: 0,
      purchases_no: 0,
      coupons: null,
      coupons_no: 0,
      comments: null,
      comments_no: 0,
    };
  },

  methods: {
    ...mapActions([
      "getStatesAction",
      "getOrders",
      "getComments",
      "getCoupons",
      "getUser",
    ]),
    ...mapMutations({
      setNoCartItems: "setNoCartItems",
    }),
  },

  computed: {
    ...mapGetters([
      "getUserDetails",
      "getCachedOrders",
      "getStoredCoupons",
      "getStoredComments",
    ]),
    ...mapState({
      userDetails: "user",
      geoData: "geoData",
      allOrders: "ordersList",
      userComments: "userComments",
      userCoupons: "userCoupons",
    }),
  },

  created() {
    this.getUser().then((response) => {
      console.log("getUser response: ", response);
    });

    this.username = this.user_.username;
    this.email = this.user_.email;
    this.newsletter = this.user_.has_subscribe;

    console.log("orders info: ", this.orders_.data);

    if (this.orders_) {
      this.orders_.data.forEach((order) => {
        // this.orders_total += order.amount;

        if (order.status == "cart") {
          this.cart_no += order.order_item.length;
        }

        if (order.status == "complete") {
          this.orders_no += order.order_item.length;
        }
      });
    }
    // load number of coupons
    if (this.coupons) {
      this.coupons_no += this.coupons_.data.coupons
        ? this.coupons_.data.coupons.length
        : this.getStoredCoupons.data.coupons.length;
    } else {
      this.coupons_no = 0;
    }

    // load number of comments
    console.log("freaking comments: ", this.comments);
    if (this.comments) {
      this.comments_no += this.userComments.message.comments
        ? this.userComments.message.comments.length
        : this.getStoredComments.message.comments.length;
    }
  },

  beforeMount() {
    if (!this.orders_) {
      this.getOrders().then(() => {
        this.setNoCartItems();
      });
    }

    if (!this.coupons_) {
      this.getCoupons(this.user_).then(() => {
        this.coupons = this.coupons_.data.coupons.length
          ? this.coupons_.data.coupons
          : this.getStoredCoupons.data.coupons;

        console.log("Coupons payment: ", this.coupons);
      });
    }

    if (!this.comments_) {
      this.getComments(this.user_).then(() => {
        this.comments = this.comments_.message.comments
          ? this.comments_.message.comments
          : this.getStoredComments.message.comments;
      });
    }
  },

  mounted() {
    // this.getStatesAction({
    //   id: this.user_.id,
    // }).then(() => {
    //   console.log("GEO DATA index: ", this.geoData);
    // });
    // this.getOrders().then(() => {
    //   this.setNoCartItems();
    // });
  },
};
</script>

<style scoped>
a.btn > i {
  font-size: 1.3rem;
}
.btn.rnd {
  border-radius: 50%;
}
.btn.rnd:hover {
  background: #db9e3557;
}

.md-theme-default a:not(.md-button):hover {
  color: var(--afprimary) !important;
  text-decoration: none;
}
</style>
