import axios from "axios";
import TokenService from "./storage";

class ApiService {
  constructor() {
    let service = axios.create({
      baseURL: this.baseURL,
      timeout: 30000,
    });

    service.interceptors.request.use(
      (request) => {
        const token = TokenService.getToken();

        if (token) {
          request.headers.Authorization = `Bearer ${token}`;
          console.log("request headers:", request.headers);
        }

        return request;
      },
      (error) => {
        console.log("Intercepted API REQUEST error", error);
        return Promise.reject(error);
      }
    );

    service.interceptors.response.use(
      (response) => {
        console.log("response intercepted", response);
        return response;
      },
      (error) => {
        console.log("interceptor: ", error);
        if (error.code === "ECONNABORTED") {
          console.log("Intercepted error", error.code);
          window.alert(
            "Error!\nConnection to server timeout.\nMay be try again later"
          );
        }
        console.log("Intercepted error", error);

        console.log("intercepted error 2", Promise.reject(error));
        return Promise.reject(error);
      }
    );

    this.service = service;
  }

  setBaseURL(url) {
    this.baseURL = url;
  }

  // setHeader() {
  //   this.service.defaults.headers.common[
  //     "Authorization"
  //   ] = `Bearer ${TokenService.getToken()}`;
  //   this.service.defaults.headers.common["Content-Type"] = "application/json";
  //   // this.service.defaults.headers.common["Access-Control-Allow-Origin"] = true;

  //   console.log("Called SETHeader", this.service.defaults);
  // }

  // removeHeader() {
  //   this.service.defaults.headers.common = {};
  // }

  get(resource) {
    return this.service.get(resource);
  }

  post(resource, data) {
    return this.service.post(resource, data);
  }

  patch(resource, data) {
    return this.service.patch(resource, data);
  }

  put(resource, data) {
    return this.service.put(resource, data);
  }

  delete(resource) {
    return this.service.delete(resource);
  }

  /**
   * * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   **/

  customRequest(data) {
    console.log("custom request Data: ", data);

    if (data.method === "get") {
      return this.get(this.baseURL + data.url);
    }
    if (data.method === "post") {
      return this.post(this.baseURL + data.url, data.data);
    }
  }
}

export default ApiService;
