<template>
  <footer>
    <!-- Top footer -->
    <div class="container px-0 pb-0">
      <div class="row">
        <div class="col-md-5 col-sm-12 col-12 col-lg-5">
          <form action="" class="">
            <div class="form-group row px-3">
              <label
                for="emailLetter"
                class="
                  col-md-2 col-lg-2
                  d-sm-block d-none d-md-block d-lg-block
                  col-form-label
                  my-auto
                  text-capitalize
                "
                >{{ $t("newsletter") }}</label
              >
              <label
                for="emailLetter"
                class="
                  col-12
                  d-sm-none
                  text-center
                  d-block d-md-none d-lg-none
                  col-form-label
                  mb-n2
                  text-capitalize
                "
                >{{ $t("subscribeNewsletter") }}</label
              >
              <div class="col-sm-8 col-8 col-md-8 col-lg-8 pr-0">
                <input
                  type="email"
                  name="emailLetter"
                  id="emailLetter"
                  placeholder="Enter Email to get product updates"
                  class="form-control"
                />
              </div>

              <div class="col-sm-2 col-2 col-md-2 col-lg-2 px-0">
                <button class="btn btn-subscribe rounded-0 text-capitalize">
                  {{ $t("subscribe") }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-7 col-sm-12 col-12 col-lg-7 my-auto">
          <ul
            class="
              d-flex
              align-items-center
              list-unstyled
              social-links
              float-md-right float-lg-right
              justify-content-center
            "
          >
            <li class="mr-3">
              <a
                href="https://www.facebook.com/Afeshop-106498164278436"
                class="
                  btn btn-link btn-social-medi btn-dark
                  px-3
                  rounded-circle
                "
              >
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
            <li class="mr-3">
              <a
                href="#"
                class="
                  btn btn-link btn-social-medi btn-dark
                  px-2
                  rounded-circle
                "
              >
                <i class="fab fa-google"></i>
              </a>
            </li>
            <li class="mr-3">
              <a
                href="#"
                class="
                  btn btn-link btn-social-medi btn-dark
                  px-2
                  rounded-circle
                "
              >
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li class="mr-3">
              <a
                href="#"
                class="
                  btn btn-link btn-social-medi btn-dark
                  px-2
                  rounded-circle
                "
              >
                <i class="fab fa-youtube"></i>
              </a>
            </li>
            <li class="mr-">
              <a
                href="#"
                class="
                  btn btn-link btn-social-medi btn-dark
                  px-2
                  rounded-circle
                "
              >
                <i class="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Middle footer -->
    <div id="footer-middle" class="container px-0 pt-0">
      <div class="row">
        <div
          class="col-md-3 col-sm-6 col-12 text-center text-sm-left text-md-left"
        >
          <h6>{{ $t("companyInfo") }}</h6>
          <ul class="list-unstyled px-0 pt-1">
            <li>
              <a href="">{{ $t("aboutUs") }}</a>
            </li>
            <li>
              <a href="">{{ $t("topSearches") }}</a>
            </li>
            <li>
              <a href="">{{ $t("privacyPolicy") }}</a>
            </li>
            <li>
              <a href="">{{ $t("terms") }}</a>
            </li>
            <li>
              <a href="">{{ $t("testimonials") }}</a>
            </li>
            <li>
              <a href="">{{ $t("contactUs") }}</a>
            </li>
            <li>
              <a href="">{{ $t("reportIssue") }}</a>
            </li>
            <li>
              <a href="">{{ $t("sellOnAfeshop") }}</a>
            </li>
            <li>
              <a href="">{{ $t("afshpBrands") }}</a>
            </li>
          </ul>
        </div>
        <div
          class="col-md-3 col-sm-6 col-12 text-center text-sm-left text-md-left"
        >
          <h6>{{ $t("customerService") }}</h6>
          <ul class="list-unstyled px-0 pt-1">
            <li>
              <a href="">{{ $t("shippingInfo") }}</a>
            </li>
            <li>
              <a href="">{{ $t("myfavorites") }}</a>
            </li>
            <li>
              <a href="">{{ $t("faqNewsletter") }}</a>
            </li>
            <li>
              <a href="">{{ $t("myCoupons") }}</a>
            </li>
            <li>
              <a href="">{{ $t("Warranty") }}</a>
            </li>
          </ul>
        </div>
        <div
          class="col-md-3 col-sm-6 col-12 text-center text-sm-left text-md-left"
        >
          <h6>{{ $t("OtherActivities") }}</h6>
          <ul class="list-unstyled px-0 pt-1">
            <li>
              <a href="">{{ $t("Partnership") }}</a>
            </li>
            <li>
              <a href="">{{ $t("Associate") }}</a>
            </li>
          </ul>
        </div>

        <div
          class="col-md-3 col-sm-6 col-12 text-center text-sm-left text-md-left"
        >
          <h6>{{ $t("appComingSoon") }}</h6>
          <!-- <p class="mt-3">Save XFA500 with App &amp; New user Only</p> -->
          <div class="row">
            <!-- <div class="col-md-7 col-sm-6 col-7 pr-0 pr-md-0 pr-sm-1">
              <img
                src="@/assets/images/qrcode.jpg"
                class="img-fluid"
                width="100px"
                alt="QR code"
              />
            </div> -->

            <!-- <div
              class="col-md-5 col-sm-6 col-5 pl-0 pl-md-0 pl-sm-1 app-download"
            >
              <a href="" class="btn btn-dark btn-block apple"></a>
              <a href="" class="btn btn-dark btn-block google"></a>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- Lower footer -->
    <div class="container px-0 pt-0">
      <div class="row">
        <div class="col-md-12">
          <div class="text-center partners">
            <div class="item">
              <img src="@/assets/possa.png" class="img-fluid" alt="possa" />
            </div>
            <div class="item">
              <img src="@/assets/visa.jpg" class="img-fluid" alt="nogoutech" />
            </div>
            <div class="item">
              <img
                src="@/assets/mastercard.jpg"
                class="img-fluid"
                alt="nogoutech"
              />
            </div>
            <div class="item">
              <img src="@/assets/mtn_mm.jpg" class="img-fluid" alt="om" />
            </div>
            <div class="item">
              <img src="@/assets/om.png" class="img-fluid" alt="om" />
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div
            class="d-flex align-items-center justify-content-center partners"
          >
            <p class="text-center">{{ $t("Poweredby") }}</p>

            <div class="item">
              <img
                src="@/assets/nogoutech1.png"
                class="img-fluid"
                alt="possa"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Bottom footer -->
    <div id="bottom-footer" class="container-fluid px-0 pt-0">
      <div class="text-center">
        <p class="mb-0 py-2">
          Copyright &copy;Afeshop.africa. {{ $t("AllRightsReserved") }}
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      payMethods: [],
    };
  },
};
</script>

<style scoped>
footer {
  background: whitesmoke;
  font-size: 13px !important;
}

input[type="email"] {
  border: 1px solid black;
  border-radius: 0 !important;
}

input[type="email"]::placeholder {
  font-size: 14px;
}

.btn-subscribe {
  background: black !important;
  color: white !important;
}

.btn-subscribe:hover {
  color: #db9555 !important;
}

.btn-social-media {
  border-radius: 50%;
  background: #000000d9;
}

.social-links a:not(.md-button) {
  color: #ffffff !important;
}

#footer-middle ul.list-unstyled a:not(.md-button) {
  color: #534f59 !important;
  font-size: 13px;
}

.app-download .btn.apple {
  background: url(../../assets/images/apple-store.png);
  display: block;
  width: 128px;
  height: 46px;
}

.app-download .btn.google {
  background: url(../../assets/images/google-play.png);
  display: block;
  width: 128px;
  height: 46px;
}

label[for="emailLetter"] {
  font-weight: 500;
  font-size: 14px;
  margin-top: 5px;
}

.partners .item {
  margin: 0 5px 5px;
  height: 36px;
  display: inline-block;
  vertical-align: middle;
}

.partners img.img-fluid {
  object-fit: contain;
  /* width: 3em !important; */
  width: 61px !important;
  height: 31px !important;
}

#bottom-footer {
  background: black;
  color: white;
}
</style>
