<template>
  <div class="container py-5 my-5">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6 px-0">
        <h4 class="color-font">Thank You!</h4>

        <div class="d-flex justify-content-left mt-5 pt-2 align-items-center">
          <span class="pr-5">
            <i class="mdi mdi-checkbox-marked-circle"></i>
          </span>
          <p class="font-15 mb-0">{{ paymentInfo }}</p>
        </div>

        <div class="pt-5">
          <button
            class="
              mt-2
              btn btn-lg
              shadow
              btn-block btn-blue
              rounded-0
              d-flex
              justify-content-left
              align-items-baseline
            "
            @click.prevent="$router.push({ name: 'Home' })"
          >
            <span class="font-16">
              <i class="mdi mdi-home"></i>
            </span>

            <span class="font-15 mx-auto pr-4">{{ $t("GoHome") }}</span>
          </button>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
    <!-- We have sent you an e-mail with specific instructions on how to reset your password. Please check your inbox and click on the link provided. -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      paymentInfo:
        "Thanks for doing business with us! We'll be in touch shortly...",
    };
  },
  computed: {
    // ...mapState(["paymentInfo"]),
  },
};
</script>

<style scoped>
.mdi-checkbox-marked-circle::before {
  color: #14c314;
  font-size: 2em;
}
</style>
