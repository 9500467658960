import { UserService, EndPointError } from "@/services/user";

const state = {
  countryStates: null,
  updateError: null,
  EndPointErrorCode: 0,
  EndPointError: "",
};

const mutations = {
  SET_STATES_DATA(state, payload) {
    state.countryStates = payload;
    localStorage.setItem("geo_data", JSON.stringify(payload));
    console.warn("set geo data", state.countryStates);
  },

  updateError(state, { errorCode, errorMessage }) {
    state.updateError = { code: errorCode, msg: errorMessage };
  },
};

const getters = {
  getStatesData: (state) => state.countryStates,

  EndPointError: (state) => state.EndPointError,
};

const actions = {
  async getStatesAction({ commit }, countryId) {
    try {
      const response = await UserService.getStates(countryId);

      commit("SET_STATES_DATA", response);

      console.log("STATES DATA: ", response);
      return response;
    } catch (e) {
      return e;
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
