import { render, staticRenderFns } from "./SuperDeals.vue?vue&type=template&id=be66593a&scoped=true&"
import script from "./SuperDeals.vue?vue&type=script&lang=js&"
export * from "./SuperDeals.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be66593a",
  null
  
)

export default component.exports