<template>
  <div>
    <div class="contenair-fluid pt-8">
      <div
        class="row justify-content-md-center justify-content-lg-center justify-content-center"
      >
        <div class="col col-lg-5 col-md-6 col-8 msg">
          <span>Connectez-vous a votre boutique</span>
        </div>
      </div>
      <div
        class="row justify-content-md-center justify-content-lg-center justify-content-center"
      >
        <div class="col col-lg-5 col-md-6 col-8 form">
          <form>
            <div
              class="form-group row justify-content-md-center justify-content-lg-center justify-content-center"
              :class="{ 'form-group--error': $v.shop.email.$error }"
            >
              <div class="col-sm-10">
                <input
                  type="email"
                  id="email"
                  class="form-control-plaintext input"
                  placeholder="enter your e-mail"
                  v-model.trim="$v.shop.email.$model"
                />
                <p
                  class="erreur"
                  v-if="!$v.shop.email.required && $v.shop.email.$dirty"
                >
                  Please enter your email
                </p>
                <p class="erreur" v-if="!$v.shop.email.email">
                  enter valid email
                </p>
              </div>
            </div>
            <div
              class="form-group row justify-content-md-center justify-content-lg-center justify-content-center"
              :class="{ 'form-group--error': $v.shop.password.$error }"
            >
              <div class="col-sm-10">
                <input
                  type="password"
                  id="password"
                  class="form-control-plaintext input"
                  placeholder="enter your password"
                  v-model.trim="$v.shop.password.$model"
                />
                <p
                  class="erreur"
                  v-if="!$v.shop.password.required && $v.shop.password.$dirty"
                >
                  password required
                </p>
              </div>
            </div>
            <button type="submit" class="btn">validate</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  // minLength,
  // between,
  email,
  // sameAs,
} from "vuelidate/lib/validators";
export default {
  data() {
    return {
      shop: {
        email: "",
        password: "",
      },
    };
  },
  validations: {
    shop: {
      email: { required, email },
      password: { required },
    },
  },
};
</script>

<style scoped>
.msg {
  height: 80px;
  font-size: 15px;
  background-color: #76c2f5;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.msg span {
  display: block;
  color: white;
  text-align: center;
  line-height: 15px;
  font-size: 2.5vw;
  font-weight: bolder;
}
.input {
  border: 1px solid white;
  width: 90%;
  outline: none;
  text-align: center;
  font-weight: 500;
  border-radius: 3px;
  background-color: white;
}
.erreur {
  color: red;
}
.btn {
  background-color: #76c2f5;
  width: 60%;
  height: 45px;
  margin-left: 16%;
  color: white;
  font-weight: bolder;
}
.form {
  background-color: #f2f2f2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
