<template>
  <div class="container">
    <PopDown />

    <div class="row mb-12" :class="isMobile ? '' : 'pb-5'">
      <div
        class="col-md-12 col-lg-3 d-block d-lg-block d-md-block"
        :class="isMobile ? 'px-3' : 'pl-0 '"
      >
        <CustomerNav v-if="!isMobile" />
        <CustomerNavMobile v-else />
      </div>

      <!-- Details Panel -->
      <div
        class="col-md-12 col-lg-9 col-12 pr-md-0 px-0 panel-view py-6"
        v-show="!isMobile"
      >
        <router-view></router-view>
      </div>
    </div>

    <Recommended :data="recommended" v-if="!showAside" />
  </div>
</template>

<script>
import CustomerNav from "@/components/customer/CustomerNav.vue";
import CustomerNavMobile from "@/components/customer/CustomerNavMobile.vue";
import Recommended from "@/components/home/Recommended";
import PopDown from "@/components/widgets/PopDown";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    CustomerNav,
    CustomerNavMobile,
    Recommended,
    PopDown,
  },
  data() {
    return {
      recommended: [],
      showAside: false,
      // message: null,
    };
  },
  methods: {
    ...mapActions(["getGuestData", "getOrders", "getUser"]),
    ...mapMutations(["setNoCartItems"]),
  },
  computed: {
    ...mapGetters(["loggedIn", "getUserDetails", "getGuestDataObj"]),

    isMobile() {
      return this.$device.mobile;
    },
  },

  created() {
    if (!this.user_) {
      this.getUser().then(() => {
        console.log("getting the User: ", this.user_);
      });
    }

    console.log("this device: ", this.isMobile);
  },

  beforeMount() {
    if (!this.user_) {
      this.getUser().then(() => {
        console.log("getting the User: ", this.user_);
      });
    }
  },

  mounted() {
    this.getGuestData().then((response) => {
      this.recommended = this.getGuestDataObj.recommended_items;
    });

    this.$root.$on("close_sidebar", (val) => {
      this.showAside = val;
    });
  },
};
</script>

<style scoped>
.panel-view {
  margin: 0;
  display: flex;
}
@media (max-width: 991px) {
  .panel-view {
    top: 5em;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
