<template>
  <div
    class="row subcategories shadow-sm bg-white card rounded-0 border-0"
    v-show="active"
    @mouseover="setActive"
    @mouseleave="deactivate"
  >
    <div class="col-md-4" v-for="child in children" :key="child.id">
      <div>
        <h6 class="border-bottom py-2 text-capitalize">{{ child.name }}</h6>
        <ul class="list-group px-0 mb-4">
          <li
            class="list-group-item border-0 py-1 px-0"
            v-for="subs in child.child"
            :key="subs.id"
          >
            <router-link
              :to="{
                name: 'category',
                params: {
                  parentSlug: child.slug,
                  childSlug: subs.slug,
                  catId: subs.id,
                  parent: child,
                  child: subs,
                },
              }"
              >{{ subs.name }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    ...mapMutations({
      setActive: "SET_ACTIVE_CATEGORY",
      deactivate: "UNSET_ACTIVE_CATEGORY",
      truncateCats: "truncateCategories",
    }),

    setChild() {
      this.$store.commit("truncateCategories");
    },
  },

  computed: {
    ...mapGetters(["getCurrentCategory", "getTruncatedCats"]),

    currentCategory() {
      return this.getCurrentCategory;
    },

    children() {
      this.setChild();

      return this.getTruncatedCats;
    },
  },

  created() {
    this.setChild();

    console.log("Children: ", this.children);
  },
};
</script>

<style scoped>
.card.subcategories {
  width: 64.5em;
}
.subcategories {
  position: absolute;
  top: 12px;
  z-index: 10;
  width: 65.5%;
  right: 0;
  left: 18em;
  height: 33.5em;
}
h6 {
  font-size: 0.9rem;
}
.list-group-item {
  font-size: 0.8rem;
}

.md-theme-default a:not(.md-button) {
  color: #495057 !important;
}

.md-theme-default a:not(.md-button):hover {
  color: #000 !important;
  font-weight: 500;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6a1 !important;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 1296px) {
}
</style>
