<template>
  <div>
    <BaseContent />
  </div>
</template>

<script>
// import Header from "@/components/Header.vue";
import BaseContent from "@/components/home/BaseContent.vue";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";

export default {
  components: {
    BaseContent,
  },
  data() {
    return {
      s_token: "",
    };
  },

  methods: {
    ...mapActions(["getUser", "socialAuthenticate"]),
    ...mapMutations(["loginSuccess2"]),
  },

  computed: {
    ...mapState(["intlPrdts"]),
    ...mapGetters(["getIntlProdts"]),
  },

  created() {},

  mounted() {
    console.log("social query", this.$route.query);
    if (this.$route.query.username) {
      this.s_token = this.$route.query.redirect_id;

      this.socialAuthenticate(this.s_token).then((resp) => {
        console.log("soclalAuth Indexjs", resp);
      });
    }
  },
};
</script>
