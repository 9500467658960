var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.newProducts),expression:"newProducts"}],staticClass:"row mt-5 mb-3",attrs:{"id":"newProducts"}},[_c('div',{staticClass:"col-md-12 px-0 py-auto"},[_c('div',{staticClass:"\n        col-md-12\n        px-0\n        section-head\n        d-flex\n        justify-content-between\n        align-items-center\n      "},[_c('h3',{staticClass:"sectionTitle my-0 px-md-0 px-lg-0 pl-3 pr-2"},[_c('i',{staticClass:"common new_products"}),_vm._v(" "+_vm._s(_vm.$t("newPrdts"))+" ")])]),(_vm.products.length > 0)?_c('div',[_c('carousel',{staticClass:"\n          mx-0\n          py-md-auto py-lg-auto py-0\n          my-0 my-md-auto my-lg-auto\n          card\n          slidecard\n          border-0\n        ",attrs:{"id":"newProducts-crs","items":5,"dots":false,"margin":2,"padding":130,"autoplay":false,"autoplaySpeed":2000,"nav":false,"loop":true,"rewind":true,"responsive":{
          1200: { items: 5, nav: false },
          960: { items: 4, nav: false },
          768: { items: 3, nav: false },
          500: { items: 2, nav: false },
          400: { items: 2, nav: false },
          0: { items: 2, nav: false },
        }}},[_c('template',{slot:"prev"},[_c('button',{staticClass:"rounded owl-prev"},[_c('i',{staticClass:"mdi mdi-chevron-left"})])]),_vm._l((_vm.products),function(product){return _c('div',{key:product.id},[_c('router-link',{staticClass:"\n              card\n              h-100\n              prdtCard\n              rounded\n              mx-lg-1 mx-md-1 mx-sm-auto mx-0\n            ",attrs:{"to":{
              name: 'newproduct-detail',
              params: { slug: product.slug, prdtId: product.id },
            }}},[_c('div',{staticClass:"prdtImage"},[_c('img',{staticClass:"img-fluid p-2",attrs:{"src":product.image_url},on:{"error":_vm.defaultImage}})]),(product.item_discount)?_c('div',{staticClass:"prdtDiscount"},[_c('strong',{staticClass:"my-auto"},[_vm._v(_vm._s(_vm._f("percent")(product.item_discount.percentage_decrease)))]),_c('p',{staticClass:"mb-0 mt-n1 text-center"},[_vm._v(_vm._s(_vm.$t("percentOff")))])]):_vm._e(),_c('div',{staticClass:"\n                card-footer\n                pt-1\n                bg-transparent\n                rounded-0\n                border-0\n                px-3\n                pb-6\n              "},[_c('router-link',{attrs:{"to":{
                  name: 'newproduct-detail',
                  params: { slug: product.slug, prdtId: product.id },
                }}},[_c('h6',{staticClass:"prdtName text-capitalize text-truncate"},[_vm._v(" "+_vm._s(product.name)+" ")])]),(product.item_discount)?_c('div',{staticClass:"mt-4 mb-1"},[_c('p',{staticClass:"text-muted mb-0 rrp text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("rrp"))+": "),_c('span',[_c('strike',[_vm._v(_vm._s(_vm._f("currency")(product.price,"XFA ")))])],1)]),(product.item_discount)?_c('p',{staticClass:"color-font priceTag mb-0 mt-1"},[_vm._v(" "+_vm._s(_vm._f("currency")(product.item_discount.unit_price,"XFA "))+" ")]):_vm._e()]):_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"color-font priceTag mb-0 mt-1"},[_vm._v(" "+_vm._s(_vm._f("currency")(product.price,"XFA "))+" ")])])],1)])],1)}),_c('template',{slot:"next"},[_c('button',{staticClass:"rounded owl-next"},[_c('i',{staticClass:"mdi mdi-chevron-right"})])])],2)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }