<template>
  <div class="container">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="var(--afprimary)"
      :height="height"
      :width="width"
      :transition="transition"
      background-color="#000"
    ></loading>

    <!-- Breadcrumbs -->
    <!-- <v-breadcrumbs :items="routeItems" class="bc py-1 px-0">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs> -->

    <!-- Main Section -->
    <listing-widget
      :widgetName="'Intl Products'"
      :widgetImage="require('@/assets/images/cameroon.jpg')"
      :widgetInfo="info"
      :banner="true"
      :colors="colors"
      :categories="categories"
      :products="intlProducts"
    ></listing-widget>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import ListingWidget from "@/components/widgets/GenericListing.vue";

export default {
  components: {
    Loading,
    ListingWidget,
  },
  data() {
    return {
      colors: ["red", "green", "#ec9b0a", "black"],
      categories: [
        {
          id: 42,
          name: "Books",
        },
        {
          id: 2,
          name: "Art",
        },
        {
          id: 3,
          name: "Music",
        },
        {
          id: 12,
          name: "Home",
        },
        {
          id: 10,
          name: "Appliances",
        },
        {
          id: 9,
          name: "Sports",
        },
        {
          id: 1,
          name: "Computers",
        },
        {
          id: 8,
          name: "Kitchen",
        },
      ],
      products: [],
      productInfo: null,
      disable: false,
      page: 1,
      last_page: 0,
      pageSize: 5,
      loading: true,
      height: 80,
      width: 100,
      transition: "fade",
      shopInfo: null,
      intlProducts: [],
      categoriesList: null,
      overseaCountries: [
        "Cameroon",
        "Nigeria",
        "Benin",
        "France",
        "USA",
        "UK",
        "China",
      ],
      headings: ["Latest Products", "Super Deals", "All"],
      sortList: [
        "Alphabetical",
        "Newest",
        "Best Selling",
        "Price Ascending",
        "Price Descending",
      ],
      discounts: [],
      selectedCriteria: "",
      rtext:
        " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam, ad, adipisicing elit. Quam, ad, adipisicing elit. Quam, ad!",
      gridView: true,
      listView: false,
      selectedDiscount: null,
      countryInfo: null,

      routeItems: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: this.$route.meta.title,
          disabled: true,
          href: this.$route.path,
        },
      ],
    };
  },

  methods: {
    ...mapActions([
      "addToCart",
      "getGuestData",
      "getOrders",
      "getShopDetails",
      "getShopCategories",
      "getIntlProducts",
    ]),

    newPrice(price, discount) {
      return price - discount * price;
    },

    changeSelectSort({ data, text }) {
      console.log("Change Select criteria: ", data);
      this.selectedCriteria = text;
    },

    showGrid() {
      if (this.gridView) {
        this.gridView = false;
        this.listView = true;
      } else {
        this.gridView = true;
        this.listView = false;
      }
    },

    showList() {
      if (this.listView) {
        this.gridView = true;
        this.listView = false;
      } else {
        this.gridView = false;
        this.listView = true;
      }
    },
  },

  computed: {
    ...mapGetters([
      "loggedIn",
      "getUserDetails",
      "getGuestDataObj",
      "getShop",
      "getIntlCtries",
      "getIntlProdts",
    ]),
    ...mapState(["cart_no", "shop", "intlCountries", "intlPrdts"]),

    info() {
      return {
        title: this.countryInfo.name + " products",
        description:
          "A collection of products from our partner stores from " +
          this.countryInfo.name,
        min: 500,
        max: 1000,
      };
    },
  },

  created() {
    // this.loading = true;

    $(".list-card").on("hover", () => {
      console.log("list card hovereth");
    });

    console.log("Selected discount", this.selectedDiscount);

    this.loading = true;

    this.getIntlProducts({
      short_name: this.$route.params.short_name,
    }).then(() => {
      this.intlProducts = this.intlPrdts
        ? this.intlPrdts.items.data
        : this.getIntlProdts.items.data;

      console.log("International Products afshop: ", this.getIntlProdts);
      console.log("International Products afshop 2: ", this.intlProducts);

      var countries = this.intlCountries
        ? this.intlCountries.countries
        : this.getIntlCtries.countries;

      countries.forEach((country) => {
        if (country.short_name === this.$route.params.short_name) {
          this.countryInfo = country;
        }
      });

      // console.log("Country Infor: ", this.countryInfo);
      this.loading = false;
    });
  },

  mounted() {
    this.loading = true;

    $(".list-card.card").hover(
      function () {
        // console.log("list card hovered");
        $(this).addClass("shadow").css("cursor", "pointer");
      },
      function () {
        $(this).removeClass("shadow");
      }
    );
  },
};
</script>

<style scoped>
/* input[type="radio"] {
  display: none;
}

input[type="radio"] + label:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 6px;
  margin-right: 3px;
  background-clip: content-box;
  border: 2px solid #bbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

input[type="radio"]:checked + label:before,
.overseaCountries input[type="radio"]:checked + label:before {
  background-color: var(--afprimary);
  border: 1.5px solid var(--afprimary);
}

.overseaCountries {
  height: 10em;
  overflow-y: auto;
}
.overseaCountries input[type="radio"] + label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 5px;
  margin-right: 3px;
  background-clip: content-box;
  border: 2px solid #bbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

label {
  display: flex;
  align-items: center;
}

a.btn:not(.md-button) {
  color: #f1921d !important;
}

a.btn.btn-update:hover {
  background: black !important;
}
.prdtCard {
  height: 23.8em;
  height: 388px;
  display: block;
  position: relative;
  transition: all 0.2s linear;
}

.color-font {
  color: #f1921d !important;
}

a:hover > .prdtName,
a:hover > .prdtName2 {
  color: #ff8100 !important;
}

.flash-illus {
  width: 100px;
  margin-top: -1em;
  display: flex;
}

.prdtCard:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-color: #e0e0e0;
}

.prdtImage .img-fluid {
  width: 100%;
  height: 17.5em;
  object-fit: cover;
}

img.prdtImage2.img-fluid {
  width: 100%;
  height: 14.5em;
  object-fit: cover;
}

.prdtName {
  font-size: 15px;
  font-weight: normal;
}

.prdtName2 {
  font-size: 15px;
  font-weight: normal;
  width: 30em;
}

.item_detail {
  line-height: 20px;
}

.item_delete {
  margin-top: 2px;
  margin-bottom: 4px;
  height: 28px;
}

.delPrice {
  display: inline-block;
  width: 100%;
  height: 28px;
  font-size: 12px;
  color: #999;
  text-decoration: none;
}

.priceTag {
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  line-height: 20px;
  height: 20px;
}

.rrp {
  display: inline-block;
  line-height: 14px;
}

.rrp2 {
  font-size: 0.9rem;
}

.prdtDiscount {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 10px;
  width: 50px;
  height: 50px;
  background-color: #ffa300;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}

.prdtDiscount2 {
  position: absolute;
  right: 26px;
  top: 10px;
  padding: 10px;
  width: 49px;
  height: 50px;
  background-color: #ffa300;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}

.prdtDiscount > strong {
  font-size: 15px;
}

.prdtDiscount > em {
  font-size: 12px;
}

.prdtDiscount > p {
  font-size: 11px;
}
a.card {
  color: black !important;
}

a > .prdtName,
a > .prdtName2 {
  color: black !important;
}

#cat-list {
  height: 10em;
  overflow-y: auto;
}

#discount-list {
  height: 9em;
  overflow-y: auto;
}

.bootstrap-select {
  width: 8.7rem !important;
}

.list-card.card.shadow {
  transition: box-shadow 1s !important;
} */
</style>
