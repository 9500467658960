<template>
  <div id="editUser" class="row">
    <div class="col-md-12 py-0">
      <div class="card pane border-0 shadow-sm bg-white py-4 px-5">
        <!-- Header -->
        <div class="card-header border-0 bg-transparent pl-3">
          <div class="d-flex align-items-center">
            <button @click="$router.go(-1)" class="btn btn-lg pl-0 text-dark">
              <i class="mdi mdi-arrow-left h5"></i>
            </button>
            <h4>{{ $t("Details") }}</h4>
          </div>
          <p class="text-danger font-12 mb-0" v-if="errorMsg">
            {{ errorMsg }}
          </p>
        </div>

        <!-- Content -->
        <div class="row px-2 mt-n5">
          <div class="col-md-12">
            <form action>
              <div class="row px-2">
                <div class="col-md-12">
                  <!-- Profile Info -->
                  <div
                    class="
                      profileSummary
                      row
                      my-3
                      align-items-center
                      jusitify-content-end
                    "
                  >
                    <div class="col-6 d-flex align-items-center">
                      <!-- Profile Image -->
                      <div class="py-auto my-auto text-">
                        <img
                          v-if="!imageUrl"
                          :src="user.picture"
                          alt="profile picture"
                          class="img-fluid"
                        />
                        <img
                          v-else
                          :src="imageUrl"
                          alt="profile picture"
                          class="img-fluid"
                        />

                        <div class="p-image">
                          <button
                            class="upload-button color-font"
                            @click.prevent="triggerFileUpload"
                          >
                            <i class="fa fa-camera"></i>
                          </button>
                          <input
                            class="file-upload"
                            type="file"
                            accept="image/*"
                            ref="picUpload"
                            @change="onFileChange"
                          />
                        </div>
                      </div>

                      <div class="profileInfo mx-5">
                        <h6 class="font-15 mb-1">
                          {{ $t("Username") }}:
                          <span class="color-font"> {{ form.username }}</span>
                        </h6>

                        <div class="mb-0 d">
                          <h6 class="font-15 mb-1">
                            {{ $t("FullName") }}:
                            <span
                              >{{ form.firstName }} {{ form.lastName }}</span
                            >
                          </h6>
                        </div>
                      </div>
                    </div>

                    <div class="col-6">
                      <div class="profileInfo">
                        <div class="mb-0">
                          <h6 class="font-15 mb-1">
                            {{ $t("E-mail") }}:
                            <span>{{ form.email }}</span>
                          </h6>
                        </div>
                        <div class="mb-0">
                          <h6 class="font-15 mb-1">
                            {{ $t("Country") }}:
                            <span>{{ form.country.split("(")[0] }}</span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- First Name and Last Name  -->
                  <div class="form-group row my-0">
                    <div class="col-12 pt-0 col-md-6">
                      <md-field
                        :class="[
                          formTouched && $v.form.firstName.$error
                            ? 'md-invalid'
                            : '',
                        ]"
                      >
                        <label for="firstName">{{ $t("FirstName") }}</label>
                        <md-input
                          name="firstName"
                          id="firstName"
                          v-model="$v.form.firstName.$model"
                          required
                        ></md-input>

                        <div v-if="errors">
                          <span
                            class="mb-0 md-error"
                            v-if="!$v.form.firstName.minLength"
                            >{{ $t("notLessThan4") }}</span
                          >
                        </div>
                      </md-field>
                    </div>

                    <div class="col-12 pt-0 col-md-6">
                      <md-field
                        :class="[
                          formTouched && $v.form.lastName.$error
                            ? 'md-invalid'
                            : '',
                        ]"
                      >
                        <label for="lastName">{{ $t("LastName") }}</label>
                        <md-input
                          name="lastName"
                          id="lastName"
                          v-model="$v.form.lastName.$model"
                        ></md-input>
                        <div v-if="errors">
                          <span
                            class="mb-0 md-error"
                            v-if="!$v.form.lastName.minLength"
                            >{{ $t("nameAtLeast3") }}</span
                          >
                        </div>
                      </md-field>
                    </div>
                  </div>

                  <!-- Email and Phone Fields -->
                  <div class="form-group mb-0">
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <md-field
                          :class="[
                            formTouched && $v.form.email.$error
                              ? 'md-invalid'
                              : '',
                          ]"
                        >
                          <label for="email">{{ $t("E-mail") }}</label>
                          <md-input
                            name="email"
                            type="email"
                            id="email"
                            required
                            v-model="$v.form.email.$model"
                          ></md-input>
                          <div v-if="$v.form.email.$error">
                            <span class="mb-0 md-error">{{
                              $t("InvalidE-mail")
                            }}</span>
                          </div>
                        </md-field>
                      </div>

                      <div class="col-12 col-md-6">
                        <label>{{ $t("Telephone") }}</label>
                        <vue-tel-input
                          v-bind="bindProps"
                          v-on:country-changed="countryChanged"
                          v-model="$v.form.phoneNumber.$model"
                          @validate="validatePhone"
                        ></vue-tel-input>

                        <div
                          v-if="!validPhone"
                          class="invalid-feedback d-block"
                        >
                          <p class="mb-0 mt-n3" v-if="!validPhone">
                            {{ $t("invalidPhone") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Username and Country Fields -->
                  <div class="form-group mb-0">
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <md-field
                          :class="[
                            formTouched && $v.form.username.$error
                              ? 'md-invalid'
                              : '',
                          ]"
                        >
                          <label for="username">{{ $t("Username") }}</label>
                          <md-input
                            name="username"
                            id="username"
                            v-model="$v.form.username.$model"
                            required
                          ></md-input>

                          <div v-if="errors">
                            <span
                              class="mb-0 md-error"
                              v-if="!$v.form.username.minLength"
                              >{{ $t("nameAtLeast5") }}</span
                            >
                            <span
                              class="mb-0 md-error"
                              v-if="!$v.form.username.required"
                              >{{ $t("fistNameRequired") }}</span
                            >
                          </div>
                        </md-field>
                      </div>

                      <div class="col-12 col-md-6">
                        <md-field>
                          <label>{{ $t("Country") }}</label>
                          <md-input
                            v-model="$v.form.country.$model"
                            readonly
                          ></md-input>
                        </md-field>
                      </div>
                    </div>
                  </div>

                  <button
                    @click.prevent="submitForm"
                    class="btn btn-block btn-orange shadow py-2 my-3"
                  >
                    {{ $t("SAVE") }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/customer/edit/edit.css";

import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import { required, email, minLength } from "vuelidate/lib/validators";
import PopDown from "@/components/widgets/PopDown";

export default {
  components: { PopDown },
  data() {
    return {
      form: {
        firstName: "",
        lastName: "",
        email: "",
        country: "",
        countryCode: "",

        username: "",
        phoneNumber: "",
      },
      bindProps: {
        mode: "international",
        // placeholder: "Phone Number (optional)",
        dynamicPlaceholder: true,
        required: false,
        enabledCountryCode: true,
        enabledFlags: true,
        autocomplete: "off",
        name: "telephone",
        maxLen: 25,
        inputOptions: {
          showDialCode: true,
        },
        validCharactersOnly: true,
        wrapperClasses: ["form-group"],
        inputClasses: "form-control is-invalid",
      },

      user: null,
      states: [],
      cities: [],
      stateSet: true,
      subCities: [],
      user_country: null,
      errorMsg: "",

      errors: false,
      formTouched: false,

      imageUrl: "",
      file: null,
      formData: null,
      countryName: "",
      countryObject: null,

      validPhone: false,
      errorList: [],
    };
  },

  validations: {
    form: {
      firstName: {
        minLength: minLength(4),
        required,
      },
      lastName: {
        minLength: minLength(4),
        required,
      },
      phoneNumber: {
        required,
      },
      email: {
        required,
        email,
      },

      country: {
        required,
      },
      username: {
        required,
        minLength: minLength(5),
      },
    },
  },

  methods: {
    ...mapActions(["update", "logout", "getStatesAction"]),
    ...mapMutations({
      setUserData: "SET_USER_DATA",
      updateSuccess: "updateSuccess",
    }),

    countryChanged(country) {
      this.form.country = country.name;
      this.form.countryCode = country.iso2;
    },

    validatePhone({ number, isValid, country }) {
      this.validPhone = isValid;
      console.log("validatePhone: ", number, isValid, country);
      if (isValid) {
        this.bindProps.inputClasses = "form-control is-valid";
      } else {
        this.bindProps.inputClasses = "form-control is-invalid";
      }
    },

    triggerFileUpload() {
      this.$refs.picUpload.click();

      console.log("click upload triggered");
    },

    onFileChange(e) {
      this.file = e.target.files[0];
      this.imageUrl = URL.createObjectURL(this.file);

      console.log("file object: ", this.file.name);
      console.log("image file: ", this.imageUrl);

      this.formData = new FormData();
      // formData.append("file name", file.name);
      this.formData.append("avatar", this.file, this.file.name);

      console.log("formData:", this.formData.getAll("avatar"));
    },

    formUpdate() {
      var userUpdateData = {
        name: this.form.firstName + " " + this.form.lastName,
        id: this.user.id ? this.user.id : Math.random(2000),
        username: this.form.username,
        email: this.form.email,
        phone: this.form.phoneNumber,
        country_id: this.country_id,
        picture: this.file ? this.formData.getAll("avatar") : this.user.picture,
      };

      console.log("form update data: ", userUpdateData);

      this.update(userUpdateData).then((response) => {
        console.log("update response: ", response);

        if (response.code == "PROFILE_UPDATE") {
          this.setUserData(response.data.user);

          console.log("update progress", response.data);
          this.updateSuccess(response.data);

          this.logout();
        } else {
          this.errorMsg = response.data.message;
          console.warn("update trouble", response);
        }
      });
    },

    submitForm() {
      this.$v.$touch();
      this.submitRequest = true;

      this.formTouched = this.$v.form.$anyDirty;
      this.errors = this.$v.form.$anyError;
      console.log("entered");

      if (this.errors === false && this.formTouched === true) {
        console.log("form valid");
        console.log("username: ", this.form.username);

        this.formUpdate();
      }
    },

    setCities() {
      this.stateSet = false;
      if (!this.form.state) {
        this.form.state = this.user.city.state_id;
      }
      console.log("form state", this.form.state);

      this.states.forEach((state) => {
        if (state.id == this.form.state) {
          this.cities = state.cities;
        }
      });
    },

    async getCountryName() {
      console.log("waiting for form country");
      while (this.form.country == "") {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      return this.form.country;
    },
  },

  computed: {
    ...mapGetters(["getUserDetails", "getUpdateError", "getStatesData"]),
    ...mapState({ userDetails: "user", geoData: "geoData" }),
  },

  created() {
    this.user = this.user_;
    console.log("User details: ", this.user);

    this.form.firstName = this.user.name ? this.user.name.split(" ")[0] : "";
    this.form.lastName = this.user.name ? this.user.name.split(" ")[1] : "";
    this.form.email = this.user.email;
    this.form.phoneNumber = this.user.phone ? this.user.phone : "";
    this.form.username = this.user.username;
    if (this.user.city != null) {
      this.form.city = this.user.city.id;
      this.form.state = this.user.city.state_id;
    }

    console.log("User Info", this.user);

    console.log("geo city", this.form.city);
    console.log("geo state", this.form.state);
  },

  mounted() {
    this.getCountryName().then((response) => {
      console.log("data response", response);
      this.countryName = this.form.country.split("(")[0].trim();
      this.countryObject = this.getCountryObject(this.countryName);
      this.country_id = this.countryObject.id;
      console.log("User Info Ctry:", this.countryObject);
    });
  },
};
</script>

<style scoped></style>
