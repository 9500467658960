<template>
  <div class="price-range">
    <div class="range-slider py-3 mb-7">
      <input type="range" :min="min" :max="max" step="50" v-model="sliderMin" />
      <input type="range" :min="min" :max="max" step="50" v-model="sliderMax" />
    </div>

    <div class="position-relative mb-8">
      <div class="price float-left">
        <span class="mr-1">{{ $t("Price") }}:</span>
        <span class="min"
          >{{ sliderMin | currency("", 0, { thousandsSeparator: "," }) }}
        </span>
        <span>&nbsp;—&nbsp;</span>
        <span class="max"
          >{{ sliderMax | currency("", 0, { thousandsSeparator: "," }) }}
        </span>
        <span>&nbsp;XFA</span>
      </div>
      <!-- <button
        class="btn-orange btn shadow-sm filter-btn float-right float-right"
      >
        {{ $t("Filter") }}
      </button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceRangeSlider",

  props: {
    trackHeight: {
      type: Number,
      deafult: 1,
    },

    min: {
      type: Number,
      required: true,
    },

    max: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      minAngle: this.min,
      maxAngle: this.max,
    };
  },

  computed: {
    sliderMin: {
      get: function () {
        var val = parseInt(this.minAngle);

        return val;
      },
      set: function (val) {
        val = parseInt(val);

        if (val > this.maxAngle) {
          this.maxAngle = val;
        }
        this.minAngle = val;
        this.$root.$emit("currentMin", this.minAngle);
      },
    },
    sliderMax: {
      get: function () {
        var val = parseInt(this.maxAngle);

        return val;
      },
      set: function (val) {
        val = parseInt(val);
        if (val < this.minAngle) {
          this.minAngle = val;
        }
        this.maxAngle = val;
        this.$root.$emit("currentMax", this.maxAngle);
      },
    },
  },

  beforeUpdate() {
    if (
      this.minAngle === Infinity ||
      this.maxAngle === Infinity ||
      this.minAngle === JSON.stringify(undefined) ||
      this.maxAngle === JSON.stringify(undefined) ||
      localStorage.getItem("maxAngle") === "-Infinity" ||
      localStorage.getItem("minAngle") === "Infinity"
    ) {
      console.log("min max angle ---", this.$props.min, this.$props.max);
      localStorage.setItem("minAngle", this.min);
      localStorage.setItem("maxAngle", this.max);
      this.maxAngle =
        localStorage.getItem("maxAngle") != "-Infinity" ||
        localStorage.getItem("maxAngle") != JSON.stringify(undefined)
          ? localStorage.getItem("maxAngle")
          : this.max;
      this.minAngle =
        localStorage.getItem("minAngle") != "Infinity" ||
        localStorage.getItem("minAngle") != JSON.stringify(undefined)
          ? localStorage.getItem("minAngle")
          : this.min;
    } else if (
      !localStorage.getItem("maxAngle") ||
      !localStorage.getItem("minAngle")
    ) {
      this.maxAngle = this.max;
      this.minAngle = this.min;
      localStorage.setItem("minAngle", this.min);
      localStorage.setItem("maxAngle", this.max);
    }
  },

  created() {
    this.$root.$on("resetMinMax", (reset) => {
      console.log("in reset ");
      if (reset) {
        this.minAngle = this.min;
        this.maxAngle = this.max;
        console.log(
          "reset min: ",
          this.minAngle,
          " reset max: ",
          this.maxAngle
        );
      }
    });
  },
};
</script>

<style scoped>
.range-slider {
  width: 100%;
  margin: auto;
  text-align: center;
  position: relative;
  height: auto;
}

.price {
  position: relative;
  top: 9px;
  bottom: 0;
  font-size: 12px;
  line-height: 15px;
  display: flex;
}

.filter-btn {
  font-size: 13px !important;
  letter-spacing: 1;
  text-transform: uppercase;
}

.range-slider input[type="range"] {
  position: absolute;
  left: 0;
  bottom: 0;
}

input[type="number"] {
  border: 1px solid #ddd;
  text-align: center;
  font-size: 1.6em;
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"]:invalid,
input[type="number"]:out-of-range {
  border: 2px solid #ff6347;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--afprimary);
}

input[type="range"]:focus::-ms-fill-lower {
  background: red;
}

input[type="range"]:focus::-ms-fill-upper {
  background: var(--afprimary);
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: var(--afprimary);
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}

input[type="range"]::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid var(--afprimary);
  height: 18px;
  width: 18px;
  border-radius: 25px;
  cursor: pointer;
  background: #f5c26c;

  -webkit-appearance: none;
  margin-top: -7px;
}
</style>
