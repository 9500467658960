<template>
  <div class="row justify-content-center">
    <div class="col-md-8 py-12">
      <div class="text-center">
        <emptyOrders></emptyOrders>
        <h5 class="text-muted mt-5">{{ $t("notYetPlacedOrders") }}</h5>

        <p class="font-15 mt-4">
          {{ $t("ordersWillbeRecorded") }}
        </p>

        <button
          @click="$router.push({ path: '/' })"
          class="btn btn-orange rounded-0 shadow-sm mr-3 px-5 py-2 mt-3 mb-12"
        >
          {{ $t("CONTINUESHOPPING") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import emptyOrders from "@/assets/images/empty-orders.svg";

export default {
  components: {
    emptyOrders,
  },
};
</script>
