<template>
  <div class="row">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="#ffa300"
      :height="height"
      :width="width"
      :transition="transition"
      background-color="#000"
    ></loading>
    <div class="col-md-12 py-0">
      <div
        class="card pane border-0 shadow-sm bg-white py-4 px-5"
        style="height: 100%"
      >
        <!-- Header -->
        <div
          class="
            card-header
            border-0
            bg-transparent
            pl-3
            pb-2
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <div class="d-flex align-items-center">
            <button @click="$router.go(-1)" class="btn btn-lg pl-0 text-dark">
              <i class="mdi mdi-arrow-left h5"></i>
            </button>
            <h4>
              {{ $t("Addresses") }}
              <span>({{ addresses ? noAddresses : 0 }})</span>
            </h4>
          </div>

          <button
            @click.stop="$router.push({ name: 'addaddress' })"
            class="
              btn
              text-white
              addbtn
              btn-orange
              shadow
              outline-none
              rounded-0
              mr-n3
            "
            v-show="!addresses || addresses.length != 0"
          >
            <i class="mdi mdi-plus"></i>
            {{ $t("ADDNEWADDRESS") }}
          </button>
        </div>

        <!-- Content -->
        <div class="row px-2" v-if="addresses">
          <div
            :class="[
              'col-md-12',
              addresses && addresses.length == 0 ? 'my-auto' : '',
            ]"
          >
            <!-- Empty Store -->
            <div
              class="text-center my-auto mx-auto"
              v-if="addresses && addresses.length == 0"
            >
              <div class="w-50 mx-auto">
                <img
                  src="@/assets/images/addressBook.png"
                  width="150px"
                  class="mt-n12 img-fluid"
                  alt
                />
                <h6 class="font-weight-bold py-2">
                  {{ $t("emptyAddressBook") }}
                </h6>
                <p class="mb-0 mx-n12">
                  {{ $t("addYourShippingAddressExp") }}
                </p>
                <p class>{{ $t("youCanAdd") }}</p>

                <button
                  @click.prevent="$router.push({ name: 'addaddress' })"
                  class="
                    btn btn-orange
                    rounded-0
                    mt-2
                    shadow
                    p-2
                    text-uppercase
                  "
                >
                  {{ $t("ADDNEWADDRESS") }}
                </button>
              </div>
            </div>

            <!-- Non empty -->
            <div class="row" v-else>
              <div
                class="col-md-6"
                v-for="address in address_slice"
                :key="address.id"
                v-show="goAway"
              >
                <div class="card border book">
                  <div class="p-3">
                    <p class="mb-2 font-15">{{ user.username }}</p>

                    <p class="mb-1 text-muted">
                      {{ address.quarter }}
                    </p>
                    <p class="mb-1 text-muted">
                      {{ state_name(address.state_id) }}
                    </p>

                    <p class="mb-2 text-muted">{{ address.phone }}</p>

                    <p
                      class="mb-0 mt-3 font-15 text-success"
                      v-if="address.default == 1"
                    >
                      {{ $t("DefaultAddress") }}
                    </p>
                  </div>

                  <div class="card-footer bg-transparent px-1 py-1">
                    <div class="d-flex justify-content-between">
                      <!-- <button
                        @click.prevent="setDefault = !setDefault"
                        class="btn btn-font-trans btn-sm"
                        :disabled="setDefault"
                      >
                        {{ $t("SETASDEFAULT") }}
                      </button> -->

                      <!-- <div class="d-flex align-items-center mr-2"> -->
                      <button
                        :to="{ name: 'account-edit' }"
                        class="btn rnd"
                        @click.stop="
                          $router.push({
                            name: 'editaddress',
                            params: { id: address.id },
                          })
                        "
                      >
                        <i class="mdi mdi-pencil color-font"></i>
                        <span class="color-font">{{ $t("EDIT") }}</span>
                      </button>
                      <button
                        class="btn rnd"
                        @click.prevent="delete_address(address)"
                      >
                        <i class="fas fa-trash text-danger"></i>
                      </button>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Loader -->
        <div class="mx-auto my-auto">
          <scroll-loader
            :loader-method="fetchAddresses"
            :loader-disable="disable"
          ></scroll-loader>
        </div>

        <!-- Pagination -->
        <div
          class="d-flex justify-content-between align-items-center"
          v-if="addresses"
        >
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            class="ml-"
            total-visible="6"
            color="var(--afprimary)"
            @input="updateResource"
          ></v-pagination>
          <p class="text-secondary mr-3">
            {{ currentPage }} {{ $t("of") }} {{ totalPages }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Loading },
  data() {
    return {
      emptyAddressBook: false,
      username: "daniel daniel",
      address: "Bastos, Yaounde",
      phone1: "+237652232342",
      phone2: "+237652342323",
      firstName: "",
      lastName: "",
      email: "",
      country: "",
      countryCode: "",
      state: "",
      city: "",
      username: "",
      phoneNumber: "",

      setDefault: true,
      numberOfAddress: 2,
      addresses: null,
      address_slice: [],

      user: null,
      disable: false,
      currentPage: 1,
      totalPages: 8,
      perPage: 4,
      inc: 0,
      states: [],

      goAway: true,

      loading: false,
      height: 80,
      width: 100,
      transition: "fade",
    };
  },

  methods: {
    ...mapActions(["getAddresses", "deleteAddress"]),

    state_name(id) {
      let name = "";

      this.states.cities.forEach((state) => {
        if (state.id == id) {
          name = state.name;
        }
      });

      return name;
    },

    fetchAddresses() {
      this.disable = false;
      this.getAddresses(this.user).then(() => {
        this.addresses = this.getStoredAddresses.data.addresses;
        console.log("User Addresses: ", this.addresses);
        this.disable = true;
        this.address_slice =
          this.addresses.length > 0
            ? this.addresses.slice(0, this.perPage)
            : [];
        this.totalPages = Math.ceil(this.addresses.length / this.perPage);
      });
    },

    delete_address(address) {
      // this.loading = true;
      this.disable = true;
      this.address_slice = null;
      this.goAway = false;

      this.deleteAddress(address).then((response) => {
        this.$forceUpdate();
        // this.loading = false;
        this.disable = false;
        this.goAway = true;
        console.log("delete address response:", response);
      });
    },

    updateResource() {
      if (this.currentPage == 1) {
        this.inc = 0;
        this.address_slice = this.addresses.slice(
          this.inc,
          this.perPage + this.inc
        );
        console.log("Inc: ", this.inc);
        console.log("current page: ", this.currentPage);
      } else {
        this.inc = (this.currentPage - 1) * this.perPage;
        this.address_slice = this.addresses.slice(
          this.inc,
          this.perPage + this.inc
        );
        console.log("Inc: ", this.inc);
        console.log("current page: ", this.currentPage);
      }

      console.log("Orders slice", this.address_slice);
    },
  },

  computed: {
    ...mapState({ userDetails: "user", geoData: "geoData" }),
    ...mapGetters(["getUserDetails", "getStoredAddresses", "getStatesData"]),

    noAddresses() {
      if (this.addresses.length >= 0 || this.addresses) {
        return this.addresses.length;
      }
    },
  },

  created() {
    this.user = this.user_;
    this.states = this.getStatesData;
    if (this.user.city != null) {
      this.city = this.user.city.id;
      this.state = this.user.city.state_id;
    }

    this.getStatesData.cities.forEach((country) => {
      if (country.id == 37) {
        this.country_id = country.id;
        this.user_country = country.name;
      }
    });

    this.states = this.getStatesData;

    console.log("states list: ", this.states);
  },

  mounted() {
    // this.fetchAddresses();
  },
};
</script>

<style scoped>
a.btn > i {
  font-size: 1.3rem;
}
.btn.rnd {
  border-radius: 50%;
}
.btn.rnd:hover {
  background: #db9e3557;
}
.btn-rnd {
  border-radius: 50% !important;
}
.card.book {
  height: 100%;
}
.card.pane {
  height: 100%;
}
.emptyAddress {
  position: relative;
  top: 27%;
  width: 55%;
}

@media (max-width: 768px) {
  /* .card.pane {
    height: 100 !important;
  } */
}
</style>
