<template>
  <div>
    <div class="head d-flex align-items-baseline py-2">
      <strong class="my-auto"><i class="mdi mdi-menu"></i></strong>
      <h6 class="ml-2 my-auto">{{ $t("AllCategories") }}</h6>
    </div>
    <div id="categories">
      <ul class="list-group px-0">
        <a
          href=""
          @click.prevent
          class="text-decoration-none"
          v-for="(category, index) in categories"
          :key="index"
        >
          <li
            :class="[
              'list-group-item',
              'list-group-item-action',
              'px-3',
              'py-2',
              'border-0',
              'd-flex',
              'align-items-center',
            ]"
            @mouseover="
              setCategory(category.child);
              setActive();
            "
            @mouseleave="deactivate"
          >
            <i :class="['mdi', category.icon, 'text-muted', 'pr-2']"></i>
            {{ category.name }}
          </li>
        </a>
      </ul>
    </div>

    <SubCategories :active="activeCategoryState" />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import SubCategories from "./SubCategories.vue";

export default {
  components: {
    SubCategories,
  },

  props: {
    allCategories: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  data() {
    return {
      previous_active_id: null,
      children: [],
    };
  },
  methods: {
    ...mapMutations({
      setActive: "SET_ACTIVE_CATEGORY",
      deactivate: "UNSET_ACTIVE_CATEGORY",
    }),

    setCategory(child) {
      console.log("Category child: ", child);
      this.$store.commit("SET_CURRENT_CATEGORY", {
        children: child,
      });
    },
  },

  computed: {
    ...mapGetters(["getGuestDataObj", "activeCategoryState"]),

    categories() {
      return this.allCategories;
    },
  },

  created() {
    console.log("Inner guest categories: ", this.allCategories);
  },
};
</script>

<style scoped>
.list-group-item.active {
  background-color: #ededed !important;
  border-color: #ededed !important;
  color: rgba(0, 0, 0, 0.733);
  font-weight: bold;
}

.list-group-item:hover {
  font-weight: bold;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.637);
}

.list-group-item.active {
  background-color: transparent !important;
  border-color: transparent !important;
  color: rgba(0, 0, 0, 0.733);
  font-weight: bold;
}

li {
  font-size: 12.5px;
}

.mdi-menu {
  font-size: 1.5em;
  margin-left: 20px;
}

.head {
  background: var(--afprimary);
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
}
</style>
