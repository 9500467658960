import { render, staticRenderFns } from "./RegisterSuccess.vue?vue&type=template&id=c089d030&scoped=true&"
var script = {}
import style0 from "./RegisterSuccess.vue?vue&type=style&index=0&id=c089d030&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c089d030",
  null
  
)

export default component.exports