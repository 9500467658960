<template>
  <div id="newProducts" class="row mt-5 mb-3" v-show="newProducts">
    <div class="col-md-12 px-0 py-auto">
      <div
        class="
          col-md-12
          px-0
          section-head
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <h3 class="sectionTitle my-0 px-md-0 px-lg-0 pl-3 pr-2">
          <i class="common new_products"></i>
          {{ $t("newPrdts") }}
        </h3>
      </div>

      <div v-if="products.length > 0">
        <carousel
          id="newProducts-crs"
          :items="5"
          :dots="false"
          :margin="2"
          :padding="130"
          :autoplay="false"
          :autoplaySpeed="2000"
          :nav="false"
          :loop="true"
          :rewind="true"
          :responsive="{
            1200: { items: 5, nav: false },
            960: { items: 4, nav: false },
            768: { items: 3, nav: false },
            500: { items: 2, nav: false },
            400: { items: 2, nav: false },
            0: { items: 2, nav: false },
          }"
          class="
            mx-0
            py-md-auto py-lg-auto py-0
            my-0 my-md-auto my-lg-auto
            card
            slidecard
            border-0
          "
        >
          <template slot="prev"
            ><button class="rounded owl-prev">
              <i class="mdi mdi-chevron-left"></i></button
          ></template>

          <div v-for="product in products" :key="product.id">
            <router-link
              :to="{
                name: 'newproduct-detail',
                params: { slug: product.slug, prdtId: product.id },
              }"
              class="
                card
                h-100
                prdtCard
                rounded
                mx-lg-1 mx-md-1 mx-sm-auto mx-0
              "
            >
              <div class="prdtImage">
                <img
                  :src="product.image_url"
                  @error="defaultImage"
                  class="img-fluid p-2"
                />
              </div>

              <div class="prdtDiscount" v-if="product.item_discount">
                <strong class="my-auto">{{
                  product.item_discount.percentage_decrease | percent
                }}</strong>
                <p class="mb-0 mt-n1 text-center">{{ $t("percentOff") }}</p>
              </div>

              <div
                class="
                  card-footer
                  pt-1
                  bg-transparent
                  rounded-0
                  border-0
                  px-3
                  pb-6
                "
              >
                <router-link
                  :to="{
                    name: 'newproduct-detail',
                    params: { slug: product.slug, prdtId: product.id },
                  }"
                >
                  <h6 class="prdtName text-capitalize text-truncate">
                    {{ product.name }}
                  </h6>
                </router-link>

                <div class="mt-4 mb-1" v-if="product.item_discount">
                  <p class="text-muted mb-0 rrp text-uppercase">
                    {{ $t("rrp") }}:
                    <span>
                      <strike>{{ product.price | currency("XFA ") }}</strike>
                    </span>
                  </p>
                  <p
                    class="color-font priceTag mb-0 mt-1"
                    v-if="product.item_discount"
                  >
                    {{ product.item_discount.unit_price | currency("XFA ") }}
                  </p>
                </div>
                <div class="mt-2" v-else>
                  <p class="color-font priceTag mb-0 mt-1">
                    {{ product.price | currency("XFA ") }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>

          <template slot="next"
            ><button class="rounded owl-next">
              <i class="mdi mdi-chevron-right"></i></button
          ></template>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/home/newproducts/new-products.css";

export default {
  props: {
    newProducts: {
      type: Array,
      required: true,
      default: [],
    },
  },
  data() {
    return {
      model: null,
    };
  },

  methods: {
    newPrice(price, discount) {
      return price - discount * price;
    },

    organizeProducts() {
      var products = [];
      if (this.products) {
        for (const i in this.products) {
          if (this.products[i].quantity > 0) {
            products.push(this.products[i]);
          }
        }

        console.log("New products: ", products);
      }
      this.products = products;
    },
  },

  computed: {
    products() {
      return this.newProducts;
    },
  },

  created() {
    console.log("New Products__ !!!: ", this.newProducts);
  },

  beforeMount() {
    if (!this.newProducts) {
      this.organizeProducts();
      console.log("New Products Action");
    }
  },
};
</script>

<style scoped></style>
