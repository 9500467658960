<template>
  <div>
    <div class="contenair-fluid">
      <div
        class="row justify-content-md-center justify-content-lg-center justify-content-center"
      >
        <div class="col col-lg-5 col-md-6 col-8 msg">
          <span>Créer votre compte boutique</span>
        </div>
      </div>
      <div
        class="row justify-content-md-center justify-content-lg-center justify-content-center"
      >
        <div class="col col-lg-5 col-md-6 col-8 form">
          <form>
            <div
              class="form-group row justify-content-md-center justify-content-lg-center justify-content-center"
              :class="{ 'form-group--error': $v.user.name.$error }"
            >
              <div class="col-sm-10">
                <input
                  type="text"
                  class="form-control-plaintext input"
                  id="name"
                  placeholder="enter user Name"
                  v-model.trim="$v.user.name.$model"
                />
                <span
                  class="erreur"
                  v-if="!$v.user.name.required && $v.user.name.$dirty"
                  >user name is required</span
                >
                <span class="erreur" v-if="!$v.user.name.minLength"
                  >username must have at least
                  {{ $v.user.name.$params.minLength.min }} letters</span
                >
              </div>
            </div>
            <div
              class="form-group row justify-content-md-center justify-content-lg-center justify-content-center"
              :class="{ 'form-group--error': $v.user.email.$error }"
            >
              <div class="col-sm-10">
                <input
                  type="email"
                  class="form-control-plaintext input"
                  id="email"
                  placeholder="e-m@il"
                  v-model.trim="$v.user.email.$model"
                />
                <span
                  class="erreur"
                  v-if="!$v.user.email.required && $v.user.email.$dirty"
                  >email is required</span
                >
                <span class="erreur" v-if="!$v.user.email.email"
                  >enter a valid email</span
                >
              </div>
            </div>

            <div
              class="form-group row justify-content-md-center justify-content-lg-center justify-content-center"
            >
              <vue-tel-input
                class="phone phoneinput"
                v-model.trim="$v.user.phone.$model"
                id="phone"
              ></vue-tel-input>
            </div>
            <span
              class="erreur special"
              v-if="!$v.user.phone.required && $v.user.phone.$dirty"
              >phone number is required</span
            >

            <div
              class="form-group row justify-content-md-center justify-content-lg-center justify-content-center"
            >
              <div class="col-sm-10">
                <input
                  type="password"
                  class="form-control-plaintext input"
                  id="password"
                  placeholder="enter Password"
                  v-model.trim="$v.user.password.$model"
                />
                <p
                  class="erreur"
                  v-if="!$v.user.password.required && $v.user.password.$dirty"
                >
                  Password is required
                </p>
                <span
                  class="erreur"
                  v-if="
                    !$v.user.password.strongPassword && $v.user.password.$dirty
                  "
                  >your password must have letters and numbers</span
                >
              </div>
            </div>
            <div
              class="form-group row justify-content-md-center justify-content-lg-center justify-content-center"
            >
              <div class="col-sm-10">
                <input
                  type="password"
                  class="form-control-plaintext input"
                  id="confirmpassword"
                  placeholder="Confirm Password"
                  v-model.trim="$v.user.confirmPassword.$model"
                />
                <p
                  class="erreur"
                  v-if="
                    !$v.user.confirmPassword.required &&
                    $v.user.confirmPassword.$dirty
                  "
                >
                  re-enter the same password
                </p>
                <p
                  class="erreur"
                  v-if="!$v.user.confirmPassword.sameAsPassword"
                >
                  password don't match
                </p>
              </div>
            </div>
            <div
              class="form-group row justify-content-md-center justify-content-lg-center justify-content-center"
            >
              <div class="col-sm-10">
                <input
                  type="text"
                  class="form-control-plaintext input"
                  id="shopname"
                  placeholder="Shop's name"
                  v-model.trim="$v.user.shopname.$model"
                />
                <p
                  class="erreur"
                  v-if="!$v.user.shopname.required && $v.user.shopname.$dirty"
                >
                  Your shop's name is required
                </p>
                <p class="erreur" v-if="!$v.user.shopname.minLength">
                  shop's name must have at least
                  {{ $v.user.shopname.$params.minLength.min }} letters
                </p>
              </div>
            </div>

            <div
              class="form-group row justify-content-md-center justify-content-lg-center justify-content-center"
            >
              <div class="col-sm-10">
                <input
                  type="text"
                  class="form-control-plaintext input"
                  id="shopdescription"
                  placeholder="Shop Description"
                  v-model.trim="$v.user.shopdescription.$model"
                />
                <p
                  class="erreur"
                  v-if="
                    !$v.user.shopdescription.required &&
                    $v.user.shopdescription.$dirty
                  "
                >
                  the description is required
                </p>
                <p class="erreur" v-if="!$v.user.shopdescription.minLength">
                  it must be at least
                  {{ $v.user.shopdescription.$params.minLength.min }} letters
                </p>
              </div>
            </div>

            <div
              class="form-group row justify-content-md-center justify-content-lg-center justify-content-center"
            >
              <select class="custom-select shop-type" id="type" required>
                <option value v-for="option in options" :key="option">{{
                  option.name
                }}</option>
              </select>
            </div>
            <!-- <p class="erreur" v-if="!$v.user.type.required && $v.user.type.$dirty">type is required</p> -->

            <div
              class="form-check justify-content-md-center justify-content-lg-center justify-content-center"
              :class="{ invalid: $v.user.agreeToTerms.$invalid }"
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="agreeToTerms"
                @change="$v.user.agreeToTerms.$touch()"
                v-model.trim="$v.user.agreeToTerms.$model"
              />
              <label class="form-check-label" for="terms">
                I accept the terms and conditions of store creation on Afeshop
              </label>
              <p
                class="erreur"
                v-if="
                  !$v.user.agreeToTerms.sameAs && $v.user.agreeToTerms.$dirty
                "
              >
                you should agree to terms
              </p>
            </div>
            <button type="submit" class="btn">Validate</button>
          </form>
        </div>
      </div>
    </div>
    <div class="contenair-fluid empty"></div>
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import {
  required,
  minLength,
  // between,
  email,
  sameAs,
} from "vuelidate/lib/validators";
export default {
  data() {
    return {
      options: [
        { name: "choose shop's type" },
        { name: "clothes" },
        { name: "electronics" },
        { name: "shoes" },
        { name: "foods" },
      ],
      user: {
        name: "",
        email: "",
        phone: "",
        password: "",
        confirmPassword: "",
        shopname: "",
        shopdescription: "",
        type: "",
        agreeToTerms: false,
      },
      submitted: false,
    };
  },
  validations: {
    user: {
      name: { required, minLength: minLength(4) },
      email: { required, email },
      phone: { required },
      password: {
        required,
        strongPassword(password) {
          return (
            /[a-z]/.test(password) &&
            /[0-9]/.test(password) &&
            password.length >= 5
          );
        },
      },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      shopname: { required, minLength: minLength(4) },
      shopdescription: { required, minLength: minLength(24) },
      type: { required },
      agreeToTerms: {
        sameAs: sameAs(() => true),
      },
    },
  },
  components: {
    VueTelInput,
  },
  computed: {},
};
</script>

<style scoped>
.msg {
  height: 80px;
  font-size: 15px;
  background-color: #76c2f5;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.msg span {
  display: block;
  color: white;
  text-align: center;
  line-height: 15px;
  font-size: 2.5vw;
  font-weight: bolder;
}
.btn {
  background-color: #76c2f5;
  width: 80%;
  height: 45px;
  margin-top: 20px;
  margin-left: 10%;
  color: white;
  font-weight: bolder;
}
.label {
  font-weight: bolder;
  line-height: 45px;
}
.input {
  border: 1px solid white;
  width: 90%;
  outline: none;
  text-align: center;
  font-weight: 500;
  border-radius: 3px;
  background-color: white;
}
.select {
  border: 1px solid #d4d6d8;
  width: 60%;
  height: 70%;
  margin-left: 18%;
  outline: none;
}
.phone {
  outline: none;
  /* width: 75%; */
  height: 75%;
}
.shop-type {
  width: 45%;
}
.empty {
  height: 250px;
}
.erreur {
  color: red;
}
.special {
  margin-left: 8%;
}
.form {
  background-color: #f2f2f2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.phoneinput {
  border: 1px solid white;
  /* width: 50%; */
  outline: none;
  text-align: center;
  border-radius: 3px;
  background-color: white;
}
</style>
