<template>
  <div id="cartView" class="container">
    <div class="row">
      <div class="col-md-12 py-0">
        <div class="card-header border-0 bg-transparent pl-0 pb-1 pt-0 my-2">
          <div class="d-flex align-items-center">
            <button
              @click="$router.go(-1)"
              class="btn btn-lg pl-0 my-0 text-dark"
            >
              <i class="mdi mdi-arrow-left"></i>
            </button>
            <h5>
              {{ $t("ShoppingCart") }} ({{ noCartItems }} {{ $t("items") }})
            </h5>
          </div>
          <p class="text-danger ml-9" v-if="errorMsg">{{ errorMsg }}</p>
        </div>

        <loading
          :active.sync="loading"
          :can-cancel="false"
          :is-full-page="true"
          color="var(--afprimary)"
          :height="height"
          :width="width"
          :transition="transition"
          background-color="#000"
        ></loading>
        <div v-if="orders.length != 0">
          <div class="row">
            <div id="deskCartPrdts" class="col-md-12 px-0">
              <div class="items ft-products-list">
                <div class="header">
                  <div class="item">{{ $t("Item") }}</div>
                  <div class="quantity">{{ $t("Quantity") }}</div>
                  <div class="unit-price">{{ $t("UnitPrice") }}</div>
                  <div class="subtotal">{{ $t("Subtotal") }}</div>
                </div>

                <form
                  class="product ft-product"
                  v-for="filtered in filteredOrders"
                  :key="filtered.id"
                >
                  <div
                    class="cartPrdt mb-2 shadow-sm"
                    v-for="order in filtered.order_item"
                    :key="order.id"
                  >
                    <div class="image ft-product-image">
                      <a href="" @click.stop>
                        <img
                          class="lazy image -loaded"
                          :src="order.item.image_url"
                          :alt="order.item.image"
                          :data-placeholder="order.item.image"
                        />
                      </a>
                    </div>
                    <div class="item pb-2">
                      <div
                        class="
                          seller
                          -mbs
                          ft-product-seller
                          text-secondary
                          mb-2
                        "
                      >
                        {{ $t("Seller") }}:
                        <span class="text-capitalize shopName">
                          {{ order.item.shop ? order.item.shop.name : "" }}
                          <router-link
                            :to="{
                              name: 'shop-view',
                              params: { shop_id: order.item.shop_id },
                            }"
                          >
                            &nbsp;<i class="fas fa-external-link-alt"></i>
                          </router-link>
                        </span>
                      </div>
                      <router-link
                        :to="{
                          name: 'deals-detail',
                          params: { prdtId: order.item.id },
                        }"
                        class="mb-0 mt-auto text-capitalize prdtName"
                      >
                        {{ order.item.name }}
                      </router-link>

                      <p
                        class="
                          seller
                          -mbs
                          ft-product-seller
                          text-secondary
                          mt-2
                          mb-0
                          text-capitalize
                        "
                      >
                        {{ $t("Category") }}:
                        <span class="mb-0 mt-auto black--text">
                          {{ order.item.category.name }}
                        </span>
                      </p>

                      <div class="mt-1 ml-n1 d-flex justify-content-start">
                        <a
                          class="
                            btn btn-link btn-sm
                            px-0
                            align-items-center
                            d-flex
                          "
                          @click.stop="remove(order)"
                        >
                          <i class="mdi mdi-delete"></i>
                          {{ $t("Remove") }}
                        </a>
                      </div>
                    </div>
                    <div class="quantity">
                      <div class="form-group d-flex justify-content-center">
                        <select
                          name="qty"
                          id="qty"
                          class="form-control"
                          style="width: 3.5em"
                          @change="onChange($event, order)"
                        >
                          <option
                            :value="i"
                            v-for="i in order.item.quantity"
                            :key="i"
                            :selected="i === order.quantity ? true : false"
                          >
                            {{ i }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="unit-price ft-product-unit-price">
                      <span class="current d-block">
                        <span
                          dir="ltr"
                          :data-price="order.item.item_discount.unit_price"
                          v-if="order.item.item_discount"
                          >{{
                            order.item.item_discount.unit_price
                              | currency("XFA ")
                          }}</span
                        >
                        <span
                          dir="ltr"
                          :data-price="order.item.item_discount.unit_price"
                          v-else
                          >{{ order.item.unit_price | currency("XFA ") }}</span
                        >
                      </span>
                      <span class="old -mtm d-block text-secondary mt-1">
                        <strike dir="ltr" :data-price="order.item.unit_price">{{
                          order.item.unit_price | number("0,0")
                        }}</strike>
                      </span>
                      <span
                        class="text-success d-block mt-2"
                        v-if="order.item.item_discount"
                      >
                        {{ $t("Savings") }}:
                        <span dir="ltr">{{
                          (order.item.unit_price -
                            order.item.item_discount.unit_price)
                            | number("0,0")
                        }}</span>
                      </span>
                    </div>
                    <div class="subtotal ft-product-subtotal-price">
                      <span
                        dir="ltr"
                        :data-price="subtotal"
                        v-if="order.item.item_discount"
                        >{{
                          subtotal(
                            order.quantity,
                            order.item.item_discount.unit_price
                          ) | currency("XFA ")
                        }}</span
                      >
                      <span dir="ltr" :data-price="subtotal" v-else>{{
                        subtotal(order.quantity, order.item.unit_price)
                          | currency("XFA ")
                      }}</span>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <!-- Mobile Cart items -->
            <div id="mobiCartPrdts" class="col-md-12">
              <form
                v-for="filtered in filteredOrders"
                :key="'mobiCart-' + filtered.id"
              >
                <div
                  class="bg-white row border-0 shadow-sm mb-2"
                  v-for="order in filtered.order_item"
                  :key="order.id"
                >
                  <div class="col-3 pb-0">
                    <div class="image ft-product-image">
                      <a href="" @click.stop>
                        <img
                          class="lazy image -loaded"
                          :src="order.item.image_url"
                          :alt="order.item.image"
                          :data-placeholder="order.item.image"
                        />
                      </a>
                    </div>
                  </div>
                  <div class="col-9 pb-0 pl-0">
                    <div class="item">
                      <router-link
                        :to="{
                          name: 'deals-detail',
                          params: { prdtId: order.item.id },
                        }"
                        class="mb-0 mt-auto text-capitalize prdtName"
                      >
                        {{ order.item.name }} Lorem ipsum dolor sit amet
                        consectetur adipisicing elit.
                      </router-link>
                      <div
                        class="
                          seller
                          -mbs
                          ft-product-seller
                          text-secondary
                          mb-0
                          mt-1
                        "
                      >
                        {{ $t("Seller") }}:
                        <span class="text-capitalize shopName">
                          {{ order.item.shop ? order.item.shop.name : "" }}
                          <router-link
                            :to="{
                              name: 'shop-view',
                              params: { shop_id: order.item.shop_id },
                            }"
                          >
                            &nbsp;<i class="fas fa-external-link-alt"></i>
                          </router-link>
                        </span>
                      </div>

                      <p
                        class="
                          seller
                          -mbs
                          ft-product-seller
                          text-secondary
                          mt-0
                          mb-3
                        "
                      >
                        {{ $t("Category") }}:
                        <span class="mb-0 mt-auto black--text">
                          {{ order.item.category.name | truncate(24, "...") }}
                        </span>
                      </p>

                      <!-- Mobile Price -->
                      <div class="mt-2">
                        <span
                          dir="ltr"
                          :data-price="order.item.item_discount.unit_price"
                          v-if="order.item.item_discount"
                          class="mainPrice"
                          >{{
                            order.item.item_discount.unit_price
                              | currency("XFA ")
                          }}</span
                        >
                        <span
                          dir="ltr"
                          :data-price="order.item.item_discount.unit_price"
                          v-else
                          >{{ order.item.unit_price | currency("XFA ") }}</span
                        >
                        <strike
                          class="ml-2"
                          dir="ltr"
                          :data-price="order.item.unit_price"
                          >{{ order.item.unit_price | number("0,0") }}</strike
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-12 py-1 mb-2 d-flex justify-content-between"
                    >
                      <a
                        class="
                          btn btn-link
                          px-2
                          btn-sm
                          align-items-center
                          d-flex
                        "
                        @click.stop="remove(order)"
                      >
                        <i class="mdi mdi-delete"></i>
                        {{ $t("REMOVE") }}
                      </a>

                      <div class="float-right px-5">
                        <inc-dec
                          :maxValue="order.item.quantity"
                          :prevQty="order.quantity"
                          :orderItem="order"
                          @updateQty="handleUpdateQty"
                        ></inc-dec>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-md-12 d-none d-sm-none d-md-block d-lg-block">
              <div class="float-right">
                <div class="d-flex align-items-baseline justify-content-end">
                  <p class="font-weight-bold mb-0 mr-5 pr-2">
                    {{ $t("Total") }}:
                  </p>
                  <span class="h5 color-font mb-0">{{
                    (filteredOrders && filteredOrders[0].order_item.length) > 0
                      ? total
                      : 0 | currency("XFA ")
                  }}</span>
                </div>

                <p class="text-muted mt-3 text-right mb-2">
                  {{ $t("deliveryFeeNotIncluded") }}
                </p>
                <p class="text-muted text-right">
                  {{ $t("internationalFee") }}
                </p>
              </div>
            </div>

            <div
              class="
                col-md-12
                bg-white
                shadow-sm
                mb-3
                d-block d-sm-block d-md-none d-lg-none
              "
            >
              <div class="row">
                <div class="col-6 pl-3 py-1">
                  <h5>{{ $t("Total") }}:</h5>
                </div>
                <div class="col-6 pr-3 py-1">
                  <h5 class="color-font mb-0 font-weight-bold float-right">
                    {{
                      (filteredOrders && filteredOrders[0].order_item.length) >
                      0
                        ? total
                        : 0 | currency("XFA ")
                    }}
                  </h5>
                </div>
              </div>

              <div class="row">
                <div class="col-12 px-3 pt-2">
                  <div class="border-success border p-2">
                    <p class="text-muted mb-0">
                      {{ $t("deliveryFeeNotIncluded") }}
                    </p>
                    <p class="text-muted mb-0">
                      {{ $t("internationalFee") }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div
                  class="
                    col-md-12
                    d-block d-sm-block d-md-none d-lg-none
                    py-1
                    px-2
                  "
                >
                  <button
                    @click="
                      $router.push({
                        name: 'payment',
                        params: { total: total },
                      })
                    "
                    class="
                      btn btn-orange btn-block
                      rounded-0
                      shadow
                      py-3
                      px-5
                      mb-3
                      font-weight-bold
                      text-uppercase
                    "
                  >
                    {{ $t("checkout") }}
                  </button>
                  <button
                    @click="$router.push({ path: '/' })"
                    class="
                      btn btn-white btn-block
                      font-weight-bold
                      rounded-0
                      shadow
                      mr-3
                      py-3
                      px-5
                    "
                  >
                    {{ $t("CONTINUESHOPPING") }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="row shadow-sm bg-white mb-5">
            <div class="col-md-12 py-5 d-none d-sm-none d-md-block d-lg-block">
              <div class="float-right">
                <button
                  @click="$router.push({ path: '/' })"
                  class="btn btn-white rounded-0 shadow mr-3 px-5"
                >
                  {{ $t("CONTINUESHOPPING") }}
                </button>
                <button
                  @click="
                    $router.push({ name: 'payment', params: { total: total } })
                  "
                  class="btn btn-orange rounded-0 shadow px-5"
                >
                  {{ $t("PROCEEDTOCHECKOUT") }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="row">
            <div class="col-md-12">
              <div class="text-center my-3">
                <img
                  src="@/assets/images/empty-cart.png"
                  alt="empty cart"
                  class="img-fluid"
                />
                <h3 class="text-muted mt-5">{{ $t("emptyCart") }}</h3>

                <p class="font-15 mt-4">
                  {{ $t("browseCategories") }}
                </p>

                <button
                  @click="$router.push({ path: '/' })"
                  class="
                    btn btn-orange
                    rounded-0
                    shadow-sm
                    mr-3
                    px-5
                    mt-3
                    mb-12
                  "
                >
                  {{ $t("STARTSHOPPING") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import computeTotal from "@/mixins/totalOrders";

import IncDec from "@/components/widgets/IncDec.vue";

import "@/assets/css/customer/cart.css";
export default {
  mixins: [computeTotal],
  components: {
    Loading,
    IncDec,
  },
  data() {
    return {
      newsletter: false,
      newQtty: 0,
      loading: false,
      height: 80,
      width: 100,
      transition: "fade",
      prdtImage: null,
      orders: [],
      errorMsg: "",
    };
  },
  methods: {
    ...mapActions(["getOrders", "removeFromCart", "addToCart", "updateCart"]),
    ...mapMutations({
      setOrders: "SET_ORDERS",
      setNoCartItems: "setNoCartItems",
    }),
    newPrice(price, discount) {
      return price - discount * price;
    },
    onChange(event, orderItem) {
      console.log("Update cart, ", orderItem);
      this.newQtty = event.target.value;

      this.updateRemoteCart(orderItem, this.newQtty, this.fetchOrders());
    },

    handleUpdateQty(event) {
      this.updateRemoteCart(event.order, event.nQty, this.fetchOrders());
      console.log("handling Update: ", event);
    },

    subtotal(quantity, price) {
      return quantity * price;
    },
    remove(order) {
      console.log("remove order", order);
      this.loading = true;
      this.removeFromCart(order).then(() => {
        this.loading = false;
        this.fetchOrders();
      });
    },
    fetchOrders() {
      this.loading = true;
      this.getOrders().then((response) => {
        console.log("cart response: ", response);
        if (response) {
          this.loading = false;
          this.orders = this.orders_.data;
          console.log("orders internal", this.orders);
          if (this.orders.length > 0) {
            this.orders = this.orders.filter((order) => {
              console.log("orders length", this.orders.length);
              console.log("orders card length", this.noCartItems);
              return order.status.match("cart");
            });
          }
          console.log("orders response", this.orders_);
          console.log("orders response b2", this.orders);

          this.setNoCartItems();
          this.$forceUpdate();
          console.log("orders response2", this.orders);
        } else {
          this.errorMsg = this.orders_.data.message;
          this.loading = false;
        }
      });
    },
  },
  computed: {
    ...mapGetters(["getCachedOrders", "noCartItems"]),
    filteredOrders() {
      if (this.orders.length > 0) {
        return this.orders;
      } else {
        return [];
      }
    },
    total() {
      var tot = this.computeTotal(this.orders_);
      console.log("total Orders: ", tot);
      return tot;
    },
  },
  created() {
    this.fetchOrders();
    console.log("my orders ", this.orders);
    console.log("filteredOrders Cart: ", this.orders_);
  },
  mounted() {
    this.fetchOrders();
  },
};
</script>
