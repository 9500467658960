var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user)?_c('div',{staticClass:"mobile-userInfo"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("Welcome"))+", "+_vm._s(_vm.user.name.split(" ")[0]))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.user.email))])]):_vm._e(),_c('div',{staticClass:"orders-total"},[_c('h6',[_vm._v(_vm._s(_vm._f("currency")(_vm.totalOrdersAmount,"XFA ")))])]),_c('div',{staticClass:"bg-orange"},[_c('p',{staticClass:"mb-2 ml-2 text-uppercase font-12"},[_vm._v(" "+_vm._s(_vm.$t("MyAfeshopAccount"))+" ")])]),_c('div',{staticClass:"px-auto sideDash"},[_c('ul',{staticClass:"list-group px-0 shadow-sm w-100"},[_c('router-link',{attrs:{"to":{ name: 'purchases-m' }}},[_c('li',{class:[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            'd-flex',
            'align-center',
            'border-0' ],on:{"click":_vm.close}},[_c('i',{staticClass:"mdi mdi-arrow-down h5 mb-0"}),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.$t("Orders")))]),_c('div',{staticClass:"mobi-arrow"},[_c('i',{staticClass:"mdi mdi-chevron-right h4"})])])]),_c('router-link',{attrs:{"to":{ name: 'statistics-m' }}},[_c('li',{class:[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            'd-flex',
            'align-center',
            'border-0' ],on:{"click":_vm.close}},[_c('i',{staticClass:"mdi mdi-graph h5 mb-0"}),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.$t("Statistics")))]),_c('div',{staticClass:"mobi-arrow"},[_c('i',{staticClass:"mdi mdi-chevron-right h4"})])])]),_c('router-link',{attrs:{"to":{ name: 'cart' }}},[_c('li',{class:[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            'd-flex',
            'align-center',
            'border-0' ],on:{"click":_vm.close}},[_c('i',{staticClass:"mdi mdi-cart-outline h5 mb-0"}),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.$t("MyShoppingCart")))]),_c('div',{staticClass:"mobi-arrow"},[_c('i',{staticClass:"mdi mdi-chevron-right h4"})])])]),_c('router-link',{attrs:{"to":{ name: 'comments-m' }}},[_c('li',{class:[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            this.$route.name === 'comments' ? 'active' : '',
            'd-flex',
            'align-center',
            'border-0' ],on:{"click":_vm.close}},[_c('i',{staticClass:"mdi mdi-comment-multiple-outline h5 mb-0"}),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.$t("Comments")))]),_c('div',{staticClass:"mobi-arrow"},[_c('i',{staticClass:"mdi mdi-chevron-right h4"})])])]),_c('router-link',{attrs:{"to":{ name: 'voucher-m' }}},[_c('li',{class:[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            'd-flex',
            'align-center',
            'border-0' ],on:{"click":_vm.close}},[_c('i',{staticClass:"mdi mdi-ticket-percent-outline h5 mb-0"}),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.$t("Vouchers")))]),_c('div',{staticClass:"mobi-arrow"},[_c('i',{staticClass:"mdi mdi-chevron-right h4"})])])])],1)]),_c('div',{staticClass:"bg-orange"},[_c('p',{staticClass:"mb-2 mt-4 ml-2 text-uppercase font-12"},[_vm._v(" "+_vm._s(_vm.$t("AccountSettings"))+" ")])]),_c('div',{staticClass:"px-auto sideDash"},[_c('ul',{staticClass:"list-group px-0 shadow-sm w-100"},[_c('router-link',{attrs:{"to":{ name: 'account-edit-m' }}},[_c('li',{class:[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            'd-flex',
            'align-center',
            'border-0' ],on:{"click":_vm.close}},[_c('span',[_vm._v(_vm._s(_vm.$t("Details")))]),_c('div',{staticClass:"mobi-arrow"},[_c('i',{staticClass:"mdi mdi-chevron-right h4"})])])]),_c('router-link',{attrs:{"to":{ name: 'addressbook-m' }}},[_c('li',{class:[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            'd-flex',
            'align-center',
            'border-0' ],on:{"click":_vm.close}},[_c('span',[_vm._v(_vm._s(_vm.$t("AddressBook")))]),_c('div',{staticClass:"mobi-arrow"},[_c('i',{staticClass:"mdi mdi-chevron-right h4"})])])]),_c('router-link',{attrs:{"to":{ name: 'newsletter-m' }}},[_c('li',{class:[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            'd-flex',
            'align-center',
            'border-0' ],on:{"click":_vm.close}},[_c('span',[_vm._v(_vm._s(_vm.$t("NewsletterPreferences")))]),_c('div',{staticClass:"mobi-arrow"},[_c('i',{staticClass:"mdi mdi-chevron-right h4"})])])])],1)]),_c('li',{staticClass:"\n      py-2\n      list-group-item\n      border-0\n      text-center\n      list-unstyled\n      bg-transparent\n    "},[_c('button',{staticClass:"btn btn-font-trans color-font",on:{"click":function($event){$event.stopPropagation();_vm.logout();
        _vm.close();}}},[_vm._v(" "+_vm._s(_vm.$t("LOGOUT"))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }