<template>
  <div class="container py-5 my-5">
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6 px-0">
        <h4 class="color-font">{{ $t("Assistance") }}</h4>

        <div class="d-flex justify-content-left align-items-center mt-5 pt-2">
          <span class="pr-5">
            <i class="mdi mdi-checkbox-marked-circle"></i>
          </span>
          <p class="font-15 mb-0 text-capitalize">
            {{ $store.state.auth.resetMsg }}
          </p>
        </div>

        <div class="pt-5 d-flex align-items-center">
          <button
            class="
              mt-2
              btn btn-lg
              shadow
              btn-block btn-blue
              rounded-0
              d-flex
              justify-content-left
              align-items-baseline
              py-3
              font-weight-bold
            "
            @click.prevent="$router.push('/')"
          >
            <span class="font-15">
              <i class="mdi mdi-home"></i>
            </span>

            <span class="font-15 mx-auto pr-4">{{ $t("BackHome") }}</span>
          </button>
          <button
            class="
              mx-2
              btn btn-lg
              shadow
              btn-block btn-orange
              rounded-0
              d-flex
              justify-content-left
              align-items-baseline
              py-3
              font-weight-bold
            "
            @click.prevent="$router.push({ name: 'signin' })"
          >
            <span class="font-15">
              <i class="fas fa-sign-in-alt"></i>
            </span>

            <span class="font-15 mx-auto pr-4">{{ $t("Login") }}</span>
          </button>
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
    <!-- We have sent you an e-mail with specific instructions on how to reset your password. Please check your inbox and click on the link provided. -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.mdi-checkbox-marked-circle::before {
  color: #14c314;
  font-size: 2em;
}
</style>
