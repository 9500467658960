import { UserService } from "@/services/user";

const state = {
  userStats: null,
};

const mutations = {
  SET_STATISTICS(state, payload) {
    state.userStats = payload;
    localStorage.setItem("user_stats", JSON.stringify(payload));
    console.log("set statistics", state.userStats);
  },
};

const getters = {
  getStoredStatistics: (state) => state.userStats,
};

const actions = {
  async getStatistics({ commit }, payload) {
    try {
      const response = await UserService.getStatistics(payload);

      console.log("Get Statistics index: ", response);
      commit("SET_STATISTICS", response);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
