const state = {
  cartDrawer: null,
  drawer: null,

  currentProduct: null,
  reviewDialog: null,

  successInfo: "",
  paymentInfo: "",

  checkoutError: null,
  completionError: null,

  currentLanguage: null,
};

const mutations = {
  SET_DRAWER(state, payload) {
    state.cartDrawer = payload;
  },

  SET_DIALOG(state, payload) {
    state.reviewDialog = payload;
  },

  toggleCartNav(state) {
    state.cartDrawer = !state.cartDrawer;
  },

  SET_LANGUAGE(state, payload) {
    state.currentLanguage = payload;
    localStorage.setItem("language", JSON.stringify(payload));

    console.log("store language data", state.currentLanguage);
  },
};

const getters = {
  currentProduct: (state) => state.currentProduct,

  getCurrentLanguage(state) {
    return state.currentLanguage
      ? state.currentLanguage
      : JSON.parse(localStorage.getItem("language"));
  },
};

const actions = {};

export default {
  state,
  mutations,
  getters,
  actions,
};
