<template>
  <div>
    <header id="headerNav" class="header">
      <!-- Top Header -->
      <div class="header-top d-none d-sm-none d-md-block">
        <div
          class="
            container
            d-flex
            align-items-center
            justify-content-between
            py-1
            px-0
          "
        >
          <div class="header-left">
            <div class="d-flex">
              <a href="">
                <i class="mdi mdi-cellphone-android"></i
                >{{ $t("appDownload") }}</a
              >
              <div class="dropdown right1 md_2 hr_">
                <span
                  class="dropdown-toggle"
                  role="menu"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  ><a href="#" class=""
                    >{{ langText }}&nbsp;

                    <i class="fas fa-angle-down"></i>
                  </a>
                </span>
                <div class="dropdown-menu r_menu">
                  <a
                    class="dropdown-item"
                    href="#"
                    v-for="(entry, i) in languages"
                    :key="i"
                    @click="changeLocale(entry)"
                    >{{ entry.name }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="header-right">
            <div class="navbar text-capitalize pr-0">
              <div class="dropdown right1 md_acc">
                <span
                  class="dropdown-toggle"
                  role="menu"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  ><a href="#" class="text-capitalize" v-if="!loggedIn">
                    {{ $t("myaccount") }}&nbsp; <i class="fas fa-angle-down"></i
                  ></a>
                  <a href="#" class="text-capitalize" v-else>
                    {{ $t("hi") }}
                    {{ userName | truncate(12, "...") }}&nbsp;
                    <i class="fas fa-angle-down"></i>
                  </a>
                </span>
                <div
                  class="dropdown-menu r_menu dropdown-menu-rig"
                  v-if="!loggedIn"
                >
                  <router-link class="dropdown-item" :to="{ name: 'signin' }">{{
                    $t("login")
                  }}</router-link>
                  <router-link class="dropdown-item" :to="{ name: 'signup' }">{{
                    $t("register")
                  }}</router-link>
                </div>
                <div class="dropdown-menu r_menu dropdown-menu-rig" v-else>
                  <router-link class="dropdown-item" :to="{ name: 'customer' }"
                    >{{ $t("Dashboard") }}
                  </router-link>
                  <a class="dropdown-item" @click.prevent.stop="logout()">{{
                    $t("logout")
                  }}</a>
                </div>
              </div>
              <!-- ---------------------------- -->
              <a href="" class="hr_">{{ $t("sellerCenter") }}</a>
              <!-- ---------------------------- -->
              <a href="" class="hr_">{{ $t("supportCenter") }}</a>
              <!-- ---------------------------- -->
              <div class="right1 hr_ dropdown">
                <span
                  class="dropdown-toggle"
                  role="menu"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <a href="#" class="text-capitalize">
                    {{ $t("afshpIntl") }}<i class="mdi mdi-arrow-top-right"></i
                  ></a>
                </span>

                <div class="dropdown-menu">
                  <router-link
                    class="dropdown-item"
                    v-for="country in countries"
                    :key="country.id"
                    :to="{
                      name: 'AfshopIntl',
                      params: { short_name: country.short_name },
                    }"
                  >
                    {{ country.name }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Lower Header -->
      <div class="header-lower" v-if="$route.name != 'paymethod'">
        <Header></Header>
      </div>
      <div class="header-lower" v-else>
        <PaymentHeader></PaymentHeader>
      </div>
    </header>
  </div>
</template>

<script>
import Header from "@/components/Header";
import PaymentHeader from "@/components/customer/PaymentHeader";
import $ from "jquery";
import { mapGetters, mapState, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { mixin as clickaway } from "vue-clickaway";

import "@/assets/css/home/top-nav.css";

export default {
  mixins: [clickaway],

  components: {
    Header,
    PaymentHeader,
    Loading,
  },

  data() {
    return {
      drawerLinks: [
        {
          name: "Home",
          route: "Home",
        },
        {
          name: "Categories",
        },
        {
          name: "Afeshop International",
        },
        {
          name: "Seller Center",
          route: "shops",
          line: true,
        },
        {
          name: "Account",
          route: "account-index",
        },
        {
          name: "Track Order",
          route: "purchases",
        },
        {
          name: "Orders",
          route: "purchases",
        },
        {
          name: "Statistics",
          route: "statistics",
        },
        {
          name: "Shopping Cart",
          route: "cart",
        },
        {
          name: "Comments",
          route: "comments",
        },
        {
          name: "Vouchers",
          route: "voucher",
          line: true,
        },
        {
          name: "Support Center",
          route: "support",
        },
      ],
      categories: [],
      countries: [],
      loading: false,
      height: 80,
      width: 100,
      transition: "fade",
    };
  },

  methods: {
    ...mapActions(["getIntlCountries", "getIntlProducts", "logout"]),

    closeDrawer() {
      document.getElementById("side-menu").style.width = "0";
      document.getElementById("main").style.marginLeft = "0";
      this.$root.$emit("closeDrawer", { ml: "0" });
    },

    away: () => {
      console.log("nav clicked away");
    },

    showCountrySites() {
      let dropdown = $("#countrySites.dropdown-container");

      console.log(dropdown);

      if (dropdown.hasClass("active")) {
        dropdown.removeClass("active");
        $(".caret1.mdi-chevron-down").removeClass("rotate");
      } else {
        dropdown.addClass("active");
        $(".caret1.mdi-chevron-down").addClass("rotate");
      }
    },

    showCategories() {
      let dropdown = $("#categories.dropdown-container");

      console.log(dropdown);

      if (dropdown.hasClass("active")) {
        dropdown.removeClass("active");
        $(".caret2.mdi-chevron-down").removeClass("rotate");
      } else {
        dropdown.addClass("active");
        $(".caret2.mdi-chevron-down").addClass("rotate");
      }
    },

    getCountryProducts(country) {
      console.log("Conuntry selected: ", country);
      this.loading = true;

      this.getIntlProducts({
        short_name: country.short_name,
      }).then(() =>
        console.log("International Products Nav: ", this.intlPrdts)
      );
    },
  },

  computed: {
    ...mapGetters([
      "getIntlCtries",
      "getIntlProdts",
      "loggedIn",
      "getUserDetails",
    ]),
    ...mapState(["intlCountries", "intlPrdts"]),

    userName() {
      if (this.loggedIn) {
        return this.user_ ? this.user_.username : this.getUserDetails.username;
      }
    },
  },

  created() {
    this.$root.$on("navDrawer", (props) => {
      document.getElementById("side-menu").style.width = props.width;
      document.getElementById("main").style.marginLeft = props.ml;
      console.log("navdrawer entered");
    });

    this.getIntlCountries().then(() => {
      this.countries = this.countries_
        ? this.countries_.countries
        : this.intlCountries.countries;

      console.log("InlCountries: ", this.countries);

      console.log("Registered Countries: ", this.countries_.countries);
    });

    console.log("this user is : ", this.user_);
  },

  mounted() {
    var hideNav = $("#side-menu");
    console.log(hideNav);

    var loc = JSON.parse(localStorage.getItem("language"));

    if (loc) {
      this.changeLocale(loc);
    }

    console.log("user Name: ", this.userName);

    console.log("locale: ", loc);
  },
};
</script>

<style scoped>
.header {
  font-size: 0.8rem;
}

.header-top {
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  color: #534f59 !important;
  background: #f7f6f4;
}

.md-theme-default a:not(.md-button) {
  color: #534f59 !important;
}
.md-theme-default a:not(.md-button):hover {
  color: #fba618 !important;
}

.separator {
  height: 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  margin: 0 12px;
}

.w-lg-max {
  flex: 1;
  max-width: 100%;
}

.header-search {
  position: relative;
}

.header-search-wrapper {
  display: flex;
  position: static;
  right: -2.3rem;
  z-index: 999;
  margin: 0;
  border-width: 0;
  color: #8d8d8d;
  box-sizing: content-box;
  height: 40px;
  border-radius: 25px;
  border: 1px solid grey;
}

.header-search .form-control,
.header-search .select-custom {
  background: #f4f4f4;
}

.header-search-category .form-control {
  border-radius: 5rem 0 0 5rem;
}

.header-search-inline .form-control {
  min-width: 21rem;
  padding: 0 1rem;
}

.header-search .form-control,
.header-search select {
  margin: 0;
  border: 0;
  color: inherit;
  font-size: 1rem;
  height: 100%;
  box-shadow: none;
}

.header-search-wrapper .select-custom {
  margin: 0;
  min-width: 13rem;
  flex: 0 0 13rem;
}

.header-search-wrapper .select-custom:after {
  font-size: 1.4rem;
  line-height: 0;
  right: 14px;
}

.mdi-chevron-down::before {
  transform: translateY(-10%);
}

.mdi-chevron-down.rotate::before {
  transform: translateY(-10%) rotate(180deg);
}

.form-control {
  height: 6rem;
  margin-bottom: 1rem;
  padding: 2rem;
  transition: all 0.3s;
  border: 1px solid #dfdfdf;
  border-radius: 0;
  background-color: #fff;
  color: #777;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
}

.select-custom:after {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 1.9rem;
  transform: translateY(-50%);
  font-family: "porto";
  font-size: 1.5rem;
  content: "\e81c";
}

.side-nav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background: #111;
  opacity: 0.98;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.7s;
}

.side-nav a {
  padding: 10px 10px 10px 30px;
  text-decoration: none;
  font-size: 14px;
  color: #f4f4f4 !important;
  display: block;
  transition: 0.3s;
  text-transform: uppercase;
  border-bottom: 1px solid #f4f4f407;
}

.side-nav button {
  color: #f4f4f4;
}

.side-nav a:hover {
  color: #fba618;
}

.side-nav a:focus {
  color: #fba618 !important;
}

.side-nav .btn-close {
  position: absolute;
  top: 0;
  right: 2px;
  font-size: 33px;
  margin-left: 50px;
  margin-top: -8px;
  color: #fba618;
}

.dropdown-btn {
  transition: 1s;
}

.dropdown-container {
  background-color: #262626;
  padding-left: 8px;
  /* transition: all 1s ease-in; */
  overflow: hidden;
  /* transform: translateY(50%);
  transition: transform 1s; */
  display: none;
  transition: all 3s ease;
}

.dropdown-container.active {
  display: block;
}

.dropdown-container a:not(.md-button) {
  color: #908d94 !important;
}

.afsh-intl.border-orange {
  border: 2px solid #fba618 !important;
}

a:not(.md-button).afsh-intl {
  color: #fba618 !important;
}
a:not(.md-button):hover.afsh-intl {
  color: #ff4500 !important;
}
a.afsh-intl {
  /* background: rgb(48, 43, 43); */
}

@media (orientation: landscape) {
  .header-lower.fixed-top {
    position: unset !important;
  }
}
</style>
