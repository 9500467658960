import { UserService, EndPointError } from "@/services/user";

const state = {
  userCoupons: null,
  couponError: null,
  EndPointError: "",
};

const mutations = {
  applyCouponError(state, { errorCode, errorMessage }) {
    state.couponError = { code: errorCode, msg: errorMessage };
    console.log("apply Coupon error", state.couponError);
  },

  SET_COUPONS(state, payload) {
    state.userCoupons = payload;
    localStorage.setItem("user_coupons", JSON.stringify(payload));
    console.log("set coupons", state.userCoupons);
  },
};

const getters = {
  getStoredCoupons: (state) => state.userCoupons,

  EndPointError: (state) => state.EndPointError,
};

const actions = {
  async getCoupons({ commit }, payload) {
    try {
      const response = await UserService.getCoupons(payload);

      console.log("Get Coupons index: ", response);
      commit("SET_COUPONS", response);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async applyCoupon({ commit }, payload) {
    try {
      const response = await UserService.applyCoupon(payload);

      // console.log("Apply Coupon index: ", response);

      return response;
    } catch (e) {
      if (e instanceof EndPointError) {
        console.error("apply coupon error", e);
        commit("applyCouponError", {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }
      return false;
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
