var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],staticClass:"row subcategories shadow-sm bg-white card rounded-0 border-0",on:{"mouseover":_vm.setActive,"mouseleave":_vm.deactivate}},_vm._l((_vm.children),function(child){return _c('div',{key:child.id,staticClass:"col-md-4"},[_c('div',[_c('h6',{staticClass:"border-bottom py-2 text-capitalize"},[_vm._v(_vm._s(child.name))]),_c('ul',{staticClass:"list-group px-0 mb-4"},_vm._l((child.child),function(subs){return _c('li',{key:subs.id,staticClass:"list-group-item border-0 py-1 px-0"},[_c('router-link',{attrs:{"to":{
              name: 'category',
              params: {
                parentSlug: child.slug,
                childSlug: subs.slug,
                catId: subs.id,
                parent: child,
                child: subs,
              },
            }}},[_vm._v(_vm._s(subs.name))])],1)}),0)])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }