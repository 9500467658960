import { ItemService } from "@/services/item";

const state = {
  intlCountries: null,
};

const mutations = {
  SET_INTL_CTRIES(state, payload) {
    state.intlCountries = payload;
    localStorage.setItem("intlCountries", JSON.stringify(payload));
    console.log("state intl countries: ", state.intlCountries);
  },
};

const getters = {
  getIntlCtries: (state) => state.intlCountries,
};

const actions = {
  /**
   * International Countries response
   */
  async getIntlCountries({ commit }) {
    try {
      const response = await ItemService.getRegisteredCountries();

      console.log("INTL countries index: ", response);
      commit("SET_INTL_CTRIES", response);

      return true;
    } catch (e) {
      console.log("IntlCountries error: ", e);
      return false;
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
