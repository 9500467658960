<template>
  <div class="row">
    <div class="col p-0">
      <!-- messages -->
      <div
        :class="['alert', message.alert, 'mb-0']"
        class="text-capitalize"
        v-if="message"
      >
        <strong class="text-capitalize">{{ message.type }}!</strong>
        {{ message.message }}
        <button
          type="button"
          class="close mt-n1"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: null,
    };
  },
  mounted() {
    this.$root.$on("infoMsg", (obj) => {
      this.message = obj;
      console.log("Info Object: ", obj);
      setTimeout(() => {
        this.message = null;
      }, 15000);
    });
  },
};
</script>