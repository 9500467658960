<template>
  <div class="row my-6" v-show="brands">
    <div class="col-md-12 px-0 py-0">
      <div
        class="
          col-md-12
          px-0
          section-head
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <h3 class="sectionTitle my-0 px-md-0 px-lg-0 pl-3 pr-2">
          <i class="common afeshop_brands"></i>
          {{ $t("afshpBrands") }}
        </h3>
      </div>

      <div v-if="brands.length > 0">
        <carousel
          id="brands-crs"
          :items="7"
          :dots="false"
          :margin="3"
          :padding="3"
          :autoplay="false"
          :autoplaySpeed="2000"
          :nav="false"
          :loop="true"
          :rewind="true"
          :responsive="{
            0: { items: 2, nav: false, autoplay: true },
            600: { items: 4, nav: false, autoplay: true },
            900: { items: 6, nav: false },
            1296: { items: 7, nav: false },
          }"
          class="slidecard border-0 mx-3 mx-lg-auto mx-md-auto"
        >
          <!-- px-2
            py-md-auto py-lg-auto py-0
            my-0 my-md-auto my-lg-auto -->
          <template slot="prev"
            ><button class="rounded owl-prev mb-5">
              <i class="mdi mdi-chevron-left"></i></button
          ></template>

          <!-- <div > -->
          <router-link
            :to="{ name: 'shop-view', params: { shop_id: brand.id } }"
            class="border-0 rounded-0 card mt-1 py-4"
            v-for="brand in brands"
            :key="brand.id"
          >
            <div class="prdtImage">
              <img
                :src="brand.avatar_url"
                @error="defaultImage"
                class="img-fluid"
              />
            </div>
          </router-link>
          <!-- </div> -->

          <template slot="next"
            ><button class="rounded owl-next">
              <i class="mdi mdi-chevron-right"></i></button
          ></template>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    brandItems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {};
  },

  methods: {},

  computed: {
    brands() {
      return this.brandItems;
    },
  },

  created() {
    console.log("brands: ", this.brands);
    console.log("brandItems: ", this.brandItems);
  },
};
</script>

<style scoped>
.sectionTitle .afeshop_brands {
  background: url(../../assets/brands.png) no-repeat;
  background-size: cover;
}

.prdtImage {
  transition: all 0.2s linear;
}

.item {
  padding-left: 1px;
  padding-right: 1px;
}

.prdtImage .img-fluid {
  object-fit: contain;
  -o-object-position: center;
  object-position: center;
  width: 100%;
  height: 8.5em;
}

.prdtName {
  font-size: 16px;
  font-weight: normal;
  text-align: center;
}

a.card {
  color: black !important;
}

a > .prdtName {
  color: black !important;
}

@media (max-width: 600px) {
  .prdtImage .img-fluid {
    height: 10em;
    object-fit: contain;
  }

  .card.slidecard {
    background: transparent;
    padding: 25px;
    margin: 15px;
  }
}
</style>
