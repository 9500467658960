<template>
  <div class="container">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="var(--afprimary)"
      :height="height"
      :width="width"
      :transition="transition"
      background-color="#000"
    ></loading>

    <div class="row">
      <div class="col-3"></div>
      <div class="col-12 col-12 col-md-6 col-lg-6">
        <v-alert v-show="msgInfo" dense elevation="4" text :type="msgType">{{
          msgInfo
        }}</v-alert>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-between align-items-center mb-1">
              <h5 class="text-muted text-uppercase mb-0">
                {{ $t("OrderSummary") }}
              </h5>
              <a href="" class="text-primary text-uppercase see"
                >{{ $t("Seedetails") }}
                <span class="my-auto"
                  ><i class="mdi mdi-chevron-right font-15 my-auto"></i></span
              ></a>
            </div>

            <!-- Total to pay -->
            <div class="card shadow-sm">
              <div class="card-body py-7 px-6">
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    total-summ
                  "
                >
                  <h4 class="text-uppercase mb-0">{{ $t("TotaltoPay") }}</h4>

                  <h4 class="text-uppercase mb-0">
                    {{ cart_items.amount | currency("XFA ") }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="text-muted text-uppercase">
                {{ $t("Chooseapaymentmethod") }}
              </h5>
            </div>

            <!-- payment methods radio buttons -->
            <form class="" action="">
              <div class="accordion" id="accordionPayment">
                <!-- Pay by bank -->
                <div class="card shadow-sm border-0 mb-3 px-1">
                  <div class="card-header py-5 bg-transparent" id="headingOne">
                    <h4
                      class="
                        mb-0
                        d-flex
                        justify-content-between
                        align-items-center
                      "
                    >
                      <a
                        class="btn btn-link btn-block px-0"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                        @click="payType = 'bank'"
                      >
                        <div class="form-group my-0">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="bank"
                            id="bank"
                            value="bank"
                            v-model="payType"
                          />
                          <label class="radio form-check-label" for="bank">
                            {{ $t("PaybyBankTransfer") }}
                          </label>
                        </div>
                      </a>
                      <img
                        src="@/assets/images/mastercard-01.png"
                        alt="bank mastercard"
                        class="img-fluid pm-image"
                      />
                    </h4>
                  </div>
                  <div
                    id="collapseOne"
                    class="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordionPayment"
                  >
                    <div class="card-body">
                      <md-field>
                        <label>{{ $t("CardNumber") }}</label>
                        <md-input v-model="cardNum"></md-input>
                      </md-field>

                      <p class="mb-0 font-12">{{ $t("ExpireDate") }}</p>

                      <div class="row">
                        <div class="col-5">
                          <md-field md-inline>
                            <label for="movies">MM</label>
                            <md-select v-model="month" name="month" id="month">
                              <md-option :value="i" v-for="i in 12" :key="i">{{
                                i | zeroPad
                              }}</md-option>
                            </md-select>
                          </md-field>
                        </div>
                        <div class="col-5">
                          <md-field md-inline>
                            <label for="movies">YY</label>
                            <md-select v-model="year" name="year" id="year">
                              <md-option
                                :value="y"
                                v-for="(y, i) in years"
                                :key="i"
                                >{{ y }}</md-option
                              >
                            </md-select>
                          </md-field>
                        </div>
                        <div class="col-2">
                          <md-field md-inline>
                            <label>CVV</label>
                            <md-input
                              v-model="cvv"
                              type="tel"
                              maxlength="3"
                            ></md-input>
                          </md-field>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Pay with MoMo -->
                <div class="card shadow-sm border-0 mb-3 px-1">
                  <div class="card-header py-5 bg-transparent" id="headingTwo">
                    <h4
                      class="
                        mb-0
                        d-flex
                        justify-content-between
                        align-items-center
                      "
                    >
                      <a
                        class="btn btn-link btn-block px-0"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                        @click="payType = 'mtn'"
                      >
                        <div class="form-group my-0">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="mtn"
                            id="mtn"
                            value="mtn"
                            v-model="payType"
                          />
                          <label class="radio form-check-label" for="mtn">
                            {{ $t("payWithMoMo") }}
                          </label>
                        </div>
                      </a>

                      <img
                        src="@/assets/images/momo-01.png"
                        alt="bank mastercard"
                        class="img-fluid pm-image"
                      />
                    </h4>
                  </div>
                  <div
                    id="collapseTwo"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionPayment"
                  >
                    <div class="card-body">
                      <label class="font-15 mb-4">{{ $t("mtnPhone") }}</label>
                      <vue-tel-input
                        v-bind="bindProps"
                        v-on:country-changed="countryChanged"
                        v-model="momoNumber"
                      ></vue-tel-input>
                    </div>
                  </div>
                </div>

                <!-- Pay with Omo -->
                <div class="card shadow-sm border-0 mb-3 px-1">
                  <div
                    class="card-header py-5 bg-transparent"
                    id="headingThree"
                  >
                    <h4
                      class="
                        mb-0
                        d-flex
                        justify-content-between
                        align-items-center
                      "
                    >
                      <a
                        class="btn btn-link btn-block px-0"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                        @click="payType = 'orange'"
                      >
                        <div class="form-group my-0">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="orange"
                            id="orange"
                            value="orange"
                            v-model="payType"
                          />
                          <label class="radio form-check-label" for="orange">
                            {{ $t("payWithOmo") }}
                          </label>
                        </div>
                      </a>

                      <img
                        src="@/assets/images/omo-01.png"
                        alt="orange"
                        class="img-fluid pm-image"
                      />
                    </h4>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionPayment"
                  >
                    <div class="card-body">
                      <label class="font-15 mb-4">{{
                        $t("orangePhone")
                      }}</label>
                      <vue-tel-input
                        v-bind="bindProps"
                        v-on:country-changed="countryChanged"
                        v-model="omoNumber"
                      ></vue-tel-input>
                    </div>
                  </div>
                </div>
              </div>

              <button
                class="
                  btn btn-primary btn-block
                  text-uppercase
                  font-weight-bold
                  py-2
                  shadow-sm
                "
                @click.prevent="payNow()"
              >
                {{ $t("PayNow") }}: {{ cart_items.amount | currency("XFA ") }}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-3"></div>
    </div>

    <v-dialog v-model="momoLoader" hide-overlay persistent width="450">
      <v-card color="orange lighten-2" class="py-5">
        <v-card-text v-if="msgInfo">
          {{ msgInfo }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import helpers from "@/store/helpers.js";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import axios from "axios";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      payType: null,
      cardNum: null,
      month: null,
      year: null,
      years: null,
      cvv: null,
      orders: null,
      cart_items: null,
      momoNumber: "",
      omoNumber: "",
      country: "",
      countryCode: "",
      bindProps: {
        mode: "national",
        placeholder: "Phone Number",
        required: false,
        enabledCountryCode: true,
        enabledFlags: true,
        autocomplete: "on",
        name: "telephone",

        onlyCountries: ["CM"],
        maxLen: 25,
        inputOptions: {
          showDialCode: true,
        },
      },

      p_uuid: null,
      p_when: null,
      addr: null,
      msgType: null,
      msgInfo: null,

      transaction_id: "",
      momoLoader: false,
      pendingValidation: false,

      loading: false,
      height: 80,
      width: 100,
      transition: "fade",
    };
  },

  methods: {
    ...mapActions(["validateOrder", "getPaymentStatus", "getOrders"]),
    ...mapMutations(["setNoCartItems"]),

    countryChanged(country) {
      this.country = country.name;
      this.countryCode = country.iso2;
    },

    payNow() {
      this.loading = true;

      this.validateOrder({
        uuid: this.p_uuid,
        when: this.p_when,
        method: this.payType,
        phone: this.transNumber,
      }).then((response) => {
        console.log("checkout order paymethod", response);
        this.loading = false;
        if (response) {
          console.log("response checkout: ", response);
          this.msgInfo = response.message;
          // this.msgType = "success";
          this.transaction_id = response.data.transaction_id;

          localStorage.removeItem("appliedCoupon");

          this.momoLoader = true;

          // this.$router.push({ name: "customer" });

          // this.$root.$emit("infoMsg", {
          //   alert: "alert-success",
          //   type: "success",
          //   message: this.msgInfo,
          // });

          // this.$router.push({ name: "paymentInfo" });
        } else if (this.checkoutError) {
          if (this.checkoutError.code === "VALIDATION_ERROR") {
            this.msgInfo = "Please select a payment method.";
            this.msgType = "error";
          } else {
            this.msgInfo = this.checkoutError.msg;
            this.msgType = "error";
          }
        }
      });
    },

    checkMomoStatus() {
      this.pendingValidation = true;

      // while (this.pendingValidation) {
      setTimeout(() => {
        if (this.transaction_id) {
          // this.loading = true;
          // this.momoLoader = true;
          this.getPaymentStatus(this.transaction_id).then((response) => {
            console.log("checking momo status:", response);
            if (response.code === "SUCCESSFUL") {
              this.msgInfo = response.message;
              this.msgType = "success";
              this.pendingValidation = false;
              this.momoLoader = false;

              this.$root.$emit("infoMsg", {
                alert: "alert-success",
                type: "success",
                message: this.msgInfo,
              });

              this.getOrders().then((respon) => {
                console.log("updating orders", respon);
                this.setNoCartItems();
              });

              this.$router.push({ name: "paymentInfo" });
            } else if (response.code === "PENDING_REQUEST") {
              console.log("check momo at pendiing request", response);
              this.pendingValidation = true;
            } else {
              this.momoLoader = false;
              this.pendingValidation = false;

              this.msgInfo = response.message;
              this.msgType = "error";
              console.log("check momo error within: ", response);
            }

            // this.loading = false;
          });
        }
        console.log("momoLoader on");
      }, 10000);
      // }

      if (this.pendingValidation) {
        this.momoLoader = true;
        // this.checkMomoStatus();
      } else {
        this.momoLoader = false;
      }
    },
  },

  watch: {
    momoLoader(val) {
      if (!val) return;

      this.checkMomoStatus();
    },
  },

  created() {
    this.years = helpers.generateArrayOfYears();

    // load orders
    this.orders = this.orders_.data ? this.orders_.data : this.getCachedOrders;
    this.orders.forEach((order) => {
      if (order.status === "cart") {
        this.cart_items = order;
      }
    });
    this.p_uuid = this.$route.params.uuid;
    this.p_when = this.$route.params.when;
    this.addr = this.$route.params.addr;
    console.log("pMI", JSON.parse(localStorage.getItem("payMethodInfo")));

    if (
      JSON.stringify(JSON.parse(localStorage.getItem("payMethodInfo"))) ==
        JSON.stringify({}) &&
      JSON.stringify(JSON.parse(localStorage.getItem("payMethodInfo"))) !==
        JSON.stringify({
          p_uuid: this.$route.params.uuid,
          p_when: this.$route.params.when,
        })
    ) {
      localStorage.setItem(
        "payMethodInfo",
        JSON.stringify({
          p_uuid: this.$route.params.uuid,
          p_when: this.$route.params.when,
        })
      );
    }

    if (
      JSON.stringify(JSON.parse(localStorage.getItem("payMethodInfo"))) ==
      JSON.stringify({})
    ) {
    }

    console.log(
      "props payment: ",
      this.$route.params.uuid,
      this.$route.params.when,
      this.$route.params.addr
    );
  },

  computed: {
    ...mapGetters(["getCachedOrders"]),
    ...mapState({
      userDetails: "user",
      userAddresses: "userAddresses",
      ordersList: "ordersList",
      checkoutError: "checkoutError",
    }),

    transNumber() {
      if (this.payType == "mtn") {
        return "+237" + this.momoNumber.trim().replaceAll(" ", "");
      }

      if (this.payType == "orange") {
        return "+237" + this.omoNumber.trim().replaceAll(" ", "");
      }

      if (this.payType == "bank") {
        return this.cvv.trim().replaceAll(" ", "");
      }
    },
  },

  mounted() {
    $(window).load(function () {
      $("#loading").hide();
    });

    // load payment method props
    if (localStorage.getItem("payMethodInfo")) {
      console.log(
        "payMethodInfo",
        JSON.parse(localStorage.getItem("payMethodInfo"))
      );
      this.p_uuid = JSON.parse(localStorage.getItem("payMethodInfo")).p_uuid;
      this.p_when = JSON.parse(localStorage.getItem("payMethodInfo")).p_when;
    } else {
    }
  },
};
</script>

<style scoped>
.bs-example {
  margin: 20px;
}
input[type="radio"]:checked + label:before {
  background-color: #444aff;
  border: 1.5px solid #444aff;
}

input[type="radio"] + label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 5px;
  margin-right: 25px;
  background-clip: content-box;
  border: 2px solid #bbb;
  background-color: #e7e6e7;
  border-radius: 50%;
  font-weight: 500;
}

label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 17px;
}

input[type="radio"] {
  display: none;
}

.btn-link:hover {
  color: #000;
  text-decoration: none;
}

a:not(.md-button) {
  color: #000 !important;
}

.pm-image {
  width: 3em;
  object-fit: cover;
}

h5 {
  letter-spacing: 1.5px;
  font-size: 14px;
}

a.see {
  letter-spacing: 1px;
  font-size: 0.8rem;
}

a.see:not(.md-button) {
  color: red !important;
  font-weight: 500;
}

.total-summ h4 {
  font-size: 19px;
  color: #000000b8;
  letter-spacing: 1px;
}

.md-field.md-theme-default.md-focused .md-input {
  color: #000000b8 !important;
}
.md-field.md-theme-default.md-focused label {
  color: #000000b8 !important;
}
.md-field.md-theme-default:before {
  background-color: #444aff !important;
}

.vue-tel-input {
  border-radius: 0;
  display: flex;
  border-bottom: 1.5px solid #747474 !important;
  border: transparent;
  text-align: left;
  min-height: 44px;
  line-height: 4em;
  font-size: 20px;
  margin-top: -1.5em;
  margin-bottom: 1em;
}

.vue-tel-input:focus {
  border: turquoise;
}
</style>
