export default {
  methods: {
    countdown() {
      if (this.details.item_discount) {
        let t = Math.abs(
          new Date() - new Date(this.details.item_discount.end_date)
        );
        let seconds = Math.floor((t / 1000) % 60);
        let minutes = Math.floor((t / 1000 / 60) % 60);
        let hours = Math.floor(t / (1000 * 60 * 60));
        let days = Math.floor(t / (1000 * 60 * 60 * 24));
        if (t > 0) {
          this.currentTime = {
            total: t,
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
          };
          setTimeout(this.countdown, this.speed);
        } else {
          this.currentTime = null;
        }
      } else {
      }
    },
  },
};
