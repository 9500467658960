<template>
  <div id="shopList" class="">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="var(--afprimary)"
      :height="height"
      :width="width"
      :transition="transition"
    ></loading>

    <!-- Title and Filter -->
    <div class="shops-banner py-3 mb-3">
      <div class="container">
        <div
          class="d-flex justify-content-between align-items-center text-white"
        >
          <div class="co px-0">
            <h4>{{ $t("AfeshopShops") }}</h4>
          </div>
          <div class="align-items-center d-flex px-0">
            <span class="mr-3 my-auto">{{ $t("Sortby") }}:</span>
            <form class="filter-ordering float-right d-flex">
              <select
                name="sortby"
                class="sortby form-control"
                aria-label="Sort by Shop Category"
                @change="onSortChange($event)"
              >
                <option
                  :value="item.id"
                  v-for="(item, index) in sortCategories"
                  :key="index"
                >
                  {{ item.name }}
                </option>
              </select>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row card-group" v-if="shopList">
        <div
          class="col-md-6 col-lg-6 col-12 px-2 py-2"
          v-for="shop in shopList"
          :key="'shopId-' + shop.id"
        >
          <div
            class="
              card
              rounded
              border-0
              shadow-sm
              px-md-3 px-lg-3 px-4
              py-md-4 py-lg-4 py-5
            "
          >
            <div class="row shopsDisplay">
              <router-link
                :to="{ name: 'shop-view', params: { shop_id: shop.id } }"
                class="col-md-4 col-lg-4 col-sm-4 col-12"
              >
                <img
                  :src="shop.avatar_url"
                  :alt="shop.name + ' avatar'"
                  @error="defaultImage"
                  class="img-fluid shopImg"
                />
              </router-link>

              <div class="col-md-8 col-lg-8 col-sm-8 col-12 pl-2">
                <router-link
                  :to="{ name: 'shop-view', params: { shop_id: shop.id } }"
                >
                  <h5 class="text-capitalize">{{ shop.name }}</h5>
                </router-link>

                <p class="mb-0">
                  {{ shop.description | truncate(200, "...") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/shop/index.css";
import { mapGetters, mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      shopList: null,

      loading: true,
      transition: "fade",
      height: 80,
      width: 100,

      sortCategories: ["Fashion", "Computing", "Food", "Automobile"],
    };
  },

  computed: {
    ...mapGetters(["getShopList"]),
  },

  methods: {
    ...mapActions(["getShops", "getMainCategories", "getFilteredShops"]),

    onSortChange(e) {
      this.loading = true;
      console.log("sorting by: ", e.target.value);
      this.getFilteredShops(e.target.value).then((response) => {
        console.log("filtered shops: ", response);
        if (response.brands.data.length > 0) {
          this.shopList = response.brands.data;
        }
        this.loading = false;
      });
    },
  },

  created() {
    this.loading = true;
    this.getShops().then(() => {
      this.shopList = this.shopList_
        ? this.shopList_.brands.data
        : this.getShopList.brands.data;

      console.log("Shop List: ", this.shopList);

      if (this.shopList) {
        console.log("shop list length", this.shopList);
        this.loading = false;
      }
    });

    this.getMainCategories().then((response) => {
      this.sortCategories = response.all_categories;
      console.log("main categories response: ", this.sortCategories);
    });
  },
};
</script>
