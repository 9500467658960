<template>
  <div id="shopView" class="">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="var(--afprimary)"
      :height="height"
      :width="width"
      :transition="transition"
      background-color="#000"
    ></loading>

    <!-- Main Section -->
    <section class="container">
      <listing-widget
        :widgetName="'Shop View'"
        :widgetImage="
          shopInfo
            ? shopInfo.cover_url
            : require('@/assets/images/sample-banner.jpg')
        "
        :widgetInfo="info"
        :banner="true"
        :colors="colorFeatures"
        :categories="categoriesList"
        :products="shopProducts_s"
        :otherFilters="featuresNonColor"
        :filterRequest="filterRequest"
      >
        <template #categoryLoader>
          <div class="row text-center">
            <div class="col-12 pb-1" v-show="categoryLoading">
              {{ $t("Loading") }}...
            </div>
            <div class="col-12 mb-4 pt-0">
              <v-progress-linear
                color="orange accent-3"
                :indeterminate="categoryLoading"
                :active="categoryLoading"
                rounded
                height="6"
              ></v-progress-linear>
            </div>
          </div>
        </template>
      </listing-widget>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import apiService from "@/main";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ListingWidget from "@/components/widgets/GenericListing.vue";

export default {
  components: {
    Loading,
    ListingWidget,
  },
  data() {
    return {
      products: [],
      productInfo: null,
      disable: false,
      page: 1,
      last_page: 0,
      pageSize: 5,
      loading: false,
      height: 80,
      width: 100,
      transition: "fade",
      shopInfo: null,
      shopProducts: [],
      shopProducts_s: [],
      coverImageDefault: require("@/assets/no image.png"),
      overseaCountries: [
        "Cameroon",
        "Nigeria",
        "Benin",
        "France",
        "USA",
        "UK",
        "China",
      ],
      headings: ["Latest Products", "Super Deals", "All"],
      sortList: [
        "Alphabetical",
        "Newest",
        "Best Selling",
        "Price Ascending",
        "Price Descending",
      ],
      discounts: [],
      selectedCriteria: "",
      rtext:
        " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam, ad, adipisicing elit. Quam, ad, adipisicing elit. Quam, ad!",
      gridView: true,
      listView: false,
      selectedDiscount: null,
      shopName: "",

      categoriesList: [],
      values: [],
      keys: [],

      colorfilters: [],
      categoryfilters: [],
      featurefilters: [],
      currentMin: null,
      currentMax: null,

      colorFeatures: null,
      featuresNonColor: [],
    };
  },

  methods: {
    ...mapActions([
      "addToCart",
      "getGuestData",
      "getOrders",
      "getShopDetails",
      "getShopCategories",
      "filterCategories",
      "searchItemByRange",
      "searchItemByFeatures",
    ]),

    newPrice(price, discount) {
      return price - discount * price;
    },

    defaultCover(e) {
      e.target.src = this.coverImageDefault;
    },

    changeSelectSort({ data, text }) {
      console.log("Change Select criteria: ", data);
      this.selectedCriteria = text;
    },

    showGrid() {
      if (this.gridView) {
        this.gridView = false;
        this.listView = true;
      } else {
        this.gridView = true;
        this.listView = false;
      }
    },

    showList() {
      if (this.listView) {
        this.gridView = true;
        this.listView = false;
      } else {
        this.gridView = false;
        this.listView = true;
      }
    },

    filterRequest() {
      this.loading = true;
      // this.loading2 = true;
      this.shopProducts_s = [];
      let colorResp = [];
      let featureResp = [];
      let categoryResp = [];

      if (this.currentMin || this.currentMax) {
        console.log("min: ", this.currentMin, " max: ", this.maxPrice);
        this.searchItemByRange({
          minPrice: this.currentMin ? this.currentMin : this.minPrice,
          maxPrice: this.currentMax ? this.currentMax : this.maxPrice,
        }).then((response) => {
          this.shopProducts_s = [
            ...this.shopProducts_s,
            ...response.items.data,
          ];
          this.loading = false;
          this.shopProducts_s = this.removeDuplicates(this.shopProducts_s);

          console.log("min filter response", this.shopProducts_s);
        });
      } else if (this.colorfilters.length > 0) {
        this.colorfilters.forEach((color) => {
          this.searchItemByFeatures({
            values: color,
            keys: "color",
          }).then((response) => {
            this.loading = false;
            colorResp = [...new Set([...colorResp, ...response.items.data])];
            console.log("color: ", color, " response: ", response);
            this.shopProducts_s = [...this.shopProducts_s, ...colorResp];
            this.shopProducts_s = this.removeDuplicates(this.shopProducts_s);

            console.log("filter colorResp: ", this.shopProducts_s);
          });
        });
      } else if (this.featurefilters.length > 0) {
        this.featurefilters.forEach((feature) => {
          this.searchItemByFeatures({
            values: feature.value,
            keys: feature.key,
          }).then((response) => {
            this.loading = false;
            featureResp = [
              ...new Set([...featureResp, ...response.items.data]),
            ];
            console.log("prev Searchprod", this.shopProducts_s);
            this.shopProducts_s = [...this.shopProducts_s, ...featureResp];
            this.shopProducts_s = this.removeDuplicates(this.shopProducts_s);
            console.log("featureResp: ", featureResp, " response: ", response);
            console.log("filter Shop Products: ", this.shopProducts_s);
          });
        });
      }

      // filter categories
      else if (this.categoryfilters.length > 0) {
        this.categoryfilters.forEach((category) => {
          this.filterCategories({
            category_id: category.id,
          }).then((response) => {
            this.loading = false;
            categoryResp = [
              ...new Set([...categoryResp, ...response.items.data]),
            ];
            this.shopProducts_s = [...this.shopProducts_s, ...categoryResp];
            this.shopProducts_s = this.removeDuplicates(this.shopProducts_s);
            console.log(
              "categoryResp: ",
              categoryResp,
              " response: ",
              response
            );
            console.log("filterCategories: ", this.shopProducts_s);
          });
        });
      } else {
        this.loading = false;
        this.shopProducts_s = this.shopProducts;
      }
    },

    // filterProducts() {
    //   var products = this.getShop.items;
    //   this.shopProducts = [];

    //   if (this.selectedDiscount == "all") {
    //     this.loading = true;

    //     this.shopProducts = products;
    //     console.log("shop products filter ", products);

    //     this.loading = false;
    //   } else {
    //     this.loading = true;

    //     this.shopProducts = [];

    //     products.forEach((product) => {
    //       if (product.item_discount) {
    //         if (
    //           product.item_discount.percentage_decrease == this.selectedDiscount
    //         ) {
    //           this.shopProducts.push(product);
    //         }
    //       }
    //     });
    //     this.loading = false;
    //   }

    //   console.log("selected discount ", this.selectedDiscount);
    // },

    // async filterByHeading(heading) {
    //   var products = this.getShop.items;

    //   if (heading === this.headings[0]) {
    //     this.loading = true;
    //     var array = products;
    //     array.sort(function (a, b) {
    //       var c = new Date(a.created_at);
    //       var d = new Date(b.created_at);
    //       return c - d;
    //     });

    //     this.shopProducts = array;
    //     console.log("sorted array", this.shopProducts);
    //     this.loading = false;
    //   }
    //   if (heading === this.headings[1]) {
    //     this.loading = true;
    //     const resp = await apiService.customRequest({
    //       method: "get",
    //       url: "/items/superdeals",
    //       params: { shop_id: this.$route.params.shop_id },
    //     });

    //     this.shopProducts = resp.data.data.super_deals.data;

    //     this.loading = false;

    //     console.log("shop super deals ", this.shopProducts);
    //   }
    //   if (heading === this.headings[2]) {
    //     this.loading = true;
    //     this.shopProducts = products;
    //     this.loading = false;
    //   }
    // },

    // async filterByCategory(cat_id) {
    //   var products = this.getShop.items;

    //   if (cat_id === "all") {
    //     this.loading = true;

    //     this.shopProducts = products;
    //     this.loading = false;
    //   } else {
    //     this.loading = true;

    //     const resp = await apiService.customRequest({
    //       method: "get",
    //       url: "/category/" + cat_id + "/items/",
    //     });

    //     this.shopProducts = resp.data.data.items;
    //     this.loading = false;

    //     console.log(
    //       "shop category products for",
    //       cat_id,
    //       ", ",
    //       this.shopProducts
    //     );
    //   }
    // },

    categoryProducts(id) {
      this.categoriesList.forEach((category) => {
        if (category.id === id) {
          console.log("Category selected: ", category);
        }
      });
    },

    loadUpStuff() {
      console.log("loading stuff");
      this.featuresNonColor = this.loadFeatures_(this.shopProducts);
      this.colorFeatures = this.loadColorFeatures_();

      this.loadPrdtPrices_(this.shopProducts);
    },
  },

  watch(shopName) {
    console.log("watch shop Name", shopName);
  },

  computed: {
    ...mapGetters([
      "loggedIn",
      "getUserDetails",
      "getGuestDataObj",
      "getShop",
      "getShopCats",
    ]),
    ...mapState(["cart_no"]),

    info() {
      return {
        title: this.shopName,
        description:
          "Just some random caption information provided by the shop owner to draw user's attention!",
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        other: this.shopInfo,
      };
    },
  },

  created() {
    $(".list-card").on("hover", () => {
      console.log("list card hovereth");
    });

    this.loading = true;
    this.getShopDetails({
      id: this.$route.params.shop_id,
    }).then(() => {
      this.shopProducts = this.shopProducts_s = this.shop_
        ? this.shop_.items.data
        : this.getShop.items.data;

      this.shopInfo = this.shop_ ? this.shop_.shop : this.getShop.shop;

      this.info.title = this.shopInfo.name;
      this.info.other = this.shopInfo;
      this.shopName = this.shopInfo.name;

      console.log("Opening shop id ", this.shopProducts);

      console.log("Shop details ", this.shopInfo);

      // loading discounts
      this.shopProducts.forEach((product) => {
        if (product.item_discount) {
          this.discounts.push(product.item_discount.percentage_decrease * 100);
          var uniq_d = [...new Set(this.discounts)];

          this.discounts = uniq_d.sort();
        }
      });

      // load features
      this.loadUpStuff();

      this.loading = false;
    });

    console.log("Shop id ", this.$route.params.shop_id);

    console.log("Selected discount", this.selectedDiscount);

    // loading shop categories
    this.getShopCategories({
      shop_id: this.$route.params.shop_id,
    }).then(() => {
      var categories = this.shopCats_
        ? this.shopCats_.categories
        : this.getShopCats.categories;

      // sort in alphabetical order
      categories = JSON.parse(JSON.stringify(categories));
      categories.sort((a, b) => a.name.localeCompare(b.name));

      this.categoriesList = categories;

      // this.categoriesList.unshift({
      //   id: "all",
      //   name: "All",
      // });

      console.log("Shop Categories List: ", this.categoriesList);
    });

    // ///////////
    this.$root.$on("refreshFilter", (refresh) => {
      if (refresh) {
        console.log("refreshing filter");
        this.filterRequest();
      }
    });

    this.$root.$on("emptyFilters", (empty) => {
      if (empty) {
        this.shopProducts_s = this.shopProducts;
        this.currentMin = null;
        this.currentMax = null;

        console.log(
          "empty min:",
          this.info["minPrice"],
          " max: ",
          this.info["minPrice"]
        );
      }
    });
  },

  mounted() {
    $(".list-card.card").hover(
      function () {
        // console.log("list card hovered");
        $(this).addClass("shadow").css("cursor", "pointer");
      },
      function () {
        $(this).removeClass("shadow");
      }
    );

    this.$root.$on("filterValues", (filters) => {
      console.log("filter request colors", filters.colors);
      console.log("filter request categories", filters.categories);
      console.log("filter request features", filters.features);
      this.colorfilters = filters.colors;
      this.categoryfilters = filters.categories;
      this.featurefilters = filters.features;
    });

    this.$root.$on("currentMin", (min) => {
      console.log("current Min: ", min);
      this.currentMin = min;
    });
    this.$root.$on("currentMax", (max) => {
      console.log("current Max: ", max);
      this.currentMax = max;
    });

    // this.categoriesList = this.loadCategories_(this.shopProducts);
  },
};
</script>

<style scoped></style>
