<template>
  <div class="row">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="#ffa300"
      :height="height"
      :width="width"
      :transition="transition"
      background-color="#000"
    ></loading>
    <div class="col-md-12 py-0">
      <div class="card pane border-0 shadow-sm bg-white py-4 px-5">
        <!-- Header -->
        <div class="card-header border-0 bg-transparent pl-2 pb-2">
          <div class="d-flex align-items-center">
            <button
              @click.stop="$router.go(-1)"
              class="btn btn-lg pl-0 text-dark"
            >
              <i class="mdi mdi-arrow-left"></i>
            </button>
            <h4>{{ $t("OrderDetails") }}</h4>
          </div>
        </div>

        <!-- Content -->
        <div class="row px-2">
          <div class="col-12">
            <!-- Order Info -->
            <div class="row" v-if="rcvdOrder">
              <div class="col-9 pb-0">
                <h6 class="mb-1">{{ $t("OrderNo") }}: {{ rcvdOrder.uuid }}</h6>
                <p class="mb-0">
                  {{ rcvdOrder.order_item.length }}
                  {{ "item" | pluralize(rcvdOrder.order_item.length) }}
                </p>
                <p class="mb-0">
                  {{ $t("PlacedOn") }}:
                  <span>{{ rcvdOrder.transaction.created_at | dateOn }}</span>
                </p>
                <p class="mb-0">
                  {{ $t("OrderAmount") }}:
                  <span>{{ rcvdOrder.amount | currency("XFA ") }}</span>
                </p>
                <p class="mb-0">
                  {{ $t("ShippingFee") }}:
                  <span>{{ rcvdOrder.shipping.fee | currency("XFA ") }}</span>
                </p>
                <p>
                  {{ $t("Total") }}:
                  <span>{{
                    rcvdOrder.transaction.amount | currency("XFA ")
                  }}</span>
                </p>
              </div>
              <div class="col-3 pb-0">
                <button
                  class="btn btn-block btn-light btn-track rounded-0 shadow"
                  v-show="$route.params.type == 'pending'"
                  @click="
                    $router.push({
                      name: 'tracking',
                      params: {
                        order: rcvdOrder,
                        uuid: rcvdOrder.uuid,
                      },
                    })
                  "
                >
                  {{ $t("TrackOrder") }}
                </button>
                <button
                  class="btn btn-block btn-confirm rounded-0 shadow mt-3"
                  @click="show_modal = true"
                  v-show="$route.params.type == 'pending'"
                >
                  {{ $t("Confirmreception") }}
                </button>
              </div>
              <div class="col-12 p-0 mb-4 mt-n3">
                <hr class="mb-2" />
              </div>
            </div>

            <!-- Order Items -->
            <div class="row">
              <div class="col-12 pt-0" v-if="rcvdOrder">
                <h6 class="text-uppercase">{{ $t("Itemsinyourorder") }}</h6>
                <div
                  class="row mt-2 mb-3"
                  v-for="item in rcvdOrder.order_item"
                  :key="item.id"
                >
                  <div class="col-12 border">
                    <div class="row">
                      <div class="col-lg-9 col-md-9 col-12">
                        <div
                          class="badge py-1 text-uppercase"
                          :class="[
                            $route.params.type == 'pending'
                              ? 'badge-info'
                              : $route.params.type == 'completed'
                              ? 'badge-success'
                              : 'badge-secondary',
                          ]"
                        >
                          {{ badgeInfo[`${$route.params.type}`] }}
                        </div>

                        <p class="mt-3 font-15 item-date">
                          {{ $t("On") }} {{ item.created_at | dateOn }}
                        </p>

                        <div class="row">
                          <div class="col-3 pr-1">
                            <img :src="item.item.image_url" alt="" />
                          </div>
                          <div class="col-9">
                            <p class="prdtName">
                              {{ item.item.name }}
                            </p>

                            <p class="text-uppercase">
                              {{ $t("Quantity") }}: {{ item.quantity }}
                            </p>

                            <p class="price">
                              {{ $t("Price") }}:
                              {{ item.amount | currency("XFA ") }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- Order Item actions -->
                      <div class="col-lg-3 col-md-3 col-12">
                        <button
                          class="
                            btn btn-block btn-light btn-comment
                            rounded-0
                            shadow
                            mt-3
                          "
                          @click="commentDialog = true"
                          v-show="$route.params.type == 'completed'"
                        >
                          {{ $t("Comment") }}
                        </button>
                        <button
                          class="
                            btn btn-block btn-light btn-orange
                            rounded-0
                            shadow
                            mt-3
                          "
                          @click="$router.push({ name: 'Home' })"
                          v-show="$route.params.type == 'closed'"
                        >
                          {{ $t("ProceedPayment") }}
                        </button>
                      </div>
                    </div>
                  </div>

                  <modal
                    title="Confirm Reception"
                    name="Confirm"
                    :is-small="false"
                    v-if="show_modal"
                    @close="show_modal = false"
                  >
                    <div class="modal-body py-1">
                      <p>{{ $t("sureOfReception") }} ?</p>
                    </div>

                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        @click="show_modal = false"
                      >
                        {{ $t("noIdont") }}
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="
                          confirmReception();
                          show_modal = false;
                        "
                      >
                        {{ $t("YesIConfirm") }}
                      </button>
                    </div>
                  </modal>

                  <v-dialog
                    v-model="commentDialog"
                    persistent
                    max-width="400px"
                  >
                    <v-card>
                      <v-card-title>
                        <span class="text-h5 text-capitalize">{{
                          $t("reviewMatters")
                        }}</span>
                      </v-card-title>

                      <v-card-text class="mt-3">
                        <div class="d-flex align-items-center mb-3">
                          <span class="text-capitalize"
                            >{{ $t("ratings") }}:</span
                          >

                          <v-rating
                            v-model="rating"
                            background-color="grey-3"
                            color="#ffa300"
                            dense="hover"
                            size="20"
                          ></v-rating>
                          <span
                            class="
                              orange--text
                              text--lighten-1 text-caption
                              mr-2
                            "
                          >
                            ({{ rating }})
                          </span>
                        </div>

                        <div class="mt-2">
                          <span>{{ $t("Comment") }}:</span>
                          <v-textarea
                            solo
                            name="comment"
                            :label="$t('honetsReviewHere')"
                            v-model="comment"
                          ></v-textarea>
                        </div>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="commentDialog = false"
                        >
                          {{ $t("Close") }}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="submitComment(item)"
                        >
                          {{ $t("Submit") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import vPagination from "vue-plain-pagination";
import { mapState, mapActions, mapMutations } from "vuex";
import orders$ from "./purchases.json";
import modal from "../comments/ModalView";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { modal, Loading },
  data() {
    return {
      orders__: orders$.orders,
      rcvdOrder: [],
      rcvdItem: [],
      rcvdOrders: [],
      badgeInfo: {
        pending: "Initiated - Order in progress",
        closed: "Cancelled - Order was aborted",
        completed: "Completed - Order was approved",
      },
      show_modal: false,

      newsletter: false,
      currentPage: 1,
      totalPages: 8,
      perPage: 6,
      inc: 0,
      bootstrapPaginationClasses: {
        ul: "pagination",
        li: "page-item",
        liActive: "active",
        liDisable: "disabled",
        button: "page-link",
      },
      paginationAnchorTexts: {
        // first: "First",
        prev: "Previous",
        next: "Next",
        // last: "Last",
      },

      orderItems: null,
      orderItem_slice: [],
      orderUUID: null,
      commentDialog: false,
      rating: 3,
      comment: "",

      loading: false,
      height: 80,
      width: 100,
      transition: "fade",
    };
  },

  methods: {
    ...mapActions([
      "getOrders",
      "removeFromCart",
      "markOrderComplete",
      "addComment",
      "setRate",
    ]),
    ...mapMutations(["setNoCartItems"]),

    submitComment(item) {
      this.commentDialog = false;
      this.loading = true;

      if (this.comment) {
        this.addComment({
          id: item.id,
          description: this.comment,
        }).then((response) => {
          console.log("comment response: ", response);
        });

        this.setRate({ id: item.id, rate: this.rating }).then((response) => {
          console.log("rating response: ", response);
          this.loading = false;
        });
      }
    },

    confirmReception() {
      console.log("Confirm reception called");
      this.loading = true;
      this.markOrderComplete({ uuid: this.rcvdOrder.uuid }).then((response) => {
        console.log("mark as complete", response);
        this.$route.params.type = "completed";

        this.getOrders().then((respon) => {
          console.log("updating orders", respon);
          // this.setNoCartItems();
          this.loading = false;
          this.$router.push({ name: "purchases" });
        });
      });
    },

    cancelled() {
      console.log("operation cancelled");
    },

    formatDate(date) {
      return new Date(date).toLocaleString();
    },

    total(price, qty) {
      return price * qty;
    },

    updateResource() {
      if (this.currentPage == 1) {
        this.inc = 0;
        this.orderItem_slice = this.orderItems.slice(
          this.inc,
          this.perPage + this.inc
        );
        console.log("Inc: ", this.inc);
        console.log("current page: ", this.currentPage);
      } else {
        this.inc = (this.currentPage - 1) * this.perPage;
        this.orderItem_slice = this.orderItems.slice(
          this.inc,
          this.perPage + this.inc
        );
        console.log("Inc: ", this.inc);
        console.log("current page: ", this.currentPage);
      }

      console.log("OrderItems slice", this.orderItem_slice);
    },

    remove(order) {
      console.log("remove order view", order);
      this.disable = true;
      this.removeFromCart(order).then(() => {
        this.disable = false;
        this.getOrders().then(() => {
          this.setNoCartItems();
          this.$forceUpdate();
        });
      });
    },
  },

  computed: {
    ...mapState({ allOrders: "ordersList", cart: "cartItems" }),
  },

  mounted() {
    this.getOrders().then((response) => {
      console.log("View Orders: ", response);
      this.currentPage = response.current_page;
      // this.totalPages = response.total;
    });
  },

  created() {
    if (this.allOrders != null) {
      this.allOrders.forEach((order) => {
        if (order.id == this.$route.params.id) {
          this.orderItems = order.order_item;
          this.orderItem_slice = this.orderItems.slice(0, this.perPage);
          this.totalPages = Math.ceil(this.orderItems.length / this.perPage);
          console.log("totalPages: ", this.orderItems.length);
        }
      });
      this.orderUUID = this.allOrders[0].uuid;
    }
    console.log("view order items: ", this.orderItems);
    console.log("route:", this.$route.params.id, this.$route.params.uuid);

    if (this.$route.params.type == "pending") {
      this.rcvdOrders = this.pendingOrders();
      console.log("rcvOrders pending", this.rcvdOrders);
      this.pendingOrders().forEach((order) => {
        if (this.$route.params.uuid == order.uuid) {
          this.rcvdOrder = order;
        }
      });
      console.log("rcvd Order param", this.rcvdOrder);
    }
    if (this.$route.params.type == "closed") {
      this.rcvdOrders = this.orders_.closed;

      this.orders__.closed.forEach((order) => {
        if (this.$route.params.uuid == order.uuid) {
          this.rcvdOrder = order;
        }
      });
      console.log("rcvd Order closed param", this.rcvdOrder);
    }
    // if (this.$route.params.type == "completed") {
    this.rcvdOrders = this.completedOrders();
    console.log("rcvOrders", this.rcvdOrders);

    this.completedOrders().forEach((order) => {
      if (this.$route.params.uuid == order.uuid) {
        this.rcvdOrder = order;
      }
    });
    console.log("rcvd Order completed param", this.rcvdOrder);
    // }

    console.log("received order: ", this.rcvdOrder);
  },
};
</script>

<style scoped>
.card.pane {
  height: 100%;
}
.btn-confirm {
  background: var(--afprimary) !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 500;
}
.btn-track.btn-light {
  background: white !important;
  color: var(--afprimary) !important;
  text-transform: uppercase;
  font-weight: 500;
}
.btn-comment.btn-light {
  background: var(--afblue) !important;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 500;
}
p.item-date {
  color: black !important;
  font-weight: 500;
}
p.prdtName {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
}
p.price {
  font-weight: 17px;
  font-weight: 500;
}
/* .badge-primary {
  
} */
@media (max-width: 768px) {
  .card.pane {
    height: auto;
  }
}
</style>
