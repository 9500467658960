var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.categories),expression:"categories"}],staticClass:"row mt-auto",attrs:{"id":"hotCategory"}},[_c('div',{staticClass:"col-md-12 px-0 py-3"},[_c('div',{staticClass:"\n        col-md-12\n        px-0\n        section-head\n        d-flex\n        justify-content-between\n        align-items-center\n      "},[_c('h3',{staticClass:"sectionTitle px-md-0 px-lg-0 pl-3 pr-2 my-0"},[_c('i',{staticClass:"common hot_categories"}),_vm._v(" "+_vm._s(_vm.$t("hot"))+" ")])]),(_vm.categories.length > 0)?_c('div',{staticClass:"card-"},[_c('carousel',{staticClass:"slidecard border-0 mx-3 mx-lg-auto mx-md-auto",attrs:{"id":"hot-crs","items":6,"dots":false,"margin":3,"autoplay":false,"autoplaySpeed":2000,"nav":false,"loop":true,"rewind":true,"responsive":{
          0: { items: 2, nav: false, autoplay: false },
          600: { items: 3, nav: false, autoplay: false },
          900: { items: 6, nav: false },
          1296: { items: 6, nav: false },
        }}},[_c('template',{slot:"prev"},[_c('button',{staticClass:"rounded owl-prev"},[_c('i',{staticClass:"mdi mdi-chevron-left"})])]),_vm._l((_vm.categories),function(category,index){return _c('router-link',{key:index,staticClass:"card prdtCard border-0 rounded",attrs:{"to":{
            name: 'category',
            params: { childSlug: category.slug, catId: category.id },
          }}},[_c('div',{staticClass:"prdtImage"},[_c('img',{staticClass:"d-block mx-auto img-fluid",attrs:{"src":require("@/assets/images/category_1.jpg")}})]),_c('div',{staticClass:"card-footer bg-transparent rounded-0 border-0"},[_c('h5',{staticClass:"prdtName mb-2 mt-3"},[_vm._v(" "+_vm._s(_vm._f("truncate")(category.name,15, "..."))+" ")])])])}),_c('template',{slot:"next"},[_c('button',{staticClass:"rounded owl-next"},[_c('i',{staticClass:"mdi mdi-chevron-right"})])])],2)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }