import { render, staticRenderFns } from "./CustomerNavMobile.vue?vue&type=template&id=5a656fa8&scoped=true&"
import script from "./CustomerNavMobile.vue?vue&type=script&lang=js&"
export * from "./CustomerNavMobile.vue?vue&type=script&lang=js&"
import style0 from "./CustomerNavMobile.vue?vue&type=style&index=0&id=5a656fa8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a656fa8",
  null
  
)

export default component.exports