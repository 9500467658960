<template>
  <div class>
    <ul
      class="nav nav-pills nav-tabs px-0 justify-content-center row mx-0"
      role="tablist"
      :class="[isSticky ? stickyClass : '']"
      @scroll="handleScroll"
    >
      <li class="nav-item col-md-6 pt-n5 pb-0 px-0" role="presentation">
        <a
          class="nav-link active text-center"
          id="current-tab"
          data-toggle="tab"
          href="#current"
          @click.prevent="dealType = 'current'"
          ><small class="lh-4">{{ $t("EndsToday") }}</small>
          <!-- <div class="mb-1">23:30:00</div> -->
        </a>
      </li>

      <li class="nav-item col-md-6 pt-n5 pb-0 px-0" role="presentation">
        <a
          class="nav-link text-center"
          id="coming-tab"
          data-toggle="tab"
          href="#coming"
          @click.prevent="dealType = 'coming'"
          ><small class="lh-4">{{ $t("ComingUp") }}</small>
          <!-- <p class="mb-1">23:30:00 17/11/20</p> -->
        </a>
      </li>
    </ul>

    <div class="container py-0 px-1">
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="current"
          role="tabpanel"
          aria-labelledby="current-tab"
          v-show="dealType == 'current'"
        >
          <current-deals :products="deals_cr" :dealType="dealType" />
        </div>
        <div
          class="tab-pane fade"
          id="coming"
          role="tabpanel"
          aria-labelledby="coming-tab"
          v-show="dealType == 'coming'"
        >
          <coming-deals :products="deals_cm" :dealType="dealType" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RangeSlider from "vue-range-slider";
// you probably need to import built-in style
import "vue-range-slider/dist/vue-range-slider.css";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";

import ComingDeals from "@/components/superDeals/ComingDeals";
import CurrentDeals from "@/components/superDeals/CurrentDeals";

export default {
  components: {
    RangeSlider,
    ComingDeals,
    CurrentDeals,
  },
  data() {
    return {
      isSticky: false,
      stickyClass: "stick",
      scrollPosition: 0,
      sidebar: false,
      sliderValue: 50,
      dates: [],
      dealType: "current",
      deals_cr: [],
      deals_cm: [],
      deals: null,
      prdt_name:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia consequuntur consequatur dolore animi! Explicabo, ullam dolor.",
      productInfo: null,
      disable: false,
      page: 1,
      last_page: 0,
      pageSize: 5,
      products: [],
      crumbs: [],
      routeItems: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Super Deals",
          disabled: true,
          href: "/deals",
        },
      ],
    };
  },

  destroyed() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    ...mapActions(["addToCart", "getSuperDeals", "getOrders"]),
    ...mapMutations(["setNoCartItems"]),

    add_to_cart(id) {
      this.productInfo = {
        id: id,
        quantity: 1,
      };

      console.log("add product, ", this.productInfo);
      console.log("add product, ", this.details);
      this.addToCart(this.productInfo).then(() => {
        this.getOrders().then(() => {
          this.setNoCartItems();
          this.$root.$emit("cart_item", this.noCartItems);
          this.$forceUpdate();
        });
      });
    },

    handleScroll(event) {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition >= 100) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },

    newPrice(price, discount) {
      return price - discount * price;
    },

    getTheDeals() {
      this.getSuperDeals().then(() => {
        this.deals_cr = this.superDeals_.currentDeals
          ? this.superDeals_.currentDeals
          : (this.deals_cr.length = 0);

        this.deals_cm = this.superDeals_.comingDeals
          ? this.superDeals_.comingDeals
          : (this.deals_cm.length = 0);

        console.log("Inner super deals: ", this.superDeals_);
      });
    },
  },

  computed: {
    ...mapGetters([
      "loggedIn",
      "getUserDetails",
      "getSuperDealsObj",
      "noCartItems",
    ]),

    ...mapState(["superDeals", "cart_no"]),

    deadline() {
      let max_date =
        this.dates.length > 0
          ? new Date(Math.max(...this.dates)).toString()
          : new Date().toString();

      console.log("max_date_", new Date(Math.max(...this.dates)));
      return max_date;
    },
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);

    console.log("Index guest current ", this.deals_cr);
    console.log("Index guest coming ", this.deals_cm);
  },

  mounted() {
    console.log("Super deals_: ", this.newProducts_);

    console.log("dates ", this.dates);
  },

  beforeMount() {
    if (!this.superDeals_) {
      this.getTheDeals();
      console.log("Super deals Action");
    } else {
      this.deals = this.superDeals_;
      this.deals_cr = this.superDeals_.current_deals
        ? this.superDeals_.current_deals.data
        : [];
      // this.deals_cr = this.newProducts_.data;
      // this.deals_cm = this.newProducts_.data;

      this.deals_cm = this.superDeals_.coming_deals
        ? this.superDeals_.coming_deals.data
        : [];
      console.log("all good with super deals ", this.deals_cr);
    }
  },
};
</script>

<style scoped>
.lh-4 {
  line-height: 4em;
}
.widget {
  padding: 1.2rem;
  border: 1px solid #e7e7e7;
}
.widget-title {
  margin: 0;
  color: #7d8287;
  font-size: 0.8rem;
  font-weight: 600;
  font-family: Poppins, sans-serif;
  line-height: 1.4;
  text-transform: uppercase;
}

.widget-body {
  padding: 1rem 0;
}

.widget-body ul > li > a:hover {
  color: #ff9d00 !important;
}

.cat-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.slider {
  width: 200px;
}

.md-theme-default a:not(.md-button) {
  color: #8f8787 !important;
}
a.nav-link {
  padding: 0.1em 8em !important;
}
ul.nav-tabs {
  font-size: 1.2rem;
  height: fit-content;
  width: 100%;
  background: #382924;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background: linear-gradient(180deg, #ffa300, #ff4500) !important;
}
.nav-tabs .nav-link {
  border: none !important;
}

.nav-pills .nav-link {
  border-radius: 0;
}
.stick {
  position: fixed;
  top: 0;
  margin-top: 0;
  z-index: 50;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 991px) {
  .mobile-sidebar {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 260px;
    padding: 2rem;
    margin: 0;
    transform: translate(-260px);
    transition: transform 0.2s ease-in-out 0s;
    background-color: #fff;
    z-index: 9999;
    overflow-y: auto;
  }
  .sidebar-opened .mobile-sidebar {
    transform: none;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  ul.nav-tabs {
    font-size: 0.9rem;
    height: fit-content;
    width: 100%;
    background: #382924;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
