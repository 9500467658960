<template>
  <div class="container pt-0 pt-sm-0 pt-md-auto pt-lg-auto">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="var(--afprimary)"
      :height="height"
      :width="width"
      :transition="transition"
      background-color="#000"
    ></loading>
    <div class="row mt-2" v-if="orders">
      <div class="col-md-8 d-none d-sm-none d-md-block d-lg-block">
        <h6 class="text-secondary">{{ $t("CHECKOUT") }}</h6>
        <p class="text-danger mt-" v-if="errorMsg2">{{ errorMsg2 }}</p>

        <div :class="['bg-white', 'py-3']">
          <div
            :class="[
              'd-flex',
              'align-items-center',
              'px-3',
              'justify-content-between',
              'border-bottom',
            ]"
          >
            <h6 :class="['d-flex', 'align-items-center', '']">
              <i
                :class="[
                  'fas',
                  'fa-check-circle',
                  'mr-3',
                  addressAvailable != null ? 'text-success' : 'text-muted',
                ]"
              ></i>
              1. {{ $t("ADDRESSDETAILS") }}
            </h6>

            <button
              @click="show_modal = true"
              class="btn mb-2 btn-font-trans btn-sm font-weight-bolder"
            >
              {{ $t("CHANGE") }}
            </button>
          </div>

          <div class="px-5 mx-5 py-3">
            <div v-if="addressToUse">
              <p
                class="font-15 mb-0 font-weight-bold"
                v-if="user.first_name || user.last_name"
              >
                {{ user.first_name + " " + user.last_name }}
              </p>
              <p class="font-12 mb-0">{{ user.email }}</p>
              <p class="font-12 mb-0">{{ addressToUse.phone }}</p>
              <p class="font-12 mb-0 text-capitalize">
                {{ addressToUse.quarter }}
              </p>
            </div>

            <div v-else>
              <p
                class="font-15 mb-0 font-weight-bold"
                v-if="user.first_name || user.last_name"
              >
                {{ user.first_name + " " + user.last_name }}
              </p>
              <p class="font-12 mb-0">{{ user.email }}</p>
              <p class="font-12 mb-0" v-if="user.city">
                {{ user.city.name }}
              </p>
              <p class="font-12 mt-2 font-weight-bold text-danger" v-else>
                {{ $t("Noaddressesavailable") }}
                <br />
                {{ $t("Clickthe") }} <strong>{{ $t("CHANGE") }}</strong>
                {{ $t("buttonToAdd") }}
              </p>
            </div>
          </div>
        </div>

        <form
          action
          :class="['bg-white', addressDone ? 'py-1' : 'py-3', 'mt-3']"
        >
          <div
            :class="[
              !addressDone ? 'border-bottom py-1' : 'mt-2 py-0',
              'd-flex',
              'justify-content-between',
              'align-items-center',
              'px-3',
            ]"
          >
            <h6 :class="['d-flex', 'align-items-center']">
              <i
                :class="[
                  'mdi',
                  'mdi-check-circle',
                  'mr-3',
                  'font-20',
                  !addressDone ? 'text-muted' : 'text-success',
                ]"
              ></i>
              2. {{ $t("PAYMENTMETHOD") }}
            </h6>

            <button
              @click.prevent="addressDone = !addressDone"
              class="btn mb-2 btn-font-trans btn-sm font-weight-bolder"
              v-if="addressDone"
            >
              {{ $t("CHANGE") }}
            </button>
          </div>

          <div class="form-body px-5 py-3 mx-5" v-if="!addressDone">
            <div class="form-group mt-3 pb-2">
              <p>
                <b>{{ $t("howDoYouWantToPay") }}</b>
              </p>

              <!-- Payment options -->
              <div class="option">
                <div class="form-group">
                  <input
                    class=""
                    type="radio"
                    name="afeshopPay"
                    id="afeshopPay"
                    value="before"
                    v-model="payMethod"
                    @click="selectedPaymentMethod($event)"
                  />
                  <label class="radio" for="afeshopPay">
                    <b>{{ $t("afeshopBankMethods") }}</b>
                  </label>
                </div>

                <div class="mt-4 ml-5">
                  <p class="text-muted mb-0 font-12">
                    {{ $t("cardPaymentsListed") }}
                  </p>
                  <p class="text-muted font-12">
                    {{ $t("feelFreeToSelectAny") }}
                  </p>
                </div>

                <!-- Payment methods images-->
                <div class="mt-4 ml-3">
                  <div class="row">
                    <div class="col">
                      <img
                        src="@/assets/images/visapay-1.png"
                        class="img-fluid"
                        alt="visa pay"
                      />
                    </div>
                  </div>
                </div>

                <hr class="my-3" />
              </div>

              <div class="option">
                <div class="form-group">
                  <input
                    class=""
                    type="radio"
                    name="payOnDelivery"
                    id="payOnDelivery"
                    value="after"
                    v-model="payMethod"
                    :disabled="payOnDelivery"
                    @click="selectedPaymentMethod($event)"
                  />
                  <label class="radio" for="payOnDelivery">
                    <b
                      >{{ $t("PayonDelivery") }} ({{
                        $t("payingUponDelivery")
                      }})</b
                    >
                  </label>
                </div>
                <p class="text-muted mt-1 ml-5" v-if="payOnDelivery">
                  {{ $t("Unavailable") }}
                  <span
                    class="text-dark text-decoration-underline why"
                    @click.stop="showWhy = !showWhy"
                    >{{ $t("Why") }}?</span
                  >
                </p>

                <!-- Pay on delivery message -->
                <div
                  class="card rounded-0 border-0 bg-grey px-3 py-2 ml-5"
                  v-if="showWhy"
                >
                  <p>{{ $t("payOnDeliveryUnavailable") }}:</p>

                  <p class="mb-0">1) {{ $t("totalCartValueUnder") }}</p>
                  <p class="mb-0">2) {{ $t("totalCartValueAbove") }}</p>

                  <p>
                    {{ $t("forFurtherInfo") }}
                    <strong class="why"
                      ><a href="https://my.artibot.ai/afeshop-support">{{
                        $t("here")
                      }}</a></strong
                    >
                  </p>
                </div>

                <!-- Afeshop Voucher -->
                <div class="mt-5 pt-3 row">
                  <label for="voucherCode" class="px-4 mb-n1">
                    <b>{{ $t("gotAnAfeshopVoucher") }}:</b>
                  </label>
                  <div class="col-md-8 form-group pr-0">
                    <input
                      type="code"
                      name="voucherCode"
                      class="form-control"
                      v-model="voucherCode"
                      id="voucherCode"
                      placeholder="Enter voucher code here"
                      :disabled="vouchDisable"
                    />

                    <div class="" v-if="errorMsg">
                      <p class="mb-0 mt-1 text-capitalize text-danger font-12">
                        {{ errorMsg }}
                      </p>
                    </div>
                    <div class="" v-if="successMsg">
                      <p class="mb-0 mt-1 text-capitalize text-success font-12">
                        {{ successMsg }}
                      </p>
                    </div>
                  </div>

                  <div class="col-md-4 pl-0">
                    <button
                      @click.prevent="addVoucher()"
                      class="
                        btn btn-orange
                        form-control
                        btn-block
                        rounded-0
                        shadow-sm
                      "
                      :disabled="btnVouchDisable"
                    >
                      {{ $t("ADDVOUCHER") }}
                    </button>
                  </div>

                  <div class="position-absolute d-flex justify-content-center">
                    <scroll-loader
                      :loader-method="checkVoucher"
                      :loader-disable="disable"
                    ></scroll-loader>
                  </div>
                </div>

                <!-- Summations -->
                <div>
                  <div class="d-flex justify-content-between">
                    <p class="mb-2">{{ $t("Subtotal") }}:</p>
                    <p class="mb-2">
                      {{ total | currency("XFA ") }}
                    </p>
                  </div>
                  <div class="d-flex justify-content-between">
                    <p class="mb-0">{{ $t("Deliveryfee") }}:</p>
                    <p class="mb-0">
                      <b>{{ deliveryFee | currency("XFA ") }}</b>
                    </p>
                  </div>
                </div>

                <hr class="my-3" />

                <div class="d-flex justify-content-between">
                  <p>
                    <b>{{ $t("Total") }}:</b>
                  </p>
                  <h6 class="color-font">
                    {{ sumToPay | currency("XFA ") }}
                  </h6>
                </div>
              </div>
            </div>

            <button
              type="button"
              class="mt-4 btn btn-block btn-orange rounded-0 py-2"
              @click.prevent="confirmPayment()"
              :disabled="confirmbtn"
            >
              {{ $t("CONFIRMORDER") }}
            </button>

            <div
              class="
                text-center
                mx-n4
                px-3
                py-2
                d-block d-sm-block d-md-none d-lg-none
              "
            >
              <button
                @click="$router.push({ name: 'cart' })"
                class="btn btn-default color-font"
              >
                {{ $t("MODIFYCART") }}
              </button>
            </div>
          </div>
        </form>
      </div>

      <!-- Order Summary -->
      <div class="col-md-4 d-none d-sm-none d-md-block d-lg-block">
        <h6 class="text-secondary">{{ $t("ORDERSUMMARY") }}</h6>
        <div class="card rounded-0 border-0 shadow-sm">
          <div class="card-head border-bottom px-2 py-3 my-1">
            <h6 class="mb-0">
              {{ $t("YOURORDER") }} ({{ cart_items.order_item.length }}
              {{ $t("items") }})
            </h6>
          </div>

          <div class="card-body py-0 mx-n1">
            <ul
              class="list-unstyled px-0 py-0 my-0"
              v-if="cart_items.order_item"
            >
              <li v-for="item in cart_items.order_item" :key="item.id">
                <div class="row border-bottom">
                  <div class="col-md-3 pl-2 pr-0">
                    <img :src="item.item.image_url" class="img-fluid" alt />
                  </div>
                  <div class="col-md-9 px-3">
                    <p class="mb-1 text-truncate itemName">
                      {{ item.item.name }}
                    </p>

                    <p class="mx-0 mt-2 mb-0 color-font" v-if="item.coupon">
                      {{
                        item.item.item_discount.unit_price | currency("XFA ")
                      }}
                    </p>

                    <p class="mx-0 mt-2 mb-0 color-font" v-else>
                      {{ item.item.unit_price | currency("XFA ") }}
                    </p>

                    <p
                      class="mx-0 mb-2 text-secondary font-italic"
                      v-if="item.coupon"
                    >
                      <small>
                        <span class="my-0 text-success">{{
                          item.coupon.code
                        }}</span>
                        {{ $t("applied") }}</small
                      >
                    </p>

                    <p class="m-0">
                      {{ $t("Qty") }}:
                      <span class="font-weight-bold">{{ item.quantity }}</span>
                    </p>
                  </div>
                </div>
              </li>

              <li>
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-between
                    border-bottom
                    mx-n4
                    px-3
                    py-2
                  "
                >
                  <p class="m-0">{{ $t("Subtotal") }}</p>
                  <p class="m-0">
                    {{ total | currency("XFA ") }}
                  </p>
                </div>
              </li>
              <li>
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-between
                    border-bottom
                    mx-n4
                    px-3
                    py-2
                  "
                >
                  <p class="m-0">{{ $t("Deliveryfee") }}:</p>
                  <p class="m-0">
                    <b>{{ deliveryFee | currency("XFA ") }}</b>
                  </p>
                </div>
              </li>
              <li>
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-between
                    border-bottom
                    mx-n4
                    px-3
                    py-2
                  "
                >
                  <p class="m-0 h6">{{ $t("Total") }}</p>
                  <p class="m-0 color-font h5">
                    {{ sumToPay | currency("XFA ") }}
                  </p>
                </div>
              </li>
              <li>
                <div class="text-center mx-n4 px-3 py-2">
                  <router-link
                    :to="{ name: 'cart' }"
                    class="btn-link btn btn-default color-font"
                  >
                    {{ $t("MODIFYCART") }}
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Payment page for Mobile -->
      <mobi-payment>
        <template v-slot:delivery>
          <div class="container px-3 py-0">
            <div class="row">
              <div class="col-12 px-0">
                <h6 class="text-uppercase pl-5 mt-2">
                  {{ $t("YourCurrentAddress") }}
                </h6>
                <ul class="list-unstyled form-group addressList px-0">
                  <li class="mb-3 card border-0 shadow-sm p-3">
                    <div class="form-group mb-0">
                      <div class="mb-1">
                        <input
                          class=""
                          type="radio"
                          name="mobiAddressRadio"
                          id="mobiAddressRadioFirst"
                          :value="user"
                          v-model="addressToUse"
                          @change="selectedAddress()"
                          :checked="
                            addressToUse === user || addressToUse === null
                          "
                        />

                        <label
                          class="radio font-15"
                          for="mobiAddressRadioFirst"
                        >
                          {{
                            user.first_name && user.last_name
                              ? user.first_name + " " + user.last_name
                              : user.username
                          }}
                        </label>
                      </div>

                      <div class="d-flex justify-content-between">
                        <div class="ml-6">
                          <p class="text-muted mb-0 font-15" v-if="user.city">
                            {{ user.quarter ? user.quarter : "" }}
                          </p>
                          <p class="text-muted mb-0 font-15">
                            {{ user.phone }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col pb-0">
                        <a
                          @click.prevent="
                            editAddress(user);
                            show_modal3 = !show_modal3;
                          "
                          class="font-12 ml-6 d-block float-left ctrl"
                          >{{ $t("EDIT") }} <i class="mdi mdi-pencil"></i
                        ></a>
                        <a class="font-12 d-block float-right ctrl"
                          >{{ $t("REMOVE") }} <i class="mdi mdi-delete"></i
                        ></a>
                      </div>
                    </div>
                  </li>
                </ul>

                <!-- Other Addresses Header -->
                <div class="row">
                  <div class="col-12 mt-2 align-bottom py-0">
                    <h6 class="text-uppercase pl-5 float-left" v-if="addresses">
                      {{ $t("OtherAddresses") }}
                    </h6>
                    <!-- Add Address -->
                    <a
                      @click.prevent="show_modal2 = !show_modal2"
                      class="btn-link btn float-right my-auto mobi-add"
                    >
                      <h6 class="color-font">
                        <i class="mdi mdi-plus-circle font-"></i>
                        {{ $t("Add") }}
                      </h6>
                    </a>
                  </div>
                </div>

                <!-- Other Addresses List -->
                <div class="mb-3">
                  <ul class="list-unstyled addressList px-0" v-if="addresses">
                    <li
                      class="mb-3 card border-0 shadow-sm p-3"
                      v-for="(addr, index) in addresses"
                      :key="index"
                    >
                      <div class="form-group mb-0">
                        <div class="mb-1">
                          <input
                            class=""
                            type="radio"
                            name="mobiAddressRadio"
                            :id="'mobiAddressRadio' + index"
                            :value="addr"
                            v-model="addressToUse"
                            @change="selectedAddress()"
                            :checked="addressToUse === addr"
                          />
                          <label
                            class="radio font-15"
                            :for="'mobiAddressRadio' + index"
                          >
                            {{
                              user.first_name && user.last_name
                                ? user.first_name + " " + user.last_name
                                : user.username
                            }}
                          </label>
                        </div>

                        <div class="d-flex justify-content-between">
                          <div class="ml-6">
                            <p class="text-muted mb-0 font-15">
                              {{ addr.quarter }}
                            </p>
                            <p class="text-muted mb-0 font-15">
                              {{ addr.phone }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col pb-0">
                          <a
                            @click.prevent="
                              editAddress(addr);
                              show_modal3 = true;
                            "
                            class="font-12 ml-6 d-block float-left ctrl"
                            >{{ $t("EDIT") }} <i class="mdi mdi-pencil"></i
                          ></a>
                          <a class="font-12 d-block float-right ctrl"
                            >{{ $t("REMOVE") }} <i class="mdi mdi-delete"></i
                          ></a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:payment>
          <div class="container px-3 py-0">
            <div class="row">
              <div class="col-12">
                <h6 class="text-uppercase pl-2 mb-0 mt-2">
                  {{ $t("SelectaPaymentmethod") }}:
                </h6>

                <!-- Payment options -->
                <div class="row">
                  <div class="col-12 py-2">
                    <div class="option card shadow-sm border-0 p-3">
                      <div class="form-group mb-0">
                        <input
                          class=""
                          type="radio"
                          name="afeshopPay"
                          id="afeshopPay"
                          value="before"
                          v-model="payMethod"
                          @click="selectedPaymentMethod($event)"
                        />
                        <label class="radio" for="afeshopPay">
                          {{ $t("afeshopBankMethods") }}
                        </label>
                      </div>

                      <div class="mt-0 ml-5">
                        <p class="text-muted mb-0 font-12">
                          {{ $t("cardPaymentsListed") }}
                        </p>
                        <p class="text-muted font-12">
                          {{ $t("feelFreeToSelectAny") }}
                        </p>
                      </div>

                      <!-- Payment methods images-->
                      <div class="ml-3">
                        <div class="row">
                          <div class="col py-0 mt-n3">
                            <img
                              src="@/assets/images/visapay-1.png"
                              class="img-fluid mobi-img"
                              alt="visa pay"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="option mt-3 card shadow-sm border-0 p-3">
                      <div class="form-group mb-0">
                        <input
                          class=""
                          type="radio"
                          name="payOnDelivery"
                          id="payOnDelivery"
                          value="after"
                          v-model="payMethod"
                          :disabled="payOnDelivery"
                          @click="selectedPaymentMethod($event)"
                        />
                        <label class="radio" for="payOnDelivery">
                          {{ $t("PayonDelivery") }} ({{
                            $t("payingUponDelivery")
                          }})
                        </label>
                      </div>
                      <p class="text-muted mt-1 ml-5" v-if="payOnDelivery">
                        {{ $t("Unavailable") }}
                        <span
                          class="text-dark text-decoration-underline why"
                          @click.stop="showWhy = !showWhy"
                          >{{ $t("Why") }}?</span
                        >
                      </p>

                      <!-- Pay on delivery message -->
                      <div
                        class="card rounded-0 border-0 bg-grey px-3 py-2 ml-5"
                        v-if="showWhy"
                      >
                        <p>{{ $t("payOnDeliveryUnavailable") }}:</p>

                        <p class="mb-0">1) {{ $t("totalCartValueUnder") }}</p>
                        <p class="mb-0">2) {{ $t("totalCartValueAbove") }}</p>

                        <p>
                          {{ $t("forFurtherInfo") }}
                          <strong class="why"
                            ><a href="https://my.artibot.ai/afeshop-support">{{
                              $t("here")
                            }}</a></strong
                          >
                        </p>
                      </div>
                    </div>

                    <!-- Afeshop Voucher -->
                    <label for="voucherCode" class="mt-5">
                      <h6 class="text-uppercase pl-2 mb-0">
                        {{ $t("YouhaveaCoupon") }}
                      </h6>
                    </label>
                    <div class="row bg-white border-0 shadow-sm py-2 px-2">
                      <div class="col-9 pr-0 form-group mb-0">
                        <input
                          type="code"
                          name="voucherCode"
                          class="form-control border-right-0"
                          v-model="voucherCode"
                          id="mobiVoucherCode"
                          placeholder="Enter voucher code here"
                          :disabled="vouchDisable"
                        />

                        <div class="" v-if="errorMsg">
                          <p
                            class="
                              mb-0
                              mt-1
                              text-capitalize text-danger
                              font-12
                            "
                          >
                            {{ errorMsg }}
                          </p>
                        </div>
                        <div class="" v-if="successMsg">
                          <p
                            class="
                              mb-0
                              mt-1
                              text-capitalize text-success
                              font-12
                            "
                          >
                            {{ successMsg }}
                          </p>
                        </div>
                      </div>

                      <div class="col-3 pl-0">
                        <button
                          @click.prevent="addVoucher()"
                          class="
                            btn btn-orange
                            form-control
                            btn-block
                            rounded-0
                            shadow-sm
                            px-auto
                            text-uppercase
                            font-weight-bold
                          "
                          :disabled="btnVouchDisable"
                        >
                          {{ $t("Use") }}
                        </button>
                      </div>

                      <div
                        class="position-absolute d-flex justify-content-center"
                      >
                        <scroll-loader
                          :loader-method="checkVoucher"
                          :loader-disable="disable"
                        ></scroll-loader>
                      </div>
                    </div>

                    <!-- Summations -->
                    <div class="row bg-white shadow-sm border-0 mt-3 px-2">
                      <div class="col-12">
                        <div>
                          <div class="d-flex justify-content-between">
                            <p class="mb-2">{{ $t("Subtotal") }}:</p>
                            <p class="mb-2">
                              {{ total | currency("XFA ") }}
                            </p>
                          </div>
                          <div class="d-flex justify-content-between">
                            <p class="mb-0">{{ $t("Deliveryfees") }}:</p>
                            <p class="mb-0">
                              <b>{{ deliveryFee | currency("XFA ") }}</b>
                            </p>
                          </div>
                        </div>

                        <hr class="my-3" />

                        <div class="d-flex justify-content-between py-2">
                          <p class="mb-0">{{ $t("Total") }}:</p>
                          <h5 class="color-font font-weight-bold mb-0">
                            {{ sumToPay | currency("XFA ") }}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-slot:summary>
          <div class="container px-3 py-0">
            <div class="row">
              <div class="col-12">
                <h6 class="text-uppercase pl-2 mb-0 mt-2">
                  {{ $t("OrderSummary") }}
                </h6>
                <!-- Summations -->
                <div class="row bg-white shadow-sm border-0 mt-3 px-2">
                  <div class="col-12">
                    <div>
                      <div class="d-flex justify-content-between">
                        <p class="mb-3">{{ $t("Subtotal") }}:</p>
                        <p class="mb-3">
                          {{ total | currency("XFA ") }}
                        </p>
                      </div>
                      <div class="d-flex justify-content-between">
                        <p class="mb-1">{{ $t("Deliveryfees") }}:</p>
                        <p class="mb-1">
                          <b>{{ deliveryFee | currency("XFA ") }}</b>
                        </p>
                      </div>
                    </div>

                    <hr class="my-2" />

                    <div class="d-flex justify-content-between py-3">
                      <h5 class="my-0">{{ $t("Total") }}:</h5>
                      <h5 class="color-font font-weight-bold mb-0">
                        {{ sumToPay | currency("XFA ") }}
                      </h5>
                    </div>
                  </div>
                </div>

                <!-- Selected Delivery Address Header -->
                <div class="row mb-0">
                  <div class="col-12 mt-5 mb-n3 align-bottom py-0">
                    <h6 class="text-uppercase pl-2 float-left">
                      {{ $t("DeliveryAddress") }}
                    </h6>
                    <!-- Edit Address -->
                    <a
                      @click.prevent="
                        editAddress(addressToUse);
                        show_modal3 = true;
                      "
                      class="btn-link btn pr-2 float-right my-auto mobi-edit"
                    >
                      <h6 class="color-font font-weight-bold">
                        <i class="mdi mdi-pencil"></i> {{ $t("Edit") }}
                      </h6>
                    </a>
                  </div>
                </div>

                <!-- Selected Delivery Address -->
                <div class="row bg-white shadow-sm border-0 mt-3 px-0">
                  <div class="col-12 px-0" v-if="addressToUse">
                    <div class="row">
                      <div class="col-12">
                        <p class="ml-6 mt-n3 mb-1 selectedName">
                          {{
                            user.first_name && user.last_name
                              ? user.first_name + " " + user.last_name
                              : user.username
                          }}
                        </p>

                        <div class="d-flex justify-content-between">
                          <div class="ml-6 mb-n2">
                            <p class="text-muted mb-0 font-15">
                              {{ state_name(addressToUse.state_id) }}
                            </p>

                            <p class="text-muted mb-0 font-15">
                              {{ addressToUse.quarter }}
                            </p>

                            <p class="text-muted mb-0 font-15">
                              {{ addressToUse.phone }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Selected Payment Method Header -->
                <div class="row mb-0">
                  <div class="col-12 mt-5 mb-n3 align-bottom py-0">
                    <h6 class="text-uppercase pl-2 float-left">
                      {{ $t("PAYMENTMETHOD") }}
                    </h6>
                    <!-- Edit Payment method -->
                    <a
                      @click="$root.$emit('showPreviousTab')"
                      class="btn-link btn pr-2 float-right my-auto mobi-edit"
                    >
                      <h6 class="color-font font-weight-bold">
                        <i class="mdi mdi-pencil"></i> {{ $t("Edit") }}
                      </h6>
                    </a>
                  </div>
                </div>
                <div class="row bg-white shadow-sm border-0 mt-3 px-2">
                  <div class="col-12">
                    <div v-if="payMethod === 'before'">
                      <h6>{{ $t("AfeshopPaymentMethods") }}</h6>

                      <!-- Payment methods images-->
                      <div class="row">
                        <div class="col px-3 ml-n3 py-0 mb-2 mt-1">
                          <img
                            src="@/assets/images/visapay-1.png"
                            class="img-fluid mobi-img"
                            alt="visa pay"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-else-if="payMethod === 'after'">
                      <h6>{{ $t("PaymentonDelivery") }}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </mobi-payment>

      <!-- Addresses Modal 1 -->
      <modal
        title="Address Book"
        :is-large="true"
        v-show="show_modal"
        @close="show_modal = false"
      >
        <div class="modal-body">
          <!-- Add Address -->
          <div class="mx-5">
            <a
              @click.prevent="show_modal2 = !show_modal2"
              class="text-uppercase mb-0 font-20 font-weight-bold"
            >
              <i class="mdi mdi-plus-circle font-20"></i>
              {{ $t("AddANewAddress") }}
            </a>
          </div>

          <div class="divider mt-5 mb-5 border-bottom border-bottom"></div>

          <h6 class="text-uppercase pl-5 mb-4">{{ $t("DefaultAddress") }}</h6>
          <div class="mb-3">
            <ul class="list-unstyled form-group addressList pl-5">
              <li class="mb-3">
                <!-- <div class="form-group"> -->
                <div class="mb-1">
                  <input
                    class=""
                    type="radio"
                    name="addressRadio"
                    id="addressRadio9"
                    :value="user"
                    v-model="addressToUse"
                    checked="true"
                  />
                  <label class="radio font-15" for="addressRadio9">
                    {{
                      user.first_name && user.last_name
                        ? user.first_name + " " + user.last_name
                        : user.username
                    }}
                  </label>
                </div>

                <div class="d-flex justify-content-between">
                  <div class="ml-6">
                    <p
                      class="text-muted mb-0 font-15"
                      v-if="user.state_id && user.city"
                    >
                      {{ user.quarter ? user.quarter : "" }}
                    </p>
                    <p class="text-muted mb-0 font-15">{{ user.phone }}</p>
                  </div>

                  <div class="mr-5 mt-n5">
                    <a
                      @click.prevent="
                        show_modal3 = !show_modal3;
                        editAddress(user);
                      "
                      class="font-12 d-block float-right my-0 p-0"
                      >{{ $t("EDIT") }} <i class="mdi mdi-pencil"></i
                    ></a>
                    <a class="font-12 d-block my-0 p-0"
                      >{{ $t("REMOVE") }} <i class="mdi mdi-delete"></i
                    ></a>
                  </div>
                </div>
                <!-- </div> -->
              </li>
            </ul>
          </div>

          <h6 class="text-uppercase pl-5 mb-4" v-if="addresses">
            {{ $t("OtherAddresses") }}
          </h6>
          <div class="mb-3">
            <ul class="list-unstyled addressList pl-5" v-if="addresses">
              <li class="mb-3" v-for="(addr, index) in addresses" :key="index">
                <div class="form-group">
                  <div class="mb-1">
                    <input
                      class=""
                      type="radio"
                      name="addressRadio"
                      :id="'addressRadio' + index"
                      :value="addr"
                      v-model="addressToUse"
                    />
                    <label class="radio font-15" :for="'addressRadio' + index">
                      {{
                        user.first_name && user.last_name
                          ? user.first_name + " " + user.last_name
                          : user.username
                      }}
                    </label>
                  </div>

                  <div class="d-flex justify-content-between">
                    <div class="ml-6">
                      <p class="text-muted mb-0 font-15" v-if="addr.city">
                        {{ addr.quarter }}
                      </p>
                      <p class="text-muted mb-0 font-15">{{ addr.phone }}</p>
                    </div>

                    <div class="mr-5 mt-n5">
                      <a
                        @click.prevent="
                          editAddress(addr);
                          show_modal3 = true;
                        "
                        class="font-12 d-block float-right my-0 p-0"
                        >{{ $t("EDIT") }} <i class="mdi mdi-pencil"></i
                      ></a>
                      <a class="font-12 d-block my-0 p-0"
                        >{{ $t("REMOVE") }} <i class="mdi mdi-delete"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-orange rounded-0 text-uppercase btn-block"
            @click="
              show_modal = false;
              selectedAddress();
            "
          >
            {{ $t("UseThisAddress") }}
          </button>
        </div>
      </modal>

      <!-- Add Address modal 2 -->
      <modal
        title="Add Address"
        :is-large="true"
        v-if="show_modal2"
        @close="show_modal2 = false"
      >
        <div class="modal-body">
          <div class="form-body py-3 mx-5">
            <div class="form-group mt-3 pb-2">
              <!-- Names -->
              <div class="row">
                <div class="col py-0">
                  <label for="firstName" class="text-secondary"
                    >{{ $t("FirstName") }} *</label
                  >
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    v-model="firstName"
                    placeholder="John"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col py-0">
                  <label for="lastName" class="text-secondary"
                    >{{ $t("LastName") }} *</label
                  >
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    v-model="lastName"
                    placeholder="Doe"
                    class="form-control"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="form-group pb-2">
              <!-- Phone Number -->
              <label for="phonePrefix" class="text-secondary"
                >{{ $t("MobilePhoneNumber") }} *</label
              >
              <div class="d-flex justify-content-between">
                <input
                  type="text"
                  name="phonePrefix"
                  id="phonePrefix"
                  v-model="phonePrefix"
                  class="form-control mr-3"
                  style="width: 10%"
                  disabled
                />

                <input
                  type="number"
                  name="phoneNumber"
                  id="phoneNumber"
                  v-model="phoneNumber"
                  placeholder="505000000"
                  class="form-control d-block"
                  required
                />
              </div>

              <a
                @click="addPhone = !addPhone"
                class="float-right"
                v-if="!addPhone"
              >
                <small class="d-flex text-muted mt-1">
                  <i class="mdi mdi-plus-circle mr-2"></i>
                  {{ $t("addSecondPhoneNumber") }}
                </small>
              </a>
            </div>

            <div class="form-group pb-2" v-if="addPhone">
              <!-- Phone Number -->
              <label for="phonePrefix" class="text-secondary">{{
                $t("AdditionalPhoneNumber")
              }}</label>
              <div class="d-flex justify-content-between">
                <input
                  type="text"
                  name="phonePrefix"
                  id="phonePrefix"
                  v-model="phonePrefix"
                  class="form-control mr-3"
                  style="width: 10%"
                  disabled
                />

                <input
                  type="number"
                  name="phoneNumber2"
                  id="phoneNumber2"
                  v-model="phoneNumber2"
                  placeholder="505000000"
                  class="form-control d-block"
                  required
                />
              </div>
            </div>

            <div class="form-group pb-2">
              <label for="address" class="text-secondary"
                >{{ $t("Address") }} *</label
              >
              <textarea
                name="address"
                class="form-control"
                placeholder="Street Name / Building / Floor No. / Junction Name"
                id="address"
                cols="30"
                rows="3"
                v-model="address"
                required
              ></textarea>
            </div>

            <div class="form-group pb-2" v-if="states">
              <label for="state">{{ $t("State") }}/{{ $t("Region") }} *</label>
              <select
                name="state"
                id="state"
                class="form-control"
                required
                @change="onStateChanged($event)"
              >
                <option selected>{{ $t("Pleaseselect") }}...</option>
                <option
                  :value="state.id"
                  v-for="state in states"
                  :key="state.id"
                >
                  {{ state.name }}
                </option>
              </select>
            </div>

            <!-- <div class="form-group pb-2" v-if="cities">
              <label for="city">City *</label>
              <select
                name="city"
                id="city"
                class="form-control"
                required
                @change="onCityChanged($event)"
              >
                <option selected>{{ $t("Pleaseselect") }}...</option>
                <option :value="city.id" v-for="city in cities" :key="city.id">
                  {{ city.name }}
                </option>
              </select>
            </div> -->

            <small class="text-secondary mt-n10">* {{ $t("Required") }}</small>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-orange rounded-0 text-uppercase btn-block"
            @click="
              show_modal3 = false;
              addressDone = !addressDone;
              addAddress();
            "
          >
            {{ $t("SAVE") }}
          </button>
        </div>
      </modal>

      <!-- Edit Address modal -->
      <modal
        title="Edit Address"
        :is-large="true"
        v-if="show_modal3"
        @close="show_modal3 = false"
      >
        <div class="modal-body">
          <div class="form-body py-3 mx-5">
            <div class="form-group mt-3 pb-2">
              <!-- Names -->
              <div class="row">
                <div class="col py-0">
                  <label for="firstName_" class="text-secondary"
                    >{{ $t("FirstName") }} *</label
                  >
                  <input
                    type="text"
                    name="firstName_"
                    id="firstName_"
                    v-model="firstName_"
                    placeholder="John"
                    class="form-control"
                    required
                  />
                </div>
                <div class="col py-0">
                  <label for="lastName_" class="text-secondary"
                    >{{ $t("LastName") }} *</label
                  >
                  <input
                    type="text"
                    name="lastName_"
                    id="lastName_"
                    v-model="lastName_"
                    placeholder="Doe"
                    class="form-control"
                    required
                  />
                </div>
              </div>
            </div>

            <div class="form-group pb-2">
              <!-- Phone Number -->
              <label for="phonePrefix" class="text-secondary"
                >{{ $t("MobilePhoneNumber") }} *</label
              >
              <div class="d-flex justify-content-between">
                <input
                  type="text"
                  name="phonePrefix"
                  id="phonePrefix"
                  v-model="phonePrefix"
                  class="form-control mr-3"
                  style="width: 10%"
                  disabled
                />

                <input
                  type="text"
                  name="phoneNumber_"
                  id="phoneNumber_"
                  v-model="phoneNumber_"
                  placeholder="505000000"
                  class="form-control d-block"
                  required
                />
              </div>

              <a
                @click="addPhone = !addPhone"
                class="float-right"
                v-if="!addPhone"
              >
                <small class="d-flex text-muted mt-1">
                  <i class="mdi mdi-plus-circle mr-2"></i>
                  {{ $t("addSecondPhoneNumber") }}
                </small>
              </a>
            </div>

            <div class="form-group pb-2" v-if="addPhone">
              <!-- Phone Number -->
              <label for="phonePrefix" class="text-secondary">{{
                $t("AdditionalPhoneNumber")
              }}</label>
              <div class="d-flex justify-content-between">
                <input
                  type="text"
                  name="phonePrefix"
                  id="phonePrefix"
                  v-model="phonePrefix"
                  class="form-control mr-3"
                  style="width: 10%"
                  disabled
                />

                <input
                  type="text"
                  name="phoneNumber2_"
                  id="phoneNumber2_"
                  v-model="phoneNumber2_"
                  placeholder="505000000"
                  class="form-control d-block"
                  required
                />
              </div>
            </div>

            <div class="form-group pb-2">
              <label for="address" class="text-secondary"
                >{{ $t("Address") }} *</label
              >
              <textarea
                name="address"
                class="form-control"
                placeholder="Street Name / Building / Floor No. / Junction Name"
                id="address"
                cols="30"
                rows="5"
                v-model="address_"
                required
              ></textarea>
            </div>

            <div class="form-group pb-2" v-if="states">
              <label for="state">{{ $t("State") }}/{{ $t("Region") }} *</label>
              <select
                name="state"
                id="state"
                class="form-control"
                required
                @change="
                  cities_ = null;
                  onStateChanged($event);
                "
              >
                <option :selected="!state_">{{ $t("Pleaseselect") }}...</option>

                <option
                  :value="state_ ? state_ : state.id"
                  v-for="state in states"
                  :key="state.id"
                  :selected="state_"
                >
                  {{ state.name }}
                </option>
              </select>
            </div>

            <!-- <div class="form-group pb-2" v-if="cities_">
              <label for="city">City *</label>
              <select
                name="city"
                id="city"
                class="form-control"
                required
                @change="onCityChanged($event)"
              >
                <option :selected="!city_">{{ $t("Pleaseselect") }}...</option>
                <option
                  :value="city_ ? city_ : city.id"
                  v-for="city in cities_"
                  :key="city.id"
                  :selected="city_"
                >
                  {{ city.name }}
                </option>
              </select>
            </div> -->

            <small class="text-secondary mt-n10">* {{ $t("Required") }}</small>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-orange rounded-0 text-uppercase btn-block"
            @click="
              show_modal3 = false;
              addressDone = !addressDone;
            "
          >
            {{ $t("Update") }}
          </button>
        </div>
      </modal>
    </div>

    <div class="row" v-else>
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <div class="text-center my-3">
          <img
            src="@/assets/empty-cart.png"
            alt="empty search"
            class="img-fluid"
            width="50px"
            height="50px"
          />
          <h5 class="text-muted mt-5">{{ $t("yourCartIsEmpty") }}</h5>

          <p class="font-15 mt-4">
            {{ $t("youCanPlaceOrders") }}
          </p>

          <button
            class="btn shadow-sm px-3 rounded-0 btn-orange btn-large"
            @click.prevent="$router.push('/')"
          >
            {{ $t("GoShopping") }}
          </button>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import international from "@/assets/images/international.svg";
import modal from "@/views/customer/comments/ModalView";
import computeTotal from "@/mixins/totalOrders";

import MobiPayment from "@/components/customer/PaymentForMobile.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    international,
    modal,
    Loading,
    MobiPayment,
  },

  mixins: [computeTotal],
  data() {
    return {
      newsletter: false,
      addressDone: false,
      currentPage: 1,
      totalPages: 8,
      payMethod: null,
      showWhy: false,
      bootstrapPaginationClasses: {
        ul: "pagination",
        li: "page-item",
        liActive: "active",
        liDisable: "disabled",
        button: "page-link",
      },
      paginationAnchorTexts: {
        // first: "First",
        prev: "Previous",
        next: "Next",
        // last: "Last",
      },
      firstName: "",
      firstName_: "",
      lastName: "",
      lastName_: "",
      phonePrefix: "+237",
      phoneNumber: "",
      phoneNumber_: "",
      phoneNumber2: "",
      phoneNumber2_: "",
      addPhone: false,
      address: null,
      address_: null,
      addresses: [],
      // city: null,
      // city_: null,
      state: null,
      state_: null,
      states: [],
      // cities: [],
      // cities_: [],
      user_country: null,
      show_modal: false,
      show_modal2: false,
      show_modal3: false,
      coupons: [],
      voucherCode: "",
      disable: true,
      vouchDisable: false,
      btnVouchDisable: false,
      vouchResp: null,
      orders: null,
      errorMsg: null,
      errorMsg2: null,
      successMsg: null,
      cart_items: null,
      addressToUse: null,
      deliveryFee: 1000,
      confirmbtn: false,
      selectedUserPayInfo: null,
      appliedCoupon: [],
      // total: 0,
      loading: false,
      height: 80,
      width: 100,
      transition: "fade",
    };
  },

  methods: {
    ...mapActions([
      "getCoupons",
      "applyCoupon",
      "getAddresses",
      "addNewAddress",
      "checkoutOrder",
      "addressToOrders",
      "getStatesAction",
    ]),

    state_name(id) {
      let name = "";

      this.states.forEach((state) => {
        if (state.id == id) {
          name = state.name;
        }
      });

      return name;
    },

    selectedAddress() {
      console.log("Selected Address: ", this.addressToUse);
      this.addressToOrders({
        uuid: this.cart_items.uuid,
        address_id: this.addressToUse.id,
      }).then((response) => {
        console.log("address added to orders 200", response);
        localStorage.setItem("addressToUse", JSON.stringify(this.addressToUse));
      }).catch(error => {
        console.log("use address error: ", error)
      });
    },

    selectedPaymentMethod(event) {
      this.payMethod = event.target.value;
      console.log("Selected Pay Method: ", this.payMethod);

      localStorage.setItem("selPayMethod", JSON.stringify(this.payMethod));
    },

    addAddress() {
      if (this.phoneNumber && this.state && this.address) {
        this.loading = true;
        this.addNewAddress({
          id: this.user.id,
          phone: this.phoneNumber,
          country_id: this.country_id,
          city_id: this.state,
          quarter: this.address,
          default: 1,
        }).then(() => {
          if (this.addressUpdateSuccess.code === "ADDRESS_ADDED") {
            console.log("ADD address response: ", this.addressUpdateSuccess);
            this.$root.$emit("infoMsg", {
              alert: "alert-info",
              type: "info",
              message: this.addressUpdateSuccess.message,
            });

            this.$forceUpdate();
          }

          this.loading = false;
        });
      } else {
      }
    },

    editAddress(address) {
      this.firstName_ = this.user.first_name ? this.user.first_name : "";
      this.lastName_ = this.user.last_name ? this.user.last_name : "";
      this.phoneNumber_ = address.phone ? address.phone : "";
      this.address_ = address.quarter ? address.quarter : "";
      this.state_ = address.state_id ? address.state_id : null;
      // this.city_ = address.city ? address.city_id : null;

      this.states.forEach((state) => {
        if (state.id == this.state_) {
          this.cities_ = state.cities;
        }
      });

      console.log("Edit this address", address);
    },

    addVoucher() {
      this.disable = false;
      this.vouchDisable = true;
      console.log("coupon to add ", this.voucherCode);
    },

    checkVoucher() {
      this.applyCoupon({
        uuid: this.orders[0].uuid,
        vouchCode: this.voucherCode,
      }).then((response) => {
        this.vouchResp = response ? response : this.couponError;
        console.log("Vooucher response", response);

        if (
          this.vouchResp.code === "COUPON_NOT_EXIST" ||
          this.vouchResp.code === "CODE_NOT_VALID" ||
          this.vouchResp.code === "SHOP_NOT_MATCHES" ||
          this.vouchResp.code === "COUPON_NOT_VALID" ||
          this.vouchResp.code === "COUPON_NOT_AVAILABLE"
        ) {
          this.errorMsg = this.vouchResp.msg;
          this.successMsg = null;
          this.disable = true;
          this.vouchDisable = false;
        } else if (this.vouchResp.code === "COUPON_APPLIED") {
          this.successMsg = this.vouchResp.message;
          this.errorMsg = null;
          this.disable = true;
          this.vouchDisable = true;
          this.btnVouchDisable = true;

          localStorage.setItem("appliedCoupon", JSON.stringify(this.vouchResp));
        } else if (this.vouchResp.msg === "Server Error") {
          this.errorMsg =
            "Sorry, there was a problem contacting the server. Please try again later.";
          this.disable = true;
          this.vouchDisable = false;
        }
      });
    },

    onStateChanged(event) {
      var state_id = event.target.value;
      this.state_ = null;
      this.state = state_id ? state_id : null;

      console.log("state changed", this.state);

      console.log("states", this.states);
    },

    confirmPayment() {
      console.log("Payment Method selected: ", this.payMethod);
      this.confirmbtn = true;
      this.loading = true;

      this.checkoutOrder({
        uuid: this.cart_items.uuid,
      })
        .then((response) => {
          console.log("checking out the order", response);
          this.loading = false;
          // if (!response) {
          //   this.confirmbtn = false;
          // }

          if (response.data.code === "ERROR") {
            this.errorMsg2 = response.data.message;
            this.confirmbtn = false;
          } else {
            this.confirmbtn = true;
            this.$router.push({
              name: "paymethod",
              params: {
                uuid: this.cart_items.uuid,
                when: this.payMethod,
                addr: this.addressToUse,
              },
            });
          }

          // if (response) {
          //   if (this.payMethod === "after") {
          //     this.$router.push({ name: "paymentInfo" });
          //     console.log("payOndelvery route", this.payOnDelivery);
          //   } else if (this.payMethod === "before") {
          //     this.$router.push({
          //       name: "paymethod",
          //       params: { uuid: this.cart_items.uuid, when: this.payMethod },
          //     });
          //   }
          // }
        })
        .catch((error) => {
          console.log("error checkout: ", error);
        });
    },
  },

  computed: {
    ...mapGetters([
      "getUserDetails",
      "getStoredAddresses",
      "getStatesData",
      "getStoredCoupons",
      "getCachedOrders",
    ]),
    ...mapState({
      userDetails: "user",
      userAddresses: "userAddresses",
      userCoupons: "userCoupons",
      ordersList: "ordersList",
      couponResp: "couponResp",
      couponError: "couponError",
      checkoutError: "checkoutError",
      checkoutResp: "checkoutResp",
    }),

    payOnDelivery() {
      return this.cart_items.amount >= 10000 || this.cart_items.amount <= 100000
        ? false
        : true;
    },

    sumToPay() {
      return this.total + this.deliveryFee;
    },

    addressAvailable() {
      return this.addressToUse != null ? true : false;
    },

    total() {
      console.log("total Orders: ", this.computeTotal());
      return this.computeTotal();
    },
  },

  created() {
    this.user = this.user_ ? this.user_ : this.userDetails;

    console.log();

    // load states
    this.getStatesAction(this.user.country.id).then((response) => {
      console.log("got States Data:", response.cities);
      this.states = response.cities;
      this.country_id = this.user.country.id;
    });

    // this.getStatesData.forEach((country) => {
    //   if (country.id == 37) {
    //     this.states = country.states;
    //     this.country_id = country.id;
    //   }
    // });

    if (this.addresses.length == 0 || !this.addresses) {
      this.addresses = JSON.parse(localStorage.getItem("addresses"));
      console.log("users this addresses entered", this.addresses);
    }
    console.log("users this addresses", this.addresses);

    // load selected payment methods
    if (localStorage.getItem("selPayMethod")) {
      this.payMethod = JSON.parse(localStorage.getItem("selPayMethod"));
    }

    // load orders
    this.orders = this.orders_.data ? this.orders_.data : this.getCachedOrders;
    this.orders.forEach((order) => {
      if (order.status === "cart") {
        this.cart_items = order;
      }
    });

    if (localStorage.getItem("appliedCoupon")) {
      let couponInfo = JSON.parse(localStorage.getItem("appliedCoupon"));

      console.log("Coupon Info ", couponInfo);

      this.successMsg = couponInfo.message;
      this.errorMsg = null;
      this.disable = true;
      this.vouchDisable = true;
      this.btnVouchDisable = true;

      this.voucherCode = couponInfo.data.order.order_item[0].coupon.code;

      console.log("Coupon Info code  ", this.voucherCode);
    }

    console.log("this user addresses; ", this.user_);

    // // load addresses
    this.loading = true;
    this.getAddresses(this.user).then(() => {
      this.addresses =
        this.addresses_.data.addresses.length > 0
          ? this.addresses_.data.addresses
          : this.getStoredAddresses.data.addresses;

      localStorage.setItem("addresses", JSON.stringify(this.addresses));
      this.loading = false;
    });

    console.log("users cart_items", this.cart_items);
  },

  mounted() {
    console.log("users data", this.user);
    if (this.user) {
      this.firstName = this.user.first_name ? this.user.first_name : "";
      this.lastName = this.user.last_name ? this.user.last_name : "";
      this.phoneNumber = this.user.phone
        ? this.user.phone.slice(5, this.user.phone.length)
        : "";
    }

    // load coupons
    this.getCoupons(this.user).then(() => {
      this.coupons = this.coupons_.data.coupons.length
        ? this.coupons_.data.coupons
        : this.getStoredCoupons.data.coupons;

      console.log("Coupons payment: ", this.coupons);
    });

    // load address to use
    if (localStorage.getItem("addressToUse") && this.addresses) {
      this.addressToUse = JSON.parse(localStorage.getItem("addressToUse"));
    } else {
      this.addressToUse = null;
      localStorage.setItem("addressToUse", null);
    }
    console.log("addressToUse: ", this.addressToUse);
    this.$root.$on("mobiConfirmPayment", () => {
      this.confirmPayment();
    });
  },
};
</script>

<style scoped>
input[type="radio"]:checked + label:before,
.addressList input[type="radio"]:checked + label:before,
.option input[type="radio"]:checked + label:before {
  background-color: var(--afprimary);
  border: 1.5px solid var(--afprimary);
}

.addressList input[type="radio"] + label:before,
.option input[type="radio"] + label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 5px;
  margin-right: 6px;
  background-clip: content-box;
  border: 2px solid #bbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

.addressList:nth-of-type(1) {
  height: auto !important;
}

.addressList label,
.option label {
  display: flex;
  align-items: center;
}

.addressList input[type="radio"],
.option input[type="radio"] {
  display: none;
}

a.btn > i {
  font-size: 1.3rem;
}
.btn.rnd {
  border-radius: 50%;
}
.btn.rnd:hover {
  background: #db9e3557;
}

.form-body {
}
input {
  border-radius: 0 !important;
}

a:hover small {
  color: var(--afprimary) !important;
}

img {
  width: 100%;
  height: 5em;
  object-fit: cover;
}

.btn-default {
  color: var(--afprimary);
  font-weight: 500;
}

.btn.btn-default:hover {
  color: var(--afprimary);
  font-weight: 500;
}

.why:hover {
  cursor: pointer;
  color: black;
}

input[type="code"].form-control:focus {
  border-color: #dcd5d5 !important;
  outline: 0;
  box-shadow: none !important;
}

.addressList {
  height: 15em;
  overflow-y: auto;
}

.itemName.text-truncate {
  white-space: normal !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
