import Vue from "vue";
import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.css"

Vue.use(Vuetify, {
    
});

export default new Vuetify({
    iconfont: 'md',
    theme: {
        primary: '#DB9D35',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#ff4444',
        info: '#33b5e5',
        success: '#00C851',
        warning: '#ffbb33'
    }
});
