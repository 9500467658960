<template>
  <div
    id="mobilePayment"
    class="col-12 pt-0 px-0 d-block d-sm-block d-md-none d-lg-none"
  >
    <ul
      class="nav nav-pills py-3 shadow-sm nav-fill px-4"
      id="mobiPaymentTab"
      role="tablist"
    >
      <li class="nav-item" role="presentation">
        <a
          class="nav-link active"
          id="delivery-tab"
          data-toggle="tab"
          href="#delivery"
          role="tab"
          aria-controls="delivery"
          aria-selected="true"
          >{{ $t("Delivery") }}</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link disabled"
          id="payment-tab"
          data-toggle="tab"
          href="#payment"
          role="tab"
          aria-controls="payment"
          aria-selected="false"
          >{{ $t("Payment") }}</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link disabled"
          id="summary-tab"
          data-toggle="tab"
          href="#summary"
          role="tab"
          aria-controls="summary"
          aria-selected="false"
          >{{ $t("Summary") }}</a
        >
      </li>
    </ul>
    <div class="tab-content" id="mobiPaymentTabContent">
      <div
        class="tab-pane fade show active"
        id="delivery"
        role="tabpanel"
        aria-labelledby="delivery-tab"
      >
        <slot name="delivery"></slot>
      </div>
      <div
        class="tab-pane fade"
        id="payment"
        role="tabpanel"
        aria-labelledby="payment-tab"
      >
        <slot name="payment"></slot>
      </div>
      <div
        class="tab-pane fade"
        id="summary"
        role="tabpanel"
        aria-labelledby="summary-tab"
      >
        <slot name="summary"></slot>
      </div>
    </div>

    <div class="px-3">
      <button
        class="
          btn btn-orange btn-lg btn-block
          rounded-0
          text-uppercase
          font-weight-bold
          shadow-sm
        "
        v-if="currentTab !== 'summary-tab'"
        @click.prevent="showNext()"
      >
        {{ $t("next") }}
      </button>
      <button
        class="
          btn btn-orange btn-lg btn-block
          rounded-0
          text-uppercase
          font-weight-bold
          shadow-sm
        "
        @click.prevent="confirm()"
        v-else
      >
        {{ $t("confirm") }}
      </button>
      <button
        class="
          btn btn-white btn-lg
          shadow-sm
          btn-block
          rounded-0
          text-uppercase
          font-weight-bold
        "
        @click.prevent="$router.push({ name: 'cart' })"
      >
        {{ $t("ModifyCart") }}
      </button>
    </div>
  </div>
</template>

<script>
import "@/assets/css/customer/payment/payment.css";
import $ from "jquery";

export default {
  data() {
    return {
      atSummaryTab: false,
      currentTab: "",
    };
  },

  methods: {
    showNext() {
      var tabs = $("#mobiPaymentTab li>a");
      console.log("this tabs: ", tabs);
      console.log("in show next tab");

      var activeTab = tabs.filter(".active");

      activeTab.parent().next("li").children().removeClass("disabled");

      activeTab
        .parent()
        .next("li")
        .children('a[data-toggle="tab"]')
        .tab("show");

      tabs
        .filter(".active")
        .parent()
        .prev("li")
        .children()
        .addClass("color-font");

      console.log("current active tab: ", tabs.filter(".active"));
      this.currentTab = tabs.filter(".active").attr("id");

      if (this.currentTab === "summary-tab") {
        console.log("in summary tab: ", true);
        this.atSummaryTab = true;
      }
    },

    showPrev() {
      var tabs = $("#mobiPaymentTab li>a");
      console.log("this tabs: ", tabs);
      console.log("in show prev tab");

      var activeTab = tabs.filter(".active");

      activeTab.parent().prev("li").children().removeClass("disabled");

      activeTab
        .parent()
        .prev("li")
        .children('a[data-toggle="tab"]')
        .tab("show");

      tabs
        .filter(".active")
        .parent()
        .next("li")
        .children()
        .removeClass("color-font");

      this.currentTab = tabs.filter(".active").attr("id");

      if (this.currentTab === "payment-tab") {
        console.log("in payment tab: ", true);
        this.atSummaryTab = false;
      }
    },

    confirm() {
      this.$root.$emit("mobiConfirmPayment");
    },
  },
  mounted() {
    this.$root.$on("showPreviousTab", () => {
      this.showPrev();
    });

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 150) {
        $("#mobilePayment ul#mobiPaymentTab").addClass("fixed-top");
      } else {
        $("#mobilePayment ul#mobiPaymentTab").removeClass("fixed-top");
      }
    });
    var self = this;
    $('a[data-toggle="tab"]').on("shown.bs.tab", function (e) {
      // this.currentTab = e.relatedTarget.id; // previous active tab
      self.currentTab = e.target.id; // newly activated tab

      if (self.currentTab === "summary-tab") {
        self.atSummaryTab = true;
      } else {
        console.log("id of newly activated tab: " + self.currentTab);
        self.atSummaryTab = false;
      }
    });
  },
};
</script>
