<template>
  <div class="container-fluid">
    <div class="row bg-white shadow-sm srchHeader" v-if="searchWord">
      <div
        class="
          container
          py-5
          mt-3
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <!-- <div class=""> -->
        <h4 class="text-capitalize float-left srchTitle my-0">
          {{ $t("SearchResultsfor") }}: {{ searchWord }}
        </h4>
        <!-- </div> -->
        <!-- <div class=""> -->
        <span class="float-right" v-if="searchProducts.length > 0"
          >{{ $t("All") }} {{ searchProducts.length }} {{ $t("Results") }}</span
        >
        <!-- </div> -->
      </div>
    </div>
    <div class="container py-0 px-0">
      <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        color="var(--afprimary)"
        :height="height"
        :width="width"
        :transition="transition"
        background-color="#000"
      ></loading>

      <listing-widget
        :widgetName="'Search Results'"
        :widgetInfo="info"
        :banner="false"
        :categories="categoriesList"
        :products="searchProducts"
        :otherFilters="featuresNonColor"
        :colors="colors"
        :filterRequest="filterRequest"
      >
        <template #categoryLoader>
          <div class="row text-center">
            <div class="col-12 pb-1" v-show="loading2">
              {{ $t("Loading") }}...
            </div>
            <div class="col-12 mb-4 pt-0">
              <v-progress-linear
                color="orange accent-3"
                :indeterminate="loading2"
                :active="loading2"
                rounded
                height="6"
              ></v-progress-linear>
            </div>
          </div>
        </template>
      </listing-widget>

      <modal
        title="Filter"
        :is-small="false"
        v-if="show_modal"
        @close="show_modal = false"
      >
        <div class="modal-body">
          <div class="">
            <h6 class="text-capitalize mb-3">{{ $t("categories") }}</h6>
            <div
              class="mt-2 mb-2 pl-2"
              v-for="(category, index) in categoriesList"
              :key="index"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="category-1"
                />
                <label class="custom-control-label" for="category-1">{{
                  category
                }}</label>
              </div>
            </div>
          </div>

          <div class="divider mt-5 mb-5 border-bottom border-bottom"></div>

          <div class="">
            <h6 class="text-capitalize mt-5 mb-3">{{ $t("Size") }}</h6>
            <div
              class="mt-2 mb-2 pl-2"
              v-for="(size, index) in sizesList"
              :key="index"
            >
              <div class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="filter-size-1"
                />
                <label class="custom-control-label" for="filter-size-1">{{
                  size
                }}</label>
              </div>
            </div>
          </div>

          <div class="divider mt-5 mb-5 border-bottom border-bottom"></div>

          <div>
            <h6 class="mt-5 mb-3">{{ $t("Price") }}</h6>
            <div class="price-filter-control">
              <div class="form-group">
                <div class="row">
                  <div class="col">
                    <label for="price-min-control">{{ $t("From") }}:</label>
                    <input
                      type="number"
                      class="form-control pull-left mb-2"
                      value="50"
                      id="price-min-control"
                    />
                  </div>
                  <div class="col">
                    <label for="price-max-control">{{ $t("To") }}:</label>

                    <input
                      type="number"
                      class="form-control pull-right"
                      value="150"
                      id="price-max-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-dark rounded-0"
            @click="show_modal = false"
          >
            {{ $t("Close") }}
          </button>
          <button
            type="button"
            class="btn btn-orange rounded-0"
            @click="show_modal = false"
          >
            {{ $t("Update") }}
          </button>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

import modal from "@/views/customer/comments/ModalView";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ListingWidget from "@/components/widgets/GenericListing.vue";

import axios from "axios";

export default {
  components: {
    modal,
    Loading,
    ListingWidget,
  },
  data() {
    return {
      products: [],
      productInfo: null,
      disable: false,
      page: 1,
      last_page: 0,
      pageSize: 9,
      deals: [],
      sortList: [
        "Alphabetical",
        "Newest",
        "Best Selling",
        "Price Ascending",
        "Price Descending",
      ],
      categoriesList: [],
      overseaCountries: [
        "Cameroon",
        "Nigeria",
        "Benin",
        "France",
        "USA",
        "UK",
        "China",
      ],
      sizesList: ["X-Small", "Small", "Medium", "Large", "X-Large"],
      selectedCriteria: "",
      show_modal: false,
      srch_cat_id: null,
      searchWord: "",
      searchProducts: [],
      searchProducts_save: [],
      srchPrdts_slice: [],
      discounts: [],
      selectedDiscount: null,
      features: [],
      featuresNonColor: [],
      availPrices: [],
      minPriceSelected: null,
      maxPriceSelected: null,
      featureSelected: null,
      colorFeatures: [],
      colors: null,

      currentPage: 1,
      totalPages: 0,
      perPage: 9,
      inc: 0,

      loading: false,
      loading2: false,
      height: 80,
      width: 100,
      transition: "fade",

      values: [],
      keys: [],

      colorfilters: [],
      categoryfilters: [],
      featurefilters: [],
      currentMin: null,
      currentMax: null,

      routeItems: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: this.$route.meta.title,
          disabled: true,
          href: this.$route.path,
        },
      ],
    };
  },

  created() {
    this.loadSearchTerm();

    this.$root.$on("refreshFilter", (refresh) => {
      if (refresh) {
        this.filterRequest();
      }
    });

    this.$root.$on("emptyFilters", (empty) => {
      if (empty) {
        this.searchProducts = this.searchProducts_save;
      }
    });
  },

  methods: {
    ...mapActions([
      "addToCart",
      "getGuestData",
      "getOrders",
      "filterCategories",
      "filterByFeature",
      "searchItem",
      "getItemCategory",
      "searchItemByRange",
      "searchItemByFeatures",
    ]),

    newPrice(price, discount) {
      return price - discount * price;
    },

    filterRequest() {
      this.loading = true;
      // this.loading2 = true;
      this.searchProducts = [];
      let colorResp = [];
      let featureResp = [];
      let categoryResp = [];

      if (this.currentMin || this.currentMax) {
        this.searchItemByRange({
          minPrice: this.currentMin ? this.currentMin : this.minPrice,
          maxPrice: this.currentMax ? this.currentMax : this.maxPrice,
        }).then((response) => {
          this.searchProducts = [
            ...this.searchProducts,
            ...response.items.data,
          ];
          this.loading = false;
          this.searchProducts = this.removeDuplicates(this.searchProducts);

          console.log("min filter response", this.searchProducts);
        });
      } else if (this.colorfilters.length > 0) {
        this.colorfilters.forEach((color) => {
          this.searchItemByFeatures({
            values: color,
            keys: "color",
          }).then((response) => {
            this.loading = false;
            colorResp = [...new Set([...colorResp, ...response.items.data])];
            console.log("color: ", color, " response: ", response);
            this.searchProducts = [...this.searchProducts, ...colorResp];
            this.searchProducts = this.removeDuplicates(this.searchProducts);

            console.log("filter colorResp: ", this.searchProducts);
          });
        });
      } else if (this.featurefilters.length > 0) {
        this.featurefilters.forEach((feature) => {
          this.searchItemByFeatures({
            values: feature.value,
            keys: feature.key,
          }).then((response) => {
            this.loading = false;
            featureResp = [
              ...new Set([...featureResp, ...response.items.data]),
            ];
            console.log("prev Searchprod", this.searchProducts);
            this.searchProducts = [...this.searchProducts, ...featureResp];
            this.searchProducts = this.removeDuplicates(this.searchProducts);
            console.log("featureResp: ", featureResp, " response: ", response);
            console.log("filterProducts: ", this.searchProducts);
          });
        });
      }

      // filter categories
      else if (this.categoryfilters.length > 0) {
        this.categoryfilters.forEach((category) => {
          this.filterCategories({
            category_id: category.id,
          }).then((response) => {
            this.loading = false;
            categoryResp = [
              ...new Set([...categoryResp, ...response.items.data]),
            ];
            this.searchProducts = [...this.searchProducts, ...categoryResp];
            this.searchProducts = this.removeDuplicates(this.searchProducts);
            console.log(
              "categoryResp: ",
              categoryResp,
              " response: ",
              response
            );
            console.log("filterCategories: ", this.searchProducts);
          });
        });
      } else {
        this.loading = false;
        this.searchProducts = this.searchProducts_save;
      }
    },

    changeSelectSort({ data, text }) {
      console.log("Change Select criteria: ", data);
      this.selectedCriteria = text;
    },

    filterProducts() {
      var products = this.searchProducts_save;
      this.searchProducts = [];

      if (this.selectedDiscount == "all") {
        this.loading = true;

        this.searchProducts = products;

        this.sliceProducts(this.searchProducts, this.searchProducts.length);

        console.log("search products filter ", this.searchProducts);

        this.loading = false;
      } else {
        this.loading = true;

        this.searchProducts = [];

        console.log("selected discount ", this.selectedDiscount);

        products.forEach((product) => {
          console.log("selected disc product ", product);

          if (product.item_discount) {
            if (
              product.item_discount.percentage_decrease == this.selectedDiscount
            ) {
              this.searchProducts.push(product);
            }
          }
        });
        this.sliceProducts(this.searchProducts, this.searchProducts.length);

        this.loading = false;
      }
    },

    filterPrices() {
      let filtered = this.availPrices.filter((x) => {
        return (
          x.price >= this.minPriceSelected && x.price <= this.maxPriceSelected
        );
      });

      console.log("filtered prices", filtered);

      let filteredIds = [];

      filtered.forEach((product) => {
        filteredIds.push(product.id);
      });

      console.log("filtered ids", filteredIds);

      let newProducts = [];
      let products_save = this.searchProducts_save;

      products_save.forEach((product) => {
        if (filteredIds.includes(product.id)) {
          newProducts.push(product);
        }
      });
      this.searchProducts = newProducts;

      this.sliceProducts(this.searchProducts, this.searchProducts.length);

      console.log("filtered products", newProducts);
    },

    filterProductsFeature(obj, value, key) {
      console.log("feature selected", obj, value, key);

      let productId = obj.item_id;
      let catId = null;
      console.log("feature product id", productId);

      this.searchProducts_save.forEach((product) => {
        if (product.id === productId) {
          console.log("Category Id ", product.category_id);
          catId = product.category_id;
        }
      });

      this.values.push(value);
      this.keys.push(key);
      this.filterByFeature({
        category_id: catId,
        value: this.values,
        key: this.keys,
      }).then((response) => {
        console.log("filter by feature response: ", response);

        this.searchProducts = response.items;

        this.sliceProducts(this.searchProducts, this.searchProducts.length);

        this.loadCategories(this.searchProducts);
        this.loadDiscounts(this.searchProducts);
        this.loadPrdtPrices_(this.searchProducts);
      });
    },

    parseSearchProducts() {
      if (localStorage.getItem("prevSearch")) {
        this.searchProducts = this.searchProducts_save = JSON.parse(
          localStorage.getItem("prevSearch")
        ).searchProducts;

        this.sliceProducts(this.searchProducts, this.searchProducts.length);

        console.log("searchProducts_save: ", this.searchProducts_save);
      } else {
        this.searchProducts = this.searchProducts_save =
          this.$route.params.products;
        // this.srchPrdts_slice = this.searchProducts.slice(0, this.perPage);

        var length = this.$route.params.products.length;

        // this.totalPages = Math.ceil( / 9);
        // console.log("totalPages: ", this.totalPages);

        this.sliceProducts(this.searchProducts, length);
        console.log("searchProducts_save 2: ", this.searchProducts_save);

        // return this.srchPrdts_slice;
      }
      this.loadCategories(this.searchProducts);
      this.loadDiscounts(this.searchProducts);
      this.loadFeatures(this.searchProducts);
      this.loadColorFeatures();
      this.loadPrdtPrices_(this.searchProducts);
    },

    sliceProducts(products, len) {
      this.srchPrdts_slice = products.slice(0, this.perPage);
      this.totalPages = Math.ceil(len / 9);
      console.log("totalPages: ", this.totalPages);
    },

    loadCategories(results) {
      this.categoriesList = [];
      let categoryIds = [];
      if (results) {
        results.forEach((result) => {
          categoryIds.push(result.category_id);
        });
      }

      categoryIds = [...new Set(categoryIds)];

      console.log("categories main", categoryIds);
      this.loading2 = true;
      categoryIds.forEach((id) => {
        this.getItemCategory({ category_id: id }).then((response) => {
          this.loading2 = false;
          console.log("response cat id: ", response);
          this.categoriesList.push(response.category);
        });
      });

      console.log("all categories: ", this.categoriesList);
    },

    loadDiscounts(results) {
      this.discounts = [];
      if (results) {
        results.forEach((product) => {
          if (product.item_discount) {
            this.discounts.push(
              product.item_discount.percentage_decrease * 100
            );
            var uniq_d = [...new Set(this.discounts)];

            this.discounts = uniq_d.sort();
          }
        });
      }
    },

    loadFeatures(results) {
      let featureSet = [];

      results.forEach((result) => {
        featureSet.push(result.features);
      });

      console.log("feature set", featureSet);

      let features = [];

      featureSet.forEach((featurelist) => {
        let list = featurelist;
        list.forEach((feature) => {
          // if (feature.quantity) {
          features.push(feature);
          // }
        });
      });
      features = [...features];
      console.log("features all", features);
      console.log("features keys", Object.keys(features));

      // let featuresObj =
      features.forEach((feature) => {
        if (Object.keys(this.features).includes(feature.key)) {
          console.log("key not present");
          this.features[feature.key].value.push(feature);
        } else {
          this.features[feature.key.toLowerCase()] = {
            id: feature.id,
            value: [feature],
          };
        }
      });

      console.log("features", this.features);

      // fix the feature brands
      let featureBrands = this.features["brand"].value;

      featureBrands = Array.from(
        new Set(featureBrands.map((a) => a.value.toLowerCase()))
      ).map((value) => {
        return featureBrands.find(
          (a) => a.value.toLowerCase() === value.toLowerCase()
        );
      });

      console.log("feature Brands: ", featureBrands);

      this.features["brand"].value = featureBrands;
      console.log("features222", this.features);

      let keys = Object.keys(this.features);
      console.log("NEW features keys", keys);
      let dict = [];
      keys.forEach((key) => {
        dict.push({
          key: key,
          value: this.features[key].value,
        });
      });
      this.features = dict;

      this.featuresNonColor = dict.filter((element) => {
        if (!element.key.includes("color")) {
          return element;
        }
      });

      console.log("NEW features", this.featuresNonColor);
    },

    loadColorFeatures() {
      if (this.features) {
        this.features.filter((element) => {
          if (element.key.includes("color")) {
            this.colorFeatures.push(element.value);
          }
        });
        console.log("color features: ", this.colorFeatures);

        let colors = [];

        if (this.colorFeatures) {
          this.colorFeatures.forEach((feature) => {
            feature.forEach((fet) => {
              colors.push(fet.value);
            });
          });
        }

        // this.colors = [...new Set(colors)];
        this.colors = colors.reduce((x, y) => {
          if (x[y]) {
            x[y]++;
            return x;
          } else {
            var z = {};
            z[y] = 1;
            return Object.assign(x, z);
          }
        }, {});

        console.log("colors: ", this.colors);
      }
    },

    loadSearchTerm() {
      if (localStorage.getItem("prevSearch")) {
        this.searchWord = JSON.parse(localStorage.getItem("prevSearch")).word;

        console.log(
          "prevSearch: ",
          JSON.parse(localStorage.getItem("prevSearch")).word
        );
      } else {
        this.searchWord = this.$route.params.q;
      }
      // this.searchProducts = this.$route.params.products;

      console.log("Search Term: ", this.searchWord);
      console.log("Search Result Products: ", this.searchProducts);
    },

    updateResource() {
      if (this.currentPage == 1) {
        this.inc = 0;
        this.srchPrdts_slice = this.searchProducts.slice(
          this.inc,
          this.perPage + this.inc
        );
        console.log("Inc: ", this.inc);
        console.log("current page: ", this.currentPage);
      } else {
        this.inc = (this.currentPage - 1) * this.perPage;
        this.srchPrdts_slice = this.searchProducts.slice(
          this.inc,
          this.perPage + this.inc
        );
        console.log("Inc: ", this.inc);
        console.log("current page: ", this.currentPage);
      }

      console.log("Search Products slice", this.srchPrdts_slice);
    },
  },

  computed: {
    ...mapGetters(["loggedIn", "getUserDetails", "getGuestDataObj"]),
    ...mapState(["superDeals", "cart_no"]),

    searchTerm() {
      if (localStorage.getItem("prevSearch")) {
        return JSON.parse(localStorage.getItem("prevSearch")).word;
      }
      return this.$route.params.q;
    },

    info() {
      return {
        searchWord: this.searchWord,
        searchTerm: this.searchTerm,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
      };
    },
  },

  mounted() {
    // localStorage.setItem(
    //   "searchProducts",
    //   JSON.stringify(this.f$route.params.products)
    // );
    this.parseSearchProducts();
    console.log("Results Deals: ", this.deals);
    this.loadSearchTerm();

    this.$root.$on("filterValues", (filters) => {
      console.log("filter request colors", filters.colors);
      console.log("filter request categories", filters.categories);
      console.log("filter request features", filters.features);
      this.colorfilters = filters.colors;
      this.categoryfilters = filters.categories;
      this.featurefilters = filters.features;
    });

    this.$root.$on("currentMin", (min) => {
      console.log("current Min: ", min);
      this.currentMin = min;
    });
    this.$root.$on("currentMax", (max) => {
      console.log("current Max: ", max);
      this.currentMax = max;
    });

    // this.searchWord = this.$route.params.q;

    // this.searchWord = this.$route.params.q;
    // console.log("Search Term: ", this.$route.params.q);
    // console.log("Products: ", this.$route.params.products);

    // $("#backToTop").click(function () {
    //   //1 second of animation time
    //   //html works for FFX but not Chrome
    //   //body works for Chrome but not FFX
    //   //This strange selector seems to work universally
    //   $("html, body").animate({ scrollTop: 0 }, 1000);
    // });
  },
};
</script>

<style scoped>
a:not(.md-button) {
  color: #534f59 !important;
  font-size: 13px;
}

a.btn:not(.md-button) {
  color: #f1921d !important;
}

a.btn.btn-update:hover {
  background: black !important;
}
.prdtCard {
  height: 23.8em;
  width: 220px;
  height: 388px;
  display: block;
  position: relative;
  transition: all 0.2s linear;
}

.color-font {
  color: #f1921d !important;
}

a:hover > .prdtName {
  color: #ff8100 !important;
}

.flash-illus {
  width: 100px;
  margin-top: -1em;
  display: flex;
}

.prdtCard:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-color: #e0e0e0;
}

h4.srchTitle,
.srchHeader {
  font-family: "Barlow";
}

.prdtImage .img-fluid {
  width: 100%;
  height: 17.5em;
  object-fit: cover;
}

.prdtName {
  font-size: 15px;
  font-weight: normal;
}

.prdtName.text-truncate {
  white-space: normal !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.item_detail {
  line-height: 20px;
  height: 20px;
  margin-top: 15px;
}

.item_delete {
  margin-top: 2px;
  margin-bottom: 4px;
  height: 28px;
}

.delPrice {
  display: inline-block;
  width: 100%;
  height: 28px;
  font-size: 12px;
  color: #999;
  text-decoration: none;
}

.priceTag {
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  line-height: 20px;
  height: 20px;
}

.rrp {
  display: inline-block;
  line-height: 14px;
}

.prdtDiscount {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 10px;
  width: 50px;
  height: 50px;
  background-color: #ffa300;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  /* display: flex; */
}

.prdtDiscount > strong {
  font-size: 15px;
}

.prdtDiscount > em {
  font-size: 12px;
}

.prdtDiscount > p {
  font-size: 11px;
}
a.card {
  color: black !important;
}

a > .prdtName {
  color: black !important;
}

.bootstrap-select {
  width: 8.7rem !important;
}

a.cart-btn {
  bottom: 0;
  position: absolute;
  margin-bottom: 23px;
  right: 0;
  margin-right: 8px;
}

.categoriesList {
  height: 168px;
  overflow-y: auto;
}

.discountList {
  height: 168px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.discountList-no-length {
  height: auto !important;
  overflow-y: auto;
}

.categoriesList-no-length {
  height: auto !important;
  overflow-y: auto;
}

.overseaCountries {
  height: 15em;
  overflow-y: auto;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none !important;
}

input[type="radio"] {
  display: none;
}

input[type="radio"] + label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 5px;
  margin-right: 3px;
  background-clip: content-box;
  border: 2px solid #bbb;
  background-color: #e7e6e7;
  border-radius: 50%;
  margin-top: -0.2em;
}

input[type="radio"]:checked + label:before {
  background-color: var(--afprimary);
  border: 1.5px solid var(--afprimary);
}

.features {
  height: 168px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.features-no-length {
  height: auto !important;
  overflow-y: auto;
  /* display: flex; */
  /* flex-direction: column; */
}

.feature-label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  /* font-size: 1px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.feature-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  /* background-color: #eee; */
  border: 1px solid #929292;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
/* .feature-label:hover input ~ .checkmark {
  background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */
.feature-label input:checked ~ .checkmark {
  background-color: var(--afprimary);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.feature-label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.feature-label .checkmark:after {
  left: 6.5px;
  top: 1.5px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

@media only screen and (max-width: 768px) {
  .prdtCard {
    width: 100%;
  }
  h4.srchTitle {
    font-size: 16px;
    word-wrap: break-word;
  }
}
</style>
