<template>
  <div id="comments" class="row">
    <div class="col-md-12 py-0">
      <div class="card pane border-0 shadow-sm bg-white py-4 px-5">
        <!-- Header -->
        <div
          class="
            card-header
            border-0
            bg-transparent
            pl-2
            py-0
            my-1
            d-flex
            align-items-center
          "
        >
          <button @click="$router.go(-1)" class="btn btn-lg pl-0 text-dark">
            <i class="mdi mdi-arrow-left h5"></i>
          </button>
          <div>
            <h4 class="float-left">{{ $t("PendingReviews") }}</h4>
            <button class="btn btn-orange float-right" @click="demo = !demo">
              toggle Empty Reviews <i class="mdi mdi-delete-empty"></i>
            </button>
          </div>
        </div>
        <hr class="my-1" />

        <!-- Content -->
        <div class="row px-2">
          <!-- Empty comments -->
          <div v-if="!demo">
            <div class="row justify-content-center">
              <div class="col-8 py-12">
                <div class="text-center">
                  <emptyReviews></emptyReviews>
                  <h5 class="text-muted mt-5">
                    {{ $t("noOrdersForFeedback") }}
                  </h5>

                  <p class="font-15 mt-4">
                    {{ $t("afterGettingProducts") }}
                  </p>

                  <button
                    @click="$router.push({ path: '/' })"
                    class="
                      btn btn-orange
                      rounded-0
                      shadow
                      font-weight-normal
                      mr-3
                      px-5
                      py-2
                      mt-3
                      mb-12
                    "
                  >
                    {{ $t("CONTINUESHOPPING") }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Comment listing and Tabs -->
          <div class="container" v-else>
            <tabbed-content :tabList="listOfTabs" :initTab="listOfTabs[0].name">
              <!-- Tabs content -->
              <template #pending-tab>
                <div class="row mt-4">
                  <div class="col-md-12 px-7">
                    <div
                      class="row border mb-3"
                      v-for="comment in comments"
                      :key="comment.id"
                    >
                      <div class="col-md-12 col-lg-3 col-sm-12 col-12">
                        <img
                          :src="comment.image_url"
                          alt=""
                          class="img-fluid"
                        />
                      </div>

                      <div
                        class="
                          col-md-12 col-lg-9 col-sm-12 col-12
                          pl-auto pl-sm-auto pl-md-auto pl-lg-0
                        "
                      >
                        <div class="row">
                          <div class="col-9 pr-0">
                            <p class="mb-2 prdtName">
                              {{ comment.item_name }} Lorem ipsum dolor, sit
                              amet consectetur adipisicing elit. Saepe nisi
                              sequi, quos perspiciatis culpa assumenda!
                            </p>
                            <star-rating
                              :rating="comment.avg_rating"
                              :read-only="true"
                              :increment="0.5"
                              :star-size="12"
                              :show-rating="false"
                              class="rating"
                            ></star-rating>
                            <p class="mb-0 mt-2">
                              {{ $t("Order") }} {{ comment.order_id }}
                            </p>

                            <p class="mt-2 mb-0">
                              {{ $t("Quantity") }}: {{ comment.quantity }}
                            </p>
                            <p class="mb-0 mt-1">
                              {{ $t("On") }}
                              <span class="comment-date ml-1">{{
                                comment.created_at
                              }}</span>
                            </p>
                          </div>

                          <div class="col-3 pr-2">
                            <a
                              class="
                                btn btn-link btn-sm
                                float-right
                                item-actions
                              "
                              ><i class="mdi mdi-delete text-danger"></i
                            ></a>
                            <a
                              @click="
                                show_modal = !show_modal;
                                edit = true;
                                showComment(comment);
                              "
                              class="
                                btn btn-link btn-sm
                                float-right
                                item-actions
                              "
                              ><i class="mdi mdi-pencil color-font"></i
                            ></a>
                            <a
                              @click="
                                show_modal = !show_modal;
                                edit = false;
                                showComment(comment);
                              "
                              class="
                                btn btn-link btn-sm
                                float-right
                                item-actions
                              "
                              ><i class="mdi mdi-eye text-primary"></i
                            ></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #completed-tab>
                <div class="row mt-4">
                  <div class="col-md-12">
                    <div
                      class="row"
                      v-for="comment in comments"
                      :key="comment.id"
                    >
                      <div class="col-4">
                        <img
                          :src="comment.image_url"
                          alt=""
                          class="img-fluid"
                        />
                      </div>

                      <div class="col-8">
                        <h5>Lorem ipsum dolor sit.</h5>
                        <p class="mb-0">{{ comment.order_id }}</p>

                        <p class="mt-2 mb-0">
                          {{ $t("Quantity") }}: {{ comment.quantity }}
                        </p>
                        <p>{{ $t("On") }} {{ comment.created_at }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </tabbed-content>

            <modal
              title="Your Review Matters"
              :is-small="false"
              v-if="show_modal"
              @close="show_modal = false"
            >
              <div class="modal-body py-1">
                <div class="row">
                  <div class="col-md-4 pr-0 my-0">
                    <div class="card border-0">
                      <img :src="mdl_image" alt="shoe" class="img-fluid" />
                    </div>
                  </div>
                  <div class="col-md-8 my-0 align-items-center">
                    <b>{{ mdl_prodt_name }}</b>
                    <p class="mb-0 text-muted mt-1">{{ mdl_category }}</p>
                    <div class="d-flex mt-1">
                      <star-rating
                        :rating="mdl_rating"
                        :read-only="true"
                        :increment="0.5"
                        :star-size="12"
                        :show-rating="false"
                        class="rating"
                      ></star-rating>
                      <span class="ml-1">(20 {{ $t("ratings") }})</span>
                    </div>
                    <div class>
                      <p
                        class="
                          mt-3
                          mb-0
                          d-flex
                          justify-content-
                          align-items-center
                        "
                      >
                        <small class="text-muted mr-1"
                          >{{ $t("Lastedited") }}:</small
                        >
                        <small class="color-font font-weight-bold">{{
                          mdl_updated_date | fromNow
                        }}</small>
                      </p>
                    </div>
                  </div>
                </div>

                <hr class="mb-0" />

                <div class="row py-2">
                  <div class="col-md-12">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <h6>{{ $t("YourComment") }}:</h6>
                      <a
                        @click="edit = !edit"
                        v-if="!edit"
                        class="text-secondary"
                      >
                        <i class="mdi mdi-pencil"></i>
                        {{ $t("Edit") }}
                      </a>
                      <a @click="edit = !edit" v-else class="text-secondary">
                        <i class="mdi mdi-eye"></i>
                        {{ $t("View") }}
                      </a>
                    </div>

                    <form action class="mt-2">
                      <div class="form-group">
                        <textarea
                          name="comment"
                          class="form-control"
                          id="comment"
                          cols="30"
                          rows="8"
                          v-model="mdl_comment_desc"
                          :disabled="!edit"
                        ></textarea>
                        <button
                          v-if="edit"
                          class="mt-5 btn btn-orange btn-block"
                          @click="show_modal = false"
                          type="button"
                        >
                          {{ $t("SAVE") }}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import modal from "./ModalView";
import { mapState, mapActions, mapGetters } from "vuex";
import emptyReviews from "@/assets/images/empty-reviews.svg";
import TabbedContent from "@/components/widgets/TabifiedContent";
import commentList from "./test-comments.json";
import StarRating from "vue-star-rating";
import "@/assets/css/home/recommended/recommended.css";

import "@/assets/css/customer/reviews/comments.css";
export default {
  components: {
    modal,
    emptyReviews,
    TabbedContent,
    StarRating,
  },
  data() {
    return {
      newsletter: false,
      show_modal: false,
      demo: false,
      edit: false,
      view: false,
      comment:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Nihil, ab illum inventore earum laborum odit. Harum commodi, officia facere sapiente fuga nam voluptatibus ab et dicta quidem labore, quos culpa?",

      comments: commentList.comments,
      comment_slice: [],

      currentPage: 1,
      totalPages: 8,
      perPage: 3,
      inc: 0,
      bootstrapPaginationClasses: {
        ul: "pagination",
        li: "page-item",
        liActive: "active",
        liDisable: "disabled",
        button: "page-link",
      },
      paginationAnchorTexts: {
        // first: "First",
        prev: "Previous",
        next: "Next",
        // last: "Last",
      },
      disable: false,

      mdl_prodt_name: "",
      mdl_category: "",
      mdl_rating: "",
      mdl_rates: "",
      mdl_updated_date: "",
      mdl_comment_desc: "",
    };
  },
  methods: {
    ...mapActions(["getComments"]),

    showModal() {
      let element = this.$refs.modal.$el;
      $(element).modal("show");
    },

    fetchComments() {
      this.disable = false;
      this.getComments(this.user_).then((response) => {
        this.comments = this.getStoredComments.message.comments;
        this.disable = true;
        // this.comment_slice = this.comments.slice(0, this.perPage);
        this.totalPages = Math.ceil(this.comments.length / 3);

        console.log("fetched comments:", this.getStoredComments);
        console.log("totalPages: ", this.totalPages);
      });
    },

    updateResource() {
      if (this.currentPage == 1) {
        this.inc = 0;
        this.comment_slice = this.comments.slice(
          this.inc,
          this.perPage + this.inc
        );
        console.log("Inc: ", this.inc);
        console.log("current page: ", this.currentPage);
      } else {
        this.inc = (this.currentPage - 1) * this.perPage;
        this.comment_slice = this.comments.slice(
          this.inc,
          this.perPage + this.inc
        );
        console.log("Inc: ", this.inc);
        console.log("current page: ", this.currentPage);
      }

      console.log("Comment slice", this.comment_slice);
    },

    showComment(comment) {
      // this.mdl_prodt_name = comment.item.name;
      // this.mdl_category = comment.item.category.name;
      // this.mdl_updated_date = comment.updated_at;
      // this.mdl_comment_desc = comment.description;
      this.mdl_prodt_name = comment.item_name;
      this.mdl_category = comment.category_name;
      this.mdl_updated_date = comment.created_at;
      this.mdl_comment_desc = comment.description;
      this.mdl_rating = comment.avg_rating;
      this.mdl_image = comment.image_url;
    },
  },

  computed: {
    ...mapState(["userComments", "user"]),
    ...mapGetters(["getStoredComments"]),

    listOfTabs() {
      return [
        {
          name: "pending",
          title: "{{ $t('PendingReviews') }}",
          count: this.comments.length,
        },
        { name: "completed", title: "Completed Reviews", count: 3 },
      ];
    },
  },

  mounted() {},

  created() {
    this.fetchComments();
  },
};
</script>

<style scoped></style>
