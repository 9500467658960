import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "getUserDetails",
      "getStoredAddresses",
      "getStoredComments",
      "getCachedOrders",
      "getStoredCoupons",
      "getStoredStatistics",
      "getIntlCtries",
      "getGuestDataObj",
      "getEvents",
      "getSuperDealsObj",
      "getNewProductsObj",
      "getRecommendedObj",
      "getShop",
      "getShopCats",
      "getShopList",
      "getSearchWord",
      "getProduct",
      "getIntlProdts",
      "getStatesData",
      "getLastProduct",
    ]),

    guestData_() {
      var data = this.getGuestDataObj
        ? this.getGuestDataObj
        : JSON.parse(localStorage.getItem("guestData"));

      console.log("cached guest data", this.getGuestDataObj);

      return data;
    },

    superDeals_() {
      var data = this.getSuperDealsObj
        ? this.getSuperDealsObj
        : JSON.parse(localStorage.getItem("super_deals"));

      console.log("cached super deals", this.getSuperDealsObj);

      return data;
    },

    newProducts_() {
      var data = this.getNewProductsObj
        ? this.getNewProductsObj
        : JSON.parse(localStorage.getItem("new_products"));

      console.log("cached new Products", this.getNewProductsObj);

      return data;
    },

    recommended_() {
      var data = this.getRecommendedObj
        ? this.getRecommendedObj
        : JSON.parse(localStorage.getItem("recommended"));

      console.log("cached recommended prdts", this.getRecommendedObj);

      return data;
    },

    user_() {
      var user = localStorage.getItem("user")
        ? this.getUserDetails
        : JSON.parse(localStorage.getItem("user"));

      console.log("cached user", this.getUserDetails);

      return user;
    },

    addresses_() {
      var addresses = localStorage.getItem("user_addresses")
        ? this.getStoredAddresses
        : JSON.parse(localStorage.getItem("user_addresses"));

      console.log("cached addresses: ", this.getStoredAddresses);

      return addresses;
    },

    orders_() {
      var orders = localStorage.getItem("orders")
        ? JSON.parse(localStorage.getItem("orders"))
        : this.getCachedOrders;

      console.log("cached orders", this.getCachedOrders);

      return orders;
    },

    coupons_() {
      var coupons = localStorage.getItem("user_coupons")
        ? JSON.parse(localStorage.getItem("user_coupons"))
        : this.getStoredCoupons;

      console.log("cached coupons", this.getStoredCoupons);

      return coupons;
    },

    statistics_() {
      var statistics = localStorage.getItem("user_stats")
        ? this.getStoredStatistics
        : JSON.parse(localStorage.getItem("user_stats"));

      console.log("cached statistics", this.getStoredStatistics);

      return statistics;
    },

    countries_() {
      var countries = localStorage.getItem("intlCountries")
        ? this.getIntlCtries
        : JSON.parse(localStorage.getItem("intlCountries"));

      console.log("cached countries", this.getIntlCtries);

      return countries;
    },

    events_() {
      return this.guestData_.events ? this.guestData_.events : this.getEvents;
    },

    shop_() {
      var shop = localStorage.getItem("shopDetails")
        ? this.getShop
        : JSON.parse(localStorage.getItem("shopDetails"));

      console.log("cached shops", this.getShop);

      return shop;
    },

    shopList_() {
      var shop_list = localStorage.getItem("shopList")
        ? this.getShopList
        : JSON.parse(localStorage.getItem("shopList"));

      console.log("cached shop list", this.getShopList);

      return shop_list;
    },

    shopCats_() {
      var cat_s = localStorage.getItem("shopCategories")
        ? this.getShopCats
        : JSON.parse(localStorage.getItem("shopCategories"));

      console.log("cached shop categories", this.getShopCats);

      return cat_s;
    },

    comments_() {
      var comments = localStorage.getItem("user_comments")
        ? this.getStoredComments
        : JSON.parse(localStorage.getItem("user_comments"));

      console.log("cached comments", this.getShopCats);

      return comments;
    },

    searchWord_() {
      var search_word = localStorage.getItem("searchWord")
        ? this.getSearchWord
        : JSON.parse(localStorage.getItem("searchWord"));

      console.log("cached search word", this.getSearchWord);

      return search_word;
    },

    productDetails_() {
      var product = localStorage.getItem("productDetails")
        ? this.getProduct
        : JSON.parse(localStorage.getItem("productDetails"));

      console.log("cached product details", this.getProduct);

      return product;
    },

    intlProducts_() {
      var intl_products = localStorage.getItem("intlProducts")
        ? this.getIntlProdts
        : JSON.parse(localStorage.getItem("intlProducts"));

      console.log("cached intl products", this.getIntlProdts);

      return intl_products;
    },

    statesData_() {
      var states_data = localStorage.getItem("geo_data")
        ? this.getStatesData
        : JSON.parse(localStorage.getItem("geo_data"));

      console.log("cached geo states", this.getStatesData);

      return states_data;
    },

    lastProduct_() {
      var last_product = localStorage.getItem("lastProduct")
        ? this.getLastProduct
        : JSON.parse(localStorage.getItem("lastProduct"));

      console.log("cached last product", this.getLastProduct);

      return last_product;
    },
  },
  methods: {},
};
