import i18n from "@/plugins/i18n";
import { mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      languages: [
        {
          flag: "us",
          language: "en",
          title: "English",
          name: "English",
        },
        {
          flag: "fr",
          language: "fr",
          title: "Français",
          name: "Français",
        },
      ],
      langText: "English",
      langFlag: "us",
      listCount: 4,
      filterCategoriesSelected: [],
      filterColorsSelected: [],
      filterFeaturesSelected: [],
      fValues: [],
      features: [],
      availPrices: [],
      loading2: false,
    };
  },
  computed: {
    minPrice() {
      console.log("availprices", this.availPrices);
      return Math.min.apply(
        null,
        this.availPrices.map(function (item) {
          return item.price;
        })
      );
    },

    maxPrice() {
      return Math.max.apply(
        null,
        this.availPrices.map(function (item) {
          return item.price;
        })
      );
    },

    categoryLoading() {
      return this.loading2;
    },
  },
  methods: {
    ...mapActions(["updateCart", "getOrders", "getItemCategory"]),

    ...mapMutations({ setLanguage: "SET_LANGUAGE" }),

    pendingOrders() {
      var orders = this.orders_ ? this.orders_.data : this.allOrders;

      let pending = orders.filter((order) => {
        if (order.status === "pending") {
          return order;
        }
      });

      console.log("pendings:", pending);

      return pending;
    },

    closedOrders() {
      var orders = this.orders_ ? this.orders_.data : this.allOrders;

      let closed = orders.filter((order) => {
        if (order.status === "aborted") {
          return order;
        }
      });

      console.log("closed:", closed);

      return closed;
    },

    completedOrders() {
      var orders = this.orders_ ? this.orders_.data : this.allOrders;

      let completed = orders.filter((order) => {
        if (order.status === "complete") {
          return order;
        }
      });

      console.log("completed:", completed);

      return completed;
    },

    discountedPrice(price, discount) {
      return price - discount * price;
    },

    changeLocale(language) {
      this.langText = language.title;
      this.langFlag = language.flag;
      i18n.locale = language.language;
      this.setLanguage(language);
      console.log("current language", language);
    },

    removeDuplicates(arr) {
      const ids = arr.map((o) => o.id);
      const filtered = arr.filter(
        ({ id }, index) => !ids.includes(id, index + 1)
      );

      console.log("filtered duplicates", filtered);

      return filtered;
    },

    getImageBrightness(imageSrc, callback) {
      var img = document.createElement("img"),
        colorSum = 0,
        i = 0,
        len,
        canvas,
        ctx,
        imageData,
        data,
        brightness,
        r,
        g,
        b,
        avg;
      img.crossOrigin = "Anonymous";

      img.src = imageSrc;
      img.style.display = "none";

      document.body.appendChild(img);

      img.onload = function () {
        canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;

        ctx = canvas.getContext("2d");
        ctx.drawImage(this, 0, 0);

        imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        data = imageData.data;

        for (i, len = data.length; i < len; i += 4) {
          r = data[i];
          g = data[i + 1];
          b = data[i + 2];

          avg = Math.floor((r + g + b) / 3);
          colorSum += avg;
        }

        brightness = Math.floor(colorSum / (this.width * this.height));
        callback(brightness);
      };
    },

    newPrice(price, discount) {
      return price - discount * price;
    },

    minimumDiscount(products) {
      console.log("Min discount: ", products);

      var discounts = [];
      if (products) {
        products.forEach((product) => {
          if (product.item_discount) {
            discounts.push(product.item_discount.percentage_decrease * 100);
            var uniq_d = [...new Set(discounts)];

            discounts = uniq_d.sort();
          }
        });
        console.log("Discounts: ", discounts);
      }

      var min = discounts.length > 0 ? Math.max.apply(null, discounts) : 0;

      console.log("Min Value:: ", min);

      return min;
    },

    minPrice_(prices) {
      return Math.min.apply(
        null,
        prices.map(function (item) {
          return item.price;
        })
      );
    },

    maxPrice_(prices) {
      return Math.max.apply(
        null,
        prices.map(function (item) {
          return item.price;
        })
      );
    },

    check: function (e) {
      console.log("colors Selected: ", this.filterColorsSelected);

      console.log("categories Selected: ", this.filterCategoriesSelected);
      console.log("features Selected: ", this.filterFeaturesSelected);
      console.log("filters utils now:", this.filterValues());

      this.$root.$emit("filterValues", {
        colors: this.filterColorsSelected,
        categories: this.filterCategoriesSelected,
        features: this.filterFeaturesSelected,
      });
    },

    filterValues() {
      this.fValues = [];
      if (this.filterCategoriesSelected.length > 0) {
        this.filterCategoriesSelected.forEach((item) => {
          this.fValues.push(item.name);
          console.log("categories item name: ", item.name);
        });
      }

      this.fValues = this.fValues.concat(
        this.filterColorsSelected,
        this.filterFeaturesSelected
      );

      this.fValues = [...new Set(this.fValues)];

      console.log("this utils filter values func: ", this.fValues);

      return this.fValues;
    },

    removeFilterValue(value) {
      this.fValues.splice(this.fValues.indexOf(value), 1);

      if (this.filterFeaturesSelected.includes(value)) {
        this.filterFeaturesSelected.splice(
          this.filterFeaturesSelected.indexOf(value),
          1
        );
      }

      if (this.filterColorsSelected.includes(value)) {
        this.filterColorsSelected.splice(
          this.filterColorsSelected.indexOf(value),
          1
        );
      }

      if (
        this.filterCategoriesSelected.filter((item) => item.name === value)
          .length > 0
      ) {
        let fCat = this.filterCategoriesSelected.filter(
          (item) => item.name === value
        );

        console.log("fCat: ", fCat);
        this.filterCategoriesSelected.splice(
          this.filterCategoriesSelected.indexOf(fCat),
          1
        );
      }

      console.log("filter value removed: ", value);
      console.log("remaining items: ", this.fValues);

      this.$root.$emit("filterValues", {
        colors: this.filterColorsSelected,
        categories: this.filterCategoriesSelected,
        features: this.filterFeaturesSelected,
      });

      this.$root.$emit("refreshFilter", true);
      this.$root.$emit("refreshFilter", true);
    },

    removeFilters() {
      this.filterCategoriesSelected = [];
      this.filterColorsSelected = [];
      this.filterFeaturesSelected = [];
      this.fValues = [];

      this.$root.$emit("resetMinMax", true);

      this.$root.$emit("filterValues", {
        colors: this.filterColorsSelected,
        categories: this.filterCategoriesSelected,
        features: this.filterFeaturesSelected,
      });
      console.log("removing filters");

      this.$root.$emit("emptyFilters", true);
    },

    updateRemoteCart(orderItem, newQtty, callback) {
      orderItem.quantity = newQtty;
      console.log("updated item data:", this.orders_);
      this.updateCart(orderItem).then(() => {
        callback();
      });
    },

    isEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    getCountryObject(country_name) {
      let countries = this.countries_.countries;
      console.log("countries list", countries);
      let countryObject = null;
      countries.forEach((country) => {
        console.log("in loop");
        if (country_name == country.name) {
          console.log("country found:", country);
          countryObject = country;
        }
      });

      return countryObject;
    },

    defaultImage(e) {
      e.target.src = require("@/assets/default-product.png");
    },

    defaultSlideImage(e) {
      e.target.src = require("@/assets/banner.webp");
    },

    sortByLatest(arr) {
      const latestArr = arr.sort((a, b) => b.created_at - a.created_at);
      return latestArr;
    },

    loadFeatures_(results) {
      let featureSet = [];

      results.forEach((result) => {
        featureSet.push(result.features);
      });

      console.log("feature set", featureSet);

      let features = [];

      featureSet.forEach((featurelist) => {
        let list = featurelist;
        list.forEach((feature) => {
          features.push(feature);
        });
      });

      features = [...features];
      console.log("features all", features);
      console.log("features keys", Object.keys(features));

      features.forEach((feature) => {
        if (Object.keys(this.features).includes(feature.key)) {
          console.log("key not present");
          this.features[feature.key].value.push(feature);
        } else {
          this.features[feature.key.toLowerCase()] = {
            id: feature.id,
            value: [feature],
          };
        }
      });

      console.log("features", this.features);

      // fix the feature brands
      let featureBrands = this.features["brand"].value;

      featureBrands = Array.from(
        new Set(featureBrands.map((a) => a.value.toLowerCase()))
      ).map((value) => {
        return featureBrands.find(
          (a) => a.value.toLowerCase() === value.toLowerCase()
        );
      });

      console.log("feature Brands: ", featureBrands);

      this.features["brand"].value = featureBrands;
      console.log("features222", this.features);

      let keys = Object.keys(this.features);
      console.log("NEW features keys", keys);
      let dict = [];
      keys.forEach((key) => {
        dict.push({
          key: key,
          value: this.features[key].value,
        });
      });
      this.features = dict;

      let featuresNonColor = dict.filter((element) => {
        if (!element.key.includes("color")) {
          return element;
        }
      });

      console.log("NEW features", featuresNonColor);
      return featuresNonColor;
    },

    // color features loader
    loadColorFeatures_() {
      let colorFeatures = [];
      if (this.features) {
        this.features.filter((element) => {
          if (element.key.includes("color")) {
            colorFeatures.push(element.value);
          }
        });
        console.log("color features: ", colorFeatures);

        let colors = [];

        if (colorFeatures) {
          colorFeatures.forEach((feature) => {
            feature.forEach((fet) => {
              colors.push(fet.value.toLowerCase());
            });
          });
        }

        console.log("colors main: ", colors);

        // this.colors = [...new Set(colors)];
        let colors_ = colors.reduce((x, y) => {
          if (x[y]) {
            x[y]++;
            return x;
          } else {
            var z = {};
            z[y] = 1;
            return Object.assign(x, z);
          }
        }, {});

        console.log("colors: ", colors_);

        return colors_;
      }
    },

    loadCategories_(results) {
      let categoriesList = [];
      let categoryIds = [];
      if (results) {
        results.forEach((result) => {
          categoryIds.push(result.category_id);
        });
      }

      categoryIds = [...new Set(categoryIds)];

      console.log("categories main", categoryIds);
      this.loading2 = true;
      categoryIds.forEach((id) => {
        this.getItemCategory({ category_id: id }).then((response) => {
          this.loading2 = false;
          console.log("response cat id: ", response);
          categoriesList.push(response.category);
        });
      });

      console.log("all categories: ", categoriesList);

      return categoriesList;
    },

    loadPrdtPrices_(products) {
      console.log("prdtPrices products", products);
      this.availPrices = [];
      products.forEach((item) => {
        if (item.item_discount) {
          this.availPrices.push({
            id: item.id,
            price:
              Math.round(
                this.newPrice(
                  item.price,
                  item.item_discount.percentage_decrease
                ) * 100
              ) / 100,
          });
        } else {
          this.availPrices.push({
            id: item.id,
            price: Math.round(item.price * 100) / 100,
          });
        }
      });

      console.log("product prices", this.availPrices);
    },
  },
};
