<template>
  <div id="super_deals" class="row mt-5" v-show="products.length > 0">
    <div
      class="
        col-md-12
        px-0
        pb-0
        section-head
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <h3 class="sectionTitle my-0 d-flex px-md-0 px-lg-0 pl-3 pr-2">
        <span class="text-capitalize"
          ><i class="common super_deals mr-3"></i>{{ $t("superDeals") }}</span
        >
        <span class="responsiveTimer" v-if="deadline"
          ><Timer :deadline="deadline" :responsive="true" class="mt-0"></Timer
        ></span>
      </h3>
      <router-link
        :to="{ name: 'deals' }"
        class="viewall py-0 mt-1 font-15 d-flex align-items-center"
      >
        <span class="d-sm-none d-none d-md-block text-capitalize"
          >{{ $t("seeAll") }}&nbsp;</span
        >
        <span class="d-sm-none d-none d-md-block"
          ><i class="mdi mdi-chevron-right font-20 mt-3"></i
        ></span>

        <span class="d-block d-sm-block d-md-none"
          ><i class="mdi mdi-chevron-right-circle font-20 mt-3"></i
        ></span>
      </router-link>
    </div>

    <div class="row mt-1" :class="isTablet ? 'px-md-0 ' : 'px-3'">
      <!-- Super Deals side banner -->
      <div class="col-md-2 sideBanner pl-0 pr-1">
        <div class="py-8 dealsBanner">
          <img
            src="@/assets/flash-illus.png"
            alt="flash illustr"
            class="img-fluid flash-illus mx-auto"
          />
          <h5 class="text-center mt-2 text-uppercase">
            {{ $t("superDeals") }}
          </h5>

          <div v-if="deadline">
            <Timer :deadline="deadline" class="mt-5"></Timer>
          </div>

          <div class="mt-5 text-center">
            <small
              class="font-12 text-uppercase blink_animate font-weight-bold"
            >
              Place your orders ASAP!
            </small>
          </div>
        </div>
      </div>
      <!-- Super Deals Products display -->
      <div
        class="
          col-sm-12 col-lg-10 col-12
          item
          px-lg-0 px-xl-0 px-md-0 px-sm-3 px-4
          col-md-9
        "
        :class="isTablet ? 'col-md-12' : 'col-md-9'"
      >
        <div class="row px-3">
          <div
            class="col-6 col-sm-6 pb-0 pb-lg-auto pb-md-auto prodtCol"
            :class="isTablet ? 'col-md-4 px-1' : 'col-md-3'"
            v-for="product in products"
            :key="product.id"
          >
            <!-- Desktop Display -->
            <router-link
              :to="{ name: 'deals' }"
              class="card prdtCard d-sm-none d-none d-md-block d-lg-block"
            >
              <div class="prdtImage">
                <img
                  :src="product.image_url"
                  @error="defaultImage"
                  class="img-fluid p-2"
                />
              </div>

              <div class="prdtDiscount">
                <strong class="my-auto">{{
                  product.item_discount.percentage_decrease | percent
                }}</strong>
                <p class="mb-0 mt-n1 text-center text text-uppercase">
                  {{ $t("percentOff") }}
                </p>
              </div>

              <div
                class="card-footer bg-transparent rounded-0 border-0 px-3 py-3"
              >
                <router-link
                  :to="{
                    name: 'deals',
                  }"
                >
                  <h6 class="prdtName text-capitalize text-truncate">
                    {{ product.name }}
                  </h6>
                </router-link>

                <div class="my-3">
                  <p class="font-12 text-muted mb-0 rrp text-uppercase">
                    {{ $t("rrp") }}:
                    <span>
                      <strike>{{ product.price | currency("XFA ") }}</strike>
                    </span>
                  </p>
                  <p class="color-font priceTag mb-0 mt-1">
                    {{ product.item_discount.unit_price | currency("XFA ") }}
                  </p>
                </div>
              </div>
            </router-link>

            <!-- Mobile Display -->
            <router-link
              :to="{ name: 'deals' }"
              class="
                card
                h-100
                prdtCard
                rounded
                d-sm-block d-block d-lg-none d-md-none
              "
            >
              <div class="prdtImage">
                <img
                  :src="product.image_url"
                  @error="defaultImage"
                  class="img-fluid p-2"
                />
              </div>
              <div class="prdtDiscount" v-if="product.item_discount">
                <strong class="my-auto">{{
                  product.item_discount.percentage_decrease | percent
                }}</strong>
                <p class="mb-0 mt-n1 text-center">{{ $t("percentOff") }}</p>
              </div>

              <div
                class="card-footer pt-1 bg-transparent rounded-0 border-0 px-3"
              >
                <router-link :to="{ name: 'deals' }">
                  <h6 class="prdtName text-capitalize text-truncate">
                    {{ product.name }}
                  </h6>
                </router-link>

                <div class="mt-4" v-if="product.item_discount">
                  <p class="text-muted mb-0 rrp text-uppercase">
                    {{ $t("rrp") }}:
                    <span>
                      <strike>{{ product.price | currency("XFA ") }}</strike>
                    </span>
                  </p>
                  <p
                    class="color-font priceTag mb-0 mt-1"
                    v-if="product.item_discount"
                  >
                    {{ product.item_discount.unit_price | currency("XFA ") }}
                  </p>
                </div>
                <div class="mt-2" v-else>
                  <p class="color-font priceTag mb-0 mt-1">
                    {{ product.price | currency("XFA ") }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Timer from "./Timer";
import "@/assets/css/home/superdeals/superdeals.css";

export default {
  components: {
    Timer,
  },

  props: {
    currentDeals: {
      type: Array,
      default: null,
      required: true,
    },
  },

  data() {
    return {
      dates: [],
      windowWidth: window.innerWidth,
      prdtLim: 4,
    };
  },

  watch: {
    windowWidth(newWidth, oldWidth) {
      console.log(`it changed to ${newWidth} from ${oldWidth}`);
    },
  },

  methods: {
    newPrice(price, discount) {
      return price - discount * price;
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.isTablet) {
        this.prdtLim = 3;
      } else {
        this.prdtLim = 4;
      }
    },
  },

  computed: {
    deals() {
      return this.currentDeals;
    },

    products() {
      var products = [];

      if (this.deals) {
        this.deals.forEach((deal) => {
          if (deal.item_discount) {
            products.push(deal);
          }
        });

        products = products.slice(0, this.prdtLim);

        console.log("Super Deals products: ", products);
        return products;
      } else {
        return [];
      }
    },

    isTablet() {
      if (this.windowWidth >= 768 && this.windowWidth < 992) {
        return true;
      }

      return false;
    },

    deadline() {
      if (this.deals.length > 0) {
        console.log("deadline deals: ", this.deals);

        let max_date =
          this.dates.length > 0 && this.dates
            ? new Date(Math.max(...this.dates)).toString()
            : new Date().toString();

        console.log("max_date_", new Date(Math.max(...this.dates)));
        return max_date;
      } else {
        return null;
      }
    },
  },

  created() {
    console.log("Super deals_: ", this.deals);
    if (this.deals) {
      this.deals.forEach((deal) => {
        if (deal.item_discount) {
          this.dates.push(new Date(deal.item_discount.end_date));
        }
      });
    }
    console.log("dates ", this.dates);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  mounted() {
    console.log("the current deals", this.deals);

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
};
</script>

<style scoped>
</style>
