var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.recommended_)?_c('div',{staticClass:"row mb-5 mt-3",attrs:{"id":"recommended"}},[_c('div',{staticClass:"col-md-12 px-2 px-md-0 px-lg-0 py-4"},[_c('div',{staticClass:"\n        col-md-12\n        px-0\n        section-head\n        d-flex\n        justify-content-between\n        align-items-center\n      "},[_c('h3',{staticClass:"sectionTitle my-0 px-md-0 px-lg-0 pl-1 pr-2"},[_c('i',{staticClass:"common like"}),_vm._v(" "+_vm._s(_vm.$t("recommended"))+" ")])]),_c('div',{staticClass:"container pt-0 px-md-auto px-lg-auto"},[_c('div',{staticClass:"row"},[_vm._l((_vm.products),function(product,index){return _c('div',{key:'recD-' + index,staticClass:"\n            col-sm-6 col-6 col-md-4 col-lg-2\n            item\n            px-1 px-md-1 px-lg-1\n            pb-0\n            pt-3\n            d-sm-block d-lg-block d-md-block d-none\n          "},[_c('router-link',{staticClass:"card prdtCard h-100 rounded",attrs:{"to":{
              name: 'rec-detail',
              params: { slug: product.slug, prdtId: product.id },
            }}},[_c('div',{staticClass:"prdtImage"},[_c('img',{staticClass:"img-fluid p-2",attrs:{"src":product.image_url},on:{"load":function($event){return _vm.masks.push(index)},"error":_vm.defaultImage}})]),(product.item_discount)?_c('div',{staticClass:"prdtDiscount"},[_c('strong',{staticClass:"my-auto"},[_vm._v(_vm._s(_vm._f("percent")(product.item_discount.percentage_decrease)))]),_c('p',{staticClass:"mb-0 mt-n1 text-center"},[_vm._v(_vm._s(_vm.$t("percentOff")))])]):_vm._e(),_c('div',{staticClass:"card-footer bg-transparent rounded-0 border-0 px-3"},[_c('router-link',{attrs:{"to":{
                  name: 'rec-detail',
                  params: { slug: product.slug, prdtId: product.id },
                }}},[_c('h6',{staticClass:"prdtName text-capitalize text-truncate"},[_vm._v(" "+_vm._s(product.name)+" ")])]),(product.item_discount)?_c('div',{staticClass:"mt-5"},[_c('p',{staticClass:"font-15 text-muted mb-0 rrp text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("rrp"))+": "),_c('span',[_c('strike',[_vm._v(_vm._s(_vm._f("currency")(product.price,"XFA ")))])],1)]),(product.item_discount)?_c('p',{staticClass:"color-font priceTag mb-0 mt-1"},[_vm._v(" "+_vm._s(_vm._f("currency")(product.item_discount.unit_price,"XFA "))+" ")]):_vm._e()]):_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"color-font priceTag mb-0 mt-1"},[_vm._v(" "+_vm._s(_vm._f("currency")(product.price,"XFA "))+" ")])])],1)])],1)}),_vm._l((_vm.products),function(product,index){return _c('div',{key:index,staticClass:"\n            col-sm-6 col-6 col-md-4 col-lg-2\n            item\n            pb-0\n            pt-3\n            d-sm-block d-block d-lg-none d-md-none\n          "},[_c('router-link',{staticClass:"card h-100 prdtCard rounded",attrs:{"to":{
              name: 'rec-detail',
              params: { slug: product.slug, prdtId: product.id },
            }}},[_c('div',{staticClass:"prdtImage"},[_c('img',{staticClass:"img-fluid p-2",attrs:{"src":product.image_url},on:{"error":_vm.defaultImage}})]),(product.item_discount)?_c('div',{staticClass:"prdtDiscount"},[_c('strong',{staticClass:"my-auto"},[_vm._v(_vm._s(_vm._f("percent")(product.item_discount.percentage_decrease)))]),_c('p',{staticClass:"mb-0 mt-n1 text-center"},[_vm._v(_vm._s(_vm.$t("percentOff")))])]):_vm._e(),_c('div',{staticClass:"card-footer pt-1 bg-transparent rounded-0 border-0 px-3"},[_c('router-link',{attrs:{"to":{
                  name: 'rec-detail',
                  params: { slug: product.slug, prdtId: product.id },
                }}},[_c('h6',{staticClass:"prdtName text-capitalize text-truncate"},[_vm._v(" "+_vm._s(product.name)+" ")])]),(product.item_discount)?_c('div',{staticClass:"mt-3 mb-1"},[_c('p',{staticClass:"font-15 text-muted mb-0 rrp text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("rrp"))+": "),_c('span',[_c('strike',[_vm._v(_vm._s(_vm._f("currency")(product.price,"XFA ")))])],1)]),(product.item_discount)?_c('p',{staticClass:"color-font priceTag mb-0 mt-1"},[_vm._v(" "+_vm._s(_vm._f("currency")(product.item_discount.unit_price,"XFA "))+" ")]):_vm._e()]):_c('div',{staticClass:"mt-2"},[_c('p',{staticClass:"color-font priceTag mb-0 mt-1"},[_vm._v(" "+_vm._s(_vm._f("currency")(product.price,"XFA "))+" ")])])],1)])],1)})],2)]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('scroll-loader',{attrs:{"loader-method":_vm.getProductList,"loader-disable":_vm.disable}})],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }