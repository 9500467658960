<template>
  <div id="sideDash" :class="[!showAsAside ? 'shadow-sm toggled' : '']">
    <ul class="list-group px-0">
      <router-link :to="{ name: 'account-index' }">
        <li
          :class="[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            this.$route.name === 'account-index' ? 'active' : '',
            'd-flex',
            'align-center',
            'border-0',
          ]"
          @click="close"
        >
          <i class="mdi mdi-account-outline h5 mb-0"></i>
          <span class="ml-3 text-capitalize">{{ $t("MyAfeshopAccount") }}</span>
        </li>
      </router-link>
      <!-- <router-link :to="{ name: 'sell' }">
        <li
          :class="['list-group-item', 'py-4','list-group-item-action', this.$route.path.includes('/customer/sell') ? 'active' : '', 'd-flex', 'align-center', 'border-0']"
        >
          <i class="mdi mdi-cart-arrow-up h5 mb-0"></i>
          <span class="ml-3">Sell (Second hand)</span>
        </li>
      </router-link> -->
      <router-link :to="{ name: 'statistics' }">
        <li
          :class="[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            this.$route.name === 'statistics' ? 'active' : '',
            'd-flex',
            'align-center',
            'border-0',
          ]"
          @click="close"
        >
          <i class="mdi mdi-graph h5 mb-0"></i>
          <span class="ml-3 text-capitalize">{{ $t("Statistics") }}</span>
        </li>
      </router-link>
      <router-link :to="{ name: 'purchases' }">
        <li
          :class="[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            this.$route.path.includes('/customer/purchases') ? 'active' : '',
            'd-flex',
            'align-center',
            'border-0',
          ]"
          @click="close"
        >
          <i class="mdi mdi-arrow-down h5 mb-0"></i>
          <span class="ml-3 text-capitalize">{{ $t("Orders") }}</span>
        </li>
      </router-link>
      <router-link :to="{ name: 'cart' }">
        <li
          :class="[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            this.$route.name === 'cart' ? 'active' : '',
            'd-flex',
            'align-center',
            'border-0',
          ]"
          @click="close"
        >
          <i class="mdi mdi-cart-outline h5 mb-0"></i>
          <span class="ml-3 text-capitalize">{{ $t("MyShoppingCart") }}</span>
        </li>
      </router-link>
      <router-link :to="{ name: 'comments' }">
        <li
          :class="[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            this.$route.name === 'comments' ? 'active' : '',
            'd-flex',
            'align-center',
            'border-top-0',
            'border-left-0',
            'border-right-0',
          ]"
          @click="close"
        >
          <i class="mdi mdi-comment-multiple-outline h5 mb-0"></i>
          <span class="ml-3 text-capitalize">{{ $t("Comments") }}</span>
        </li>
      </router-link>
      <router-link :to="{ name: 'voucher' }">
        <li
          :class="[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            this.$route.name === 'voucher' ? 'active' : '',
            'd-flex',
            'align-center',
            'border-top-0',
            'border-left-0',
            'border-right-0',
          ]"
          @click="close"
        >
          <i class="mdi mdi-ticket-percent-outline h5 mb-0"></i>
          <span class="ml-3 text-capitalize">{{ $t("Vouchers") }}</span>
        </li>
      </router-link>
      <router-link :to="{ name: 'account-edit' }">
        <li
          :class="[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            this.$route.name === 'account-edit' ? 'active' : '',
            'd-flex',
            'align-center',
            'border-0',
          ]"
          @click="close"
        >
          <span class="text-capitalize">{{ $t("Details") }}</span>
        </li>
      </router-link>
      <router-link :to="{ name: 'addressbook' }">
        <li
          :class="[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            this.$route.name === 'addressbook' ? 'active' : '',
            'd-flex',
            'align-center',
            'border-0',
          ]"
          @click="close"
        >
          <span class="text-capitalize">{{ $t("AddressBook") }}</span>
        </li>
      </router-link>
      <router-link :to="{ name: 'newsletter' }">
        <li
          :class="[
            'list-group-item',
            'py-4',
            'list-group-item-action',
            this.$route.name === 'newsletter' ? 'active' : '',
            'd-flex',
            'align-center',
            'border-top-0',
            'border-left-0',
            'border-right-0',
          ]"
          @click="close"
        >
          <span class="text-capitalize">{{ $t("NewsletterPreferences") }}</span>
        </li>
      </router-link>
      <li
        class="
          py-2
          list-group-item
          border-left-0 border-right-0 border-bottom-0
          text-center
          list-unstyled
        "
      >
        <button
          @click.stop="
            logout();
            close();
          "
          class="btn btn-font-trans color-font"
        >
          {{ $t("LOGOUT") }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    showAsAside: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    ...mapActions(["logout"]),

    close() {
      this.$root.$emit("close_sidebar", false);
    },
  },
};
</script>

<style scoped>
#sideDash {
  flex: 1;
}
a {
  text-decoration: none !important;
}

.list-group-item > span {
  font-size: 1rem;
}

.list-group-item.active {
  background-color: #ededed !important;
  border-color: #ededed !important;
  color: rgba(0, 0, 0, 0.733);
  font-weight: bold;
}

.list-group-item:hover {
  font-weight: bold;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.637);
}

.toggled {
  margin-left: 0;
  /* position: absolute;
  z-index: 10; */
}
</style>
