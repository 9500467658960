import axios from "axios";
import router from "@/router/index";

import TokenService from "@/services/storage";
import { UserService, EndPointError } from "@/services/user";

const state = {
  user: {},
  username: "",
  password: "",

  resetMsg: "",
  registerWithPhone: false,
  userCountry: { flag: "cm", country: "Central African " },

  successInfo: "",
  userInfo: null,
  authenticating: false,
  accessToken: TokenService.getToken(),
  authenticationSuccess: false,
  EndPointErrorCode: 0,
  EndPointError: "",
  registrationError: "",
  registrationSuccess: false,
  updateError: null,
};

const mutations = {
  SET_USER_DATA(state, payload) {
    state.user = payload;
    state.username = payload.username;
    localStorage.setItem("user", JSON.stringify(payload));
    console.log("user at Index store: ", payload.user);

    console.log("Username: ", state.user.username);
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${payload.access_token}`;
  },

  SET_REGPHONE(state) {
    state.registerWithPhone = !state.registerWithPhone;
  },

  loginRequest(state) {
    state.authenticating = true;
    state.EndPointError = "";
    state.EndPointErrorCode = 0;
  },

  SET_TOKEN(state, token) {
    state.accessToken = token;
    TokenService.saveToken(token);
    console.log("loginSuccess: " + state.accessToken);
    state.authenticationSuccess = true;
  },

  loginSuccess(state, payload) {
    console.log("loginResponse: ", payload);
    state.authenticating = false;
    state.username = payload.user.username;

    state.user = payload.user;
    localStorage.setItem("user", JSON.stringify(payload.user));
    console.log("user at Index store: ", payload.user);

    console.log("Username: ", state.user.username);

    router.push({ name: "customer" });
  },

  loginError(state, { errorCode, errorMessage }) {
    state.authenticating = false;
    state.EndPointErrorCode = errorCode;
    state.EndPointError = errorMessage;
  },

  logoutSuccess(state) {
    state.accessToken = "";
    state.cart_no = 0;

    state.ordersList = null;
    state.user = null;
    state.username = "";

    localStorage.setItem("user", null);
    localStorage.setItem("user_addresses", null);
    localStorage.setItem("orders", null);
    localStorage.setItem("user_stats", null);
    localStorage.setItem("user_coupons", null);
    localStorage.setItem("user_comments", null);
    router.push({ name: "signin" });
  },

  registerSuccess(state, payload) {
    state.registrationSuccess = true;
    state.successInfo = payload.msg;

    router.push({ name: "registerSuccess" });
  },

  registrationError(state, { errorCode, errorMessage }) {
    state.registrationError = { code: errorCode, msg: errorMessage };
  },

  userInfoResults(state, payload) {
    state.userInfo = payload;
    state.user = payload.user;
  },

  updateSuccess(state, payload) {
    console.log("update success", payload);

    state.username = payload.user.username;
    state.user = payload.user;
  },

  updateError(state, { errorCode, errorMessage }) {
    state.updateError = { code: errorCode, msg: errorMessage };
  },

  passwordRecovery(state, payload) {
    state.resetMsg = payload.data.message;
    router.push({ name: "recoveryInfo" });
  },
};

const getters = {
  authenticationSuccess: (state) => state.authenticationSuccess,

  authenticating: (state) => state.authenticating,

  registrationError: (state) => state.registrationError,

  EndPointError: (state) => state.EndPointError,

  loggedIn: (state) => !!state.accessToken,

  regPhone: (state) => state.registerWithPhone,

  getUserDetails: (state) => state.user,

  getUserName: (state) => state.username,

  getUpdateError: (state) => state.updateError,
};

const actions = {
  async login({ commit }, payload) {
    commit("loginRequest");
    try {
      const response = await UserService.login(payload);

      return response;
    } catch (e) {
      console.log("Index login error: ", e);
      return e;
    }
  },

  async socialAuthenticate({ commit }, s_token) {
    console.log("in social authenticate action");
    commit("SET_TOKEN", s_token);

    try {
      const userData = await UserService.getUserInfo();
      console.log("social authenticate: ", userData);
      commit("loginSuccess", userData);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async register({}, user) {
    try {
      const response = await UserService.register(user);

      return response;
    } catch (e) {
      console.log("Indexjs register error", e);
      return e;
    }
  },

  async socialAuth({}, user) {
    try {
      const response = await UserService.social(user);

      return response;
    } catch (e) {
      console.log("Indexjs socialAuth error", e);
      return e;
    }
  },

  async getUser({ commit, state }) {
    try {
      const userInfo = await UserService.getUserInfo();
      console.log("User Information: ", userInfo);
      commit("userInfoResults", userInfo);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async checkEmail({}, payload) {
    console.log("Index Payload: ", payload);
    try {
      const response = await UserService.checkEmail(payload);
      // console.log("Reset Information: ", payload.email);
      return response;
    } catch (e) {
      console.log("Indexjs checkEmail: ", e);
      return e;
    }
  },

  async forgotPassword({}, payload) {
    console.log("Index Payload: ", payload);
    try {
      const response = await UserService.forgotPassword(payload);
      // commit("passRecovery", pass);
      return response;
    } catch (e) {
      console.log("Indexjs forgotPassword: ", e);
      return e;
    }
  },

  async setNewPassword({}, payload) {
    try {
      const pass = await UserService.setNewPassword(payload);
      // console.log("Reset Information: ", payload.email);
      return pass;
    } catch (e) {
      console.log("Indexjs setNewPassword: ", e);
      return e;
    }
  },

  async update({ commit }, user) {
    try {
      const response = await UserService.update(user);

      return response;
    } catch (e) {
      return e;
    }
  },

  logout({ commit }) {
    UserService.logout();
    commit("logoutSuccess");
  },
};

export default {
  //   namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
