import apiService from "../main";

const HomeApiService = {
  /**
   * Pack Service requests
   */
  guestData: async function () {
    const bulkData = {
      method: "get",
      url: "",
      // timeout: 2,
    };
    try {
      const response = await apiService.customRequest(bulkData);

      // console.log(response.data.data)

      return response.data.data;
    } catch (error) {
      console.log(error);
      console.log("Error code: ", error.code);
    }
  },

  /**
   * Superdeals
   */
  getSuperDeals: async function () {
    const bulkData = {
      method: "get",
      url: "/superdeals",
    };
    try {
      const response = await apiService.customRequest(bulkData);

      console.log("Super Deals", response.data.data);

      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },

  /**
   * New Products
   */
  getNewProducts: async function () {
    const bulkData = {
      method: "get",
      url: "/new-items",
    };
    try {
      const response = await apiService.customRequest(bulkData);

      console.log("New Products", response.data.data);

      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },

  /**
   * Recommended Products
   */
  getRecommendedProducts: async function () {
    const bulkData = {
      method: "get",
      url: "/recommended",
    };
    try {
      const response = await apiService.customRequest(bulkData);

      console.log("Recommended ", response.data.data);

      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },

  /**
   * Main Categories
   */
  getMainCategories: async function () {
    const bulkData = {
      method: "get",
      url: "/main/categories",
    };
    try {
      const response = await apiService.customRequest(bulkData);

      console.log("main categories ", response.data.data);

      return response.data.data;
    } catch (error) {
      console.log("main categories error: ", error);
      return error;
    }
  },
};

export default HomeApiService;

export { HomeApiService };
