<template>
  <div id="purchases" class="row">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="#ffa300"
      :height="height"
      :width="width"
      :transition="transition"
      background-color="#000"
    ></loading>
    <div class="col-md-12 py-0">
      <div class="card pane border-0 shadow-sm bg-white py-4 px-5">
        <!-- Header -->
        <div
          class="
            card-header
            border-0
            bg-transparent
            pl-2
            py-0
            d-flex
            align-items-center
          "
        >
          <button @click="$router.go(-1)" class="btn btn-lg pl-0 text-dark">
            <i class="mdi mdi-arrow-left h5"></i>
          </button>
          <h4>{{ $t("Orders") }}</h4>
        </div>
        <hr class="my-1" />

        <!-- Content -->
        <div class="row px-2">
          <div class="col-md-12 px-2" v-if="orders_">
            <!-- Loaded Orders -->
            <tabbed-content :tabList="listOfTabs" :initTab="listOfTabs[0].name">
              <!-- Tabs content -->
              <template #open-tab>
                <div class="row mt-4" v-if="pendingOrders().length > 0">
                  <div
                    class="col-md-12 px-7"
                    v-for="order in pendingOrders()"
                    :key="'pending-' + order.uuid"
                  >
                    <div
                      class="row border mb-3"
                      v-for="orderItem in order.order_item"
                      :key="orderItem.id"
                    >
                      <div class="col-md-12 col-lg-3 col-sm-12 col-12">
                        <img
                          :src="orderItem.item.image_url"
                          alt=""
                          class="img-fluid"
                        />
                      </div>

                      <div
                        class="
                          col-md-12 col-lg-9 col-sm-12 col-12
                          pl-auto pl-sm-auto pl-md-auto pl-lg-0
                        "
                      >
                        <div class="row">
                          <div class="col-9 pr-0">
                            <p class="mb-2 prdtName">
                              {{ orderItem.item.name }}
                            </p>

                            <p class="mb-0 mt-2">
                              {{ $t("Order") }} {{ order.uuid }}
                            </p>

                            <p class="mt-2 mb-0">
                              {{ $t("Quantity") }}: {{ orderItem.quantity }}
                            </p>
                            <p class="mb-0 mt-1">
                              {{ $t("On") }}
                              <span class="order-date ml-1">{{
                                orderItem.created_at | dateOn
                              }}</span>
                            </p>
                          </div>

                          <div class="col-3 pr-2">
                            <a
                              class="
                                btn btn-link btn-sm
                                float-right
                                see-details
                              "
                              @click="
                                $router.push({
                                  name: 'view-order-items',
                                  params: {
                                    id: orderItem.id,
                                    uuid: order.uuid,
                                    type: 'pending',
                                  },
                                })
                              "
                            >
                              {{ $t("Seedetails") }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Empty Orders -->
                <div v-else>
                  <empty></empty>
                </div>

                <div
                  class="mx-auto"
                  style="margin-top: -7em"
                  v-if="!pendingOrders()"
                >
                  <scroll-loader
                    :loader-method="fetchOrders"
                    :loader-disable="disable"
                  ></scroll-loader>
                  <p class="text-center" v-show="!disable">Please Wait...</p>
                </div>
              </template>

              <!-- <template #closed-tab>
                <div class="row mt-4" v-if="orders__.closed">
                  <div class="col-md-12 px-7">
                    <div
                      class="row border mb-3"
                      v-for="order in orders__.closed"
                      :key="'pending-' + order.id"
                    >
                      <div class="col-md-12 col-lg-3 col-sm-12 col-12">
                        <img
                          :src="order.item.image_url"
                          alt=""
                          class="img-fluid"
                        />
                      </div>

                      <div
                        class="
                          col-md-12 col-lg-9 col-sm-12 col-12
                          pl-auto pl-sm-auto pl-md-auto pl-lg-0
                        "
                      >
                        <div class="row">
                          <div class="col-9 pr-0">
                            <p class="mb-2 prdtName">
                              {{ order.item.name }} Lorem ipsum dolor, sit amet
                              consectetur adipisicing elit.
                            </p>

                            <p class="mb-0 mt-2">
                              {{ $t("Order") }} {{ order.uuid }}
                            </p>

                            <p class="mt-2 mb-0">
                              {{ $t("Quantity") }}: {{ order.quantity }}
                            </p>
                            <p class="mb-0 mt-1">
                              {{ $t("On") }}
                              <span class="order-date ml-1">{{
                                order.created_at
                              }}</span>
                            </p>
                          </div>

                          <div class="col-3 pr-2">
                            <a
                              class="
                                btn btn-link btn-sm
                                float-right
                                see-details
                              "
                              @click="
                                $router.push({
                                  name: 'view-order-items',
                                  params: {
                                    id: order.id,
                                    uuid: order.uuid,
                                    type: 'closed',
                                  },
                                })
                              "
                            >
                              {{ $t("Seedetails") }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <empty></empty>
                </div>
              </template> -->

              <template #completed-tab>
                <div class="row mt-4" v-if="completedOrders().length > 0">
                  <div
                    class="col-md-12 px-7"
                    v-for="order in completedOrders()"
                    :key="'completed-' + order.id"
                  >
                    <div
                      class="row border mb-3"
                      v-for="orderItem in order.order_item"
                      :key="'comp-' + orderItem.id"
                    >
                      <div class="col-md-12 col-lg-3 col-sm-12 col-12">
                        <img
                          :src="orderItem.item.image_url"
                          alt=""
                          class="img-fluid"
                        />
                      </div>

                      <div
                        class="
                          col-md-12 col-lg-9 col-sm-12 col-12
                          pl-auto pl-sm-auto pl-md-auto pl-lg-0
                        "
                      >
                        <div class="row">
                          <div class="col-9 pr-0">
                            <p class="mb-2 prdtName">
                              {{ orderItem.item.name }}
                            </p>

                            <p class="mb-0 mt-2">
                              {{ $t("Order") }} {{ order.uuid }}
                            </p>

                            <p class="mt-2 mb-0">
                              {{ $t("Quantity") }}: {{ orderItem.quantity }}
                            </p>
                            <p class="mb-0 mt-1">
                              {{ $t("On") }}
                              <span class="order-date ml-1">{{
                                orderItem.created_at
                              }}</span>
                            </p>
                          </div>

                          <div class="col-3 pr-2">
                            <a
                              class="
                                btn btn-link btn-sm
                                float-right
                                see-details
                              "
                              @click="
                                $router.push({
                                  name: 'view-order-items',
                                  params: {
                                    id: orderItem.id,
                                    uuid: order.uuid,
                                    type: 'completed',
                                  },
                                })
                              "
                            >
                              {{ $t("Seedetails") }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Empty Orders -->
                <div v-else>
                  <empty></empty>
                </div>

                <div
                  class="mx-auto"
                  style="margin-top: -7em"
                  v-if="!completedOrders()"
                >
                  <scroll-loader
                    :loader-method="fetchOrders"
                    :loader-disable="disable"
                  ></scroll-loader>
                  <p class="text-center" v-show="!disable">Please Wait...</p>
                </div>
              </template>
            </tabbed-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TabbedContent from "@/components/widgets/TabifiedContent";
import modal from "../comments/ModalView";

import orders$ from "./purchases.json";
import Empty from "@/components/customer/EmptyOrders";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import "@/assets/css/customer/purchases/purchases.css";
import moment from "moment";

export default {
  components: { Loading, TabbedContent, Empty, modal },
  data() {
    return {
      newsletter: false,
      currentPage: 1,
      totalPages: 1,
      perPage: 6,
      inc: 0,

      orders: [],
      orders__: orders$.orders,
      order_slice: [],
      disable: false,

      // pendingOrders(): [],
      // closedOrders(): [],
      // completedOrders(): [],

      loading: true,
      height: 80,
      width: 100,
      transition: "fade",
    };
  },

  methods: {
    ...mapActions(["getOrders", "completeOrder"]),

    formatDate(date) {
      return new Date(date).toLocaleString();
    },

    fetchOrders() {
      this.disable = false;
      this.orders = [];
      this.getOrders().then(() => {
        // this.orders = response.data;
        this.disable = true;

        var orders = this.orders_ ? this.orders_.data : this.allOrders;

        console.log("getOrders: ", orders);

        console.log("pending orders: ", this.pendingOrders());
        console.log("completed orders: ", this.completedOrders());
        console.log("closed orders: ", this.closedOrders());

        this.order_slice = this.orders.slice(0, this.perPage);
        this.totalPages = Math.ceil(this.orders.length / this.perPage);
        console.log("totalPages: ", this.orders.length);
      });
    },

    updateResource() {
      if (this.currentPage == 1) {
        this.inc = 0;
        this.order_slice = this.orders.slice(this.inc, this.perPage + this.inc);
        console.log("Inc: ", this.inc);
        console.log("current page: ", this.currentPage);
      } else {
        this.inc = (this.currentPage - 1) * this.perPage;
        this.order_slice = this.orders.slice(this.inc, this.perPage + this.inc);
        console.log("Inc: ", this.inc);
        console.log("current page: ", this.currentPage);
      }

      console.log("Orders slice", this.order_slice);
    },

    // completePayment(order) {
    //   this.loading = true;
    //   this.completeOrder({
    //     uuid: order.uuid,
    //   }).then((response) => {
    //     this.loading = false;
    //     console.log("response: ", response);
    //     this.fetchOrders();
    //     this.$root.$emit("infoMsg", {
    //       alert: "alert-success",
    //       type: "success",
    //       message: this.msgInfo,
    //     });
    //   });

    //   console.log("pay now complete: ", order);
    // },
  },

  computed: {
    ...mapState({
      allOrders: "ordersList",
      cart: "cartItems",
      completionError: "completionError",
    }),

    listOfTabs() {
      return [
        {
          name: "open",
          title: "Pending Orders",
          count: this.pendingOrders().length,
        },
        // {
        //   name: "closed",
        //   title: "Closed Orders",
        //   count: this.closedOrders().length,
        // },
        {
          name: "completed",
          title: "Completed Orders",
          count: this.completedOrders().length,
        },
      ];
    },
  },

  mounted() {
    this.loading = false;
  },

  created() {
    // this.getOrders().then((response) => {
    //   this.orders = response.data;
    //   this.disable = true;
    // });
    // this.fetchOrders();

    console.log("Orders this", this.orders);
    console.log("Orders $:", this.orders__);
  },
};
</script>

<style scoped>
a.btn > i {
  font-size: 1.3rem;
}
.btn.rnd {
  border-radius: 50%;
}
.btn.rnd:hover {
  background: #db9e3557;
}

.table {
  display: block;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: none !important;
}
.table td,
.table th {
  border-top: none !important;
}
.circle-suspended {
  display: block;
  height: 30px;
  width: 30px;
  font-size: 14px;
  border-radius: 50%;
  text-align: center;
  color: #ffc100;
  border-color: #ffc100;
  border: 1px solid;
}
.circle-completed {
  display: block;
  height: 30px;
  width: 30px;
  font-size: 14px;
  border-radius: 50%;
  text-align: center;
  color: #00d285;
  border-color: #00d285;
  border: 1px solid;
}
.circle-aborted {
  display: block;
  height: 30px;
  width: 30px;
  font-size: 14px;
  border-radius: 50%;
  text-align: center;
  color: #ff6868;
  border-color: #ff6868;
  border: 1px solid;
}
.circle-pending {
  display: block;
  height: 30px;
  width: 30px;
  font-size: 14px;
  border-radius: 50%;
  text-align: center;
  color: #1babfe;
  border-color: #1babfe;
  border: 1px solid;
}
.circle-aborted > i {
  color: #ff6868;
  border-color: #ff6868;
  font-size: 1rem;
  vertical-align: middle;
}
.circle-suspended > i {
  color: #ffc100;
  border-color: #ffc100;
  font-size: 1rem;
  vertical-align: middle;
}
.circle-completed > i {
  color: #00d285;
  border-color: #00d285;
  font-size: 1rem;
  vertical-align: middle;
}
.circle-pending > i {
  color: #1babfe;
  border-color: #1babfe;
  font-size: 1rem;
  vertical-align: middle;
}
tbody h6 {
  font-size: 0.9rem;
}
tbody small {
  font-size: 0.8rem;
}
.btn-danger-alt {
  color: #ff6868;
  background: #ffd8d8;
  border-color: #ffd8d8;
}
.btn-danger-alt:hover {
  color: #fff;
  background: #ff6868;
  border-color: #ff6868;
}
.btn-light-alt {
  color: #495463;
  background: #e6effb;
  border-color: #e6effb;
}
.btn-light-alt:hover {
  color: #fff;
  background: #495463;
  border-color: #495463;
}
ul.pagination {
  padding-left: 0;
}
.table td,
.table th {
  padding: 0.8rem 1.2rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.btn.font-sm {
  font-size: 0.65rem;
}
@media (max-width: 768px) {
  .card.pane {
    height: auto;
  }
}
</style>
