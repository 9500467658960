<template>
  <div class="row">
    <div class="col-md-12 py-0">
      <div class="card pane border-0 shadow-sm bg-white py-4 px-5">
        <!-- Header -->
        <div
          class="card-header border-0 bg-transparent pl-2 pb-2 d-flex justify-content-between"
        >
          <div class="d-flex align-items-center">
            <button @click="$router.go(-1)" class="btn btn-lg pl-0 text-dark">
              <i class="mdi mdi-arrow-left"></i>
            </button>
            <div>
              <h4 v-if="edit">
                Edit Product (
                <span class="text-secondary font-weight-normal">{{
                  productName
                }}</span>
                )
              </h4>
              <h4>{{ productName }}</h4>
            </div>
          </div>
        </div>

        <!-- Content -->
        <div class="row px-2">
          <!-- <form action=""> -->
          <div class="col-md-12">
            <form action>
              <div class="row">
                <div class="col-md-7 pt-0">
                  <!-- Categories -->
                  <div class="form-group">
                    <label for="categories">Choose Product Category</label>
                    <select
                      name="categories"
                      id="categories"
                      class="form-control rounded-0 rounded-0"
                    >
                      <option selected>Select Category</option>
                      <option
                        :value="category"
                        v-for="category in categories"
                        :key="category"
                        class="text-secondary"
                        >{{ category }}</option
                      >
                    </select>
                  </div>

                  <!-- Name -->
                  <div class="form-group">
                    <label for="prdtName">Product Name</label>
                    <input
                      type="text"
                      name="prdtName"
                      id="prdtName"
                      v-model="productName"
                      class="form-control rounded-0"
                    />
                  </div>

                  <!-- Price -->
                  <div class="form-group">
                    <label for="prdtName">Price Tag</label>
                    <div class="input-group">
                      <input
                        type="number"
                        name="price"
                        id="price"
                        min="500"
                        v-model="price"
                        class="form-control rounded-0"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="price">XFA</span>
                      </div>
                    </div>
                    <small>
                      <b>VAT: 200 XFA</b>
                    </small>
                  </div>

                  <!-- Quantity -->
                  <div class="form-group">
                    <label for="qtty">Quantity</label>
                    <input
                      type="number"
                      name="qtty"
                      id="qtty"
                      min="1"
                      class="form-control rounded-0"
                      v-model="quantity"
                    />
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="card preview mt-5 rounded-0">
                    <div class="card-body">
                      <img :src="imageUrl" alt class="img-fluid" />
                    </div>
                  </div>

                  <div class="form-group">
                    <input
                      type="file"
                      ref="file"
                      class="form-control d-none"
                      @change="onFileChanged"
                    />

                    <div class="mt-3 d-flex align-items-center">
                      <button
                        type="button"
                        @click="onFileSelected"
                        class="btn btn-orange rounded-0"
                      >
                        <i class="mdi mdi-upload"></i>
                        Choose file...
                      </button>
                      <h6 class="text-secondary px-2 mb-0 font-weight-normal">
                        {{ filename }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <button class="mt-3 btn btn-block btn-lg btn-orange rounded-0">
                SAVE
              </button>
            </form>
          </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      newsletter: false,
      emptyStore: false,
      edit: false,
      productName: "Washing Machine",
      price: 250000,
      quantity: 1,
      imageUrl: require("@/assets/images/appareil.jpg"),
      filename: "appareil.jpg",
      categories: [
        "Fashion",
        "Electronics",
        "Kitchen",
        "Automobile",
        "Wearables",
      ],
    };
  },
  methods: {
    onFileSelected() {
      this.$refs.file.click();
    },
    onFileChanged(e) {
      const files = e.target.files;
      let filename = files[0].name;
      this.filename = filename;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
    },
  },
  mounted() {
    if (this.emptyStore) {
      $(".card.pane").css("height", "44.2em");
    }
  },
};
</script>

<style scoped>
a.btn > i {
  font-size: 1.3rem;
}
.btn.rnd {
  border-radius: 50%;
}
.btn.rnd:hover {
  background: #db9e3557;
}
.btn-rnd {
  border-radius: 50% !important;
}

.input-group-text {
  color: white;
  background-color: var(--afprimary);
  border: none;
  border-radius: 0;
}
.card.preview img {
  /* height: 100%; */
  width: 100%;
  height: 15em;
  object-fit: cover;
}
</style>
