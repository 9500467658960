<template>
  <div class="row card shadow-sm rounded-0 border-0 mb-8">
    <div
      class="
        col-md-12
        px-0
        section-head
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <h3 class="sectionTitle ml-4 my-0">{{ $t("CUSTOMERREVIEW") }}</h3>
      <router-link
        :to="{ name: 'deals' }"
        class="btn btn-link py-0 mt-1 font-15 d-flex align-items-center"
      >
        {{ $t("seeAll") }}&nbsp;&nbsp;&nbsp;
        <span><i class="mdi mdi-chevron-right font-20 mt-3"></i></span>
      </router-link>
    </div>

    <div class="px-4 py-4">
      <div class="row">
        <div class="col-md-3">
          <h6>{{ $t("PRODUCTRATINGS") }} ({{ rating_no }})</h6>

          <div class="ratingSquare py-10 bg-light">
            <h2 class="color-font rateValue">
              {{ rating }}<span class="divisor">/5</span>
            </h2>
            <star-rating
              :rating="rating"
              :read-only="true"
              :increment="0.5"
              :star-size="16"
              :show-rating="false"
              border-color="#FFC107"
              :border-width="3"
              inactive-color="#fff"
              :rounded-corners="true"
              class="mt- d-flex justify-content-center"
            ></star-rating>
            <p class="ml-1 font-20 mt-1 mb-0">
              {{ rating_no }} {{ $t("ratings") }}
            </p>
          </div>
        </div>
        <div class="col-md-9">
          <h6>{{ $t("PRODUCTREVIEWS") }} ({{ reviews }})</h6>
          <div class="review" v-for="(comment, index) in comments" :key="index">
            <star-rating
              :rating="rating"
              :read-only="true"
              :increment="0.5"
              :star-size="12"
              :show-rating="false"
              border-color="#FFC107"
              :border-width="2"
              inactive-color="#fff"
              :rounded-corners="true"
              class="ml-n1"
            ></star-rating>

            <!-- Comment -->
            <p class="mt-3">
              {{ comment.description }}
            </p>

            <!-- Date -->
            <div class="d-flex justify-content-between align-items-center">
              <p class="text-muted">
                {{ comment.created_at | formatDateComments }}
                <span>{{ $t("by") }} {{ comment.user.username }}</span>
              </p>
              <p class="text-success d-flex align-items-center">
                <i class="mdi mdi-check-circle-outline mr-1 font-12"></i>
                {{ $t("CompletedPurchase") }}
              </p>
            </div>

            <hr class="mt-n1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import StarRating from "vue-star-rating";

export default {
  components: {
    StarRating,
  },
  props: {
    rating_no: {
      type: Number,
      required: true,
      default: 0,
    },

    rating: {
      type: Number,
      required: true,
      default: 0,
    },

    reviews: {
      type: Number,
      required: true,
      default: 0,
    },

    comments: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {};
  },

  methods: {},

  created() {
    console.log("Comments: ", this.comments);
  },
};
</script>

<style scoped>
.ratingSquare {
  text-align: center;
}
.divisor {
  font-weight: 400;
}
.rateValue {
  font-size: 2rem !important;
}
</style>
