<template>
  <div id="hotCategory" class="row mt-auto" v-show="categories">
    <div class="col-md-12 px-0 py-3">
      <div
        class="
          col-md-12
          px-0
          section-head
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <h3 class="sectionTitle px-md-0 px-lg-0 pl-3 pr-2 my-0">
          <i class="common hot_categories"></i>
          {{ $t("hot") }}
        </h3>
      </div>

      <div v-if="categories.length > 0" class="card-">
        <carousel
          id="hot-crs"
          :items="6"
          :dots="false"
          :margin="3"
          :autoplay="false"
          :autoplaySpeed="2000"
          :nav="false"
          :loop="true"
          :rewind="true"
          :responsive="{
            0: { items: 2, nav: false, autoplay: false },
            600: { items: 3, nav: false, autoplay: false },
            900: { items: 6, nav: false },
            1296: { items: 6, nav: false },
          }"
          class="slidecard border-0 mx-3 mx-lg-auto mx-md-auto"
        >
          <template slot="prev"
            ><button class="rounded owl-prev">
              <i class="mdi mdi-chevron-left"></i></button
          ></template>

          <!-- <div  class=""> -->
          <router-link
            :to="{
              name: 'category',
              params: { childSlug: category.slug, catId: category.id },
            }"
            class="card prdtCard border-0 rounded"
            v-for="(category, index) in categories"
            :key="index"
          >
            <div class="prdtImage">
              <img
                src="@/assets/images/category_1.jpg"
                class="d-block mx-auto img-fluid"
              />
            </div>

            <div class="card-footer bg-transparent rounded-0 border-0">
              <h5 class="prdtName mb-2 mt-3">
                {{ category.name | truncate(15, "...") }}
              </h5>
            </div>
          </router-link>
          <!-- </div> -->

          <template slot="next"
            ><button class="rounded owl-next">
              <i class="mdi mdi-chevron-right"></i></button
          ></template>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/home/hot-categories/hot-categories.css";

export default {
  props: {
    categoryItems: {
      type: Array,
      default: null,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    categories() {
      return this.categoryItems;
    },
  },
};
</script>

<style scoped>
@media (max-width: 424px) {
  #hot-crs .owl-next {
    right: 3.5%;
  }
}
</style>
