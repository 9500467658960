<template>
  <Details :compName="$t('newPrdts')"></Details>
</template>

<script>
import Details from "@/components/widgets/ProductDetails.vue";

export default {
  components: {
    Details,
  },
};
</script>
