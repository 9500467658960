<template>
  <div class="row">
    <div class="col-md-12 py-0">
      <loading
        :active.sync="loading"
        :can-cancel="false"
        :is-full-page="true"
        color="#ffa300"
        :height="height"
        :width="width"
        :transition="transition"
        background-color="#000"
      ></loading>
      <div class="card pane border-0 shadow-sm bg-white py-4 px-5">
        <!-- Header -->
        <div
          class="
            card-header
            border-0
            bg-transparent
            d-flex
            align-items-center
            justify-content-between
            pb-2
          "
        >
          <div class="d-flex align-items-center">
            <button
              @click.stop="$router.go(-1)"
              class="btn btn-lg pl-0 text-dark"
            >
              <i class="mdi mdi-arrow-left"></i>
            </button>
            <h4>{{ $t("AddaNewAddress") }}</h4>
          </div>
        </div>

        <!-- Content -->
        <div class="row px-2">
          <div class="col-md-12">
            <form action>
              <!-- row 1 -->
              <div class="row px-2 mt-n3">
                <div class="col-12 col-md-6 py-0">
                  <div class="form-group pb-1">
                    <md-field>
                      <label>{{ $t("FirstName") }}</label>
                      <md-input v-model="firstname"></md-input>
                    </md-field>
                  </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                  <div class="form-group pb-1">
                    <md-field>
                      <label>{{ $t("LastName") }}</label>
                      <md-input v-model="lastname"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>

              <!-- row 2 -->
              <div class="row px-2">
                <div class="col-12 col-md-6 py-0">
                  <div class="form-group pb-1">
                    <label>{{ $t("PhoneNumber") }}</label>
                    <vue-tel-input
                      v-bind="bindProps1"
                      v-on:country-changed="countryChanged"
                      v-model="phoneNumber"
                    ></vue-tel-input>
                  </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                  <div class="form-group pb-1">
                    <label>{{ $t("AdditionalPhoneNumber") }}</label>
                    <vue-tel-input
                      v-bind="bindProps2"
                      v-on:country-changed="countryChanged"
                      v-model="otherPhoneNumber"
                    ></vue-tel-input>
                  </div>
                </div>
              </div>

              <!-- row 3 -->
              <div class="row px-2">
                <div class="col-12 col-md-12 py-0">
                  <div class="form-group pb-1">
                    <md-field>
                      <label>{{ $t("Address") }}</label>
                      <md-input v-model="address"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>

              <!-- row 4 -->
              <div class="row px-2 mt-n4">
                <div class="col-12 col-md-12 py-0">
                  <div class="form-group pb-1">
                    <md-field>
                      <label>{{ $t("AnyOtherInformation") }}</label>
                      <md-input v-model="otherInfo"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>

              <!-- row 5 -->
              <div class="row px-2 mt-n4">
                <div class="col-12 col-md-6 py-0">
                  <div class="form-group">
                    <md-field>
                      <label for="region"
                        >{{ $t("Region") }}/{{ $t("State") }}</label
                      >
                      <md-select
                        v-model="state"
                        name="region"
                        id="region"
                        @md-selected="setStates()"
                      >
                        <md-option
                          v-for="(state, index) in states"
                          :key="index"
                          :value="state.id"
                          >{{ state.name }}</md-option
                        >
                      </md-select>
                    </md-field>
                  </div>
                </div>

                <div class="col-12 col-md-6 py-0">
                  <div class="form-group mt-4 mb-0">
                    <md-checkbox v-model="defaultAddress">{{
                      $t("SetasDefaultAddress")
                    }}</md-checkbox>
                  </div>
                </div>
              </div>

              <!-- <div class="form-group my-n2 px-1" v-else></div> -->

              <button
                :class="[
                  'btn',
                  'btn-block',
                  'btn-orange',
                  'shadow-sm',
                  'py-2',
                  emptyAddressBook ? 'mt-3' : 'mt-8',
                ]"
                @click.prevent="submitForm"
              >
                {{ $t("SAVE") }}
              </button>
              <!-- </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Loading },
  data() {
    return {
      newsletter: false,
      firstname: "",
      lastname: "",
      email: "dgdaniel@gmail.com",
      country: "",
      countryCode: "CM",
      address: "",
      defaultAddress: false,
      username: "daniel",
      phoneNumber: "",
      otherPhoneNumber: "",
      otherInfo: "",
      disabled: false,
      bindProps1: {
        mode: "international",
        placeholder: "Phone Number",
        required: false,
        enabledCountryCode: true,
        enabledFlags: true,
        autocomplete: "off",
        name: "phoneNumber",
        maxLen: 25,
        inputOptions: {
          showDialCode: true,
        },
      },
      bindProps2: {
        mode: "international",
        placeholder: "Additional phone Number",
        required: false,
        enabledCountryCode: true,
        enabledFlags: true,
        autocomplete: "off",
        name: "otherPhoneNumber",
        maxLen: 25,
        inputOptions: {
          showDialCode: true,
        },
      },
      states: [],
      cities: [],
      city: null,
      state: null,
      country_id: null,
      singleAddress: false,
      emptyAddressBook: false,

      user: null,
      stateSet: true,

      loading: false,
      height: 80,
      width: 100,
      transition: "fade",
    };
  },

  methods: {
    ...mapActions(["addNewAddress", "getStatesAction"]),

    countryChanged(country) {
      this.country = country.name;
      this.countryCode = country.iso2;
    },

    submitForm() {
      this.loading = true;
      this.addNewAddress({
        id: this.user.id,
        phone: this.phoneNumber,
        country_id: this.country_id,
        city_id: this.state,
        quarter: this.address,
        default: this.defaultAddress ? 1 : 0,
      }).then(() => {
        if (this.addressUpdateSuccess.code === "ADDRESS_ADDED") {
          console.log("ADD address response: ", this.addressUpdateSuccess);
          this.$root.$emit("infoMsg", {
            alert: "alert-info",
            type: "info",
            message: this.addressUpdateSuccess.message,
          });

          this.loading = false;

          this.$forceUpdate();
        }
      });
    },

    fetchUserData() {
      this.getStatesAction(this.user.country.id).then((response) => {
        console.log("states response: ", response);
        // console.log("country nnn ", this.country.split("(")[0].trim());
        this.states = response.cities;
        // response.countries.forEach((country) => {
        //   if (country.name == this.country.split("(")[0].trim()) {
        //     this.states = country.states;
        this.country_id = this.user.country.id;
        //     this.user_country = country.name;
        //   }
        // });
      });
    },

    setStates() {
      this.stateSet = false;

      console.log("form state", this.state);

      this.states.forEach((state) => {
        if (state.id == this.state) {
          this.cities = state.cities;
        }
      });
    },
  },

  computed: {
    ...mapGetters(["getUserDetails", "getStatesData", "addressUpdateSuccess"]),
  },

  created() {
    this.phoneNumber = this.getUserDetails.phone;
    this.user = this.getUserDetails;

    console.log("statesData", this.getStatesData);

    if (this.getStatesData === null) {
      this.fetchUserData();
    } else {
      console.log("user ---", this.getUserDetails);
      this.fetchUserData();
      console.warn("critical zone ", this.country);

      this.states = this.getStatesData.cities;
      console.log("geo states", this.states);
    }

    // if (this.user.city) {
    //   console.log("user city", this.states);

    //   this.setStates();
    // }
    console.log("geo cities", this.cities);
    // console.log("geo states", this.states);
    console.log("User country : ", this.user.country.name);
  },

  mounted() {
    console.log("country", this.user.country.id);
  },
};
</script>

<style scoped>
a.btn > i {
  font-size: 1.3rem;
}
.btn.rnd {
  border-radius: 50%;
}
.btn.rnd:hover {
  background: #db9e3557;
}

.vue-tel-input {
  border-radius: 3px;
  display: flex;
  border-bottom: 1px solid #949494 !important;
  border: transparent;
  text-align: left;
  min-height: 44px;
  margin-top: -1.5em;
}

.card.pane {
  height: 100% !important;
}
</style>
