import apiService from "../main";

class EndPointError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const OrderService = {
  /**
   * Get all orders
   **/
  getOrders: async function () {
    const request = {
      method: "get",
      url: "/orders",
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Orders order.js: ", response.data);

      return response.data;
    } catch (error) {
      console.log("Get Orders API error", error.response);
      // throw new EndPointError(error.response.status, error.response.data.msg);
      return error.response;
    }
  },

  /**
   * Add to cart
   **/
  addToCart: async function (product) {
    const request = {
      method: "post",
      url: "/orders/item/" + product.id + "/add",
      data: {
        quantity: product.quantity,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Add to Cart/Update Cart: ", response);

      return response;
    } catch (error) {
      throw new EndPointError(
        // error.response.code,
        error.response.data.message
      );
      console.log(error);
    }
  },

  /**
   * Update cart
   **/
  updateCart: async function (product) {
    const request = {
      method: "post",
      url: "/orders/item/" + product.item_id + "/add",
      data: {
        quantity: product.quantity,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Add to Cart/Update Cart: ", response);

      return response;
    } catch (error) {
      throw new EndPointError(
        // error.response.code,
        error.response.data.message
      );
      console.log(error);
    }
  },

  /**
   * Remove from cart
   **/
  removeFromCart: async function (product) {
    const request = {
      method: "post",
      url: "/orders/item/" + product.item.id + "/remove",
      data: {
        quantity: product.quantity,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Remove from Cart: ", response);

      return response.data;
    } catch (error) {
      throw new EndPointError(
        // error.response.code,
        error.response.data.message
      );
      console.log(error);
    }
  },

  /**
   * Order detail
   **/
  getOrderDetail: async function (uuid) {
    const request = {
      method: "get",
      url: "/orders/" + uuid,
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Order detail: ", response);

      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  /**
   * Payment status
   **/
  getPaymentStatus: async function (transaction_id) {
    const request = {
      method: "get",
      url: "/momo/status/" + transaction_id,
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("payment status: ", response);

      return response.data;
    } catch (error) {
      console.log("payment status error", error);
      return error;
    }
  },

  /**
   * Add an address to the orders
   **/
  addressToOrders: async function (payload) {
    const request = {
      method: "post",
      url: "/orders/" + payload.uuid + "/add/address",
      data: {
        address_id: payload.address_id,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Add to Cart/Update Cart: ", response);

      return response.data;
    } catch (error) {
      // throw new EndPointError(
      //   // error.response.code,
      //   error.response.data.message
      // );
      console.log("error adding address to orders", error.response);
      return error.response;
    }
  },

  /**
   * Mark order complete
   **/
  markOrderComplete: async function (payload) {
    const request = {
      method: "post",
      url: "/orders/" + payload.uuid + "/complete",
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("mark order complete: ", response);

      return response.data;
    } catch (error) {
      console.log("error adding address to orders", error);
      return error;
    }
  },

  // /**
  //  * Validate Order
  //  **/
  // addressToOrders: async function (payload) {
  //   const request = {
  //     method: "post",
  //     url: "/orders/" + payload.uuid + "/add/address",
  //     params: {
  //       address_id: payload.address_id,
  //     },
  //   };

  //   try {
  //     const response = await apiService.customRequest(request);

  //     console.log("Add to Cart/Update Cart: ", response);

  //     return response.data;
  //   } catch (error) {
  //     // throw new EndPointError(
  //     //   // error.response.code,
  //     //   error.response.data.message
  //     // );
  //     console.log("error adding address to orders", error.response);
  //     return error.response;
  //   }
  // },
};

export default OrderService;

export { OrderService, EndPointError };
