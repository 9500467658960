<template>
  <div id="bot">
    <div class="floating-chat">
      <i class="fa fa-comment" aria-hidden="true"></i>
      <div class="chat">
        <div class="header">
          <div class="container px-3 py-5">
            <div class="row">
              <div class="col-12 py-0">
                <div class="d-flex justify-content-end">
                  <span class="title"> {{ $t("Sendamessage") }} </span>
                  <button class="closebot">
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="row mt-5 mb-1">
              <div class="col-12 py-0 text-center">
                <p class="mb-0 subtitle" v-if="!submitted">
                  {{ $t("fillTheFormBelow") }}
                </p>
                <p class="mb-0 subtitle" v-else>
                  {{ $t("willGetBackToYou") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="messages">
          <form @submit.prevent="submitForm" v-if="!submitted">
            <div class="form-group">
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Name"
                class="form-control"
                v-model="form.name"
              />
            </div>

            <div class="form-group">
              <input
                type="email"
                name="email"
                id="email"
                class="form-control"
                placeholder="E-mail or Phone"
                v-model="form.email"
              />
            </div>

            <div class="form-group">
              <textarea
                name="message"
                cols="35"
                rows="5"
                placeholder="Your message here..."
                v-model="form.message"
                class="form-control"
              ></textarea>
            </div>

            <button type="submit" class="btn btn-block btn-md btn-orange">
              {{ $t("Submit") }}
            </button>
          </form>

          <div class="my-8 row nustify-content-center" v-else>
            <div class="col-12 text-center">
              <h5>{{ $t("messageSent") }}</h5>

              <p class="mt-5">
                {{ $t("agentsWillCall") }}
              </p>

              <p>{{ $t("stayTuned") }}</p>

              <div class="row">
                <div class="col-12 text-center px-10">
                  <a
                    @click.prevent="close = true"
                    class="btn btn-link btn-orange continuebtn mb-2"
                    >{{ $t("ContinueShopping") }}</a
                  >
                  <a
                    @click.prevent="submitted = false"
                    class="btn btn-link btn-primary backbtn py-auto"
                  >
                    <i class="mdi mdi-arrow-left"></i>
                    <span>{{ $t("Back") }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="footer">
          <div class="text-box" contenteditable="true" disabled="true"></div>
          <button id="sendMessage">send</button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/chatbot/chatbot.css";
import $ from "jquery";

export default {
  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
      },
      submitted: false,
      close: false,
    };
  },
  methods: {
    submitForm() {
      console.log("Submitting the form", this.form);
      this.submitted = true;
    },
    clicked() {
      console.log("clicked continue");
    },
  },
  created() {
    this.submitted = false;
  },
  mounted() {
    var element = $("#bot .floating-chat");
    var myStorage = localStorage;

    if (this.close) {
      console.log("close element clicked");
      closeElement();
    }

    $(".continuebtn").on("click", () => {
      let thisRoute = window.location.pathname;
      console.log("this route", thisRoute);

      // if (thisRoute != "/") {
      //   console.log("this route", thisRoute);
      //   window.location.replace("/");
      //   console.log("new route", window.location.pathname);
      // }
      closeElement();
    });

    if (!myStorage.getItem("chatID")) {
      myStorage.setItem("chatID", createUUID());
    }

    setTimeout(function () {
      element.addClass("enter");
    }, 1000);

    element.click(openElement);

    function openElement() {
      var messages = element.find(".messages");
      element.find(">i").hide();
      element.find(".fa-comment").hide();
      element.addClass("expand");
      element.find(".chat").addClass("enter");

      element.off("click", openElement);
      element.find(".header button.closebot").click(closeElement);
      element.find("a.continuebtn").click(closeElement);
      element.find("#sendMessage").click(sendNewMessage);
    }

    function closeElement() {
      let thisRoute = window.location.pathname;
      console.log("this route", thisRoute);
      console.log("in the closeElement function");
      element.find(".chat").removeClass("enter").hide();
      element.find(">i").show();
      element.find(".fa-comment").show();
      console.log("clicking on close");
      element.removeClass("expand");
      element.find(".header button.closebot").off("click", closeElement);
      element.find("#sendMessage").off("click", sendNewMessage);
      element
        .find(".text-box")
        .off("keydown", onMetaAndEnter)
        .prop("disabled", true)
        .blur();
      setTimeout(function () {
        element.find(".chat").removeClass("enter").show();
        element.click(openElement);
      }, 500);
    }

    function createUUID() {
      // http://www.ietf.org/rfc/rfc4122.txt
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";

      var uuid = s.join("");
      return uuid;
    }

    function sendNewMessage() {
      var userInput = $(".text-box");
      var newMessage = userInput
        .html()
        .replace(/\<div\>|\<br.*?\>/gi, "\n")
        .replace(/\<\/div\>/g, "")
        .trim()
        .replace(/\n/g, "<br>");

      if (!newMessage) return;

      var messagesContainer = $(".messages");

      messagesContainer.append(
        ['<li class="self">', newMessage, "</li>"].join("")
      );

      // clean out old message
      userInput.html("");
      // focus on input
      userInput.focus();

      messagesContainer.finish().animate(
        {
          scrollTop: messagesContainer.prop("scrollHeight"),
        },
        250
      );
    }

    function onMetaAndEnter(event) {
      if ((event.metaKey || event.ctrlKey) && event.keyCode == 13) {
        sendNewMessage();
      }
    }
  },
};
</script>
