<template>
  <div class="row">
    <div class="col-md-12 py-0">
      <div class="card pane border-0 shadow-sm bg-white py-4 px-5">
        <!-- Header -->
        <div
          class="
            card-header
            border-0
            bg-transparent
            d-flex
            align-items-center
            justify-content-between
            pb-2
          "
        >
          <div class="d-flex align-items-center">
            <button
              @click.stop="$router.go(-1)"
              class="btn btn-lg pl-0 text-dark"
            >
              <i class="mdi mdi-arrow-left"></i>
            </button>
            <h4>{{ $t("EditAddress") }}</h4>
          </div>
        </div>

        <!-- Content -->
        <div class="row px-2">
          <div class="col-md-12">
            <form action>
              <!-- row 1 -->
              <div class="row px-2 mt-n3">
                <div class="col-12 col-md-6 py-0">
                  <div class="form-group pb-1">
                    <md-field>
                      <label>{{ $t("FirstName") }}</label>
                      <md-input v-model="firstname"></md-input>
                    </md-field>
                  </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                  <div class="form-group pb-1">
                    <md-field>
                      <label>{{ $t("LastName") }}</label>
                      <md-input v-model="lastname"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>

              <!-- row 2 -->
              <div class="row px-2">
                <div class="col-12 col-md-6 py-0">
                  <div class="form-group pb-1">
                    <label>{{ $t("PhoneNumber") }}</label>
                    <vue-tel-input
                      v-bind="bindProps1"
                      v-on:country-changed="countryChanged"
                      v-model="phoneNumber"
                    ></vue-tel-input>
                  </div>
                </div>
                <div class="col-12 col-md-6 py-0">
                  <div class="form-group pb-1">
                    <label>{{ $t("AdditionalPhoneNumber") }}</label>
                    <vue-tel-input
                      v-bind="bindProps2"
                      v-on:country-changed="countryChanged"
                      v-model="otherPhoneNumber"
                    ></vue-tel-input>
                  </div>
                </div>
              </div>

              <!-- row 3 -->
              <div class="row px-2">
                <div class="col-12 col-md-12 py-0">
                  <div class="form-group pb-1">
                    <md-field>
                      <label>{{ $t("Address") }}</label>
                      <md-input v-model="quarter"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>

              <!-- row 4 -->
              <div class="row px-2 mt-n4">
                <div class="col-12 col-md-12 py-0">
                  <div class="form-group pb-1">
                    <md-field>
                      <label>{{ $t("AnyOtherInformation") }}</label>
                      <md-input v-model="otherInfo"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>

              <!-- row 5 -->
              <div class="row px-2 mt-n4">
                <div class="col-12 col-md-6 py-0">
                  <div class="form-group">
                    <md-field>
                      <label for="region"
                        >{{ $t("Region") }}/{{ $t("State") }}</label
                      >
                      <md-select
                        v-model="state"
                        name="region"
                        id="region"
                        @md-selected="setCities()"
                      >
                        <md-option
                          v-for="(state, index) in states"
                          :key="'state-' + index"
                          :value="state.id"
                          >{{ state.name }}</md-option
                        >
                      </md-select>
                    </md-field>
                  </div>
                </div>
                <!-- <div class="col-12 col-md-6 py-0">
                  <div class="form-group">
                    <md-field>
                      <label for="city">City</label>
                      <md-select
                        v-model="city"
                        name="city"
                        id="city"
                        :disabled="stateSet"
                      >
                        <md-option
                          v-for="(city, index) in cities"
                          :key="index"
                          :value="city.id"
                          >{{ city.name }}</md-option
                        >
                      </md-select>
                    </md-field>
                  </div>
                </div> -->
                <div class="col-12 col-md-6 py-0">
                  <div class="form-group mt-4 mb-0">
                    <md-checkbox v-model="defaultAddress">{{
                      $t("SetasDefaultAddress")
                    }}</md-checkbox>
                  </div>
                </div>
              </div>

              <div class="form-group my-n2 px-1" v-if="emptyAddressBook">
                <md-checkbox v-model="defaultAddress" class="md-primary">{{
                  $t("SetasDefaultAddress")
                }}</md-checkbox>
              </div>

              <!-- <div class="form-group my-n2 px-1" v-else></div> -->

              <button
                :class="[
                  'btn',
                  'btn-block',
                  'btn-orange',
                  'shadow-sm',
                  'py-2',
                  emptyAddressBook ? 'mt-3' : 'mt-8',
                ]"
                @click.prevent="submitForm"
              >
                {{ $t("SAVE") }}
              </button>
              <!-- </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      newsletter: false,
      firstname: "",
      lastname: "",
      email: "dgdaniel@gmail.com",
      country: "Cameroon",
      countryCode: "CM",
      quarter: "",
      defaultAddress: false,
      username: "daniel",
      phoneNumber: "",
      otherPhoneNumber: "",
      otherInfo: "",
      disabled: false,
      bindProps1: {
        mode: "international",
        placeholder: "Phone Number",
        required: false,
        enabledCountryCode: true,
        enabledFlags: true,
        autocomplete: "off",
        name: "phoneNumber",
        maxLen: 25,
        inputOptions: {
          showDialCode: true,
        },
      },
      bindProps2: {
        mode: "international",
        placeholder: "Additional phone Number",
        required: false,
        enabledCountryCode: true,
        enabledFlags: true,
        autocomplete: "off",
        name: "otherPhoneNumber",
        maxLen: 25,
        inputOptions: {
          showDialCode: true,
        },
      },
      states: [],
      cities: [],
      city: null,
      state: null,
      singleAddress: false,
      emptyAddressBook: false,
      addressInfo: null,

      user: null,
      stateSet: true,
    };
  },

  methods: {
    ...mapActions(["updateAddress"]),

    countryChanged(country) {
      this.country = country;
      this.countryCode = country.iso2;
    },

    submitForm() {
      this.updateAddress({
        id: this.user.id,
        phone: this.phoneNumber,
        country_id: this.user.country.id,
        city_id: this.state,
        quarter: this.quarter,
        default: this.defaultAddressStatus,
      }).then(() => {
        if (this.addressUpdateSuccess.code === "ADDRESS_UPDATE") {
          console.log("update address response: ", this.addressUpdateSuccess);
          this.$root.$emit("infoMsg", {
            alert: "alert-success",
            type: "success",
            message: this.addressUpdateSuccess.message,
          });

          this.$forceUpdate();
        }
      });
    },

    setCities() {
      this.stateSet = false;

      this.states.forEach((state) => {
        if (state.id == this.state) {
          this.cities = state.cities;
        }
      });
    },
  },

  computed: {
    ...mapGetters([
      "getUserDetails",
      // "getStatesData",
      "getStoredAddresses",
      "addressUpdateSuccess",
    ]),
    ...mapState({ userDetails: "user", geoData: "geoData" }),

    defaultAddressStatus() {
      return this.defaultAddress ? 1 : 0;
    },
  },

  created() {
    this.phoneNumber = this.getUserDetails.phone;
    this.user = this.user_;

    console.log("geoData", this.statesData_);

    this.states = this.statesData_.cities;
    // this.getStatesData.cities.forEach((country) => {
    //   if (country.id == this.user.country.id) {
    //     this.states = country.states;
    //   }
    // });

    console.log("geoData states", this.states);
  },

  mounted() {
    console.log("country", this.user.country.id);
    this.getStoredAddresses.data.addresses.forEach((address) => {
      if (address.id == this.$route.params.id) {
        this.addressInfo = address;
        this.phoneNumber = address.phone;
        this.quarter = address.quarter;
      }
    });
  },
};
</script>

<style scoped>
a.btn > i {
  font-size: 1.3rem;
}
.btn.rnd {
  border-radius: 50%;
}
.btn.rnd:hover {
  background: #db9e3557;
}

.vue-tel-input {
  border-radius: 3px;
  display: flex;
  border-bottom: 1px solid #949494 !important;
  border: transparent;
  text-align: left;
  min-height: 44px;
  margin-top: -1.5em;
}

.card.pane {
  height: 100% !important;
}
</style>
