import { HomeApiService } from "@/services/homeapi";

const state = {
  guestDataObj: {},
  superDeals: null,
  currentDeals: null,
  comingDeals: null,
  newItems: null,
  brandItems: null,
  recommendedItems: null,
  hotCategories: null,
  allCategories: null,
  events: null,
};

const mutations = {
  setGuestData(state, payload) {
    state.events = payload.guestData.events;

    state.guestDataObj.brands = payload.guestData.afeshop_brand;
    state.guestDataObj.hotCategories = payload.guestData.hot_categories;
    state.guestDataObj.superDeals = payload.guestData.super_deals;
    state.guestDataObj.allCategories = payload.guestData.all_categories;
    state.guestDataObj.events = payload.guestData.events;

    // state.superDeals = payload.guestData.super_deals;

    state.brandItems = payload.guestData.afeshop_brand;
    state.hotCategories = payload.guestData.hot_categories;
    state.allCategories = payload.guestData.all_categories;

    console.log("GUEST DATA: ", state.guestDataObj);
    localStorage.setItem("guestData", JSON.stringify(state.guestDataObj));
  },

  SET_NEW_PRODUCTS(state, payload) {
    state.newItems = payload.newProducts.new_items;

    console.log("NEW PRODUCTS: ", state.newItems);

    localStorage.setItem("new_products", JSON.stringify(state.newItems));
  },

  SET_SUPER_DEALS(state, payload) {
    state.currentDeals = payload.superDeals.current_deals;
    state.comingDeals = payload.superDeals.coming_deals;

    state.superDeals = payload.superDeals;

    console.log("SUPER DEALS LOCAL: ", payload.superDeals);

    localStorage.setItem("super_deals", JSON.stringify(state.superDeals));
  },

  SET_RECOMMENDED(state, payload) {
    state.recommendedItems = payload.recommended.recommended;

    console.log("RECOMMENDED PRODUCTS: ", state.recommendedItems);

    localStorage.setItem("recommended", JSON.stringify(state.recommendedItems));
  },

  setEvents(state, payload) {
    state.events = payload;

    console.log("set Events Indx js: ", payload);
  },
};

const getters = {
  getGuestDataObj: (state) => state.guestDataObj,

  getSuperDealsObj: (state) => state.superDeals,

  getNewProductsObj: (state) => state.newItems,

  getRecommendedObj: (state) => state.recommendedItems,

  getEvents: (state) => state.events,
};

const actions = {
  async getGuestData({ commit }) {
    try {
      const guestData = await HomeApiService.guestData();

      console.log("Guest data: ", guestData);
      commit("setGuestData", {
        guestData,
      });
      commit("setEvents", guestData.events);

      return true;
    } catch (e) {
      console.log("GUEST DATA ERROR: ", e);
      return false;
    }
  },
  async getRecommendedProducts({ commit }) {
    try {
      const recommended = await HomeApiService.getRecommendedProducts();

      console.log("Recommended Products: ", recommended);
      commit("SET_RECOMMENDED", {
        recommended,
      });

      return true;
    } catch (e) {
      console.log("RECOMMENDED PRODUCTS ERROR: ", e);
      return false;
    }
  },
  async getNewProducts({ commit }) {
    try {
      const newProducts = await HomeApiService.getNewProducts();

      console.log("New Produts: ", newProducts);
      commit("SET_NEW_PRODUCTS", {
        newProducts,
      });

      return true;
    } catch (e) {
      console.log("NEW PRODUCTS ERROR: ", e);
      return false;
    }
  },
  async getSuperDeals({ commit }) {
    try {
      const superDeals = await HomeApiService.getSuperDeals();

      console.log("Super Deals: ", superDeals);
      commit("SET_SUPER_DEALS", {
        superDeals,
      });

      return true;
    } catch (e) {
      console.log("SUPER DEALS ERROR: ", e);
      return false;
    }
  },
  async getMainCategories({ commit }) {
    try {
      const mainCategories = await HomeApiService.getMainCategories();

      console.log("Main Categories: ", mainCategories);

      return mainCategories;
    } catch (e) {
      console.log("Main Categories ERROR: ", e);
      return e;
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
