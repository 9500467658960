import Vue from "vue";
import VueRouter from "vue-router";

import Home from "@/components/home/Index";

// import SignIn from "@/views/customer/SignIn";
// import SignUp from "@/views/customer/Register";
import PasswordReset from "@/views/customer/account/ForgotPassword";
import RecoveryInfo from "@/views/customer/account/RecoveryInfo";
import RegisterSuccess from "@/views/customer/RegisterSuccess";
import RestorePassword from "@/views/customer/account/RestorePassword";
// import SocialAuthView from "@/views/SocialAuthView";

import Customer from "@/views/customer/index";
import CustomerStatistics from "@/views/customer/Statistics";
import CustomerCoupons from "@/views/customer/Vouchers";
import CustomerNewsletter from "@/views/customer/Newsletter";
import Payment from "@/views/Payment";
import PaymentInfo from "@/views/PaymentInfo";
import ShoppingCart from "@/views/Cart.vue";
import TermsConditions from "@/views/customer/Terms&Conditions";

import CustomerComments from "@/views/customer/comments/Index";
// import CustomerCommentsView from "@/views/comments/View"

import CustomerAccount from "@/views/customer/account/Index";
import AccountEdit from "@/views/customer/account/Edit";

import CustomerSell from "@/views/customer/sell/Index";
import CustomerSellAdd from "@/views/customer/sell/Add";
import CustomerSellEdit from "@/views/customer/sell/Edit";
import CustomerSellView from "@/views/customer/sell/View";

import CustomerPurchases from "@/views/customer/purchases/Index";
import CustomerViewPurchaseItems from "@/views/customer/purchases/ViewOrderItems";
import PurchaseTracking from "@/views/customer/purchases/TrackPurchase";

import AddressBook from "@/views/customer/address/Index.vue";
import AddAddress from "@/views/customer/address/Add.vue";
import EditAddress from "@/views/customer/address/Edit.vue";

// import ResetPassword from "@/views/ResetPassword"
import TokenService from "@/services/storage";

import Shops from "../views/shop/Index.vue";
import ShopRegister from "@/views/shop/ShopRegister";
import ShopLogin from "@/views/shop/ShopLogin";
import ShopView from "@/views/shop/View";

import SuperDeals from "@/views/superdeals/Index";
import SuperDealsDetail from "@/views/superdeals/Details";

import NewProducts from "@/views/newproducts/Index.vue";
import NewProductsDetail from "@/views/newproducts/Details.vue";

import RecProductsDetail from "@/views/recommended/Details.vue";

import BrandsIndex from "@/views/brands/Index.vue";

import SearchResults from "@/views/SearchResults.vue";
import ProductCategory from "@/views/Category.vue";

import PayMethod from "@/views/PayMethod.vue";

import AfshopIntl from "@/views/afeshop_intl/Index.vue";

import Support from "@/views/Support.vue";
import NotFoundPage from "@/views/NotFoundPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: false,
      breadcrumb: "Home",
      title: "Home",
    },
  },
  {
    path: "/customer/signin",
    name: "signin",
    component: () =>
      import(/* webpackChunkName: 'signin' */ "@/views/customer/SignIn"),
    meta: {
      requiresGuest: true,
      breadcrumb: "Sign In",
      title: "Sign In",
    },
  },
  {
    path: "/customer/register",
    name: "signup",
    component: () =>
      import(/* webpackChunkName: 'register' */ "@/views/customer/Register"),
    meta: {
      requiresGuest: true,
      breadcrumb: "Sign Up",
      title: "Sign Up",
    },
  },
  {
    path: "/customer",
    name: "customer",
    component: Customer,
    redirect: "customer/account/index",
    children: [
      {
        path: "account/index",
        name: "account-index",
        component: CustomerAccount,
        meta: {
          breadcrumb: "Account",
          title: "Account",
        },
      },
      {
        path: "account/edit",
        name: "account-edit",
        component: AccountEdit,
        meta: {
          breadcrumb: "Edit",
          title: "Edit",
        },
      },
      {
        path: "sell",
        name: "sell",
        component: CustomerSell,
        meta: {
          breadcrumb: "Sell",
          title: "Sell",
        },
      },
      {
        path: "sell/add",
        name: "sell-add",
        component: CustomerSellAdd,
        meta: {
          breadcrumb: "Add Product",
          title: "Add Product",
        },
      },
      {
        path: "sell/edit",
        name: "sell-edit",
        component: CustomerSellEdit,
        meta: {
          breadcrumb: "Edit Product",
          title: "Edit Product",
        },
      },
      {
        path: "sell/view/:op",
        name: "sell-view",
        component: CustomerSellView,
        meta: {
          breadcrumb: "View Product",
          title: "View Product",
        },
      },

      {
        path: "statistics",
        name: "statistics",
        component: CustomerStatistics,
        meta: {
          title: "Statistics",
        },
      },
      {
        path: "purchases",
        name: "purchases",
        component: CustomerPurchases,
        meta: {
          title: "Orders",
        },
      },

      {
        path: "purchases/view/orderitems/:id",
        name: "view-order-items",
        component: CustomerViewPurchaseItems,
        meta: {
          title: "View Orders",
        },
      },
      {
        path: "purchases/view/pending/tracking",
        name: "tracking",
        component: PurchaseTracking,
        props: true,
        meta: {
          title: "Track Order",
        },
      },

      {
        path: "voucher",
        name: "voucher",
        component: CustomerCoupons,
        meta: {
          title: "Vouchers",
        },
      },
      {
        path: "newsletter",
        name: "newsletter",
        component: CustomerNewsletter,
        meta: {
          title: "Newsletter",
        },
      },
      {
        path: "address/index",
        name: "addressbook",
        component: AddressBook,
        meta: {
          title: "Address Book",
        },
      },
      {
        path: "address/create",
        name: "addaddress",
        component: AddAddress,
        meta: {
          title: "Add Address",
        },
      },
      {
        path: "address/edit/:id",
        name: "editaddress",
        component: EditAddress,
        meta: {
          title: "Edit Address",
        },
      },
      {
        path: "reviews/index",
        name: "comments",
        component: CustomerComments,
        meta: {
          title: "Comments",
        },
      },
    ],

    meta: {
      requiresAuth: true,
    },
  },
  // -------- Mobile Customer Routes --------
  {
    path: "/customer/account/edit",
    name: "account-edit-m",
    component: AccountEdit,
    meta: {
      breadcrumb: "Edit",
      title: "Edit",
    },
  },

  {
    path: "/customer/statistics",
    name: "statistics-m",
    component: CustomerStatistics,
    meta: {
      title: "Statistics",
    },
  },
  {
    path: "/customer/purchases",
    name: "purchases-m",
    component: CustomerPurchases,
    meta: {
      title: "Orders",
    },
  },

  {
    path: "/customer/purchases/view/orderitems/:id",
    name: "view-order-items-m",
    component: CustomerViewPurchaseItems,
    meta: {
      title: "View Orders",
    },
  },
  {
    path: "/customer/purchases/view/pending/tracking",
    name: "tracking-m",
    component: PurchaseTracking,
    props: true,
    meta: {
      title: "Track Order",
    },
  },

  {
    path: "/customer/voucher",
    name: "voucher-m",
    component: CustomerCoupons,
    meta: {
      title: "Vouchers",
    },
  },
  {
    path: "/customer/newsletter",
    name: "newsletter-m",
    component: CustomerNewsletter,
    meta: {
      title: "Newsletter",
    },
  },
  {
    path: "/customer/address/index",
    name: "addressbook-m",
    component: AddressBook,
    meta: {
      title: "Address Book",
    },
  },
  {
    path: "/customer/address/create",
    name: "addaddress-m",
    component: AddAddress,
    meta: {
      title: "Add Address",
    },
  },
  {
    path: "/customer/address/edit/:id",
    name: "editaddress-m",
    component: EditAddress,
    meta: {
      title: "Edit Address",
    },
  },
  {
    path: "/customer/reviews/index",
    name: "comments-m",
    component: CustomerComments,
    meta: {
      title: "Comments",
    },
  },
  // ------- Mobile Customer routes --------------
  {
    path: "/shops",
    name: "shops",
    component: Shops,
    abstract: true,
    meta: {
      requiresGuest: true,
      breadcrumb: "Shops",
      title: "Shops",
    },
  },
  {
    path: "/shop-view/:shop_id",
    name: "shop-view",
    component: ShopView,
    abstract: true,
    meta: {
      requiresGuest: true,
      breadcrumb: "Store",
      title: "Store",
    },
  },

  {
    path: "/payment-method",
    name: "paymethod",
    component: PayMethod,
    props: (route) => ({
      query: route.query.uuid,
      query2: route.query.when,
      query3: route.query.addr,
    }),
    meta: {
      breadcrumb: "Payment Method",
      title: "Payment Method",
    },
  },
  {
    path: "/payment",
    name: "payment",
    component: Payment,
    props: (route) => ({
      query: route.query.total,
    }),
    meta: {
      breadcrumb: "Payment",
      title: "Payment",
    },
  },
  {
    path: "/payment-info",
    name: "paymentInfo",
    component: PaymentInfo,
    meta: {
      breadcrumb: "Payment Info",
      title: "Payment Info",
    },
  },
  {
    path: "/shopping-cart",
    name: "cart",
    component: ShoppingCart,
    meta: {
      requiresAuth: true,
      breadcrumb: "Cart",
      title: "Cart",
    },
  },

  {
    path: "/customer/account/forgotPassword",
    name: "resetPassword",
    component: PasswordReset,
    meta: {
      requiresGuest: true,
      title: "Reset Password",
    },
  },
  {
    path: "/customer/account/recovery-information",
    name: "recoveryInfo",
    component: RecoveryInfo,
    meta: {
      requiresGuest: true,
      title: "Recover Password",
    },
  },
  {
    path: "/customer/account/register-success",
    name: "registerSuccess",
    component: RegisterSuccess,
    meta: {
      requiresGuest: true,
      title: "Register Success",
    },
  },
  {
    path: "/customer/restore-password/:token",
    name: "restorePassword",
    component: RestorePassword,
    props: (route) => ({
      query: route.query.email,
    }),
    meta: {
      requiresGuest: true,
      title: "Restore Password",
    },
  },
  {
    path: "/customer/terms-and-conditions",
    name: "terms-and-conditions",
    component: TermsConditions,
    meta: {
      requiresGuest: true,
      title: "Terms & Conditions",
    },
  },
  {
    path: "/deals",
    name: "deals",
    component: SuperDeals,
    meta: {
      requiresGuest: true,
      breadcrumb: "Super Deals",
      title: "Super Deals",
    },
  },
  {
    path: "/deals/:slug.html&pid=:prdtId",
    name: "deals-detail",
    component: SuperDealsDetail,
    // alias: "/deals/:slug.html/pid=:prdtId",
    meta: {
      breadcrumb: {
        label: "Deal Details",
        parent: "Super Deals",
      },
      title: "Deal Details",
    },
  },
  {
    path: "/new-products",
    name: "newProducts",
    component: NewProducts,

    meta: {
      requiresGuest: true,
      breadcrumb: "New Products",
      title: "New Products",
    },
  },
  {
    path: "/new-products/:slug.html&pid=:prdtId",
    name: "newproduct-detail",
    component: NewProductsDetail,
    meta: {
      breadcrumb: {
        label: "New Product Detail",
        parent: "Home",
      },
      title: "New Product Detail",
    },
  },
  {
    path: "/recommended/:slug.html?pid=:prdtId",
    name: "rec-detail",
    component: RecProductsDetail,
    meta: {
      breadcrumb: {
        label: "Recommended Product Detail",
        parent: "Home",
      },
      title: "Recommended Product Detail",
    },
  },
  {
    path: "/brands",
    name: "brands",
    component: BrandsIndex,
    meta: {
      breadcrumb: {
        label: "Afeshop Brands",
        parent: "Home",
      },
      title: "Afeshop Brands",
    },
  },
  {
    path: "/search-results/:q",
    name: "search-results",
    component: SearchResults,
    props: (route) => ({
      query: route.query.products,
    }),
    meta: {
      breadcrumb: {
        label: "Search Results",
        parent: "Home",
      },
      title: "Search Results",
    },
  },
  {
    path: "/category/:parentSlug?/:childSlug/",
    name: "category",
    component: ProductCategory,
    props: (route) => ({
      query: route.query.parent,
      query2: route.query.child,
      query3: route.query.catId,
    }),
    meta: {
      breadcrumb: {
        label: "Product Category",
        parent: "Home",
      },
      title: "Product Category",
    },
  },

  {
    path: "/support",
    name: "support",
    component: Support,
    meta: {
      breadcrumb: {
        label: "Customer Support",
        parent: "Home",
      },
      title: "Customer Support",
    },
  },

  {
    path: "/ShopRegister",
    name: "ShopRegister",
    component: ShopRegister,
    meta: {
      requiresAuth: true,
      breadcrumb: "Shop Registration",
      title: "Shop Registration",
    },
  },
  {
    path: "/ShopLogin",
    name: "ShopLogin",
    component: ShopLogin,
    meta: {
      requiresAuth: true,
      breadcrumb: "Shop Login",
      title: "Shop Login",
    },
  },
  {
    path: "/afshop-intl/:short_name",
    name: "AfshopIntl",
    component: AfshopIntl,
    meta: {
      requiresAuth: true,
      breadcrumb: "Afeshop International",
      title: "Afeshop International",
    },
  },
  {
    path: "*",
    name: "notfound",
    component: NotFoundPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = !!TokenService.getToken();
  console.log("token loggedIn: " + loggedIn);

  window.scrollTo(0, 0);

  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    // if (to.matched.some((record) => record.meta.requiresAuth)) {
    // return next({
    //   path: "/customer",
    //   query: { redirect: to.fullPath },
    // });
    return next({ name: "signin" });
  } else {
    return next();
  }
});

const TITLE = "AFESHOP";
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || TITLE;
  });
  // NProgress.done();
});

export default router;
