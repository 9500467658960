<template>
  <div id="tabified" class="row py-4">
    <div class="col-12">
      <ul class="nav nav-tabs px-0" id="tabifier" role="tablist">
        <li
          class="nav-item"
          role="presentation"
          v-for="tab in tabList"
          :key="tab.name"
        >
          <a
            class="nav-link px-5"
            :class="[activeTab === tab.name ? 'active' : '']"
            :id="tabSlotName(tab.name)"
            data-toggle="tab"
            :href="'#' + tab.name"
            role="tab"
            :aria-controls="tab.name"
            aria-selected="true"
            @click="switchTab(tab.name)"
          >
            {{ tab.title }} ({{ tab.count }})
          </a>
        </li>
      </ul>
      <div class="tab-content" id="tabifierContent">
        <div
          class="tab-pane fade"
          :class="[activeTab == tab.name ? 'show active' : '']"
          :id="tab.name"
          role="tabpanel"
          :aria-labelledby="tab.name + '-tab'"
          v-for="tab in tabList"
          :key="tab.name + '-tab-content'"
        >
          <slot :name="tabPaneSlotName"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/customer/reviews/tabbed.css";

export default {
  props: {
    tabList: {
      type: Array,
    },
    counts: {
      type: Object,
    },
    initTab: {
      type: String,
    },
  },

  data() {
    return {
      activeTab: this.initTab,
    };
  },

  computed: {
    tabPaneSlotName() {
      return `${this.activeTab}-tab`;
    },
  },

  methods: {
    tabSlotName(tabName) {
      return `${tabName}-tab`;
    },

    switchTab(tabName) {
      this.activeTab = tabName;
    },
  },
};
</script>
