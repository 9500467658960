<template>
  <div class="container">
    <!-- Breadcrumb -->
    <v-breadcrumbs :items="routeItems" class="bc px-0 py-1">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <!-- Store banner Section -->
    <section>
      <div class="row">
        <div class="col-md-12">
          <!-- Image Store Banner -->
          <div class="card border-0 rounded-0">
            <img
              src="@/assets/dano-store.jpg"
              alt="dano store"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </section>

    <!-- Main Section -->
    <section>
      <div class="row">
        <div class="col-md-3">
          <div class="card border-0 rounded-0">
            <div class="card-body">
              <!-- Shop Categories -->
              <h6 class="text-uppercase">{{ $t("category") }}</h6>
              <ul id="cat-list" class="list-unstyled px-1">
                <button
                  type="button"
                  class="list-group-item-action py-2 px-2"
                  v-for="(category, index) in categoriesList"
                  :key="'brd-cat-' + index"
                >
                  {{ category }}
                </button>
              </ul>

              <!-- divider -->
              <div class="divider mt-5 mb-5 border-bottom border-bottom"></div>

              <!-- Afeshop International Filter -->
              <h6 class="text-uppercase font-weight-bold">
                {{ $t("AfeshopInternational") }}
              </h6>
              <div class="overseaCountries">
                <div
                  class="mt-2 mb-2 pl-2"
                  v-for="(country, index) in overseaCountries"
                  :key="'brd-intl-' + index"
                >
                  <div class="form-group">
                    <input type="radio" name="radio" :id="'country-' + index" />
                    <label class="radio" :for="'country-' + index"
                      ><span class="ml-1">{{ country }}</span></label
                    >
                  </div>
                </div>
              </div>

              <!-- divider -->
              <div class="divider mt-5 mb-5 border-bottom border-bottom"></div>

              <!-- Price Range -->
              <h6 class="text-uppercase">{{ $t("Price") }}</h6>
              <div class="price-filter-control">
                <div class="form-group">
                  <div class="row">
                    <div class="col">
                      <label for="price-min-control">{{ $t("From") }}:</label>
                      <input
                        type="number"
                        class="form-control pull-left mb-2"
                        value="50"
                        id="price-min-control"
                      />
                    </div>
                    <div class="col">
                      <label for="price-max-control">{{ $t("To") }}:</label>

                      <input
                        type="number"
                        class="form-control pull-right"
                        value="150"
                        id="price-max-control"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <!-- divider -->
              <div class="divider mt-5 mb-5 border-bottom border-bottom"></div>

              <!-- Discount Percentages -->
              <h6 class="text-uppercase">{{ $t("DiscountPercentage") }}</h6>
              <div id="discount-list" class="form-group">
                <input type="radio" name="radio" id="radio0" checked="true" />
                <label class="radio" for="radio0">{{ discounts[0] }} %</label>

                <div
                  v-for="(discount, index) in discounts.slice(
                    1,
                    discounts.length
                  )"
                  :key="'brd-dscnt-' + index"
                >
                  <input
                    type="radio"
                    name="radio"
                    :id="'radio' + (index + 1)"
                  />
                  <label class="radio" :for="'radio' + (index + 1)"
                    >{{ discount }} %</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="card shadow-sm border-0 rounded-0">
            <div class="card-body">
              <!-- Title & Sorting -->
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-between
                  border-bottom
                  pb-3
                "
              >
                <h4 class="font-weight-bolder">Dano Store</h4>

                <div class="d-flex align-items-center">
                  <span class="mr-2 my-auto">{{ $t("Sortby") }}:</span>
                  <select-picker
                    id="sortSelectPicker"
                    :dropdown-data="sortList"
                    placeholder="Sorting Criteria"
                    v-model="selectedCriteria"
                    @change="changeSelectSort"
                  />
                </div>
              </div>

              <!-- Subtitle & Reshapers -->
              <div
                class="
                  d-flex
                  align-items-center
                  justify-content-between
                  border-bottom
                  py-4
                "
              >
                <p class="font-weight-normal mb-0">
                  {{ deals.length }} {{ $t("productsfound") }}
                </p>

                <div class="d-flex">
                  <button
                    class="btn btn-default py-0 px-0"
                    @click.prevent="showGrid()"
                  >
                    <i class="fas fa-th fa-1x mr-4 text-primary"></i>
                  </button>
                  <button
                    class="btn btn-default py-0 px-0"
                    @click.prevent="showList()"
                  >
                    <i class="fas fa-list fa-1x mr-2"></i>
                  </button>
                </div>
              </div>

              <!-- Products Listing -->
              <div class="row" v-if="deals">
                <div
                  class="col-9 col-sm-6 col-md-4 col-lg-4 mx-auto"
                  v-for="product in deals"
                  :key="'brd-prdt-grid-' + product.id"
                  v-show="gridView"
                >
                  <!-- Grid View -->
                  <router-link
                    :to="{
                      name: 'deals-detail',
                      params: { prdtId: product.id },
                    }"
                    class="card border-0 prdtCard rounded-0 mx-0"
                  >
                    <div class="prdtImage">
                      <img
                        :src="require('@/assets/images/products/product-8.jpg')"
                        class="img-fluid p-2"
                      />
                    </div>

                    <div class="prdtDiscount" v-if="product.item_discount">
                      <strong class="my-auto">{{
                        product.item_discount.percentage_decrease | percent
                      }}</strong>
                      <p class="mb-0 mt-n1 text-center">{{ $t("OFF") }}</p>
                    </div>

                    <div
                      class="
                        card-footer
                        bg-transparent
                        rounded-0
                        border-0
                        px-2
                        flex-column
                        d-flex
                      "
                    >
                      <router-link
                        :to="{
                          name: 'deals-detail',
                          params: { prdtId: product.id },
                        }"
                      >
                        <h6 class="prdtName text-truncate text-capitalize">
                          {{ product.name }}
                        </h6>
                      </router-link>

                      <div v-if="product.item_discount">
                        <div class="item_detail">
                          <span
                            class="color-font priceTag mb-0 mt-1"
                            v-if="product.item_discount"
                          >
                            {{
                              newPrice(
                                product.price,
                                product.item_discount.percentage_decrease
                              ) | currency("XFA ")
                            }}
                          </span>
                        </div>
                        <div class="item_delete text-muted mb-0 mt-0">
                          <del class="delPrice">
                            <span class="text-uppercase">
                              {{ $t("rrp") }}:&nbsp;</span
                            >
                            <del class="rrp">{{
                              product.price | currency("XFA ")
                            }}</del>
                          </del>
                        </div>

                        <button
                          @click.prevent="add_to_cart(product.id)"
                          class="btn btn-block btn-orange-shine mt-auto mb-3"
                        >
                          {{ $t("AddToCart") }}
                        </button>
                      </div>
                      <div class="m" v-else>
                        <div class="item_detail mt-0 mb-7">
                          <span class="color-font priceTag mb-0 mt-1">
                            {{ product.price | currency("XFA ") }}
                          </span>
                        </div>

                        <button
                          @click.prevent="add_to_cart(product.id)"
                          class="btn btn-block btn-orange-shine mt-auto mb-3"
                        >
                          {{ $t("AddToCart") }}
                        </button>
                      </div>
                    </div>
                  </router-link>
                </div>

                <!-- List View -->
                <div
                  class="col-lg-12 col-md-6 col-sm-12 col-12 mx-auto"
                  v-for="product in deals"
                  :key="'brd-prdt-list' + product.id"
                  v-show="listView"
                >
                  <router-link
                    :to="{
                      name: 'deals-detail',
                      params: { prdtId: product.id },
                    }"
                    class="list-card card border-0 rounded-0"
                  >
                    <div class="row">
                      <div class="col-4 py-0">
                        <img
                          :src="
                            require('@/assets/images/products/product-8.jpg')
                          "
                          class="img-fluid prdtImage2 p-2"
                        />
                      </div>

                      <div class="col-8 px-sm-2">
                        <router-link
                          :to="{
                            name: 'deals-detail',
                            params: { prdtId: product.id },
                          }"
                        >
                          <h6 class="prdtName2 text-uppercase">
                            {{ product.name }}
                          </h6>
                        </router-link>

                        <div class="mt-5">
                          <p
                            v-if="product.item_discount"
                            class="font-15 text-muted mb-0 rrp2 text-uppercase"
                          >
                            {{ $t("rrp") }}:
                            <span>
                              <strike>{{
                                product.price | currency("XFA ")
                              }}</strike>
                            </span>
                          </p>
                          <p
                            class="color-font priceTag mb-0 mt-1"
                            v-if="product.item_discount"
                          >
                            {{
                              newPrice(
                                product.price,
                                product.item_discount.percentage_decrease
                              ) | currency("XFA ")
                            }}
                          </p>
                          <p
                            class="color-font priceTag mb-0 mt-1"
                            v-if="!product.item_discount"
                          >
                            {{ product.price | currency("XFA ") }}
                          </p>
                        </div>

                        <div
                          class="
                            mt-12
                            align-items-center
                            d-flex
                            justify-content-between
                          "
                        >
                          <p class="mb-0">{{ $t("shippingCostIncluded") }}</p>

                          <button
                            class="btn btn-orange-shine mr-6 px-10 shadow"
                          >
                            {{ $t("AddToCart") }}
                          </button>
                        </div>

                        <div class="prdtDiscount2" v-if="product.item_discount">
                          <strong class="my-auto">{{
                            product.item_discount.percentage_decrease | percent
                          }}</strong>
                          <p class="mb-0 mt-n1 text-center">{{ $t("OFF") }}</p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";

export default {
  components: {},

  data() {
    return {
      products: [],
      productInfo: null,
      disable: false,
      page: 1,
      last_page: 0,
      pageSize: 5,
      deals: [],
      categoriesList: [
        "Headphones",
        "Electronics",
        "Smart Watches",
        "Home Office & Tablets",
        "Computing",
        "Fashion",
        "Gaming",
        "Automobile",
        "Health & Beauty",
      ],
      overseaCountries: [
        "Cameroon",
        "Nigeria",
        "Benin",
        "France",
        "USA",
        "UK",
        "China",
      ],
      sortList: [
        "Alphabetical",
        "Newest",
        "Best Selling",
        "Price Ascending",
        "Price Descending",
      ],
      discounts: [5, 10, 15, 25, 50],
      selectedCriteria: "",
      rtext:
        " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam, ad, adipisicing elit. Quam, ad, adipisicing elit. Quam, ad!",
      gridView: true,
      listView: false,
      crumbs: [],
      routeItems: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Afeshop Brands",
          disabled: true,
          href: "/brands",
        },
      ],
    };
  },

  methods: {
    ...mapActions(["addToCart", "getGuestData", "getSuperDeals", "getOrders"]),

    newPrice(price, discount) {
      return price - discount * price;
    },

    changeSelectSort({ data, text }) {
      console.log("Change Select criteria: ", data);
      this.selectedCriteria = text;
    },

    showGrid() {
      if (this.gridView) {
        this.gridView = false;
        this.listView = true;
      } else {
        this.gridView = true;
        this.listView = false;
      }
    },

    showList() {
      if (this.listView) {
        this.gridView = true;
        this.listView = false;
      } else {
        this.gridView = false;
        this.listView = true;
      }
    },

    selected(crumb) {
      console.log(crumb);
      this.$router.push(crumb.path);
    },

    getDeals() {
      this.getSuperDeals().then(() => {
        console.log("superDeals: ", this.getSuperDealsObj);
      });
    },
  },

  computed: {
    ...mapGetters([
      "loggedIn",
      "getUserDetails",
      "getGuestDataObj",
      "getSuperDealsObj",
    ]),
    ...mapState(["superDeals", "cart_no"]),
  },

  created() {
    console.log("super deals: BRands: ", this.getSuperDealsObj);
  },

  beforeMount() {
    if (!this.getSuperDealsObj) {
      this.getDeals();
    } else {
      this.deals =
        this.superDeals_.current_deals.data.length > 0
          ? this.superDeals_.current_deals
          : this.getSuperDealsObj.current_deals;
    }
  },

  mounted() {
    $(".list-card.card").hover(
      function () {
        $(this).addClass("shadow").css("cursor", "pointer");
      },
      function () {
        $(this).removeClass("shadow");
      }
    );
  },
};
</script>

<style scoped>
input[type="radio"] {
  display: none;
}

input[type="radio"] + label:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 6px;
  margin-right: 3px;
  background-clip: content-box;
  border: 2px solid #bbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

input[type="radio"]:checked + label:before,
.overseaCountries input[type="radio"]:checked + label:before {
  background-color: var(--afprimary);
  border: 1.5px solid var(--afprimary);
}

.overseaCountries {
  height: 10em;
  overflow-y: auto;
}
.overseaCountries input[type="radio"] + label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 5px;
  margin-right: 3px;
  background-clip: content-box;
  border: 2px solid #bbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

label {
  display: flex;
  align-items: center;
}

a.btn:not(.md-button) {
  color: #f1921d !important;
}

a.btn.btn-update:hover {
  background: black !important;
}
.prdtCard {
  height: 23.8em;
  /* width: 350px; */
  height: 388px;
  display: block;
  position: relative;
  transition: all 0.2s linear;
}

.color-font {
  color: #f1921d !important;
}

a:hover > .prdtName,
a:hover > .prdtName2 {
  color: #ff8100 !important;
}

.flash-illus {
  width: 100px;
  margin-top: -1em;
  display: flex;
}

.prdtCard:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-color: #e0e0e0;
}

.prdtImage .img-fluid {
  width: 100%;
  height: 17.5em;
  object-fit: cover;
}

.prdtName {
  font-size: 15px;
  font-weight: normal;
}

.prdtName2 {
  font-size: 15px;
  font-weight: normal;
  width: 30em;
}

.item_detail {
  line-height: 20px;
  /* height: 20px; */
  /* margin-top: 15px; */
}

.item_delete {
  margin-top: 2px;
  margin-bottom: 4px;
  height: 28px;
}

.delPrice {
  display: inline-block;
  width: 100%;
  height: 28px;
  font-size: 12px;
  color: #999;
  text-decoration: none;
}

.priceTag {
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  line-height: 20px;
  height: 20px;
}

.rrp {
  display: inline-block;
  line-height: 14px;
}

.rrp2 {
  font-size: 0.9rem;
}

.prdtDiscount {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 10px;
  width: 50px;
  height: 50px;
  background-color: #ffa300;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  /* display: flex; */
}

.prdtDiscount2 {
  position: absolute;
  right: 26px;
  top: 10px;
  padding: 10px;
  width: 49px;
  height: 50px;
  background-color: #ffa300;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}

.prdtDiscount > strong {
  font-size: 15px;
}

.prdtDiscount > em {
  font-size: 12px;
}

.prdtDiscount > p {
  font-size: 11px;
}
a.card {
  color: black !important;
}

a > .prdtName,
a > .prdtName2 {
  color: black !important;
}

#cat-list {
  height: 10em;
  overflow-y: auto;
}

#discount-list {
  height: 9em;
  overflow-y: auto;
}

.bootstrap-select {
  width: 8.7rem !important;
}

.list-card.card.shadow {
  transition: box-shadow 1s !important;
}
</style>
