<template>
  <div class="row">
    <div class="col-md-12 py-0">
      <div class="card pane border-0 shadow-sm bg-white py-4 px-5">
        <!-- Header -->
        <div
          class="
            card-header
            border-0
            bg-transparent
            pl-2
            pb-2
            d-flex
            align-items-center
          "
        >
          <button @click="$router.go(-1)" class="btn btn-lg pl-0 text-dark">
            <i class="mdi mdi-arrow-left h5"></i>
          </button>
          <h4>{{ $t("stats") }}</h4>
        </div>

        <!-- Content -->
        <div id="statistics" class="row px-2">
          <div class="col-md-4">
            <div class="card bg-primary dashcard">
              <div class="d-flex align-items-center">
                <div class="px-2">
                  <router-link :to="{ name: 'purchases' }" class="text-white">
                    <i class="mdi mdi-alarm"></i>
                  </router-link>
                </div>
                <div class="my-3">
                  <h5 class="dashcard-title">{{ $t("PendingOrders") }}</h5>
                  <p class="float-right mb-0">
                    <span
                      ><h5>{{ pending_no | zeroPad }}</h5></span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card bg-orange dashcard">
              <div class="d-flex align-items-center">
                <div class="px-2">
                  <router-link :to="{ name: 'purchases' }" class="text-white">
                    <i class="mdi mdi-checkbox-marked-circle-outline"></i>
                  </router-link>
                </div>
                <div class="my-3">
                  <h5 class="dashcard-title">{{ $t("CompletedOrders") }}</h5>
                  <p class="float-right mb-0">
                    <span
                      ><h5>{{ completed_no | zeroPad }}</h5></span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card bg-red dashcard">
              <div class="d-flex align-items-center">
                <div class="px-2">
                  <router-link :to="{ name: 'purchases' }" class="text-white">
                    <i class="mdi mdi-close-circle-outline"></i>
                  </router-link>
                </div>
                <div class="my-3">
                  <h5 class="dashcard-title">{{ $t("AbortedOrders") }}</h5>
                  <p class="float-right mb-0">
                    <span
                      ><h5>{{ aborted_no | zeroPad }}</h5></span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Chart -->
        <div class="row">
          <div class="col-md-12">
            <line-chart :data="cartData"></line-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      newsletter: false,
      orders_no: 0,
      pending_no: 0,
      cart_no: 0,
      aborted_no: 0,
      completed_no: 0,
      orders_total: 0,

      orders: null,
      statistics: null,
      cartData: [],
    };
  },

  methods: {
    ...mapActions(["get{{ $t('stats') }}", "getOrderDetail"]),
  },

  computed: {
    ...mapState({ allOrders: "ordersList", cart: "cartItems", user: "user" }),
    ...mapGetters(["getCachedOrders", "getStored{{ $t('stats') }}"]),
  },

  created() {
    this.orders = this.orders_.data ? this.orders_.data : this.allOrders;

    console.log("statistics: ", this.orders);

    this.orders.forEach((order) => {
      this.orders_total += order.amount;
      this.orders_no = order.length;

      if (order.status == "pending") {
        this.pending_no += 1;
      }
      if (order.status == "card") {
        this.cart_no += 1;
      }
    });

    this.getStatistics(this.user_).then(() => {
      this.statistics = this.statistics_.data;
      console.log("User Statistics: ", this.statistics);

      // load orders with "cart" status
      var card = this.statistics.card;

      console.log("cart status", card);

      if (card) {
        this.statistics.card.forEach((order) => {
          console.log("card order: ", order);
          this.cartData.push({
            name: order.uuid,
            data: {
              amount: order.amount,
              date: order.created_at,
            },
          });
        });
      }

      /** GET ORDER DETAIL FOR ABOVE CODE */

      // this.getOrderDetail(card[2].uuid).then((response) => {
      //   console.log("stats response: ", response);
      // });

      console.log("cart data", this.cartData);
    });
  },

  mounted() {},
};
</script>

<style scoped>
a.btn > i {
  font-size: 1.3rem;
}
.btn.rnd {
  border-radius: 50%;
}

.btn.rnd:hover {
  background: #db9e3557;
}

.bg-dash-card {
  background-color: #efefef;
}

.text-dash-card {
  color: #020202e0;
}
.text-purchases {
  color: #a0ff07;
}
.text-comments {
  color: #a0ff07;
}
.text-vouchers {
  color: #a0ff07;
}
#statistics div.col-10 > h1 {
  font-size: 2rem !important;
}
#statistics div.col-10 > h1 > small {
  font-size: 1.3rem !important;
}
.dashcard {
  color: white !important;
  padding: 10px 10px;
}

.dashcard i {
  font-size: 2.5rem;
}

.bg-red {
  background: #be4933;
}

.bg-primary {
  background: #25abff !important;
}

.bg-orange {
  background: var(--afprimary);
}

.dashcard-title {
  font-size: 1.2rem;
}

a > i.mdi {
  color: whitesmoke;
}

.sub {
  display: block;
  line-height: 0.9;
  font-size: 1.2rem;
  letter-spacing: 0.05em;
}
.list-inline-item {
  margin-right: 4em !important;
}
li.list-inline-item:last-of-type {
  margin-right: 0 !important;
}

.dash-card-body {
  padding-bottom: 10px;
}
</style>
