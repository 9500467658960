import router from "@/router/index";
import { UserService } from "@/services/user";
import { OrderService } from "@/services/order";

const state = {
  userAddresses: null,
  infoMessages: null,
};

const mutations = {
  SET_ADDRESSES(state, payload) {
    state.userAddresses = payload;
    localStorage.setItem("user_addresses", JSON.stringify(payload));
    console.log("set addresses", state.userAddresses);

    if (payload.code === "ADDRESS_UPDATE") {
      console.log("Index addressbook: ", payload);
      state.infoMessages = {
        code: payload.code,
        message: payload.message,
      };
      router.push({ name: "addressbook" });
    }

    if (payload.code === "ADDRESS_ADDED") {
      console.log("Index added addressbook: ", payload);
      state.infoMessages = {
        code: payload.code,
        message: payload.message,
      };
      router.push({ name: "addressbook" });
    }
  },
};

const getters = {
  getStoredAddresses: (state) => state.userAddresses,

  addressUpdateSuccess: (state) => state.infoMessages,
};

const actions = {
  async addNewAddress({ commit }, payload) {
    try {
      const response = await UserService.addNewAddress(payload);

      console.log("Address Addition: ", response);
      commit("SET_ADDRESSES", response);

      return response;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async getAddresses({ commit }, payload) {
    try {
      const response = await UserService.getAddresses(payload);

      // console.log("Get Addresses index: ", response);
      commit("SET_ADDRESSES", response);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async updateAddress({ commit }, payload) {
    try {
      const response = await UserService.updateAddress(payload);

      commit("SET_ADDRESSES", response);

      console.log("Update Address index: ", response);

      return response;
    } catch (e) {
      console.warn("error update address", e);
      return false;
    }
  },

  async addressToOrders({}, payload) {
    try {
      const response = await OrderService.addressToOrders(payload);

      console.log("Address to Orders index: ", response);

      return response;
    } catch (e) {
      console.error("error addressToOrders address", e);
      return e;
    }
  },

  async deleteAddress({ commit }, payload) {
    try {
      const response = await UserService.deleteAddress(payload);

      console.log("Delete Address index: ", response);
      commit("SET_ADDRESSES", response);

      commit("updateSuccess", response);

      // router.push(router.history.current.query.redirect || '/user')
      return true;
    } catch (e) {
      console.warn("error delete address", e);
      return false;
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
