import apiService from "../main";

class EndPointError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const ItemService = {
  /**
   * Get Item Detail
   */
  getItemDetails: async function (payload) {
    const request = {
      method: "get",
      url: "/items/" + payload.id + "/show",
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Item detail service: ", response.data.data);

      return response.data.data;
    } catch (error) {
      throw new EndPointError(error.response.code, error.response.data.message);
    }
  },

  /**
   * Get all shops
   */
  getShops: async function () {
    const request = {
      method: "get",
      url: "/shops",
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Shops service: ", response.data.data);

      return response.data.data;
    } catch (error) {
      throw new EndPointError(error.response.status, error.response.data.msg);
    }
  },

  /**
   * Get Shop Detail
   */
  getShopDetails: async function (payload) {
    const request = {
      method: "get",
      url: "/shop/" + payload.id + "/items/",
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Shop detail service: ", response.data.data);

      return response.data.data;
    } catch (error) {
      throw new EndPointError(error.response.status, error.response.data.msg);
    }
  },

  /**
   * Get Item Category Info
   */
  getItemCategory: async function (payload) {
    const request = {
      method: "get",
      url: "/category/" + payload.category_id + "/show",
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Item category service: ", response.data.data);

      return response.data.data;
    } catch (error) {
      console.log("item category error:", error);
    }
  },

  /**
   * Get Shop Categories
   */
  getShopCategories: async function (payload) {
    const request = {
      method: "get",
      url: "/shop/" + payload.shop_id + "/category",
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Shop categories service: ", response.data.data);

      return response.data.data;
    } catch (error) {
      throw new EndPointError(error.response.status, error.response.data.msg);
    }
  },

  /**
   * Get Shop Categories
   */
  getFilteredShops: async function (category_id) {
    const request = {
      method: "get",
      url: "/shops?category_id=" + category_id + "&is_brand=true",
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Shop filtered category: ", response.data.data);

      return response.data.data;
    } catch (error) {
      console.log("shop filtered category: ", error);
      return error;
    }
  },

  /**
   * Filter items by Categories
   */
  filterCategories: async function (payload) {
    console.log("filter endpoint", payload);
    const request = {
      method: "get",
      url: "/category/" + payload.category_id + "/items/",
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Filter categories service: ", response.data.data);

      return response.data.data;
    } catch (error) {
      throw new EndPointError(error.response.status, error.response.data.msg);
    }
  },

  /**
   * Filter items by Feature
   */
  filterByFeature: async function (payload) {
    const request = {
      method: "post",
      url: "/items/filter/" + payload.category_id,
      params: {
        value: payload.value,
        key: payload.key,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Filter Feature service: ", response.data.data);

      return response.data.data;
    } catch (error) {
      throw new EndPointError(error.response.status, error.response.data.msg);
    }
  },

  /**
   * Search Items by range
   */
  searchItemByRange: async function (payload) {
    const request = {
      method: "get",
      url:
        "/items/search?min_amount=" +
        payload.minPrice +
        "&max_amount=" +
        payload.maxPrice,
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Search Item range service: ", response.data.data);

      return response.data.data;
    } catch (error) {
      console.log("search item range error:", error);
      return error;
    }
  },

  /**
   * Search Items by discount
   */
  searchItemByDiscount: async function (payload) {
    const request = {
      method: "get",
      url: "/items/search?percentage_decreases=" + payload.discount,
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Search Item discount service: ", response.data.data);

      return response.data.data;
    } catch (error) {
      console.log("search item discount error:", error);
      return error;
    }
  },

  /**
   * Search Items by features
   */
  searchItemByFeatures: async function (payload) {
    const request = {
      method: "get",
      url:
        "/items/search?values[]=" + payload.values + "&keys[]=" + payload.keys,
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Search Item features service: ", response.data.data);

      return response.data.data;
    } catch (error) {
      console.log("search item features error:", error);
      return error;
    }
  },

  /**
   * Search Items
   */
  singleSearchItem: async function (payload) {
    const request = {
      method: "get",
      url: "/items/search?query=" + payload.query,
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Search Item service: ", response.data.data);

      return response.data.data;
    } catch (error) {
      console.log("search item error:", error);
      return error;
    }
  },

  /**
   * Get International Products
   */
  getInternationalProducts: async function (payload) {
    const request = {
      method: "get",
      url: "/items/country/" + payload.short_name,
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("International Products service: ", response.data.data);

      return response.data.data;
    } catch (error) {
      throw new EndPointError(error.response.status, error.response.data.msg);
    }
  },

  /**
   * Get Registered Countries
   */
  getRegisteredCountries: async function () {
    const request = {
      method: "get",
      url: "/countries/all",
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Registered Countries service: ", response.data.data);

      return response.data.data;
    } catch (error) {
      throw new EndPointError(error.response.status, error.response.data.msg);
    }
  },

  /**
   * Add a comment
   **/
  addComment: async function (payload) {
    const request = {
      method: "post",
      url: "/items/" + payload.id + "/add/comment",
      data: {
        description: payload.description,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Added Comment: ", response.data);

      return response.data;
    } catch (error) {
      console.log("add comment error: ", error);
      return error;
    }
  },

  /**
   * Update comment
   **/
  updateComment: async function (payload) {
    const request = {
      method: "post",
      url: "/items/" + payload.id + "/update/comment",
      data: {
        description: payload.description,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Update Comment: ", response);

      return response.data;
    } catch (error) {
      throw new EndPointError(error.response.code, error.response.data.message);
      console.log(error);
    }
  },

  /**
   * Delete comment
   **/
  deleteComment: async function (payload) {
    const request = {
      method: "post",
      url: "/items/" + payload.id + "/delete/comment",
      data: {
        description: payload.description,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Deleted Comment: ", response);

      return response;
    } catch (error) {
      throw new EndPointError(error.response.code, error.response.data.message);
      console.log(error);
    }
  },

  /**
   * Add a rating
   **/
  setRate: async function (payload) {
    const request = {
      method: "post",
      url: "/items/" + payload.id + "/add/star",
      data: {
        rate: payload.rate,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Rating: ", response);

      return response;
    } catch (error) {
      throw new EndPointError(error.response.code, error.response.data.message);
      console.log(error);
    }
  },
};

export default ItemService;

export { ItemService, EndPointError };
