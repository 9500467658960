<template>
  <div>
    <!-- Welcome User -->
    <div class="mobile-userInfo" v-if="user">
      <p class="mb-0">{{ $t("Welcome") }}, {{ user.name.split(" ")[0] }}</p>
      <p class="mb-0">{{ user.email }}</p>
    </div>

    <!-- Total Orders Amount -->
    <div class="orders-total">
      <h6>{{ totalOrdersAmount | currency("XFA ") }}</h6>
    </div>

    <!-- Section Title -->
    <div class="bg-orange">
      <p class="mb-2 ml-2 text-uppercase font-12">
        {{ $t("MyAfeshopAccount") }}
      </p>
    </div>
    <div class="px-auto sideDash">
      <ul class="list-group px-0 shadow-sm w-100">
        <!-- <router-link :to="{ name: 'account-index' }">
          <li
            :class="[
              'list-group-item',
              'py-4',
              'list-group-item-action',
              'd-flex',
              'align-center',
              'border-0',
            ]"
            @click="close"
          >
            <i class="mdi mdi-account-outline h5 mb-0"></i>
            <span class="ml-3">My Afeshop Account</span>

            <div class="mobi-arrow">
              <i class="mdi mdi-chevron-right h4"></i>
            </div>
          </li>
        </router-link> -->
        <router-link :to="{ name: 'purchases-m' }">
          <li
            :class="[
              'list-group-item',
              'py-4',
              'list-group-item-action',
              'd-flex',
              'align-center',
              'border-0',
            ]"
            @click="close"
          >
            <i class="mdi mdi-arrow-down h5 mb-0"></i>
            <span class="ml-3">{{ $t("Orders") }}</span>

            <div class="mobi-arrow">
              <i class="mdi mdi-chevron-right h4"></i>
            </div>
          </li>
        </router-link>
        <router-link :to="{ name: 'statistics-m' }">
          <li
            :class="[
              'list-group-item',
              'py-4',
              'list-group-item-action',
              'd-flex',
              'align-center',
              'border-0',
            ]"
            @click="close"
          >
            <i class="mdi mdi-graph h5 mb-0"></i>
            <span class="ml-3">{{ $t("Statistics") }}</span>

            <div class="mobi-arrow">
              <i class="mdi mdi-chevron-right h4"></i>
            </div>
          </li>
        </router-link>

        <router-link :to="{ name: 'cart' }">
          <li
            :class="[
              'list-group-item',
              'py-4',
              'list-group-item-action',
              'd-flex',
              'align-center',
              'border-0',
            ]"
            @click="close"
          >
            <i class="mdi mdi-cart-outline h5 mb-0"></i>
            <span class="ml-3">{{ $t("MyShoppingCart") }}</span>

            <div class="mobi-arrow">
              <i class="mdi mdi-chevron-right h4"></i>
            </div>
          </li>
        </router-link>
        <router-link :to="{ name: 'comments-m' }">
          <li
            :class="[
              'list-group-item',
              'py-4',
              'list-group-item-action',
              this.$route.name === 'comments' ? 'active' : '',
              'd-flex',
              'align-center',
              'border-0',
            ]"
            @click="close"
          >
            <i class="mdi mdi-comment-multiple-outline h5 mb-0"></i>
            <span class="ml-3">{{ $t("Comments") }}</span>

            <div class="mobi-arrow">
              <i class="mdi mdi-chevron-right h4"></i>
            </div>
          </li>
        </router-link>
        <router-link :to="{ name: 'voucher-m' }">
          <li
            :class="[
              'list-group-item',
              'py-4',
              'list-group-item-action',
              'd-flex',
              'align-center',
              'border-0',
            ]"
            @click="close"
          >
            <i class="mdi mdi-ticket-percent-outline h5 mb-0"></i>
            <span class="ml-3">{{ $t("Vouchers") }}</span>

            <div class="mobi-arrow">
              <i class="mdi mdi-chevron-right h4"></i>
            </div>
          </li>
        </router-link>
      </ul>
    </div>

    <!-- Section Title -->
    <div class="bg-orange">
      <p class="mb-2 mt-4 ml-2 text-uppercase font-12">
        {{ $t("AccountSettings") }}
      </p>
    </div>
    <div class="px-auto sideDash">
      <ul class="list-group px-0 shadow-sm w-100">
        <router-link :to="{ name: 'account-edit-m' }">
          <li
            :class="[
              'list-group-item',
              'py-4',
              'list-group-item-action',
              'd-flex',
              'align-center',
              'border-0',
            ]"
            @click="close"
          >
            <span>{{ $t("Details") }}</span>

            <div class="mobi-arrow">
              <i class="mdi mdi-chevron-right h4"></i>
            </div>
          </li>
        </router-link>
        <router-link :to="{ name: 'addressbook-m' }">
          <li
            :class="[
              'list-group-item',
              'py-4',
              'list-group-item-action',
              'd-flex',
              'align-center',
              'border-0',
            ]"
            @click="close"
          >
            <span>{{ $t("AddressBook") }}</span>

            <div class="mobi-arrow">
              <i class="mdi mdi-chevron-right h4"></i>
            </div>
          </li>
        </router-link>
        <router-link :to="{ name: 'newsletter-m' }">
          <li
            :class="[
              'list-group-item',
              'py-4',
              'list-group-item-action',
              'd-flex',
              'align-center',
              'border-0',
            ]"
            @click="close"
          >
            <span>{{ $t("NewsletterPreferences") }}</span>

            <div class="mobi-arrow">
              <i class="mdi mdi-chevron-right h4"></i>
            </div>
          </li>
        </router-link>
      </ul>
    </div>

    <li
      class="
        py-2
        list-group-item
        border-0
        text-center
        list-unstyled
        bg-transparent
      "
    >
      <button
        @click.stop="
          logout();
          close();
        "
        class="btn btn-font-trans color-font"
      >
        {{ $t("LOGOUT") }}
      </button>
    </li>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    showAsAside: {
      type: Boolean,
      default: false,
      required: false,
    },

    isMobile: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  computed: {
    ...mapGetters(["getUserDetails"]),

    user() {
      return this.user_ ? this.user_ : this.getUserDetails;
    },

    totalOrdersAmount() {
      return this.orders_ ? this.orders_.data[0].amount : 0;
    },
  },

  methods: {
    ...mapActions(["logout"]),

    close() {
      this.$root.$emit("close_sidebar", false);
    },
  },

  created() {
    console.log("Mobile Custom Nav user: ", this.user_);
    console.log("Mobile Custom Nav orders: ", this.orders_);
  },
};
</script>

<style scoped>
.sideDash {
  flex: 1;
}
a {
  text-decoration: none !important;
}

.list-group-item > span {
  font-size: 1rem;
}

.list-group-item.active {
  background-color: #ededed !important;
  border-color: #ededed !important;
  color: rgba(0, 0, 0, 0.733);
  font-weight: bold;
}

.list-group-item:hover {
  font-weight: bold;
  font-size: 1.4rem;
  color: rgba(0, 0, 0, 0.637);
}

.toggled {
  margin-left: 0;
  /* position: absolute;
  z-index: 10; */
}

.mobi-arrow {
  right: 0;
  position: absolute;
  margin-right: 15px;
  float: right;
}

.mobile-userInfo {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
  background: var(--afprimary);
  color: white;

  padding: 15px;
}

.mobile-userInfo > p {
  font-family: "Barlow", sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.mobile-userInfo > p:nth-child(2) {
  margin-top: -5px;
  font-size: 13px !important;
}

.orders-total {
  background: white;
  margin-bottom: 15px;
  margin-left: -15px;
  margin-right: -15px;
  padding: 9px 15px 3px 15px;
  color: #193ce9;
}

.mobi-arrow > i.mdi-chevron-right {
  font-weight: bolder !important;
}
</style>
