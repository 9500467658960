<template>
  <div id="details" class="container py-5">
    <!-- Breadcrumb -->
    <v-breadcrumbs :items="routeItems" class="bc px-0 mx-n3 pt-0">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>

    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="var(--afprimary)"
      :height="height"
      :width="width"
      :transition="transition"
    ></loading>

    <div v-if="!loading">
      <div
        class="row mb-10 section card rounded-0 border-0 shadow-sm py-5 px-3"
      >
        <!-- Product Image Gallery -->
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-5 px-0 mb-5">
              <lingallery
                :width="width2"
                :height="height2"
                :items="images2"
                :responsive="true"
                baseColor="var(--afprimary)"
                accentColor="var(--afprimary)"
                :disableImageClick="true"
                :addons="{ enableLargeView: true }"
              />
            </div>
            <div class="col-md-7">
              <!-- product information section -->
              <div class="mb-5">
                <h5>{{ prdtName }}</h5>
                <p class="text-muted mb-1 font-15">
                  {{ description }}
                </p>
              </div>

              <!-- Super deals banner -->
              <div
                class="
                  d-flex
                  justify-content-between
                  align-items-center
                  text-capitalize
                  mb-0
                  py-2
                  px-3
                "
                :class="
                  compName == 'New Products'
                    ? 'banner2'
                    : compName == 'Recommended'
                    ? 'banner3'
                    : 'banner'
                "
                v-if="details.item_discount"
              >
                <h5 class="text-white my-0 text-uppercase">
                  {{ this.compName }} !!!
                </h5>

                <div class="text-right">
                  <p class="text-white mb-0 font-12">2 {{ $t("Soldout") }}</p>
                  <p class="text-white mb-0 font-12">
                    {{ $t("Endsin") }}
                    <span v-if="currentTime">
                      {{ `${"" + currentTime.days}` }} {{ $t("days") }}
                      {{ `${("0" + currentTime.hours).slice(-2)}` }}:{{
                        `${("0" + currentTime.minutes).slice(-2)}`
                      }}:{{ `${("0" + currentTime.seconds).slice(-2)}` }}
                    </span>
                  </p>
                </div>
              </div>

              <!-- Price section -->
              <div class="d-flex align-items-center mt-3 py-2">
                <p class="mr-10 mb-0">{{ $t("Price") }}:</p>
                <div class="d-flex align-items-center justify-content-between">
                  <h3 class="priceNum mr-3">
                    {{ price | currency("XFA ") }}
                  </h3>

                  <small
                    class="font-15 text-muted mr-1 text-uppercase"
                    v-if="details.item_discount"
                    >{{ $t("rrp") }}:
                    <strike>{{ rrp | currency("XFA ") }}</strike></small
                  >

                  <span
                    class="badge badge-danger mx-1 font-15"
                    v-if="details.item_discount"
                  >
                    <small>{{ discount | percent }} {{ $t("OFF") }}</small>
                  </span>
                </div>
              </div>

              <!-- Shop -->
              <div class="d-flex align-items-center mt-4 mb-5">
                <p class="mr-10 mb-0">{{ $t("Shop") }}:</p>
                <h6 class="text-capitalize mb-0">
                  {{ shopName }}
                  <small
                    ><router-link
                      :to="{
                        name: 'shop-view',
                        params: { shop_id: details.shop.id },
                      }"
                      >&nbsp;<i
                        class="fas fa-external-link-alt text-primary"
                      ></i></router-link
                  ></small>
                </h6>
              </div>

              <!-- Quantity -->
              <div class="d-flex align-items-center mt-8 mb-12">
                <p class="mr-5 mb-0">{{ $t("Quantity") }}:</p>
                <div id="quantity" class="number">
                  <span class="minus" @click="quantity"
                    ><i class="fas fa-minus"></i
                  ></span>
                  <input type="number" :value="qtty" min="1" :max="itemQtty" />
                  <span
                    class="plus"
                    @click="qtty >= itemQtty ? (qtty = itemQtty) : qtty++"
                    ><i class="fas fa-plus"></i
                  ></span>
                </div>
                <span class="text-success mx-2" v-if="qtty < itemQtty">{{
                  $t("InStock")
                }}</span>
                <span class="text-danger mx-2" v-if="qtty >= itemQtty">{{
                  $t("OutofStock")
                }}</span>
              </div>

              <button
                class="
                  mt-12
                  btn btn-lg
                  shadow
                  btn-block btn-orange
                  rounded-0
                  d-flex
                  justify-content-left
                  align-items-baseline
                  font-weight-bold
                "
                @click.prevent="addProduct"
              >
                <span class="font-16">
                  <i class="fas fa-cart-arrow-down"></i>
                </span>
                <span class="font-15 mx-auto pr-4">{{ $t("ADDTOCART") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <CustomerReview
        :rating_no="rating_no"
        :rating="rating"
        :reviews="reviews"
        :comments="comments"
      />

      <Recommended />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import Slick from "vue-slick";
import Recommended from "@/components/home/Recommended";
import CustomerReview from "@/components/customer/CustomerReview";
import StarRating from "vue-star-rating";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";

import countdown from "@/mixins/countdown";

import "@/assets/css/prdts-display/details.css";

import images from "./sample-images.json";

export default {
  props: {
    compName: {
      type: String,
    },
  },
  components: {
    Slick,
    Recommended,
    CustomerReview,
    StarRating,
    Loading,
  },

  mixins: [countdown],

  data() {
    return {
      details: null,
      qtty: 1,
      shopName: null,
      price: null,
      rrp: null,
      discount: null,
      rating: null,
      rating_no: null,
      reviews: null,
      description: null,
      itemQtty: null,
      prdtName: null,
      loading: false,
      transition: "fade",
      height: 80,
      width: 100,
      width2: 800,
      height2: 400,
      images2: [],
      sImages: images,
      images: [],
      sdKey: 0,

      routeItems: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: this.compName,
          disabled: false,
          href: "/deals",
        },
        {
          text: "Details",
          disabled: true,
          href: "./",
        },
      ],

      selected: null,
      disabled: true,
      currentTime: null,
      productInfo: null,
      item: null,
      comments: null,
      crumbs: [],
    };
  },
  methods: {
    ...mapActions(["addToCart", "getItemDetails", "getShopDetails"]),
    ...mapMutations({ setLastProduct: "SET_LAST_PRDT" }),

    addProduct() {
      this.productInfo = {
        id: this.details.id,
        product: this.details,
        quantity: this.qtty,
      };

      console.log("add product, ", this.productInfo);
      console.log("add product, ", this.details);
      this.addToCart(this.productInfo).then(() => {
        this.$router.push({ name: "cart" });
      });
    },

    openShop(shop_id) {},

    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      this.$refs.slick.reSlick();
    },

    setImage(image) {
      this.selected = image;

      console.log("image: ", this.selected);
    },

    quantity() {
      if (this.qtty <= 1) {
        this.qtty = 1;
      } else {
        this.qtty--;
      }
    },

    newPrice(price, discount) {
      return price - discount * price;
    },

    getProductDet() {
      this.getItemDetails({
        id: this.routerPid,
      }).then(() => {
        this.item = this.itemPack ? this.itemPack : this.getProduct;
        this.details = this.itemPack
          ? this.itemPack.item
          : this.getProduct.item;

        console.log("this item: ", this.item);
        console.log("this details: ", this.details);

        if (this.details) {
          this.prdtName = this.details.name;
          this.shopName = this.details.shop.name;
          this.images = this.details.images_url;
          // this.images = this.sImages;

          Object.values(this.images["normal_size"]).forEach((normal, i) => {
            let val = {};
            val["id"] = normal.id;
            val["src"] = normal.url;
            val["thumbnail"] = this.images["thumbs"][i].url;
            val["largeViewSrc"] = this.images["large_size"][i].url;
            this.images2.push(val);
          });

          console.log("Images gallery Superdeals: ", this.images2);

          this.routeItems[2].text = this.prdtName;

          if (this.details.item_discount) {
            this.discount = this.details.item_discount.percentage_decrease;
            this.price = this.details.item_discount.unit_price;
            this.rrp = this.details.unit_price;
          } else {
            this.price = this.details.unit_price;
          }

          this.description = this.details.description;
          this.itemQtty = this.details.quantity;
          this.reviews = this.item.comments_count;
          this.rating = this.item.average_rate ? this.item.average_rate : 0;
          this.rating_no = this.details.star;
          this.comments = this.item.comments;
          this.loading = false;
        }

        this.selected = require("@/assets/images/cuir.jpg");
        setTimeout(this.countdown, 1);
      });
    },
  },
  computed: {
    ...mapState({
      deals: "superDeals",
      prevProduct: "currentProduct",
      itemPack: "item",
    }),

    ...mapGetters(["getProduct", "getShop"]),

    async small() {
      return await this.images.normal_size;
    },

    async large() {
      return await this.images.large_size;
    },

    async thumb() {
      return await this.images.thumbs;
    },

    routerPid() {
      return this.lastProduct_
        ? this.lastProduct_.prdtId
        : this.$route.params.prdtId;
    },
  },
  created() {
    this.loading = true;

    this.setLastProduct({
      prdtId: this.$route.params.prdtId,
      slug: this.$route.params.slug,
    });

    // console.log("last product: ", this.lastProduct_);
    console.log("prodt pid: ", this.$route.query.pid);

    // this.getProductDet();
    // this.large = this.images.large_size;
    // this.thumb = this.images.thumbs;
  },

  mounted() {
    console.log("last product: ", this.lastProduct_);
    console.log("prodt pid: ", this.$route);
    this.getProductDet();
    console.log("Product id: ", this.$route.params.prdtId);
  },
};
</script>

<style scoped></style>
