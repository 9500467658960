<template>
  <div class="container mt-5">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="var(--afprimary)"
      :height="height"
      :width="width"
      :transition="transition"
      background-color="#000"
    ></loading>
    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6 px-0">
        <h4 class="color-font">{{ $t("Passwordresetassistance") }}</h4>
        <div class="" v-if="errorMsg">
          <p class="mb-0 mt-1 text-capitalize text-danger font-12">
            {{ errorMsg }}
          </p>
        </div>

        <div class="d-flex justify-content-left mt-5 pt-2">
          <span class="pr-3">
            <i class="mdi mdi-information-outline color-font"></i>
          </span>
          <p class="font-15">{{ $t("ResetMsg") }}</p>
        </div>

        <form @submit.prevent="submitForm">
          <div v-if="!resPhone">
            <div class="form-group">
              <md-field
                :class="[
                  'mt-5',
                  formTouched && $v.form.email.$error ? 'md-invalid' : '',
                ]"
              >
                <label for="emailOpt">{{ $t("enterEmailAddress") }}</label>
                <md-input
                  type="email"
                  name="emailOpt"
                  id="emailOpt"
                  v-model="$v.form.email.$model"
                ></md-input>
                <span class="mb-0 md-error">{{ $t("InvalidE-mail") }}</span>
              </md-field>
            </div>
          </div>

          <div class="form-group mt-5" v-else>
            <label>{{ $t("Telephone") }}</label>
            <vue-tel-input
              id="resPhoneNum"
              v-bind="bindProps"
              v-on:country-changed="countryChanged"
              v-model="$v.form.phoneNumber.$model"
              @validate="validatePhone"
            ></vue-tel-input>

            <div v-if="!validPhone" class="invalid-feedback">
              <p class="mb-0" v-if="!validPhone">{{ $t("invalidPhone") }}</p>
            </div>
          </div>

          <div class="form-group pt-3">
            <button
              type="submit"
              class="mt-5 btn btn-lg shadow btn-block btn-orange rounded-0"
            >
              <span class="font-15 mx-auto pr-4">{{ $t("ResetPass") }}</span>
            </button>

            <button
              class="
                mt-5
                btn btn-lg
                shadow
                btn-block btn-blue
                rounded-0
                d-flex
                justify-content-left
                align-items-baseline
              "
              @click.prevent="resPhone = !resPhone"
              v-if="resPhone"
            >
              <span class="font-16">
                <i class="fas fa-envelope"></i>
              </span>

              <span class="font-15 mx-auto pr-4">{{
                $t("RESETPASSWORDVIAE-MAIL")
              }}</span>
            </button>
          </div>

          <div class="text-center mt-4 py-3 mb-5">
            <a
              href="/customer/signin"
              class="
                btn btn-link
                py-1
                font-16 font-weight-bold font-12
                rounded-0
              "
              >{{ $t("LOGININSTEAD") }}</a
            >
          </div>
        </form>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  requiredIf,
  minLength,
} from "vuelidate/lib/validators";
import { mapActions, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      form: {
        email: "",
        country: "Cameroon",
        countryCode: "CM",
        phoneNumber: "",
        // phoneNumberNew: "",
        // password: "",
      },
      bindProps: {
        mode: "international",
        dynamicPlaceholder: true,
        required: true,
        enabledCountryCode: true,
        enabledFlags: true,
        autocomplete: "off",
        name: "telephone",
        maxLen: 25,
        onlyCountries: ["CM"],
        inputOptions: {
          showDialCode: true,
        },
        validCharactersOnly: true,
        wrapperClasses: ["form-group"],
        inputClasses: [
          "form-control",
          !this.validPhone ? "is-invalid" : "is-valid",
        ],
      },
      resPhone: false,
      validPhone: false,
      errors: false,
      formTouched: false,
      loading: false,
      height: 80,
      width: 100,
      transition: "fade",
      errorMsg: "",
    };
  },

  validations: {
    form: {
      email: {
        required,
        email,
      },
      phoneNumber: {
        required: !requiredIf("resWP"),
      },
      // phoneNumberNew: {
      //   required: true,
      // },
      // password: {
      //   required,
      //   minLength: minLength(6),
      // },
    },
  },

  methods: {
    ...mapActions(["forgotPassword", "checkEmail"]),
    ...mapMutations(["passwordRecovery"]),

    countryChanged(country) {
      this.country = country;
      this.countryCode = country.iso2;
    },

    submitForm() {
      this.$v.$touch();
      this.formTouched = this.$v.form.$anyDirty;
      this.errors = this.$v.form.$anyError;

      // this.loading = true;
      if (this.errors === false && this.formTouched === true) {
        this.loading = true;
        console.log("Recover1: ", this.form);
        let payload = {
          email: this.$v.form.$model.email,
          // phone: this.$v.form.$model.phoneNumberNew,
          // password: this.$v.form.$model.password,
        };

        this.forgotPassword(payload).then((response) => {
          this.loading = true;

          console.log("Response checkEmail: ", response);

          if (response.status === 200) {
            console.log("Response 200: ", response);

            this.loading = false;

            localStorage.setItem("passRecoverInfo", JSON.stringify(payload));

            this.passwordRecovery(response);
          } else {
            console.log("Response Error code: ", response.status);
            this.loading = false;
            console.log("Response error data: ", response.data);
            this.errorMsg = response.data.message;
          }
        });
      }
    },
    validatePhone({ number, isValid, country }) {
      if (isValid) {
        this.validPhone = !this.validPhone;
      }
    },
  },

  computed: {
    resWP() {
      return this.resPhone;
    },
  },
};
</script>

<style scoped>
.vue-tel-input {
  border-radius: 3px;
  display: flex;
  border-bottom: 1px solid #949494 !important;
  border: transparent;
  text-align: left;
  min-height: 44px;
  margin-top: -1.5em;
}
.mdi-information-outline::before {
  font-size: 1.7em;
}
a.btn-link:hover {
  background: #db9e3557;
}
#resPhoneNum + .invalid-feedback,
#newPhoneNum + .invalid-feedback {
  display: block !important ;
  width: 100%;
  margin-top: -0.75rem !important;
  font-size: 80%;
  color: #dc3545;
}
</style>
