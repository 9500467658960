<template>
  <form>
    <button
      class="btn btn-sm value-button"
      id="decrease"
      @click.prevent="decreaseValue()"
      title="Decrease Value"
    >
      <i class="mdi mdi-minus"></i>
    </button>
    <input type="number" id="number" v-model.number="quantity" readonly />
    <button
      class="btn btn-sm value-button"
      id="increase"
      @click.prevent="increaseValue()"
      title="Increase Value"
    >
      <i class="mdi mdi-plus"></i>
    </button>
  </form>
</template>

<script>
export default {
  props: {
    maxValue: {
      type: Number,
      required: true,
    },
    prevQty: {
      type: Number,
      required: true,
    },
    orderItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      quantity: this.prevQty,
      minValue: 1,
    };
  },

  watch: {
    quantity(value) {
      this.$emit("updateQty", { order: this.orderItem, nQty: value });
    },
  },

  methods: {
    increaseValue() {
      if (this.quantity < this.maxValue) {
        this.quantity++;
      }
    },

    decreaseValue() {
      console.log("decreasing: ", this.quantity);
      if (this.quantity > this.minValue) {
        this.quantity--;
      }
    },
  },
};
</script>

<style scoped>
.btn.value-button {
  padding: 0 2px;
  text-align: center;
  color: white;
  vertical-align: middle;
  /* font-size: 20px; */
  position: relative;
  top: -4px;
  background: var(--afprimary);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.value-button:hover {
  cursor: pointer;
}

form #decrease,
form #increase {
  margin-right: -4px;
  border-radius: 50%;
}

input#number {
  text-align: center;
  border: none;
  margin: 0px;
  width: 29px;
  height: 40px;
  font-size: 18px;
  font-weight: 500;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.value-button i.mdi {
  font-weight: 700;
}
</style>
