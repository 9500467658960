import Vue from "vue";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import device from "vue-device-detector";

import CountryFlag from "vue-country-flag";
import VueTelInput from "vue-tel-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import ApiService from "./services/api.js";
import TokenService from "./services/storage";
import appConfig from "./config";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.css";
import "vue-material/dist/theme/default.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import VModal from "vue-js-modal";
import Vue2Filters from "vue2-filters";
import ScrollLoader from "vue-scroll-loader";
import moment from "moment";
import Chartkick from "vue-chartkick";
import Chart from "chart.js";
import VueScrollableTabs from "vue-scrollable-tabs";
import VuePageTransition from "vue-page-transition";
import Lingallery from "lingallery";

import VueSocialauth from "vue-social-auth";
import "vue-bootstrap-selectpicker/dist/css/vue-bootstrap-selectpicker.min.css";
import SelectPicker from "vue-bootstrap-selectpicker";

Vue.use(VueSocialauth, {
  providers: {
    github: {
      clientId: "",
      redirectUri: "/auth/github/callback", // Your client app URL
    },
  },
});

import OwlCarousel from "v-owl-carousel";
// mixins
import user_ from "@/mixins/cached";
import orders_ from "@/mixins/cached";
import coupons_ from "@/mixins/cached";
import statistics_ from "@/mixins/cached";
import countries_ from "@/mixins/cached";
import guestData_ from "@/mixins/cached";
import superDeals_ from "@/mixins/cached";
import newProducts_ from "@/mixins/cached";
import recommended_ from "@/mixins/cached";
import events_ from "@/mixins/cached";
import shop_ from "@/mixins/cached";
import shopCats_ from "@/mixins/cached";
import shopList_ from "@/mixins/cached";
import addresses_ from "@/mixins/cached";
import searchWord_ from "@/mixins/cached";
import productDetails_ from "@/mixins/cached";
import intlProducts_ from "@/mixins/cached";
import statesData_ from "@/mixins/cached";
import lastProduct_ from "@/mixins/cached";

import cart_orders from "@/mixins/filterOrders";

import discountedPrice from "@/mixins/utils.js";
import changeLocale from "@/mixins/utils.js";
import getImageBrightness from "@/mixins/utils.js";
import minimumDiscount from "@/mixins/utils.js";
import check from "@/mixins/utils.js";
import minPrice_ from "@/mixins/utils.js";
import maxPrice_ from "@/mixins/utils.js";
import pendingOrders from "@/mixins/utils.js";
import closedOrders from "@/mixins/utils.js";
import completedOrders from "@/mixins/utils.js";
import updateRemoteCart from "@/mixins/utils.js";
import isEmail from "@/mixins/utils.js";
import getCountryObject from "@/mixins/utils.js";
import defaultImage from "@/mixins/utils.js";
import defaultSlideImage from "@/mixins/utils.js";
import getCurrentLanguage from "@/mixins/utils.js";
import filterValues from "@/mixins/utils.js";
import removeFilterValue from "@/mixins/utils.js";
import removeFilters from "@/mixins/utils.js";
import removeDuplicates from "@/mixins/utils.js";
import loadFeatures_ from "@/mixins/utils.js";
import loadColorFeatures_ from "@/mixins/utils.js";
import loadCategories_ from "@/mixins/utils.js";
import loadPrdtPrices_ from "@/mixins/utils.js";
import minPrice from "@/mixins/utils.js";
import maxPrice from "@/mixins/utils.js";
import categoryLoading from "@/mixins/utils.js";

Vue.mixin(guestData_);
Vue.mixin(superDeals_);
Vue.mixin(newProducts_);
Vue.mixin(recommended_);
Vue.mixin(user_);
Vue.mixin(addresses_);
Vue.mixin(orders_);
Vue.mixin(coupons_);
Vue.mixin(statistics_);
Vue.mixin(countries_);
Vue.mixin(events_);
Vue.mixin(shop_);
Vue.mixin(shopCats_);
Vue.mixin(shopList_);
Vue.mixin(searchWord_);
Vue.mixin(productDetails_);
Vue.mixin(intlProducts_);
Vue.mixin(statesData_);
Vue.mixin(discountedPrice);
Vue.mixin(changeLocale);
Vue.mixin(getImageBrightness);
Vue.mixin(minimumDiscount);
Vue.mixin(check);
Vue.mixin(minPrice_);
Vue.mixin(maxPrice_);
Vue.mixin(pendingOrders);
Vue.mixin(closedOrders);
Vue.mixin(completedOrders);
Vue.mixin(lastProduct_);
Vue.mixin(updateRemoteCart);
Vue.mixin(isEmail);
Vue.mixin(getCountryObject);
Vue.mixin(defaultImage);
Vue.mixin(defaultSlideImage);
Vue.mixin(getCurrentLanguage);
Vue.mixin(filterValues);
Vue.mixin(removeFilterValue);
Vue.mixin(removeFilters);
Vue.mixin(removeDuplicates);
Vue.mixin(loadFeatures_);
Vue.mixin(loadColorFeatures_);
Vue.mixin(loadCategories_);
Vue.mixin(loadPrdtPrices_);
Vue.mixin(minPrice);
Vue.mixin(maxPrice);
Vue.mixin(categoryLoading);

Vue.mixin(cart_orders);

Vue.use(VuePageTransition);
Vue.use(VueMaterial);
Vue.use(Vuelidate);
Vue.use(Vue2Filters);
Vue.use(ScrollLoader);
Vue.use(Chartkick.use(Chart));
Vue.component("country-flag", CountryFlag);
Vue.use(VueScrollableTabs);
Vue.component("lingallery", Lingallery);
Vue.component("carousel", OwlCarousel);
Vue.use(SelectPicker);
Vue.use(device);

export const bus = new Vue();
// Vue.http.headers.common["Access-Control-Allow-Origin"] = true;

Vue.use(appConfig);
// Vue.use(vueCountryRegionSelect);
Vue.use(VModal);

Vue.prototype.$http = axios;

Vue.config.productionTip = true;

// Set the base URL of the API
let apiService = new ApiService();
apiService.setBaseURL(appConfig.config.apiBaseUrl);
console.log("production mode: ", process.env.NODE_ENV);
console.log("base url: ", process.env.VUE_APP_BASE_URL);

// if token exists set header
if (TokenService.getToken()) {
}
export default apiService;

Vue.use(VueTelInput);

Vue.filter("truncate", function (text, length, suffix) {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  } else {
    return text;
  }
});

Vue.filter("zeroPad", function (number) {
  return number < 10 ? "0" + number : number;
});

Vue.filter("formatDateComments", (rawDate) => {
  return moment(String(rawDate)).format("YYYY-MM-DD");
});

Vue.filter("fromNow", (rawDate) => {
  return moment(String(rawDate)).fromNow();
});

Vue.filter("dateOn", (rawDate) => {
  return moment(String(rawDate)).format("LL");
});

Vue.filter("generateArrayOfYears", () => {
  var min = new Date().getFullYear();
  var max = max + 20;
  var years = [];

  for (var i = min; i <= max; i++) {
    years.push(i);
  }
  return years;
});

Vue.filter("decimalPoint", (value) => {
  return parseFloat(value).toFixed(2);
});

Vue.filter("pluralize", (word, amount) =>
  amount > 1 || amount === 0 ? `${word}s` : word
);

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
