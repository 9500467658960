<template>
  <div class="container py-5 my-5">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="var(--afprimary)"
      :height="height"
      :width="width"
      :transition="transition"
      background-color="#000"
    ></loading>

    <div class="row">
      <div class="col-md-3"></div>
      <div class="col-md-6 px-0">
        <h4 class="color-font">{{ $t("SetNewPassword") }}</h4>
        <div class="" v-if="errorMsg">
          <p class="mb-0 mt-1 text-capitalize text-danger font-12">
            {{ errorMsg }}
          </p>
        </div>

        <p class="pt-4 font-15 pb-2">
          {{ $t("enterNewPasswordFor") }}
          <span class="text-primary">{{ email }}</span>
        </p>

        <form @submit.prevent="submitForm" class="pb-3">
          <div class="form-group mb-5">
            <md-field
              :class="['mt-5', $v.form.password.$invalid ? 'md-invalid' : '']"
            >
              <label for="password">{{ $t("NewPassword") }}</label>
              <md-input
                type="password"
                name="password"
                id="password"
                required
                v-model="$v.form.password.$model"
              ></md-input>
              <span class="mb-0 md-error" v-if="!$v.form.password.required">{{
                $t("passwordRequired")
              }}</span>
              <span class="mb-0 md-error" v-if="!$v.form.password.minLength">{{
                $t("passwordAtLeast6")
              }}</span>
            </md-field>
          </div>

          <div class="form-group">
            <md-field
              :class="[
                formTouched && $v.form.confirmPassword.$error
                  ? 'md-invalid'
                  : '',
              ]"
            >
              <label for="confirmPassword">{{ $t("ConfirmPassword") }}</label>
              <md-input
                name="confirmPassword"
                type="password"
                id="confirmPassword"
                required
                v-model="$v.form.confirmPassword.$model"
              ></md-input>
              <div v-if="$v.form.confirmPassword.$error">
                <span
                  class="mb-0 md-error"
                  v-if="!$v.form.confirmPassword.sameAsPassword"
                  >{{ $t("passwordsNotSame") }}</span
                >
              </div>
            </md-field>
          </div>

          <div class="form-group pt-3">
            <button
              type="submit"
              class="mt-5 btn btn-lg shadow btn-block btn-orange rounded-0"
            >
              <span class="font-15 mx-auto pr-4">{{
                $t("SETNEWPASSWORD")
              }}</span>
            </button>
          </div>
        </form>
      </div>
      <div class="col-md-3"></div>
    </div>
  </div>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { mapActions } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PopDown from "@/components/widgets/PopDown";

export default {
  components: {
    Loading,
    PopDown,
    PopDown,
  },

  data() {
    return {
      userEmail: "",
      formTouched: false,
      errors: false,
      loading: false,
      height: 80,
      width: 100,
      transition: "fade",
      errorMsg: "",
      validPhone: false,
      token: "",
      email: "",

      happy: true,
      info: "",

      form: {
        password: "",
        confirmPassword: "",
      },

      bindProps: {
        mode: "international",
        dynamicPlaceholder: true,
        required: true,
        enabledCountryCode: true,
        enabledFlags: true,
        autocomplete: "off",
        name: "telephone",
        maxLen: 25,
        onlyCountries: ["CM"],
        inputOptions: {
          showDialCode: true,
        },
        validCharactersOnly: true,
        wrapperClasses: ["form-group"],
        inputClasses: [
          "form-control",
          !this.validPhone ? "is-invalid" : "is-valid",
        ],
      },
    };
  },

  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },

  methods: {
    ...mapActions(["setNewPassword"]),

    validatePhone({ number, isValid, country }) {
      if (isValid) {
        this.validPhone = !this.validPhone;
      }
    },

    countryChanged(country) {
      this.country = country;
      this.countryCode = country.iso2;
    },

    showInfoMsg() {
      if (this.info) {
        this.$root.$emit("infoMsg", {
          type: "success",
          message: this.info,
          alert: "alert-success",
        });
      }
    },

    submitForm() {
      this.$v.$touch();
      this.formTouched = this.$v.form.$anyDirty;
      this.errors = this.$v.form.$anyError;

      if (this.errors === false && this.formTouched === true) {
        this.loading = true;
        this.setNewPassword({
          token: this.token,
          email: this.email,
          password: this.$v.form.$model.password,
          confirm_password: this.$v.form.$model.confirmPassword,
        }).then((response) => {
          console.log("setNewPassword response:", response);

          if (response.code == "PASSWORD_RESET") {
            this.loading = false;
            console.log("setNewPassword 200:", response);

            this.$root.$emit("infoMsg", {
              type: "success",
              message: response.message,
              alert: "alert-success",
            });

            this.$router.push({ name: "signin" });
          } else {
            this.loading = false;
            this.errorMsg = response.data.message;
            console.log("setNewPassword error code: ", this.errorMsg);
          }
        });
      }
    },
  },

  created() {
    if (localStorage.getItem("passRecoverInfo")) {
      this.userEmail = JSON.parse(
        localStorage.getItem("passRecoverInfo")
      ).email;
    }

    if (this.$route.params.token || this.$route.query.email) {
      this.email = this.$route.query.email;
      this.token = this.$route.params.token;

      console.log("token restore password", this.token);
      console.log("email route: ", this.email);
    }
  },

  mounted() {},
};
</script>

<style scoped>
.vue-tel-input {
  border-radius: 3px;
  display: flex;
  border-bottom: 1px solid #949494 !important;
  border: transparent;
  text-align: left;
  min-height: 44px;
  margin-top: -1.5em;
}
#restorePhone + .invalid-feedback {
  display: block !important ;
  width: 100%;
  margin-top: -0.75rem !important;
  font-size: 80%;
  color: #dc3545;
}
</style>
