<template>
  <div id="mainhead" class="main-header sticky shadow-sm mb-2 border">
    <!-- Desktop Nav -->
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="var(--afprimary)"
      :height="height"
      :width="width"
      :transition="transition"
      background-color="#000"
    ></loading>
    <div
      class="
        container
        desktopNav
        d-none d-sm-none d-md-block d-lg-block d-xl-block
      "
    >
      <div class="row">
        <div
          class="
            col-4 col-xl-2 col-md-12 col-sm-12 col-lg-3
            logo-holder
            pl-0
            py-2
            pr-3
          "
        >
          <div class="logo">
            <a title="Afeshop Cameroon" @click.stop="$router.push('/')">
              <img
                src="@/assets/logo_afeshop.png"
                alt="Afeshop Logo"
                class="img-fluid"
              />
            </a>
          </div>
        </div>

        <div
          class="
            col-xl-5 col-lg-4 col-md-12 col-sm-12 col-12
            top-search-holder
            py-2
          "
        >
          <div class="search-area">
            <form id="searchform">
              <div class="control-group">
                <input
                  type="text"
                  ref="searchfield"
                  class="search-field"
                  name="search"
                  id="search"
                  :placeholder="$t('searchPrdt')"
                  v-model="searchWord"
                  autocomplete="off"
                  v-on-clickaway="away"
                />
                <!-- @keydown="presentRslts(searchWord ? searchWord : 'none')" -->
                <!-- @focus="
                    showTempList = true;
                    presentRslts(searchWord ? searchWord : 'none');
                  " -->
                <button
                  type="submit"
                  class="search-button"
                  @click.prevent="filterProducts(searchWord)"
                >
                  <i class="fas fa-search"></i>
                </button>
                <input type="hidden" name="post_type" value="product" />
              </div>
            </form>
          </div>
          <!-- 
          <div
            class="card bg-white shadow-sm border-0 rounded"
            v-show="showTempList"
            id="searchList"
          >
            <div class="list-group temp-list" v-if="tempList">
              <a
                @click.prevent="filterProducts(item.name)"
                class="
                  list-group-item list-group-item-action
                  d-flex
                  justify-content-between
                "
                v-for="(item, index) in tempList"
                :key="index"
              >
                <span class="text-truncate">{{ item.name }}</span>
                <i class="mdi mdi-magnify"></i>
              </a>
            </div>
          </div> -->
        </div>

        <div
          class="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 py-2 right-nav"
          :class="[
            languageFlag() === 'fr'
              ? 'px-0'
              : [
                  'px-lg-auto',
                  'px-xl-auto',
                  'px-md-auto',
                  'px-sm-auto',
                  'pl-auto',
                  'pr-0',
                ],
          ]"
        >
          <div
            class="navbar navbar-expand-lg navbar-light float-left px-0 my-3"
          >
            <button
              class="btn-navbar navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#horizontalMenu"
              aria-controls="horizontalMenu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i class="fas fa-bars"></i>
            </button>

            <div class="collapse navbar-collapse" id="horizontalMenu">
              <ul class="navbar-nav m-0 ml-lg-auto p-3 p-lg-0">
                <li class="my-n3 close1">
                  <button
                    data-toggle="collapse"
                    data-target="#horizontalMenu"
                    class="close float-right my-0"
                  >
                    &times;
                  </button>
                </li>

                <li
                  class="nav-item dropdown"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  :title="$t('latestDeals')"
                >
                  <a
                    class="nav-link dropdown-toggle"
                    href="/#"
                    @click.prevent
                    id="dealsDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ $t("deals") | truncate("6", "...") }}
                  </a>

                  <div class="dropdown-menu" aria-labelledby="dealsDropdown">
                    <a class="dropdown-item" href="/deals#current">{{
                      $t("currentDeals")
                    }}</a>
                    <a class="dropdown-item" href="/deals#coming">{{
                      $t("comingDeals")
                    }}</a>
                  </div>
                </li>

                <li
                  class="nav-item"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  :title="$t('newPrdts')"
                >
                  <a class="nav-link" href="/new-products">{{
                    $t("new") | truncate("6", "...")
                  }}</a>
                </li>

                <li
                  class="nav-item"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  :title="$t('offers')"
                >
                  <a class="nav-link" href="/#" @click.prevent>{{
                    $t("offers") | truncate("6", "...")
                  }}</a>
                </li>

                <li
                  class="nav-item"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  :title="$t('stores')"
                >
                  <a class="nav-link" href="/shops">{{
                    $t("stores") | truncate("6", "...")
                  }}</a>
                </li>
              </ul>
            </div>
          </div>

          <div class="top-cart-row py-2">
            <div class="dropdown dropdown-cart">
              <a
                href="/#"
                @click.prevent
                class="dropdown-toggle lnk-cart submenu-open"
                data-toggle="dropdown"
              >
                <div class="items-cart-inner">
                  <div class="basket">
                    <i class="fas fa-shopping-cart"></i>
                  </div>
                  <div class="basket-item-count">
                    <span class="cartcount" v-if="loggedIn">
                      {{ noCartItems > 9 ? "9+" : noCartItems }}
                    </span>
                    <span class="cartcount" v-if="!loggedIn"> 0 </span>
                  </div>
                  <div class="total-price-basket tpb1">
                    <span class="lbl">{{ $t("cart") }}</span>
                  </div>
                </div>
              </a>

              <div class="dropdown-menu" v-show="loggedIn">
                <div class="fl-mini-cart-content" v-if="cartOrders.length > 0">
                  <ul class="cart_list product_list_widget list-unstyled pl-0">
                    <li
                      class="mini_cart_item"
                      v-for="order in cartOrders[0].order_item"
                      :key="order.id"
                    >
                      <div class="cart-item product-summary">
                        <div class="row">
                          <div class="col-4 pr-2 pb-0">
                            <div class="image">
                              <a href="/#" @click.prevent>
                                <img
                                  width="90"
                                  height="90"
                                  :src="order.item.image_url"
                                  class="img-fluid py-1"
                                  :alt="order.item.name"
                                />
                              </a>
                            </div>
                          </div>
                          <div class="col-7 px-1 pb-0">
                            <h5 class="name text-truncate">
                              <a href="/shopping-cart">{{ order.item.name }}</a>
                            </h5>
                            <div class="price">
                              <span class="quantity"
                                >{{ order.quantity }} ×
                                <span
                                  class="woocommerce-Price-amount amount"
                                  v-if="order.item.item_discount"
                                  >{{
                                    order.item.item_discount.unit_price
                                      | currency("XFA")
                                  }}</span
                                >
                                <span
                                  class="woocommerce-Price-amount amount"
                                  v-else
                                  >{{
                                    order.item.unit_price | currency("XFA")
                                  }}</span
                                >
                              </span>
                            </div>
                          </div>
                          <div class="col-1 action px-2">
                            <a
                              href="https://klbtheme.com/dealsdot/cart/?remove_item=854d6fae5ee42911677c739ee1734486&amp;_wpnonce=62fbc02965"
                              class="remove remove_from_cart_button"
                              aria-label="Remove this item"
                              data-product_id="202"
                              ><i class="fa fa-trash"></i
                            ></a>
                          </div>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <hr class="mx-0" />
                    </li>
                  </ul>
                  <div class="clearfix cart-total">
                    <div class="float-right">
                      <p class="woocommerce-mini-cart__total total">
                        <strong>{{ $t("Subtotal") }}:</strong>
                        <span class="woocommerce-Price-amount amount">{{
                          cartOrders[0].amount | currency("XFA")
                        }}</span>
                      </p>
                    </div>

                    <div class="clearfix"></div>

                    <p class="woocommerce-mini-cart__buttons buttons">
                      <a
                        @click.stop="$router.push('/shopping-cart')"
                        class="button wc-forward"
                        >{{ $t("Viewcart") }}</a
                      ><a
                        @click.stop="$router.push('/payment')"
                        class="button checkout wc-forward"
                        >{{ $t("checkout") }}</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile Nav -->
    <div
      class="
        container
        mobileNav
        pl-2
        pr-0
        d-block d-sm-block d-md-none d-lg-none d-xl-none
      "
    >
      <div class="row">
        <div class="col-12">
          <div class="row px-3 py-0 mt-2">
            <!-- Navbar drawer -->

            <div class="col-2 py-0">
              <div
                class="
                  navbar navbar-expand-lg navbar-light
                  float-left
                  px-0
                  my-0
                "
              >
                <button
                  class="btn-navbar navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#horizontalMenu"
                  aria-controls="horizontalMenu"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <i class="fas fa-bars"></i>
                </button>

                <div class="collapse navbar-collapse" id="horizontalMenu">
                  <ul class="navbar-nav m-0 ml-lg-auto pl-0 py-0 p p-lg-0">
                    <li class="">
                      <div class="drawer-header">
                        <button
                          data-toggle="collapse"
                          data-target="#horizontalMenu"
                          class="close float-right my-0 mr-3 mt-n2"
                        >
                          &times;
                        </button>

                        <div class="pt-7">
                          <div class="logo">
                            <a
                              title="Afeshop Cameroon"
                              @click.stop="$router.push('/')"
                            >
                              <img
                                src="@/assets/logo_afeshop.png"
                                alt="Afeshop Logo"
                                class="img-fluid"
                                style="height: 60px"
                              />
                            </a>
                          </div>

                          <a
                            class="text-capitalize d-block"
                            v-if="loggedIn && user_"
                          >
                            {{ $t("hi") }}
                            {{ user_.username | truncate(15, "...") }}
                          </a>

                          <button
                            class="btn btn-lg btn-orange mt-3"
                            @click="$router.push({ name: 'signin' })"
                            v-if="!loggedIn"
                          >
                            {{ $t("signIn") }} / {{ $t("register") }}
                          </button>
                          <button
                            class="btn btn-lg btn-warning mt-3"
                            @click.prevent.stop="logout()"
                            v-else
                          >
                            {{ $t("logout") }}
                          </button>
                        </div>
                      </div>
                    </li>

                    <!-- Home -->
                    <li class="nav-item">
                      <a class="nav-link" href="/#"
                        ><i class="fas fa-home"></i
                        ><span class="ml-2">{{ $t("home") }}</span></a
                      >
                    </li>

                    <!-- Categories -->
                    <MegaCategoryList
                      :categories="megaCatgories"
                      class="pr-4"
                    />

                    <!-- Deals -->
                    <li class="vertical_menu nav-item pt-1 pr-4 dropdown">
                      <a
                        class="dropdown-toggle"
                        href="/#"
                        @click.prevent
                        id="miniDealsDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fas fa-bolt font-15 mr-1 ml-1"></i>
                        <span class="ml-2 px-0">{{ $t("deals") }}</span>
                        <span class="float-right"
                          ><i class="fas fa-angle-down"></i>
                        </span>
                      </a>

                      <div
                        class="dropdown-menu bg-light pl-2"
                        aria-labelledby="miniDealsDropdown"
                      >
                        <a class="dropdown-item" href="/deals#current">{{
                          $t("CurrentDeals")
                        }}</a>
                        <a class="dropdown-item" href="/deals#coming">{{
                          $t("ComingDeals")
                        }}</a>
                      </div>
                    </li>

                    <!-- New products -->
                    <li class="nav-item pr-4">
                      <a class="nav-link" href="/new-products"
                        ><i class="fas fa-star"></i
                        ><span class="ml-2">{{ $t("new") }}</span></a
                      >
                    </li>

                    <!-- Offers -->
                    <li class="nav-item pr-4">
                      <a class="nav-link" href="/#" @click.prevent
                        ><i class="fas fa-gift"></i
                        ><span class="ml-2">{{ $t("offers") }}</span></a
                      >
                    </li>

                    <!-- Shops -->
                    <li class="nav-item pr-4 d-inline d-md-none d-lg-none">
                      <a class="nav-link" href="/shops"
                        ><i class="fas fa-store font-12"></i
                        ><span class="ml-2">{{ $t("stores") }}</span></a
                      >
                    </li>

                    <hr class="my-1" />

                    <!-- User Account -->
                    <li class="nav-item pr-4">
                      <router-link
                        :to="{ name: 'account-index' }"
                        class="color-font font-weight-bolder"
                        @click.prevent
                        v-if="loggedIn"
                        ><span class="font-12">{{ $t("myAccount") }}</span>
                        <span class="float-right"
                          ><i class="mdi mdi-chevron-right"></i></span
                      ></router-link>
                    </li>

                    <li class="nav-item mt- pr-4">
                      <router-link
                        :to="{ name: 'purchases-m' }"
                        @click.prevent
                        v-if="loggedIn"
                        class="d-flex align-items-center"
                        ><i class="mdi mdi-arrow-down font-15"></i>
                        <span class="ml-2">{{ $t("Orders") }}</span>
                      </router-link>
                    </li>

                    <li class="nav-item mt-0 pr-4">
                      <router-link
                        :to="{ name: 'comments-m' }"
                        @click.prevent
                        v-if="loggedIn"
                        class="d-flex align-items-center"
                        ><i class="mdi mdi-comment-multiple font-15"></i>
                        <span class="ml-2">{{ $t("Comments") }}</span>
                      </router-link>
                    </li>

                    <li class="nav-item mt-0 pr-4">
                      <router-link
                        :to="{ name: 'voucher-m' }"
                        @click.prevent
                        v-if="loggedIn"
                        class="d-flex align-items-center"
                        ><i class="mdi mdi-ticket-percent font-15"></i>
                        <span class="ml-2">{{ $t("Vouchers") }}</span>
                      </router-link>
                    </li>

                    <hr class="my-1" />

                    <!-- Support Center -->
                    <li class="nav-item pr-4">
                      <a class="nav-link" href="/#" @click.prevent>{{
                        $t("supportCenter")
                      }}</a>
                    </li>

                    <!-- Seller Center -->
                    <li class="nav-item pr-4">
                      <a class="nav-link" href="/#" @click.prevent>{{
                        $t("sellerCenter")
                      }}</a>
                    </li>

                    <hr class="my-1" />

                    <!-- Translate -->
                    <li class="py-2 pr-4 dropdown vertical_menu">
                      <a
                        class="dropdown-toggle"
                        href="#languages"
                        role="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        >{{ $t("translate") }}&nbsp;
                        <span class="float-right"
                          ><i class="fas fa-angle-down"></i>
                        </span>
                      </a>
                      <div class="dropdown-menu pl-n4 bg-light">
                        <a
                          class="
                            dropdown-item
                            d-flex
                            align-items-center
                            px-2
                            py-0
                          "
                          href="#languages"
                          v-for="(entry, i) in languages"
                          :key="i"
                          @click="changeLocale(entry)"
                        >
                          <country-flag
                            :country="entry.flag"
                            size="small"
                            class="mx-0"
                          />
                          <span class="ml-n3">{{ entry.name }}</span>
                        </a>
                      </div>
                    </li>

                    <!-- Afeshop International -->
                    <li
                      class="
                        nav-item
                        dropdown
                        pr-4
                        vertical_menu
                        pt-2
                        d-inline d-md-none d-lg-none
                      "
                    >
                      <a
                        class="dropdown-toggle my-auto"
                        href="#afshpIntl"
                        role="button"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        >{{ $t("afshpIntl") }}&nbsp;
                        <span class="float-right"
                          ><i class="fas fa-angle-down"></i>
                        </span>
                      </a>
                      <div
                        class="dropdown-menu pl-0 bg-light"
                        v-if="countries_"
                      >
                        <router-link
                          class="
                            dropdown-item
                            d-flex
                            align-items-center
                            px-2
                            py-0
                          "
                          href="#languages"
                          v-for="country in countries_.countries"
                          :key="country.id"
                          :to="{
                            name: 'AfshopIntl',
                            params: { short_name: country.code },
                          }"
                        >
                          <country-flag
                            :country="country.code"
                            size="small"
                            class="mx-0"
                          />
                          <span class="ml-n3">{{ country.name }}</span>
                        </router-link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-8 py-0 text-center">
              <div class="logo">
                <a title="Afeshop Cameroon" @click.stop="$router.push('/')">
                  <img
                    src="@/assets/logo_afeshop.png"
                    alt="Afeshop Logo"
                    class="img-fluid"
                  />
                </a>
              </div>
            </div>

            <div class="col-2 py-0">
              <div class="top-cart-row py-2 d-none d-sm-block">
                <div class="dropdown dropdown-cart">
                  <a
                    href="/#"
                    @click.prevent
                    class="dropdown-toggle lnk-cart submenu-open"
                    data-toggle="dropdown"
                  >
                    <div class="items-cart-inner">
                      <div class="basket">
                        <i class="fas fa-shopping-cart"></i>
                      </div>
                      <div class="basket-item-count">
                        <span class="cartcount" v-if="loggedIn">
                          {{ noCartItems > 9 ? "9+" : noCartItems }}
                        </span>
                        <span class="cartcount" v-if="!loggedIn"> 0 </span>
                      </div>
                    </div>
                  </a>

                  <div class="dropdown-menu" v-show="loggedIn">
                    <div
                      class="fl-mini-cart-content"
                      v-if="cartOrders.length > 0"
                    >
                      <ul
                        class="cart_list product_list_widget list-unstyled pl-0"
                      >
                        <li
                          class="mini_cart_item"
                          v-for="order in cartOrders[0].order_item"
                          :key="order.id"
                        >
                          <div class="cart-item product-summary">
                            <div class="row">
                              <div class="col-4 pr-2 pb-0">
                                <div class="image">
                                  <a href="/#" @click.prevent>
                                    <img
                                      width="90"
                                      height="90"
                                      :src="order.item.image_url"
                                      class="img-fluid py-1"
                                      :alt="order.item.name"
                                    />
                                  </a>
                                </div>
                              </div>
                              <div class="col-7 px-1 pb-0">
                                <h5 class="name text-truncate">
                                  <a href="/shopping-cart">{{
                                    order.item.name
                                  }}</a>
                                </h5>
                                <div class="price">
                                  <span class="quantity"
                                    >{{ order.quantity }} ×
                                    <span
                                      class="woocommerce-Price-amount amount"
                                      v-if="order.item.item_discount"
                                      >{{
                                        order.item.item_discount.unit_price
                                          | currency("XFA")
                                      }}</span
                                    >
                                    <span
                                      class="woocommerce-Price-amount amount"
                                      v-else
                                      >{{
                                        order.item.unit_price | currency("XFA")
                                      }}</span
                                    >
                                  </span>
                                </div>
                              </div>
                              <div class="col-1 action px-2">
                                <a
                                  href="#"
                                  class="remove remove_from_cart_button"
                                  aria-label="Remove this item"
                                  data-product_id="202"
                                  ><i class="fa fa-trash"></i
                                ></a>
                              </div>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <hr class="mx-0" />
                        </li>
                      </ul>
                      <div class="clearfix cart-total">
                        <div class="float-right">
                          <p class="woocommerce-mini-cart__total total">
                            <strong>{{ $t("Subtotal") }}:</strong>
                            <span class="woocommerce-Price-amount amount">{{
                              cartOrders[0].amount | currency("XFA")
                            }}</span>
                          </p>
                        </div>

                        <div class="clearfix"></div>

                        <p class="woocommerce-mini-cart__buttons buttons">
                          <a
                            @click.stop="$router.push('/shopping-cart')"
                            class="button wc-forward"
                            >{{ $t("Viewcart") }}</a
                          ><a
                            @click.stop="$router.push('/payment')"
                            class="button checkout wc-forward"
                            >{{ $t("checkout") }}</a
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--  Alternate cart button for mobile -->
              <a
                @click.stop="$router.push({ name: 'cart' })"
                class="mini-cart d-block d-sm-none"
              >
                <div class="basket">
                  <i class="mdi mdi-cart-outline"></i>
                  <span
                    class="badge badge-danger"
                    id="cartCount"
                    v-if="!loggedIn"
                    >0</span
                  >
                  <span
                    class="badge badge-danger"
                    id="cartCount"
                    v-if="loggedIn"
                  >
                    {{ noCartItems > 9 ? "9+" : noCartItems }}</span
                  >
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 top-search-holder px-7 mb-1 pt-0">
          <div class="search-area">
            <form id="mobiSearchform">
              <div class="control-group">
                <input
                  type="text"
                  ref="searchfield"
                  class="search-field"
                  name="mobiSearch"
                  id="mobiSearch"
                  :placeholder="$t('searchPrdt')"
                  v-model="searchWord"
                  @keydown="presentRslts(searchWord ? searchWord : 'none')"
                  autocomplete="off"
                  @focus="
                    showTempList = true;
                    presentRslts(searchWord ? searchWord : 'none');
                  "
                  v-on-clickaway="away"
                />
                <button
                  type="submit"
                  class="search-button"
                  @click.prevent="filterProducts(searchWord)"
                >
                  <i class="fas fa-search"></i>
                </button>
                <input type="hidden" name="post_type" value="product" />
              </div>
            </form>
          </div>

          <div
            class="card bg-white shadow-sm border-0 rounded"
            v-show="showTempList"
            id="searchList"
          >
            <div class="list-group temp-list" v-if="tempList">
              <a
                @click.prevent="filterProducts(item.name)"
                class="
                  list-group-item list-group-item-action
                  d-flex
                  justify-content-between
                "
                v-for="item in tempList"
                :key="item.id"
              >
                <span class="text-truncate">{{ item.name }}</span>
                <i class="mdi mdi-magnify"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mixin as clickaway } from "vue-clickaway";
import MegaCategoryList from "./MegaCategoryList.vue";

import "@/assets/css/home/header/header.css";

import $ from "jquery";

export default {
  mixins: [clickaway],
  components: {
    Loading,
    MegaCategoryList,
  },

  data() {
    return {
      userName: "",

      loading: false,
      height: 80,
      width: 100,
      transition: "fade",
      showTempList: false,
      tempList: [],
      drawerOpened: false,
    };
  },

  methods: {
    ...mapActions(["logout", "searchAction", "getOrders", "singleSearchItem"]),
    ...mapMutations({
      setNoCartItems: "setNoCartItems",
      setSearchWord: "SET_SEARCH_WORD",
    }),

    dimTheBody() {
      console.log("in dimming");
      if ($("div.navbar-collapse").hasClass("show")) {
        console.log("has class show", true);
      } else {
        console.log();
      }
    },

    away: function () {
      console.log("clicked away");
      this.showTempList = false;
    },

    filterProducts(word) {
      this.loading = true;
      this.showTempList = false;
      var schword = word ? word : this.searchWord;

      console.log("search word", word, schword);

      this.singleSearchItem({
        query: schword,
      }).then((response) => {
        console.log("Search response", response.items.data);
        console.log("search word: ", this.searchWord);

        var prdts = this.tempList ? this.tempList : this.getSearchedProduct;

        localStorage.setItem(
          "prevSearch",
          JSON.stringify({
            word: schword,
            searchProducts: response.items.data,
          })
        );

        if (response.items) {
          if (word) {
            this.$router.push({
              name: "search-results",
              params: {
                q: schword,
                products: prdts,
              },
            });
          } else {
            this.$router.push({
              name: "search-results",
              params: {
                q: this.searchWord,
                products: response.items.data,
              },
            });
          }
        }

        this.loading = false;
      });
    },

    presentRslts(word) {
      console.log("In search field: ", word);
      if (word === "none") {
        this.showTempList = true;

        this.tempList = this.newProducts_
          ? this.newProducts_.data
          : this.getNewProductsObj.data;
        console.log("Word Search keydown: ", this.getNewProductsObj);
      } else {
        this.singleSearchItem(word).then((response) => {
          this.tempList = response.items.data;
          console.log("Word Search keydown: ", this.tempList);
        });
      }
    },

    languageFlag() {
      var flag = this.localeLang ? this.localeLang.flag : "us";
      console.log("function get flag", flag);
      return flag;
    },
  },

  computed: {
    ...mapGetters([
      "loggedIn",
      "getUserDetails",
      "getCachedOrders",
      "noCartItems",
      "getSearchedProduct",
      "getSearchWord",
      "getCurrentLanguage",
      "getNewProductsObj",
    ]),

    searchWord: {
      set(word) {
        this.setSearchWord(word);
      },
      get() {
        return this.getSearchWord;
      },
    },

    cartOrders() {
      return this.loggedIn
        ? this.cart_orders(this.orders_ ? this.orders_.data : {})
        : [];
    },

    megaCatgories() {
      return this.guestData_ ? this.guestData_.allCategories : null;
    },

    localeLang() {
      return this.$store.state.currentLanguage
        ? this.$store.state.currentLanguage
        : this.getCurrentLanguage;
    },
  },

  mounted() {
    if (this.loggedIn) {
      this.setNoCartItems();
      this.userName = this.getUserDetails.username;
      console.log("user Name: ", this.userName);

      this.$root.$on("cart_item", (cartNo) => {
        console.log("Cart Number head", cartNo);
        this.setNoCartItems();
        console.log("cartNo", this.noCartItems);
        this.cart_no = cartNo;
      });

      var loc = JSON.parse(localStorage.getItem("language"));

      if (loc) {
        this.changeLocale(loc);
      }

      console.log("locale: ", loc);
    }

    console.log("header language:", this.localeLang);

    $("button.btn-navbar").on("click", (e) => {
      console.log("btn-nabar clicked");
      $("body").toggleClass("active");
      this.drawerOpened = true;
    });

    $("button.close").on("click", (e) => {
      console.log("navbar closing");
      $("body").toggleClass("active");
      this.drawerOpened = false;
    });

    if (
      $("body").hasClass("active") &&
      $("navbar-collapse").hasClass("show") === false
    ) {
      $("body").removeClass("active");
    }

    $("body.active").on("click", () => {
      console.log("was clicked");
      if ($("navbar-collapse").hasClass("show") === false) {
        $("body").removeClass("active");
        console.log("removing the class");
      }
    });
  },

  created() {
    this.setNoCartItems();
    if (this.loggedIn) {
      console.log("Header Orders: ", this.orders_);
      console.log("Header Orders 2: ", this.cart_orders(this.orders_.data));
      console.log("Header Orders 3: ", this.discountedPrice(5000, 0.5));
      console.log("Header Orders 4: ", this.cartOrders);
    }

    console.log("all catgories in the header: ", this.guestData_.allCategories);
  },
};
</script>

<style scoped></style>
