export default {
  methods: {
    computeTotal() {
      let total = 0,
        i = 0;
      if (this.filteredOrders.length > 0) {
        this.filteredOrders.forEach((filtered) => {
          console.log("Item: ", filtered);
          filtered.order_item.forEach((item) => {
            console.log("Item inside: ", item.item);
            console.log("i: ", ++i, total);

            if (item.item.item_discount) {
              total += item.item.item_discount.unit_price * item.quantity;
            } else {
              total += item.item.unit_price * item.quantity;
            }
          });
        });
      }

      return total;
    },
    computeTotal(orders) {
      let total = 0,
        i = 0;
      if (this.orders.length > 0) {
        this.orders.forEach((filtered) => {
          console.log("Item: ", filtered);
          filtered.order_item.forEach((item) => {
            console.log("Item inside: ", item.item);
            console.log("i: ", ++i, total);

            if (item.item.item_discount) {
              total += item.item.item_discount.unit_price * item.quantity;
            } else {
              total += item.item.unit_price * item.quantity;
            }
          });
        });
      }

      return total;
    },
  },
};
