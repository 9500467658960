const config = {
  apiBaseUrl: "https://api.afeshop.africa/api/route237",
  // apiBaseUrl: "http://127.0.0.1:8000/api/route237",
};

export default {
  config,
  install(Vue) {
    Vue.appConfig = config;
    Vue.prototype.$appConfig = config;
  },
};
