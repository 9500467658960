import { ItemService } from "@/services/item";

const state = {
  item: null,
  intlPrdts: null,
  searchItems: null,
  searchWord: null,
  lastProduct: null,
};

const mutations = {
  SET_ITEM_DETAIL(state, payload) {
    state.item = payload;
    localStorage.setItem("productDetails", JSON.stringify(payload));
  },

  SET_SEARCH_ITEMS(state, payload) {
    state.searchItems = payload;
    localStorage.setItem("searchItems", JSON.stringify(payload));
  },

  SET_SEARCH_WORD(state, payload) {
    state.searchWord = payload;
    localStorage.setItem("searchWord", JSON.stringify(payload));
  },

  SET_INTL_PRDTS(state, payload) {
    state.intlPrdts = payload;
    localStorage.setItem("intlProducts", JSON.stringify(payload));
  },

  SET_LAST_PRDT(state, payload) {
    state.lastProduct = payload;
    localStorage.setItem("lastProduct", JSON.stringify(payload));
  },
};

const getters = {
  getSearchedProduct: (state) => state.searchItems,

  getSearchWord: (state) => state.searchWord,

  getProduct: (state) => state.item,

  getIntlProdts: (state) => state.intlPrdts,

  getLastProduct: (state) => state.lastProduct,
};

const actions = {
  async getItemDetails({ commit }, payload) {
    try {
      const response = await ItemService.getItemDetails(payload);

      console.log("Item detail index: ", response);
      commit("SET_ITEM_DETAIL", response);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async getItemCategory({ commit }, payload) {
    try {
      const response = await ItemService.getItemCategory(payload);

      console.log("Item category index: ", response);
      // commit("SET_ITEM_DETAIL", response);

      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  },

  async filterCategories({}, payload) {
    try {
      const response = await ItemService.filterCategories(payload);
      console.log("async parameter", payload);
      return response;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async filterByFeature({}, payload) {
    try {
      const response = await ItemService.filterByFeature(payload);

      return response;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  // async searchItem({ commit }, payload) {
  //   try {
  //     const response = await ItemService.searchItem(payload);

  //     console.log("Search Items index: ", response);
  //     commit("SET_SEARCH_ITEMS", response);

  //     return response;
  //   } catch (e) {
  //     console.log(e);
  //     return false;
  //   }
  // },

  async singleSearchItem({ commit }, payload) {
    try {
      const response = await ItemService.singleSearchItem(payload);

      console.log("Search Items index: ", response);
      commit("SET_SEARCH_ITEMS", response);

      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  },

  async searchItemByRange({ commit }, payload) {
    try {
      const response = await ItemService.searchItemByRange(payload);

      console.log("Search Items range index: ", response);
      // commit("SET_SEARCH_ITEMS", response);

      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  },

  async searchItemByFeatures({ commit }, payload) {
    try {
      const response = await ItemService.searchItemByFeatures(payload);

      console.log("Search Items features index: ", response);
      // commit("SET_SEARCH_ITEMS", response);

      return response;
    } catch (e) {
      console.log(e);
      return e;
    }
  },

  async addComment({ commit }, payload) {
    try {
      const response = await ItemService.addComment(payload);

      console.log("Add comment index: ", response);
      // commit("SET_SEARCH_ITEMS", response);

      return response;
    } catch (e) {
      console.log("Add comment error", e);
      return e;
    }
  },

  async setRate({ commit }, payload) {
    try {
      const response = await ItemService.setRate(payload);

      console.log("set rating index: ", response);
      // commit("SET_SEARCH_ITEMS", response);

      return response;
    } catch (e) {
      console.log("set rating error", e);
      return e;
    }
  },

  async getIntlProducts({ commit }, payload) {
    try {
      const response = await ItemService.getInternationalProducts(payload);

      commit("SET_INTL_PRDTS", response);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
