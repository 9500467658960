const state = {
  activeCategory: false,
  activeCategory_mini: false,
  currentCategory: null,
  currentCategory_mini: null,
  truncatedCategories: null,
};

const mutations = {
  SET_ACTIVE_CATEGORY(state) {
    state.activeCategory = true;
  },

  SET_ACTIVE_CATEGORY_mini(state) {
    state.activeCategory_mini = true;
  },

  SET_CURRENT_CATEGORY(state, payload) {
    state.currentCategory = payload;
  },

  SET_CURRENT_CATEGORY_mini(state, payload) {
    state.currentCategory_mini = payload;
  },

  UNSET_ACTIVE_CATEGORY(state) {
    state.activeCategory = false;
  },

  UNSET_ACTIVE_CATEGORY_mini(state) {
    state.activeCategory_mini = false;
  },

  truncateCategories(state) {
    if (state.currentCategory) {
      var cats = state.currentCategory.children;
      cats = cats.slice(0, 5);

      cats.forEach((cat) => {
        if (cat.child.length > 5) {
          cat.child = cat.child.slice(0, 5);
        }
      });

      state.truncatedCategories = cats;
    }
  },
};

const getters = {
  activeCategoryState: (state) => state.activeCategory,

  getCurrentCategory: (state) => state.currentCategory,

  getTruncatedCats: (state) => state.truncatedCategories,
};

const actions = {};

export default {
  state,
  mutations,
  getters,
  actions,
};
