<template>
  <div class="container">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="var(--afprimary)"
      :height="height"
      :width="width"
      :transition="transition"
      background-color="#000"
    ></loading>

    <!-- Main Section -->
    <section class="container">
      <listing-widget
        :widgetName="'Category List'"
        :widgetInfo="info"
        :banner="false"
        :colors="colors"
        :categories="categories"
        :products="catProducts"
        :otherFilters="features"
        :numSlots="2"
      >
        <!--Parent Categories List -->
        <template #customFilter1>
          <h3 class="section-title">
            {{ parent_category.name }}
          </h3>
          <ul id="cat-list" class="list-unstyled px-1">
            <button
              type="button"
              class="list-group-item-action py-2"
              v-for="(category, index) in parent_category.child"
              :key="index"
              @click.prevent="filterCategory(category.id, category.name)"
            >
              {{ category.name }}
            </button>
          </ul>
        </template>

        <!-- Discount Filters -->
        <template #customFilter2>
          <h3 class="section-title">{{ $t("Discounts") }}</h3>
          <div id="discount-list" class="form-group mt-3">
            <input
              type="radio"
              name="radio"
              id="radio0"
              checked="true"
              v-model="selectedDiscount"
              value="all"
              @change="filterProducts"
            />
            <label class="radio" for="radio0"></label>

            <div v-for="(discount, index) in discounts" :key="index">
              <input
                type="radio"
                name="radio"
                :id="'radio' + (index + 1)"
                v-model="selectedDiscount"
                :value="discount / 100"
                @change="filterProducts"
              />
              <label class="radio" :for="'radio' + (index + 1)"
                >{{ discount }} %</label
              >
            </div>
          </div>
        </template>
      </listing-widget>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import ListingWidget from "@/components/widgets/GenericListing.vue";

export default {
  components: {
    Loading,
    ListingWidget,
  },
  data() {
    return {
      products: [],
      productInfo: null,
      disable: false,
      page: 1,
      last_page: 0,
      pageSize: 5,
      deals: [],
      parent_category: null,
      cat_child: null,
      catProducts: [],
      catProducts_save: [],
      info: {
        title: "Category List",
        min: 500,
        max: 1000,
      },
      colors: ["red", "green", "orange", "black"],
      categories: [
        {
          id: 42,
          name: "Books",
        },
        {
          id: 2,
          name: "Art",
        },
        {
          id: 3,
          name: "Music",
        },
        {
          id: 12,
          name: "Home",
        },
        {
          id: 10,
          name: "Appliances",
        },
        {
          id: 9,
          name: "Sports",
        },
        {
          id: 1,
          name: "Computers",
        },
        {
          id: 8,
          name: "Kitchen",
        },
      ],
      overseaCountries: [
        "Cameroon",
        "Nigeria",
        "Benin",
        "France",
        "USA",
        "UK",
        "China",
      ],
      sortList: [
        "Alphabetical",
        "Newest",
        "Best Selling",
        "Price Ascending",
        "Price Descending",
      ],
      discounts: [],
      features: [],
      selectedCriteria: "",
      rtext:
        " Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam, ad, adipisicing elit. Quam, ad, adipisicing elit. Quam, ad!",
      gridView: true,
      listView: false,
      title: "",
      selectedDiscount: null,
      errorMsg: "There was an error with that!",

      loading: false,
      height: 80,
      width: 100,
      transition: "fade",

      routeItems: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: this.$route.meta.title,
          disabled: true,
          href: this.$route.path,
        },
      ],

      searchObj: null,
    };
  },

  watch: {
    $route: function () {
      this.loadData();
    },
  },

  methods: {
    ...mapActions([
      "addToCart",
      "getGuestData",
      "getOrders",
      "filterCategories",
      "filterByFeature",
    ]),

    newPrice(price, discount) {
      return price - discount * price;
    },

    changeSelectSort({ data, text }) {
      console.log("Change Select criteria: ", data);
      this.selectedCriteria = text;
    },

    showGrid() {
      if (this.gridView) {
        this.gridView = false;
        this.listView = true;
      } else {
        this.gridView = true;
        this.listView = false;
      }
    },

    showList() {
      if (this.listView) {
        this.gridView = true;
        this.listView = false;
      } else {
        this.gridView = false;
        this.listView = true;
      }
    },

    filterCategory(id, name) {
      this.loading = true;
      this.filterCategories({
        category_id: id,
      }).then((response) => {
        if (response) {
          console.log("Filter Categories view, ", response.feature);

          this.catProducts_save = this.catProducts = response.items.data;
          this.features = response.feature;

          this.title = name;

          console.log("Filter features response, ", this.features);
          console.log("Filter categories, ", this.catProducts);

          this.loadDiscounts();

          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },

    filterProducts() {
      var products = this.catProducts_save;
      this.catProducts = [];

      if (this.selectedDiscount == "all") {
        this.loading = true;

        this.catProducts = products;
        console.log("shop products filter ", products);
        this.loading = false;
      } else {
        this.loading = true;

        this.catProducts = [];

        products.forEach((product) => {
          if (product.item_discount) {
            if (
              product.item_discount.percentage_decrease == this.selectedDiscount
            ) {
              this.catProducts.push(product);
            }
          }
        });
        this.loading = false;
      }

      console.log("selected discount ", this.selectedDiscount);
    },

    filterProductsFeature(value, key, catId) {
      this.filterByFeature({
        category_id: catId,
        value: value,
        key: key,
      }).then((response) => {
        console.log("filter category by feature response: ", response);
      });
    },

    loadDiscounts() {
      if (this.catProducts) {
        this.catProducts.forEach((product) => {
          if (product.item_discount) {
            this.discounts.push(
              product.item_discount.percentage_decrease * 100
            );
            var uniq_d = [...new Set(this.discounts)];

            this.discounts = uniq_d.sort();
          }
        });
      }
    },
  },

  computed: {
    ...mapGetters(["loggedIn", "getUserDetails", "getGuestDataObj"]),
    ...mapState(["superDeals", "cart_no"]),

    categorySearched() {
      return !this.$route.params.parent
        ? JSON.parse(localStorage.getItem("categorySearchProps"))
        : null;
    },
  },

  created() {
    if (this.$route.params.parent) {
      this.parent_category = this.$route.params.parent;
      this.cat_child = this.$route.params.child;

      localStorage.setItem(
        "categorySearchProps",
        JSON.stringify({
          parent: this.$route.params.parent,
          child: this.$route.params.child,
          catId: this.$route.params.catId,
        })
      );
    } else {
      this.parent_category = this.categorySearched.parent;
      this.cat_child = this.categorySearched.child;
    }

    console.log("filter params: ", this.cat_child.id, this.cat_child.name);

    this.filterCategory(this.cat_child.id, this.cat_child.name);
  },

  mounted() {
    this.loading = true;
    console.log(
      "Category Product: ",
      this.$route.params.parent,
      ", ",
      this.$route.params.child
    );
    console.log("filter params: ", this.$route.params.parent);

    this.loadDiscounts();

    $(".list-card.card").hover(
      function () {
        $(this).addClass("shadow").css("cursor", "pointer");
      },
      function () {
        $(this).removeClass("shadow");
      }
    );
  },
};
</script>

<style scoped>
input[type="radio"] {
  display: none;
}

input[type="radio"] + label:before {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  padding: 6px;
  margin-right: 15px;
  background-clip: content-box;
  border: 2px solid #bbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

input[type="radio"]:checked + label:before,
.overseaCountries input[type="radio"]:checked + label:before {
  background-color: var(--afprimary);
  border: 1.5px solid var(--afprimary);
}

label {
  display: flex;
  align-items: center;
}
</style>
