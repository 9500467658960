<template>
  <div class="row">
    <div class="col-md-12 py-0">
      <div class="card pane border-0 shadow-sm bg-white py-4 px-5">
        <!-- Header -->
        <div
          class="
            card-header
            border-0
            bg-transparent
            pl-2
            pb-2
            d-flex
            align-items-center
          "
        >
          <button @click="$router.go(-1)" class="btn btn-lg pl-0 text-dark">
            <i class="mdi mdi-arrow-left h5"></i>
          </button>
          <h4>{{ $t("VoucherCredits") }} ({{ voucherCount }})</h4>
        </div>

        <!-- Content -->
        <div class="row px-2" v-if="vouchers">
          <div class="col-md-12">
            <div class="row" v-if="vouchers.length > 0">
              <div class="col-md-6" v-for="coupon in vouchers" :key="coupon.id">
                <div class="card shadow border-0 px-3 py-1 rounded">
                  <div
                    class="
                      mt-3
                      d-flex
                      align-items-middle
                      justify-content-between
                    "
                  >
                    <b class="h5 text-uppercase"
                      >COUP-{{ coupon.coupon.type }}</b
                    >
                    <button
                      class="btn btn-font-trans btn-sm"
                      @click.stop.prevent="copyCode(coupon)"
                    >
                      COPY CODE
                    </button>
                  </div>

                  <input type="hidden" id="code" :value="code" />

                  <p class="mt-1 mb-3 text-muted">
                    <i class="mdi mdi-bookmark-outline"></i>
                    {{ $t("MaximumUsage") }}:
                    <span class="text-secondary">
                      {{ coupon.coupon.maximum_coupon_usage }}</span
                    >
                  </p>

                  <h2 class="color-font">
                    {{ coupon.coupon.discount_amount | currency("XFA ") }}
                  </h2>
                  <p class="my-0"></p>

                  <p class="mb-1" v-if="coupon.eligible == 1">
                    {{ $t("Active") }},
                    <span class="color-font-red text-capitalize"
                      >{{ coupon.coupon.discount_percentage | percent }}
                      {{ $t("of") }}</span
                    >
                  </p>
                  <p class="mb-1" v-else>{{ $t("Blocked") }}</p>

                  <p>
                    {{ $t("Validfrom") }}
                    <b>{{ coupon.created_at | formatDateComments }}</b>
                    {{ $t("until") }}
                    <b>{{ coupon.coupon.valid_date | formatDateComments }}</b>
                  </p>
                </div>
              </div>
            </div>

            <!-- Empty Vouchers -->
            <div class="row justify-content-center" v-else>
              <div class="col-8 py-12">
                <div class="text-center">
                  <emptyVouchers></emptyVouchers>
                  <h5 class="text-muted mt-5">
                    {{ $t("noAfeshopVoucher") }}
                  </h5>

                  <p class="font-15 mt-4">
                    {{ $t("yourAfeshopVouchers") }}
                  </p>

                  <button
                    @click="$router.push({ path: '/' })"
                    class="
                      btn btn-orange
                      rounded-0
                      shadow
                      font-weight-normal
                      mr-3
                      px-5
                      py-2
                      mt-3
                      mb-12
                    "
                  >
                    {{ $t("CONTINUESHOPPING") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Loader -->
        <div class="mx-auto my-auto" style="margin-top: m">
          <scroll-loader
            :loader-method="fetchCoupons"
            :loader-disable="disable"
          ></scroll-loader>
        </div>

        <!-- Pagination -->
        <div
          class="d-flex justify-content-between align-items-center"
          v-if="vouchers"
        >
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            class="ml-"
            total-visible="6"
            color="var(--afprimary)"
            @input="updateResource"
          ></v-pagination>
          <p class="text-secondary mr-3">
            {{ currentPage }} {{ $t("of") }} {{ totalPages }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations, mapActions } from "vuex";
import emptyVouchers from "@/assets/images/empty-coupons.svg";

export default {
  components: {
    emptyVouchers,
  },
  data() {
    return {
      newsletter: false,
      code: "",
      vouchers: null,
      voucher_slice: null,
      disable: false,
      currentPage: 1,
      totalPages: 8,
      perPage: 4,
      inc: 0,
    };
  },
  methods: {
    ...mapActions(["getCoupons"]),

    fetchCoupons() {
      this.disable = false;
      this.getCoupons(this.user_).then(() => {
        this.vouchers = this.getStoredCoupons.data.coupons;
        console.log("User vouchers: ", this.vouchers.length);
        this.disable = true;
        this.voucher_slice = this.vouchers.slice(0, this.perPage);
        this.totalPages = Math.ceil(this.vouchers.length / this.perPage);
      });
    },

    updateResource() {
      if (this.currentPage == 1) {
        this.inc = 0;
        this.voucher_slice = this.vouchers.slice(
          this.inc,
          this.perPage + this.inc
        );
        console.log("Inc: ", this.inc);
        console.log("current page: ", this.currentPage);
      } else {
        this.inc = (this.currentPage - 1) * this.perPage;
        this.voucher_slice = this.vouchers.slice(
          this.inc,
          this.perPage + this.inc
        );
        console.log("Inc: ", this.inc);
        console.log("current page: ", this.currentPage);
      }

      console.log("Orders slice", this.voucher_slice);
    },

    copyCode(coupon) {
      this.code = coupon.coupon.code;

      let codeToCopy = document.querySelector("#code");
      codeToCopy.setAttribute("type", "text");
      codeToCopy.select();
      console.log("Code to copy ", codeToCopy);

      try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successfully" : "unsuccessfully";
        alert("Coupon code was copied " + msg);
      } catch (err) {
        alert("Oops, unable to copy");
      }

      /* unselect the range */
      codeToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
  },

  computed: {
    ...mapState(["userCoupons", "user"]),
    ...mapGetters(["getStoredCoupons"]),

    voucherCount() {
      return this.vouchers ? this.vouchers.length : 0;
    },
  },

  mounted() {
    this.fetchCoupons();
  },

  created() {},
};
</script>

<style scoped>
a.btn > i {
  font-size: 1.3rem;
}
.btn.rnd {
  border-radius: 50%;
}
.btn.rnd:hover {
  background: #db9e3557;
}

.shadow {
  box-shadow: 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.15) !important;
}
</style>
