import { ItemService } from "@/services/item";

const state = {
  shop: null,
  shopList: null,
  shopCategories: null,
};

const mutations = {
  SET_SHOP_DETAIL(state, payload) {
    state.shop = payload;
    localStorage.setItem("shopDetails", JSON.stringify(payload));
  },

  SET_SHOP_LIST(state, payload) {
    state.shopList = payload;
    localStorage.setItem("shopList", JSON.stringify(payload));
  },

  SET_SHOP_CATEGORIES(state, payload) {
    state.shopCategories = payload;
    localStorage.setItem("shopCategories", JSON.stringify(payload));
  },
};

const getters = {
  getShop: (state) => state.shop,

  getShopList: (state) => state.shopList,

  getShopCats: (state) => state.shopCategories,
};

const actions = {
  async getShops({ commit }) {
    try {
      const response = await ItemService.getShops();

      commit("SET_SHOP_LIST", response);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async getShopDetails({ commit }, payload) {
    try {
      const response = await ItemService.getShopDetails(payload);

      // console.log("Shop detail index: ", response);
      commit("SET_SHOP_DETAIL", response);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async getShopCategories({ commit }, payload) {
    try {
      const response = await ItemService.getShopCategories(payload);

      // console.log("Shop categories index: ", response);
      commit("SET_SHOP_CATEGORIES", response);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async getFilteredShops({ commit }, payload) {
    try {
      const response = await ItemService.getFilteredShops(payload);

      // console.log("Shop categories index: ", response);
      // commit("SET_SHOP_CATEGORIES", response);

      return response;
    } catch (e) {
      console.log("filtered shops error: ", e);
      return e;
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
