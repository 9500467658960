export default {
  methods: {
    cart_orders(orders) {
      console.log("initial cart orders: ", orders);

      if (orders.length > 0) {
        var newOrders = orders.filter((order) => {
          console.log("in cart orders", order);
          return order.status.match("cart");
        });

        return newOrders;
      } else {
        return [];
      }
    },
  },
};
