var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container py-5",attrs:{"id":"details"}},[_c('v-breadcrumbs',{staticClass:"bc px-0 mx-n3 pt-0",attrs:{"items":_vm.routeItems},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),_c('loading',{attrs:{"active":_vm.loading,"can-cancel":false,"is-full-page":true,"color":"var(--afprimary)","height":_vm.height,"width":_vm.width,"transition":_vm.transition},on:{"update:active":function($event){_vm.loading=$event}}}),(!_vm.loading)?_c('div',[_c('div',{staticClass:"row mb-10 section card rounded-0 border-0 shadow-sm py-5 px-3"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5 px-0 mb-5"},[_c('lingallery',{attrs:{"width":_vm.width2,"height":_vm.height2,"items":_vm.images2,"responsive":true,"baseColor":"var(--afprimary)","accentColor":"var(--afprimary)","disableImageClick":true,"addons":{ enableLargeView: true }}})],1),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"mb-5"},[_c('h5',[_vm._v(_vm._s(_vm.prdtName))]),_c('p',{staticClass:"text-muted mb-1 font-15"},[_vm._v(" "+_vm._s(_vm.description)+" ")])]),(_vm.details.item_discount)?_c('div',{staticClass:"\n                d-flex\n                justify-content-between\n                align-items-center\n                text-capitalize\n                mb-0\n                py-2\n                px-3\n              ",class:_vm.compName == 'New Products'
                  ? 'banner2'
                  : _vm.compName == 'Recommended'
                  ? 'banner3'
                  : 'banner'},[_c('h5',{staticClass:"text-white my-0 text-uppercase"},[_vm._v(" "+_vm._s(this.compName)+" !!! ")]),_c('div',{staticClass:"text-right"},[_c('p',{staticClass:"text-white mb-0 font-12"},[_vm._v("2 "+_vm._s(_vm.$t("Soldout")))]),_c('p',{staticClass:"text-white mb-0 font-12"},[_vm._v(" "+_vm._s(_vm.$t("Endsin"))+" "),(_vm.currentTime)?_c('span',[_vm._v(" "+_vm._s(("" + ("" + _vm.currentTime.days)))+" "+_vm._s(_vm.$t("days"))+" "+_vm._s(("" + (("0" + _vm.currentTime.hours).slice(-2))))+":"+_vm._s(("" + (("0" + _vm.currentTime.minutes).slice(-2))))+":"+_vm._s(("" + (("0" + _vm.currentTime.seconds).slice(-2))))+" ")]):_vm._e()])])]):_vm._e(),_c('div',{staticClass:"d-flex align-items-center mt-3 py-2"},[_c('p',{staticClass:"mr-10 mb-0"},[_vm._v(_vm._s(_vm.$t("Price"))+":")]),_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('h3',{staticClass:"priceNum mr-3"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.price,"XFA "))+" ")]),(_vm.details.item_discount)?_c('small',{staticClass:"font-15 text-muted mr-1 text-uppercase"},[_vm._v(_vm._s(_vm.$t("rrp"))+": "),_c('strike',[_vm._v(_vm._s(_vm._f("currency")(_vm.rrp,"XFA ")))])],1):_vm._e(),(_vm.details.item_discount)?_c('span',{staticClass:"badge badge-danger mx-1 font-15"},[_c('small',[_vm._v(_vm._s(_vm._f("percent")(_vm.discount))+" "+_vm._s(_vm.$t("OFF")))])]):_vm._e()])]),_c('div',{staticClass:"d-flex align-items-center mt-4 mb-5"},[_c('p',{staticClass:"mr-10 mb-0"},[_vm._v(_vm._s(_vm.$t("Shop"))+":")]),_c('h6',{staticClass:"text-capitalize mb-0"},[_vm._v(" "+_vm._s(_vm.shopName)+" "),_c('small',[_c('router-link',{attrs:{"to":{
                      name: 'shop-view',
                      params: { shop_id: _vm.details.shop.id },
                    }}},[_vm._v(" "),_c('i',{staticClass:"fas fa-external-link-alt text-primary"})])],1)])]),_c('div',{staticClass:"d-flex align-items-center mt-8 mb-12"},[_c('p',{staticClass:"mr-5 mb-0"},[_vm._v(_vm._s(_vm.$t("Quantity"))+":")]),_c('div',{staticClass:"number",attrs:{"id":"quantity"}},[_c('span',{staticClass:"minus",on:{"click":_vm.quantity}},[_c('i',{staticClass:"fas fa-minus"})]),_c('input',{attrs:{"type":"number","min":"1","max":_vm.itemQtty},domProps:{"value":_vm.qtty}}),_c('span',{staticClass:"plus",on:{"click":function($event){_vm.qtty >= _vm.itemQtty ? (_vm.qtty = _vm.itemQtty) : _vm.qtty++}}},[_c('i',{staticClass:"fas fa-plus"})])]),(_vm.qtty < _vm.itemQtty)?_c('span',{staticClass:"text-success mx-2"},[_vm._v(_vm._s(_vm.$t("InStock")))]):_vm._e(),(_vm.qtty >= _vm.itemQtty)?_c('span',{staticClass:"text-danger mx-2"},[_vm._v(_vm._s(_vm.$t("OutofStock")))]):_vm._e()]),_c('button',{staticClass:"\n                mt-12\n                btn btn-lg\n                shadow\n                btn-block btn-orange\n                rounded-0\n                d-flex\n                justify-content-left\n                align-items-baseline\n                font-weight-bold\n              ",on:{"click":function($event){$event.preventDefault();return _vm.addProduct.apply(null, arguments)}}},[_vm._m(0),_c('span',{staticClass:"font-15 mx-auto pr-4"},[_vm._v(_vm._s(_vm.$t("ADDTOCART")))])])])])])]),_c('CustomerReview',{attrs:{"rating_no":_vm.rating_no,"rating":_vm.rating,"reviews":_vm.reviews,"comments":_vm.comments}}),_c('Recommended')],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"font-16"},[_c('i',{staticClass:"fas fa-cart-arrow-down"})])}]

export { render, staticRenderFns }