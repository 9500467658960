module.exports = {
  generateArrayOfYears() {
    var min = new Date().getFullYear();
    var max = min + 30;
    var years = [];

    for (var i = min; i <= max; i++) {
      years.push(i);
    }
    // console.log("generate years: ", years);
    return years;
  },
};
