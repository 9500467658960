<template>
  <div class="row">
    <div class="col-md-12 py-0">
      <div class="card pane border-0 shadow-sm bg-white py-4 px-5">
        <!-- Header -->
        <div
          class="card-header border-0 bg-transparent pl-3 pb-2 d-flex justify-content-between align-items-center"
        >
          <h4>Sell your own products</h4>
          <a
            href="sell/add"
            class="btn text-white addbtn btn-indigo outline-none rounded-0 mr-n3"
          >
            <i class="mdi mdi-plus"></i>
            Add Product
          </a>
        </div>

        <!-- Content -->
        <div class="row px-2">
          <div class="col-md-12">
            <!-- Empty Store -->
            <div
              class="text-center my-auto empty-store mx-auto h-100"
              v-if="emptyStore"
            >
              <img
                src="@/assets/images/icone-shopping-Cart.png"
                width="50px"
                class="my-auto img-fluid"
                alt
              />
              <h6 class="font-weight-bold py-2">Your store is empty!</h6>
              <p class="text-muted">
                You add products for retail here, by clicking on the
                <strong>Add Products</strong>button or just continue shopping by
                clicking the button below.
              </p>

              <button class="btn btn-orange rounded-0 mt-2 px-5 text-uppercase">
                Continue Shopping
              </button>
            </div>
            <!-- Non empty store -->
            <div class="mt-3" v-if="!emptyStore">
              <!-- Sorting and Filtering navigation -->
              <ul class="nav bg-light py-2">
                <li class="nav-item">
                  <h6 class="text-muted mt-3 mb-0 float-left">Sort:</h6>
                </li>
                <li class="nav-item ml-1">
                  <div class="dropdown">
                    <a
                      href
                      class="nav-link dropdown-toggle"
                      type="button"
                      id="dropdownMenu2"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      >Category</a
                    >
                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                      <button class="dropdown-item" type="button">All</button>
                      <button class="dropdown-item" type="button">
                        Sports
                      </button>
                      <button class="dropdown-item" type="button">
                        Electronics
                      </button>
                      <button class="dropdown-item" type="button">
                        Electronics
                      </button>
                    </div>
                  </div>
                </li>
                <li class="nav-item">
                  <div class="dropdown">
                    <a
                      href
                      class="nav-link dropdown-toggle"
                      id="orderMenu"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      >Order by:</a
                    >
                    <div class="dropdown-menu" aria-labelledby="orderMenu">
                      <button class="dropdown-item" type="button">All</button>
                      <button class="dropdown-item" type="button">
                        Alphabetical
                      </button>
                      <button class="dropdown-item" type="button">
                        Electronics
                      </button>
                      <button class="dropdown-item" type="button">
                        Electronics
                      </button>
                    </div>
                  </div>
                </li>

                <v-spacer></v-spacer>
                <!-- Filter -->
                <li class="nav-item">
                  <h6 class="text-muted mt-3 mb-0 float-left">Filter:</h6>
                </li>
                <li class="nav-item">
                  <div class="dropdown">
                    <a
                      href
                      class="nav-link dropdown-toggle"
                      id="orderMenu"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      >Date</a
                    >
                    <div class="dropdown-menu" aria-labelledby="orderMenu">
                      <button class="dropdown-item" type="button">All</button>
                      <button class="dropdown-item" type="button">
                        Alphabetical
                      </button>
                      <button class="dropdown-item" type="button">
                        Out of Stock
                      </button>
                      <button class="dropdown-item" type="button">
                        Newly Added
                      </button>
                    </div>
                  </div>
                </li>
              </ul>

              <!-- Products -->
              <div class="mt-2">
                <div class="row">
                  <div class="col-md-4">
                    <!-- Actions -->
                    <div class="prdt-actions mt-2">
                      <ul class="list-unstyled">
                        <li class="mt-1">
                          <button
                            @click.prevent="
                              $router.push({
                                name: 'sell-view',
                                params: { op: 1 },
                              })
                            "
                            class="btn btn-rnd btn-orange btn-sm"
                          >
                            <small>
                              <i
                                class="text-white mdi mdi-pencil font-weight-bold"
                              ></i>
                            </small>
                          </button>
                        </li>
                        <li class="mt-1">
                          <button
                            @click.prevent="
                              $router.push({
                                name: 'sell-view',
                                params: { op: 2 },
                              })
                            "
                            class="btn btn-rnd btn-primary btn-sm"
                          >
                            <small>
                              <i
                                class="text-white mdi mdi-eye font-weight-bold"
                              ></i>
                            </small>
                          </button>
                        </li>
                        <li class="mt-1">
                          <button class="btn btn-rnd btn-danger btn-sm">
                            <small>
                              <i
                                class="text-white mdi mdi-delete font-weight-bold"
                              ></i>
                            </small>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div class="card bg-transparent border-0 prdt">
                      <div class="card-header bg-transparent border-0">
                        <img
                          src="@/assets/images/elec2.png"
                          class="img-fluid"
                          alt
                        />
                      </div>
                      <div
                        class="card-footer border-top-0 bg-transparent py-0 justify-content-between d-flex"
                      >
                        <div>
                          <h6 class="text-center mb-0">Camera Pixel X</h6>
                          <small class="text-secondary">In Stock</small>
                        </div>
                        <h5 class="text-success text-center mt-0">$ 200</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <!-- Actions -->
                    <div class="prdt-actions mt-2">
                      <ul class="list-unstyled">
                        <li class="mt-1">
                          <button
                            @click.prevent="
                              $router.push({
                                name: 'sell-view',
                                params: { op: 1 },
                              })
                            "
                            class="btn btn-rnd btn-orange btn-sm"
                          >
                            <small>
                              <i
                                class="text-white mdi mdi-pencil font-weight-bold"
                              ></i>
                            </small>
                          </button>
                        </li>
                        <li class="mt-1">
                          <button
                            @click.prevent="
                              $router.push({
                                name: 'sell-view',
                                params: { op: 2 },
                              })
                            "
                            class="btn btn-rnd btn-primary btn-sm"
                          >
                            <small>
                              <i
                                class="text-white mdi mdi-eye font-weight-bold"
                              ></i>
                            </small>
                          </button>
                        </li>
                        <li class="mt-1">
                          <a href class="btn btn-rnd btn-danger btn-sm">
                            <small>
                              <i
                                class="text-white mdi mdi-delete font-weight-bold"
                              ></i>
                            </small>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="card bg-transparent border-0 prdt">
                      <div class="card-header bg-transparent border-0">
                        <img
                          src="@/assets/images/printer.jpg"
                          class="img-fluid"
                          alt
                        />
                      </div>
                      <div
                        class="card-footer border-top-0 bg-transparent py-0 justify-content-between d-flex"
                      >
                        <div>
                          <h6 class="text-center mb-0">Camera Pixel X</h6>
                          <small class="text-danger">Sold Out</small>
                        </div>
                        <h5 class="text-success text-center mt-0">$ 200</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      newsletter: false,
      emptyStore: false,
    };
  },
  mounted() {
    if (this.emptyStore) {
      $(".card.pane").css("height", "44.2em");
    }
  },
};
</script>

<style scoped>
a.btn > i {
  font-size: 1.3rem;
}
.btn.rnd {
  border-radius: 50%;
}
.btn.rnd:hover {
  background: #db9e3557;
}
.btn-rnd {
  border-radius: 50% !important;
}

.empty-store {
  position: relative;
  top: 27%;
  width: 55%;
}

ul.nav a.nav-link {
  font-size: 1rem !important;
}

ul.nav a:hover {
  text-decoration: none !important;
  color: var(--afprimary) !important;
}
.prdt.card:hover {
  box-shadow: 0px 2px 9px 0px rgb(209, 209, 209);
  border-color: rgb(194, 194, 194);
  cursor: pointer;
}
.prdt.card {
  transition: box-shadow 0.6s;
  z-index: 0;
  height: 19vw;
  width: 100%;
}

.prdt.card img {
  width: 100%;
  height: 12em;
  object-fit: cover;
}
.prdt-actions {
  position: absolute;
  z-index: 1;
  left: 72%;
}
a.addbtn {
  color: white !important;
  text-decoration: none !important;
}
</style>
