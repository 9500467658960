import { UserService } from "@/services/user";

const state = {
  userComments: null,
};

const mutations = {
  SET_COMMENTS(state, payload) {
    state.userComments = payload;
    localStorage.setItem("user_comments", JSON.stringify(payload));
    console.log("set comments", state.userComments);
  },
};

const getters = {
  getStoredComments: (state) => state.userComments,
};

const actions = {
  async getComments({ commit }, payload) {
    try {
      const response = await UserService.getComments(payload);

      console.log("Get Comments index: ", response);
      commit("SET_COMMENTS", response);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
