<template>
  <ul
    id="mobiList"
    class="navbar-nav m-0 ml-lg-auto px-0 py-4 mb-lg-auto mb-md-auto p-lg-0"
  >
    <li
      class="d-inline d-md-block d-lg-none my-n1 px-3 pb-2 mobi-filter-header"
    >
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="my-auto">{{ $t("FilterProductsby") }}</h5>
        <button
          data-toggle="collapse"
          data-target="#filterMenu"
          class="close my-auto"
          @click="
            sidbarClosed = true;
            removeActive();
          "
        >
          &times;
        </button>
      </div>
    </li>

    <hr class="my-2 mx-n4 px-3" />

    <!-- Shop Information Box -->
    <li class="mx-n1 px-3 my-2" v-if="otherInfo.other">
      <div class="card border-0 shadow-sm rounded px-4 py-4 mt-n2">
        <div class="text-center">
          <img
            :src="otherInfo.other.avatar_url"
            alt="shop avatar url"
            class="img-fluid my-3"
            style="height: 100px"
          />

          <div class="mb-3 mt-0">
            <h4 class="mb-0 text-capitalze">
              {{ otherInfo.title }}
            </h4>
            <p
              class="font-15 mt-1 mb-2"
              style="color: #c27f08"
              v-if="otherInfo.other.email"
            >
              {{ otherInfo.other.email }}
            </p>

            <div :class="otherInfo.other.email ? '' : 'mt-2'">
              <small>{{ otherInfo.other.address }}</small>
            </div>
          </div>

          <p class="my-3 text-capitalize">{{ otherInfo.other.description }}</p>
        </div>
      </div>
    </li>

    <!-- Price filter -->
    <li class="mx-n1 px-3">
      <div class="card border-0 shadow-sm rounded px-2 mt-1 pb-4">
        <h3 class="section-title">{{ $t("FilterbyPrice") }}</h3>

        <price-range-slider
          :min="priceInfo.min"
          :max="priceInfo.max"
          :trackHeight="0.5"
        />
      </div>
    </li>

    <!-- Color Filter -->
    <li class="mx-n1 px-3">
      <div class="card border-0 shadow-sm rounded px-2 mt-3 sidebar-widget">
        <h3 class="section-title px-0">{{ $t("FilterbyColor") }}</h3>

        <ul class="nav-list px-0">
          <li class="" v-for="(value, c) in colors" :key="'mobiColor-' + c">
            <div class="type-color d-flex justify-content-between">
              <label class="checkbox-container"
                ><span class="label">{{ c }}</span>
                <input
                  type="checkbox"
                  v-model="filterColorsSelected"
                  :value="c"
                  @change="check($event)"
                />
                <span
                  :class="[c == 'white' ? 'checkmark-grey' : 'checkmark']"
                  :style="[
                    c == 'white' ? { border: '1px solid grey' } : {},
                    { backgroundColor: c, opacity: 0.7 },
                  ]"
                ></span>
              </label>

              <span class="count">(5)</span>
            </div>
          </li>
        </ul>
      </div>
    </li>

    <!-- Product Categories filter -->
    <li class="mx-n1 px-3">
      <div
        id="prdtCats"
        class="card border-0 shadow-sm rounded px-2 mt-3 sidebar-widget"
      >
        <h3 class="section-title">{{ $t("ProductCategories") }}</h3>
        <ul class="list-unstyled px-0 mb-1">
          <li
            v-for="(category, index) in categories.slice(0, listCount)"
            :key="'mobiCatgry-' + index"
          >
            <a href="#">
              <label class="checkbox-container"
                ><span class="label">{{ category.name }}</span>
                <input
                  type="checkbox"
                  v-model="filterCategoriesSelected"
                  :value="category"
                  @change="check($event)"
                />
                <span class="checkmark2"></span>
              </label>
            </a>
          </li>
        </ul>
        <hr class="my-2" v-show="categories.length > listCount" />
        <button
          class="btn btn-link btn-sm pb-3 font-12"
          :disabled="categories.length < listCount"
          @click="listCount += 2"
          v-show="categories.length > listCount"
        >
          {{ $t("ShowMore") }}
        </button>
      </div>
    </li>

    <!-- Other filters -->
    <li class="mx-n1 px-3 pb mb-12">
      <div
        id="prdtCats"
        class="card border-0 shadow-sm rounded px-4 mt-3 sidebar-widget"
      >
        <h3 class="section-title">{{ $t("OtherFilters") }}</h3>
        <ul class="list-unstyled px-0 mb-1">
          <li v-for="filter in otherFilters" :key="filter.key">
            <h6 class="text-capitalize mb-5">
              {{ filter.key }}
            </h6>
            <ul class="list-unstyled px-0 mb-1">
              <li
                v-for="(value, index) in filter.value.slice(0, listCount)"
                :key="filter.key + '-' + index"
              >
                <a href="#">
                  <label class="checkbox-container"
                    ><span class="label">{{ value.value }}</span>
                    <input
                      type="checkbox"
                      v-model="filterFeaturesSelected"
                      :value="value"
                      @change="check($event)"
                    />
                    <span class="checkmark2"></span>
                  </label>
                </a>
              </li>
              <button
                :data-key="filter.key"
                class="btn btn-link btn-sm pb-3 font-12"
                :disabled="filter.value.length < listCount"
                @click="listCount += 2"
                v-show="filter.value.length > listCount"
              >
                {{ $t("ShowMore") }}
              </button>
            </ul>
          </li>
        </ul>
      </div>
    </li>

    <!-- Apply Button -->
    <li id="applyBtns" class="mx-0 px-0 mt-8">
      <div class="side_navBarBottom">
        <div class="side_actionBtn">
          <a
            class="apply_btn js-applyBtn"
            :data-toggle="applied ? 'collapse' : ''"
            data-target="#filterMenu"
            @click.prevent="
              filterRequest();

              removeActive();
            "
          >
            {{ $t("Apply") }}
          </a>
        </div>
        <div class="side_actionBtn">
          <a
            class="reset_btn js-resetBtn"
            :data-toggle="applied ? 'collapse' : ''"
            data-target="#filterMenu"
            @click.prevent="
              removeFilters();
              removeActive();
            "
          >
            {{ $t("Clear") }}
          </a>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import PriceRangeSlider from "@/components/widgets/PriceRangeSlider";
import "@/assets/css/prdts-display/display.css";
import "@/assets/css/prdts-display/product.css";

import $ from "jquery";

export default {
  props: {
    colors: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },

    priceInfo: {
      type: Object,
      required: true,
    },

    otherInfo: {
      type: Object,
      required: false,
    },

    otherFilters: {
      type: Array,
      required: false,
    },

    filterRequest: {
      type: Function,
    },
  },

  components: {
    PriceRangeSlider,
  },
  data() {
    return {
      applied: false,
      sidebarClosed: false,
    };
  },

  methods: {
    removeActive() {
      $("body").removeClass("active");
      this.applied = true;
      console.log("removing body active");
    },
  },

  created() {
    if (this.sidebarClosed) {
      $("body").removeClass("active");
    }
  },

  mounted() {
    $("ul#mobiList").scroll(function () {
      var scroll = $("ul#mobiList").scrollTop();

      if (scroll >= 50) {
        $("li#applyBtns").addClass("fixed-bottom");
      } else {
        $("li#applyBtns").removeClass("fixed-bottom");
      }
    });

    if (this.sidebarClosed) {
      $("body").removeClass("active");
    }
    // !$("#mobilefilter .navbar-collapse.collapse").hasClass("show")
  },
};
</script>
