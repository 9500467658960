<template>
  <div class="row mt-0 mt-md-0 mt-lg-0 justify-content-between">
    <div
      class="
        categoriesPane
        card
        shadow-sm
        rounded
        border-0
        py-0
        px-0
        d-none d-md-block d-lg-block d-lg-block
      "
    >
      <Categories :allCategories="allCategories" />
    </div>

    <div
      id="slide-carousel"
      class="
        sliderPane
        pl-md-0 pl-lg-0
        px-sm-0 px-3 px-md-0 px-lg-0
        mt-sm-n5 mt-n5 mt-lg-auto mt-md-auto
      "
      v-if="eventSlides"
    >
      <splide :options="options" has-slider-wrapper class="">
        <splide-slide v-for="slide in eventSlides" :key="slide.cover_url">
          <router-link
            :to="{ name: 'shop-view', params: { shop_id: slide.id } }"
            ><img
              :src="slide.cover_url"
              class="img-fluid slideImage"
              alt="Trouble Rendering this slide"
              @error="defaultSlideImage"
          /></router-link>
        </splide-slide>

        <template v-slot:controls>
          <div class="splide__progress">
            <div class="splide__progress__bar"></div>
          </div>
        </template>
      </splide>
    </div>
  </div>
</template>

<script>
import Categories from "./Categories.vue";
import SubCategories from "./SubCategories.vue";

import { Splide, SplideSlide } from "@splidejs/vue-splide";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export default {
  components: {
    Categories,
    SubCategories,
    Splide,
    SplideSlide,
  },

  props: {
    sliderData: {
      type: Object,
      default: {},
      required: true,
    },
  },
  data() {
    return {
      slideshows: [
        require("@/assets/banner.webp"),
        require("@/assets/banner.webp"),
        require("@/assets/banner.webp"),
      ],
      options: {
        rewind: true,
        perPage: 1,
        gap: "1rem",
        autoplay: true,
        arrows: "slider",

        height: "33em",
        lazyload: "nearby",
        preloadPages: 3,

        cover: true,

        breakpoints: {
          640: {
            type: "loop",
            gap: "1rem",
            padding: {
              right: "-1rem",
              left: "-1rem",
            },
            height: "14rem",
          },
          1200: {
            type: "fade",
            gap: "0",
            padding: "0",
            cover: false,
          },
        },
      },
    };
  },

  methods: {},

  computed: {
    eventSlides() {
      return this.sliderData.events;
    },

    allCategories() {
      return this.sliderData.allCategories;
    },
  },

  created() {},

  mounted() {},
};
</script>

<style scoped>
.row {
  /* height: 31.8em; */
}

a.btn-link {
  color: #535a60 !important;
}
.circle {
  border-radius: 50%;
  border: 1px var(--afprimary) solid;
}

.categoriesPane {
  width: 16.8em;
}
.categoriesPane.card {
  height: 33.5em;
}

.sliderPane {
  width: 49.3em;
}
.sliderPane #slider {
  height: 33.5em;
}
.sliderPane #slider img {
  height: 33.5em;
}
.infoPane {
  width: 17em;
  height: 30.5em;
}
.infoPane .otherInfo {
  height: 44%;
}
.infoPane .ads {
  height: 52.3%;
}

.slick-slide img {
  cursor: pointer;
}

.slideImage.img-fluid {
  height: 100% !important;
  width: 100%;
}

@media (min-width: 1200px) {
  .sliderPane {
    width: 63.9em;
  }
}

@media (max-width: 991px) {
  .sliderPane {
    width: 100%;
  }
}

@media (min-width: 959px) and (max-width: 991px) {
  .v-application .categoriesPane.d-md-block {
    display: none !important;
  }
}

@media (min-width: 959px) and (max-width: 992px) {
  .v-application .categoriesPane.d-md-block {
    display: none !important;
  }
  .sliderPane {
    width: 100%;
  }
}
</style>

<style lang="scss" scoped>
$color: var(--afprimary);

.splide {
  &__autoplay {
    margin-top: 1em;
    text-align: center;
  }

  &__arrow {
    background: $color !important;
  }
}
</style>
