<template>
  <div class="container">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="var(--afprimary)"
      :height="height"
      :width="width"
      :transition="transition"
      background-color="#000"
    ></loading>
    <listing-widget
      :widgetName="$t('CurrentDeals')"
      :widgetInfo="info"
      :banner="false"
      :colors="colorFeatures"
      :categories="categoriesList"
      :products="deals_s"
      :otherFilters="featuresNonColor"
      :filterRequest="filterRequest"
    >
      <template #categoryLoader>
        <div class="row text-center">
          <div class="col-12 pb-1" v-show="categoryLoading">
            {{ $t("Loading") }}
          </div>
          <div class="col-12 mb-4 pt-0">
            <v-progress-linear
              color="orange accent-3"
              :indeterminate="categoryLoading"
              :active="categoryLoading"
              rounded
              height="6"
            ></v-progress-linear>
          </div>
        </div>
      </template>
    </listing-widget>
  </div>
</template>

<script>
import apiService from "@/main";
import ListingWidget from "@/components/widgets/GenericListing.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { mapActions } from "vuex";

export default {
  props: {
    products: {
      type: Array,
      required: true,
    },

    dealType: {
      type: String,
      required: true,
    },
  },

  components: {
    ListingWidget,
    Loading,
  },

  data() {
    return {
      disable: false,
      page: 1,
      last_page: 0,
      pageSize: 5,
      cr_deals: null,
      deals_s: null,
      cm_deals: null,
      deals: [],
      sidebar: false,
      current: false,
      emptyResult: this.$t("resultSetIsEmpty"),
      empty: false,

      categoriesList: [],
      values: [],
      keys: [],

      colorfilters: [],
      categoryfilters: [],
      featurefilters: [],
      currentMin: null,
      currentMax: null,

      colorFeatures: null,
      featuresNonColor: [],

      loading: false,
      height: 80,
      width: 100,
      transition: "fade",
    };
  },

  methods: {
    ...mapActions([
      "filterCategories",
      "searchItemByRange",
      "searchItemByFeatures",
    ]),

    filterRequest() {
      this.loading = true;
      // this.loading2 = true;
      this.deals_s = [];
      let colorResp = [];
      let featureResp = [];
      let categoryResp = [];

      if (this.currentMin || this.currentMax) {
        this.searchItemByRange({
          minPrice: this.currentMin ? this.currentMin : this.minPrice,
          maxPrice: this.currentMax ? this.currentMax : this.maxPrice,
        }).then((response) => {
          this.deals_s = [...this.deals_s, ...response.items.data];
          this.loading = false;
          this.deals_s = this.removeDuplicates(this.deals_s);

          console.log("min filter response", this.deals_s);
        });
      } else if (this.colorfilters.length > 0) {
        this.colorfilters.forEach((color) => {
          this.searchItemByFeatures({
            values: color,
            keys: "color",
          }).then((response) => {
            this.loading = false;
            colorResp = [...new Set([...colorResp, ...response.items.data])];
            console.log("color: ", color, " response: ", response);
            this.deals_s = [...this.deals_s, ...colorResp];
            this.deals_s = this.removeDuplicates(this.deals_s);

            console.log("filter colorResp: ", this.deals_s);
          });
        });
      } else if (this.featurefilters.length > 0) {
        this.featurefilters.forEach((feature) => {
          this.searchItemByFeatures({
            values: feature.value,
            keys: feature.key,
          }).then((response) => {
            this.loading = false;
            featureResp = [
              ...new Set([...featureResp, ...response.items.data]),
            ];
            console.log("prev Searchprod", this.deals_s);
            this.deals_s = [...this.deals_s, ...featureResp];
            this.deals_s = this.removeDuplicates(this.deals_s);
            console.log("featureResp: ", featureResp, " response: ", response);
            console.log("filterProducts: ", this.deals_s);
          });
        });
      }

      // filter categories
      else if (this.categoryfilters.length > 0) {
        this.categoryfilters.forEach((category) => {
          this.filterCategories({
            category_id: category.id,
          }).then((response) => {
            this.loading = false;
            categoryResp = [
              ...new Set([...categoryResp, ...response.items.data]),
            ];
            this.deals_s = [...this.deals_s, ...categoryResp];
            this.deals_s = this.removeDuplicates(this.deals_s);
            console.log(
              "categoryResp: ",
              categoryResp,
              " response: ",
              response
            );
            console.log("filterCategories: ", this.deals_s);
          });
        });
      } else {
        this.loading = false;
        this.deals_s = this.deals;
      }
    },

    async getSuperDeals() {
      await apiService
        .customRequest({
          method: "get",
          url: "/items/superdeals",
          params: {
            page: ++this.page,
            // per_page: this.pageSize,
          },
        })
        .then((res) => {
          let response = res.data.data.current_deals;

          this.deals = [...this.deals, ...response.data];
          console.log("Resources " + this.dealType + " deals: ", this.deals);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

  computed: {
    info() {
      return {
        title: this.$t("currentDeals"),
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
      };
    },
  },

  watch: {
    page(value) {
      this.disable = value > this.last_page;
    },
  },

  created() {
    console.log(" current products props: ", this.products);

    this.cr_deals = this.products;
    this.deals = this.deals_s = this.cr_deals;
    console.log("In current deals", this.cr_deals);
    this.current = true;

    if (this.deals.length < 1) {
      console.log("empty result set 2", this.deals.length);
      this.empty = true;
      this.disable = true;
    }

    if (this.superDeals_.current_deals) {
      this.last_page = this.superDeals_
        ? this.superDeals_.current_deals.last_page
        : 0;
    }

    this.featuresNonColor = this.loadFeatures_(this.cr_deals);
    this.colorFeatures = this.loadColorFeatures_();
    this.categoriesList = this.loadCategories_(this.cr_deals);

    this.loadPrdtPrices_(this.cr_deals);

    console.log("cr_deals last page: ", this.last_page);

    this.$root.$on("refreshFilter", (refresh) => {
      if (refresh) {
        console.log("refreshing filter");
        this.filterRequest();
      }
    });

    this.$root.$on("emptyFilters", (empty) => {
      if (empty) {
        this.deals_s = this.cr_deals;
      }
    });
  },

  mounted() {
    this.$root.$on("filterValues", (filters) => {
      console.log("filter request colors", filters.colors);
      console.log("filter request categories", filters.categories);
      console.log("filter request features", filters.features);
      this.colorfilters = filters.colors;
      this.categoryfilters = filters.categories;
      this.featurefilters = filters.features;
    });

    this.$root.$on("currentMin", (min) => {
      console.log("current Min: ", min);
      this.currentMin = min;
    });
    this.$root.$on("currentMax", (max) => {
      console.log("current Max: ", max);
      this.currentMax = max;
    });
  },
};
</script>

<style scoped>
.slider {
  width: 200px;
}
.sidebar {
  height: 100vh;
  overflow-y: auto;
  overflow-x: visible;
  z-index: 1000;
  min-width: 8em;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar > div {
  position: relative;
  height: 100%;
  overflow: visible;
}

.col-2 {
  flex: 0 0 18.666667%;
  max-width: 64.666667%;
}
.prdtCard {
  height: 23.8em;
  width: 220px;
  height: 388px;
  display: block;
  position: relative;
  transition: all 0.2s linear;
}

.color-font {
  color: #f1921d !important;
}

a:hover > .prdtName {
  color: #ff8100 !important;
}

.flash-illus {
  width: 100px;
  margin-top: -1em;
  display: flex;
}

.prdtCard:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-color: #e0e0e0;
}

.prdtImage .img-fluid {
  width: 100%;
  height: 17.5em;
  object-fit: cover;
}

.prdtName {
  font-size: 15px;
  font-weight: normal;
}

.prdtName.text-truncate {
  white-space: normal !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.item_detail {
  line-height: 20px;
  height: 20px;
  margin-top: 15px;
}

.item_delete {
  margin-top: 2px;
  margin-bottom: 4px;
  height: 28px;
}

.delPrice {
  display: inline-block;
  width: 100%;
  height: 28px;
  font-size: 12px;
  color: #999;
  text-decoration: none;
}

.priceTag {
  font-size: 18px;
  font-weight: 400;
  display: inline-block;
  line-height: 20px;
  height: 20px;
}

.rrp {
  display: inline-block;
  line-height: 14px;
}

.prdtDiscount {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 10px;
  width: 50px;
  height: 50px;
  background-color: #ffa300;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  /* display: flex; */
}

.prdtDiscount > strong {
  font-size: 15px;
}

.prdtDiscount > em {
  font-size: 12px;
}

.prdtDiscount > p {
  font-size: 11px;
}
a.card {
  color: black !important;
}

a > .prdtName {
  color: black !important;
}
</style>
