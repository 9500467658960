import { OrderService } from "@/services/order";

const state = {
  cartItems: [],
};

// let session = context.rootState.order;

const mutations = {
  removeSuccess(state, payload) {},

  ADD_TO_CART(state, payload) {
    state.cartItems.push(payload);
  },

  UPDATE_CART(state, payload) {
    state.cartItems = [];
    state.cartItems.push(payload);
    // router.push({ name: "cart" });
    // $forceUpdate()
  },
};

const getters = {};

const actions = {
  async addToCart({ commit }, payload) {
    try {
      const response = await OrderService.addToCart(payload);

      console.log("Order: ", response.data.order);
      commit("ADD_TO_CART", payload);

      // router.push(router.history.current.query.redirect || '/user')
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async updateCart({ commit }, payload) {
    try {
      const response = await OrderService.updateCart(payload);

      console.log("Order Update Index: ", response.data.order);
      commit("UPDATE_CART", payload);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async removeFromCart({ commit }, payload) {
    try {
      const response = await OrderService.removeFromCart(payload);

      console.log("Remove an Order: ", response);
      commit("removeSuccess", response);

      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
