import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};

  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  console.log("locale messages: ", messages);
  return messages;
}

// console.log("messages:", loadLocaleMessages())

export default new VueI18n({
  locale: navigator.language.split("-")[0] || "en", // set locale
  fallbackLocale: "fr", // set fallback locale
  messages: loadLocaleMessages(), // set locale messages
});
