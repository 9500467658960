<template>
  <div id="recommended" class="row mb-5 mt-3" v-if="recommended_">
    <div class="col-md-12 px-2 px-md-0 px-lg-0 py-4">
      <div
        class="
          col-md-12
          px-0
          section-head
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <h3 class="sectionTitle my-0 px-md-0 px-lg-0 pl-1 pr-2">
          <i class="common like"></i>
          {{ $t("recommended") }}
        </h3>
      </div>

      <div class="container pt-0 px-md-auto px-lg-auto">
        <div class="row">
          <!-- Desktop Display -->
          <div
            class="
              col-sm-6 col-6 col-md-4 col-lg-2
              item
              px-1 px-md-1 px-lg-1
              pb-0
              pt-3
              d-sm-block d-lg-block d-md-block d-none
            "
            v-for="(product, index) of products"
            :key="'recD-' + index"
          >
            <router-link
              :to="{
                name: 'rec-detail',
                params: { slug: product.slug, prdtId: product.id },
              }"
              class="card prdtCard h-100 rounded"
            >
              <div class="prdtImage">
                <img
                  :src="product.image_url"
                  @load="masks.push(index)"
                  @error="defaultImage"
                  class="img-fluid p-2"
                />
              </div>
              <div class="prdtDiscount" v-if="product.item_discount">
                <strong class="my-auto">{{
                  product.item_discount.percentage_decrease | percent
                }}</strong>
                <p class="mb-0 mt-n1 text-center">{{ $t("percentOff") }}</p>
              </div>

              <div class="card-footer bg-transparent rounded-0 border-0 px-3">
                <router-link
                  :to="{
                    name: 'rec-detail',
                    params: { slug: product.slug, prdtId: product.id },
                  }"
                >
                  <h6 class="prdtName text-capitalize text-truncate">
                    {{ product.name }}
                  </h6>
                </router-link>

                <div class="mt-5" v-if="product.item_discount">
                  <p class="font-15 text-muted mb-0 rrp text-uppercase">
                    {{ $t("rrp") }}:
                    <span>
                      <strike>{{ product.price | currency("XFA ") }}</strike>
                    </span>
                  </p>
                  <p
                    class="color-font priceTag mb-0 mt-1"
                    v-if="product.item_discount"
                  >
                    {{ product.item_discount.unit_price | currency("XFA ") }}
                  </p>
                </div>
                <div class="mt-2" v-else>
                  <p class="color-font priceTag mb-0 mt-1">
                    {{ product.price | currency("XFA ") }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>

          <!-- Mobile Display -->
          <div
            class="
              col-sm-6 col-6 col-md-4 col-lg-2
              item
              pb-0
              pt-3
              d-sm-block d-block d-lg-none d-md-none
            "
            v-for="(product, index) of products"
            :key="index"
          >
            <router-link
              :to="{
                name: 'rec-detail',
                params: { slug: product.slug, prdtId: product.id },
              }"
              class="card h-100 prdtCard rounded"
            >
              <div class="prdtImage">
                <img
                  :src="product.image_url"
                  @error="defaultImage"
                  class="img-fluid p-2"
                />
              </div>
              <div class="prdtDiscount" v-if="product.item_discount">
                <strong class="my-auto">{{
                  product.item_discount.percentage_decrease | percent
                }}</strong>
                <p class="mb-0 mt-n1 text-center">{{ $t("percentOff") }}</p>
              </div>

              <div
                class="card-footer pt-1 bg-transparent rounded-0 border-0 px-3"
              >
                <router-link
                  :to="{
                    name: 'rec-detail',
                    params: { slug: product.slug, prdtId: product.id },
                  }"
                >
                  <h6 class="prdtName text-capitalize text-truncate">
                    {{ product.name }}
                  </h6>
                </router-link>

                <div class="mt-3 mb-1" v-if="product.item_discount">
                  <p class="font-15 text-muted mb-0 rrp text-uppercase">
                    {{ $t("rrp") }}:
                    <span>
                      <strike>{{ product.price | currency("XFA ") }}</strike>
                    </span>
                  </p>
                  <p
                    class="color-font priceTag mb-0 mt-1"
                    v-if="product.item_discount"
                  >
                    {{ product.item_discount.unit_price | currency("XFA ") }}
                  </p>
                </div>
                <div class="mt-2" v-else>
                  <p class="color-font priceTag mb-0 mt-1">
                    {{ product.price | currency("XFA ") }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <scroll-loader
          :loader-method="getProductList"
          :loader-disable="disable"
        ></scroll-loader>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "@/main";
import StarRating from "vue-star-rating";
import "@/assets/css/home/recommended/recommended.css";
import { mapGetters } from "vuex";

export default {
  components: {
    StarRating,
  },
  data() {
    return {
      productName: "Product name",
      productDetails: "product details",
      productPrice: "price",
      products: [],
      disable: false,
      page: 1,
      last_page: 0,
      pageSize: 12,
      images: [],
      masks: [],
      recommended: null,

      rating: 0,
    };
  },

  methods: {
    async getProductList() {
      await apiService
        .customRequest({
          method: "get",
          url: "/recommended",
          params: {
            page: this.page++,
            per_page: this.pageSize,
          },
        })
        .then((res) => {
          let response = res.data.data.recommended;
          response.data &&
            (this.products = [...this.products, ...response.data]);

          console.log("Resources: ", response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    newPrice(price, discount) {
      return price - discount * price;
    },
  },

  computed: {
    ...mapGetters(["getRecommendedObj"]),
  },

  watch: {
    page(value) {
      this.disable = value > this.last_page;
    },
  },

  created() {
    console.log("rec", this.recommended_);
    if (this.recommended_) {
      this.last_page = this.recommended_ ? this.recommended_.last_page : 0;
    } else {
      console.log("guest data recomended", this.getRecommendedObj);
      this.last_page = this.getRecommendedObj
        ? this.getRecommendedObj.data.last_page
        : 0;
    }
    console.log("Recommended Vue last page", this.last_page);
  },
};
</script>

<style scoped></style>
