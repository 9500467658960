<template>
  <div class="row">
    <!-- Side Panel -->
    <div class="col-sm-3 col-md-3 col-12 order-3 sidebar mt-4">
      <!-- Shop Information Box -->
      <div
        class="card border-0 shadow-sm rounded px-4 py-4 mt-n2"
        v-if="widgetName === 'Shop View' && widgetInfo"
      >
        <div class="text-center" v-if="widgetInfo.other">
          <img
            :src="widgetInfo.other.avatar_url"
            alt="shop avatar url"
            class="img-fluid my-3"
            style="height: 150px"
          />

          <div class="mb-3 mt-0">
            <h3 class="mb-0">{{ widgetInfo.title }}</h3>
            <p
              class="font-15 mt-1 mb-2"
              style="color: #c27f08"
              v-if="widgetInfo.other.email"
            >
              {{ widgetInfo.other.email }}
            </p>

            <div :class="widgetInfo.other.email ? '' : 'mt-2'">
              <small>{{ widgetInfo.other.address }}</small>
            </div>
          </div>

          <p class="my-3 text-capitalize">{{ widgetInfo.other.description }}</p>
        </div>
      </div>

      <!-- Price filter -->
      <div
        class="card border-0 shadow-sm rounded px-4 pb-4"
        :class="[
          widgetName === 'Shop View'
            ? 'mt-8'
            : widgetName.includes('Deals')
            ? 'mt-auto'
            : 'mt-n2',
          ,
        ]"
      >
        <h3 class="section-title">{{ $t("FilterbyPrice") }}</h3>

        <price-range-slider :min="min" :max="max" :trackHeight="0.5" />
      </div>

      <!-- Color Filter -->
      <div class="card border-0 shadow-sm rounded px-4 mt-8 sidebar-widget">
        <h3 class="section-title">{{ $t("FilterbyColor") }}</h3>

        <ul class="nav-list px-0">
          <li class="" v-for="(value, c) in colors" :key="c + '-color'">
            <div class="type-color d-flex justify-content-between">
              <label class="checkbox-container"
                ><span class="label">{{ c }}</span>
                <input
                  type="checkbox"
                  v-model="filterColorsSelected"
                  :value="c"
                  @change="check($event)"
                />
                <span
                  :class="[c == 'white' ? 'checkmark-grey' : 'checkmark']"
                  :style="[
                    c == 'white' ? { border: '1px solid grey' } : {},
                    { backgroundColor: c, opacity: 0.7 },
                  ]"
                ></span>
              </label>

              <span class="count">({{ value }})</span>
            </div>
          </li>
        </ul>
      </div>

      <!-- Product Categories filter -->
      <div
        id="prdtCats"
        class="card border-0 shadow-sm rounded px-4 mt-8 sidebar-widget"
        v-if="categories && widgetName != 'Category List'"
      >
        <h3 class="section-title">{{ $t("ProductCategories") }}</h3>
        <slot name="categoryLoader"></slot>

        <ul class="list-unstyled px-0 mb-1">
          <li
            v-for="category in categories.slice(
              0,
              categories.length < listCount ? categories.length : listCount
            )"
            :key="'mainListCatgry-' + category.id"
          >
            <a href="#">
              <label class="checkbox-container"
                ><span class="label">{{
                  category.name | truncate(20, "...")
                }}</span>
                <input
                  type="checkbox"
                  v-model="filterCategoriesSelected"
                  :value="category"
                  @change="check($event)"
                />
                <span class="checkmark2"></span>
              </label>
            </a>
          </li>
        </ul>
        <hr class="my-2" v-show="categories.length > listCount" />
        <button
          class="btn btn-link btn-sm pb-3 font-12"
          :disabled="categories.length < listCount"
          @click="listCount += 2"
          v-show="categories.length > listCount"
        >
          {{ $t("ShowMore") }}
        </button>
      </div>

      <!-- Custom Slot Filters -->
      <div v-if="numSlots">
        <div
          class="card border-0 shadow-sm rounded px-4 mt-8 sidebar-widget"
          v-for="i in numSlots"
          :key="i"
        >
          <slot :name="'customFilter' + i"></slot>
        </div>
      </div>

      <!-- Other filters -->
      <div
        id="otherFilters"
        class="card border-0 shadow-sm rounded px-4 mt-8 sidebar-widget"
      >
        <h3 class="section-title">{{ $t("OtherFeatures") }}</h3>
        <ul class="list-unstyled px-0 mb-1">
          <li v-for="filter in otherFilters" :key="filter.key">
            <h6 class="text-capitalize mb-5">
              {{ filter.key }}
            </h6>
            <ul class="list-unstyled px-0 mb-1">
              <li
                v-for="(value, index) in filter.value.slice(0, listCount)"
                :key="filter.key + '-' + index"
              >
                <a href="#">
                  <label class="checkbox-container"
                    ><span class="label">{{ value.value }}</span>
                    <input
                      type="checkbox"
                      v-model="filterFeaturesSelected"
                      :value="value"
                      @change="check($event)"
                    />
                    <span class="checkmark2"></span>
                  </label>
                </a>
              </li>
              <button
                :data-key="filter.key"
                class="btn btn-link btn-sm pb-3 font-12"
                :disabled="filter.value.length < listCount"
                @click="listCount += 2"
                v-show="filter.value.length > listCount"
              >
                {{ $t("ShowMore") }}
              </button>
            </ul>
          </li>
        </ul>
      </div>

      <!-- Filter button -->
      <button
        class="
          btn-orange btn btn-block
          shadow-sm
          filter-btn
          float-right float-right
          mt-5
        "
        @click.prevent="filterRequest()"
      >
        {{ $t("Filter") }}
      </button>

      <button
        class="
          btn-danger btn btn-block
          shadow-sm
          filter-btn
          float-right float-right
          mt-2
        "
        @click.prevent="removeFilters()"
      >
        {{ $t("Clear") }}
      </button>
    </div>

    <!-- Main Product Display -->
    <div
      class="col-12 col-sm-12 col-md-12 col-lg-9 order-9 px-1"
      :class="fValues ? ' py-4' : ''"
    >
      <!-- Banner Image -->
      <div id="banner" class="banner-carousel hidden-xs" v-if="banner">
        <div class="item">
          {{ computeBrightness(widgetImage) }}
          <div class="image">
            <img :src="widgetImage" alt="BIG SALE" class="img-responsive" />
            <div
              :class="[brightness > imgThreshold ? 'dark-overlay' : '']"
            ></div>
          </div>
          <div class="container-fluid">
            <div class="caption vertical-top text-left my-auto">
              <div class="big-text" v-if="widgetInfo.title">
                {{ widgetInfo.title | truncate(18, "...") }}
              </div>
              <div
                class="excerpt hidden-sm hidden-md"
                v-if="minimumDiscount(products) != 0"
              >
                {{ $t("Saveupto") }}
                <strong>{{ minimumDiscount(products) }}% </strong>
                {{ $t("off") }}
              </div>

              <div class="excerpt hidden-sm hidden-md" v-else>
                {{ $t("stunningPrices") }}
              </div>

              <div class="excerpt-normal pr-2 text-capitalize">
                {{ widgetInfo.description }}
              </div>
              <div class="buy-btn">
                <a
                  href="#"
                  class="lnk btn btn-orange text-white"
                  @click.prevent="$router.push('/')"
                  >{{ $t("ShopNow") }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Remove filters -->
      <ul class="remove-filter list-unstyled px-0 mb-0" v-if="fValues">
        <li v-for="(val, i) in fValues" :key="val + '-' + i">
          <a
            href="#"
            @click.prevent="removeFilterValue(val)"
            class="remove-filter-element attributes"
            >{{ typeof val === "object" ? val.value : val }}</a
          >
        </li>
      </ul>

      <!-- Filtered products -->
      <div
        class="filters-container shadow-sm border-0 rounded mt-3"
        v-if="products"
      >
        <!-- Filters header -->
        <div class="row bg-white">
          <div class="col col-md-6 col-6 px-0 nft">
            <div class="filter-tabs">
              <ul
                id="filter-tabs"
                class="nav nav-tabs nav-tab-box nav-tab-fa-icon"
              >
                <li :class="[gridView ? 'active' : '']">
                  <a
                    href="#"
                    class="button-grid"
                    @click.prevent="
                      gridView = true;
                      listView = false;
                    "
                    ><i class="icon fa fa-th-large"></i>{{ $t("Grid") }}</a
                  >
                </li>
                <li :class="[listView ? 'active' : '']">
                  <a
                    href="#"
                    class="button-list"
                    @click.prevent="
                      listView = true;
                      gridView = false;
                    "
                    ><i class="icon fa fa-bars"></i>{{ $t("List") }}</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <!-- Filter Select box -->
          <div class="col col-md-6 col-6 text-right nfb">
            <form class="filter-ordering" method="get">
              <select name="orderby" class="orderby" aria-label="Shop order">
                <option value="popularity">{{ $t("Sortbypopularity") }}</option>
                <option value="rating">{{ $t("Sortbyaveragerating") }}</option>
                <option value="date" selected="selected">
                  {{ $t("Sort by latest") }}
                </option>
                <option value="price">{{ $t("Sortbylowtohigh") }}</option>
              </select>
              <input type="hidden" name="paged" value="1" />
              <input type="hidden" name="shop_view" value="grid_view" /><input
                type="hidden"
                name="filter_color"
                value="black"
              />
            </form>
          </div>

          <!-- Mobile Filter Select box -->
          <div class="col-6 float-left mfb">
            <form class="filter-ordering" method="get">
              <select name="orderby" class="orderby" aria-label="Shop order">
                <option value="popularity">{{ $t("Sortbypopularity") }}</option>
                <option value="rating">{{ $t("Sortbyaveragerating") }}</option>
                <option value="date" selected="selected">
                  {{ $t("Sortbylatest") }}
                </option>
                <option value="price">{{ $t("Sortbylowtohigh") }}</option>
              </select>
              <input type="hidden" name="paged" value="1" />
              <input type="hidden" name="shop_view" value="grid_view" /><input
                type="hidden"
                name="filter_color"
                value="black"
              />
            </form>
          </div>

          <!-- Mobile Filter Categories -->
          <div class="col-6 float-right mft">
            <div
              id="mobilefilter"
              class="
                navbar navbar-expand-lg navbar-light
                float-right
                py-0
                pr-0
                mr-n2
              "
            >
              <button
                class="btn-navbar navbar-toggler border-0 float-right"
                type="button"
                data-toggle="collapse"
                data-target="#filterMenu"
                aria-controls="filterMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <div class="rule"></div>
                <i class="mdi mdi-filter-outline"></i>
              </button>

              <button
                class="btn mobi-grid"
                @click.prevent="
                  gridView = !gridView;
                  listView = !listView;
                "
              >
                <i class="mdi mdi-view-grid-outline" v-show="gridView"></i>

                <i class="mdi mdi-format-list-checkbox" v-show="listView"></i>
              </button>

              <div
                class="collapse navbar-collapse"
                id="filterMenu"
                v-if="colors"
              >
                <MobileFilter
                  :priceInfo="{
                    min: min,
                    max: max,
                  }"
                  :colors="colors"
                  :categories="categories"
                  :otherInfo="widgetInfo"
                  :otherFilters="otherFilters"
                  :filterRequest="filterRequest"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row product-listing" :class="[listView ? 'mt-5' : 'mt-2']">
          <!-- Grid View -->
          <div
            class="
              col-sm-6 col-6 col-md-4 col-lg-3
              item
              px-1 px-sm-1 px-md-1 px-lg-1
              pb-0 pb-md-3 pb-lg-3
            "
            v-for="product in products"
            :key="product.id + 'prdt'"
            v-show="gridView"
          >
            <router-link
              :to="{
                name: widgetName.includes('Deals')
                  ? 'deals-detail'
                  : 'newproduct-detail',
                params: { slug: product.slug, prdtId: product.id },
              }"
              class="
                card
                border-0
                prdtCard
                rounded
                d-sm-block d-none d-lg-block d-md-block
              "
            >
              <div class="prdtImage">
                <img
                  :src="product.image_url"
                  @error="defaultImage"
                  class="img-fluid p-2"
                />
              </div>
              <div class="prdtDiscount" v-if="product.item_discount">
                <strong class="my-auto">{{
                  product.item_discount.percentage_decrease | percent
                }}</strong>
                <p class="mb-0 mt-n1 text-center">{{ $t("percentOff") }}</p>
              </div>

              <div class="card-footer bg-transparent rounded-0 border-0 px-3">
                <router-link
                  :to="{
                    name: widgetName.includes('Deals')
                      ? 'deals-detail'
                      : 'newproduct-detail',
                    params: { slug: product.slug, prdtId: product.id },
                  }"
                >
                  <h6 class="prdtName text-capitalize text-truncate">
                    {{ product.name }}
                  </h6>
                </router-link>

                <div class="mt-5" v-if="product.item_discount">
                  <p class="font-15 text-muted mb-0 rrp text-uppercase">
                    {{ $t("rrp") }}:
                    <span>
                      <strike>{{ product.price | currency("XFA ") }}</strike>
                    </span>
                  </p>
                  <p
                    class="color-font priceTag mb-0 mt-1"
                    v-if="product.item_discount"
                  >
                    {{ product.item_discount.unit_price | currency("XFA ") }}
                  </p>
                </div>
                <div class="mt-2" v-else>
                  <p class="color-font priceTag mb-0 mt-1">
                    {{ product.price | currency("XFA ") }}
                  </p>
                </div>
              </div>
            </router-link>

            <!-- Mobile Grid View -->
            <router-link
              :to="{
                name: widgetName.includes('Deals')
                  ? 'deals-detail'
                  : 'newproduct-detail',
                params: { slug: product.slug, prdtId: product.id },
              }"
              class="
                card
                prdtCard
                rounded
                d-sm-block d-block d-lg-none d-md-none
              "
            >
              <div class="prdtImage">
                <img
                  :src="product.image_url"
                  @error="defaultImage"
                  class="img-fluid p-2"
                />
              </div>
              <div class="prdtDiscount" v-if="product.item_discount">
                <strong class="my-auto">{{
                  product.item_discount.percentage_decrease | percent
                }}</strong>
                <p class="mb-0 mt-n1 text-center">{{ $t("percentOff") }}</p>
              </div>

              <div class="card-footer bg-transparent rounded-0 border-0 px-3">
                <router-link
                  :to="{
                    name: widgetName.includes('Deals')
                      ? 'deals-detail'
                      : 'newproduct-detail',
                    params: { slug: product.slug, prdtId: product.id },
                  }"
                >
                  <h6 class="prdtName text-capitalize text-truncate">
                    {{ product.name }}
                  </h6>
                </router-link>

                <div class="mt-5" v-if="product.item_discount">
                  <p class="font-15 text-muted mb-0 rrp text-uppercase">
                    {{ $t("rrp") }}:
                    <span>
                      <strike>{{ product.price | currency("XFA ") }}</strike>
                    </span>
                  </p>
                  <p
                    class="color-font priceTag mb-0 mt-1"
                    v-if="product.item_discount"
                  >
                    {{ product.item_discount.unit_price | currency("XFA ") }}
                  </p>
                </div>
                <div class="mt-2" v-else>
                  <p class="color-font priceTag mb-0 mt-1">
                    {{ product.price | currency("XFA ") }}
                  </p>
                </div>
              </div>
            </router-link>
          </div>

          <!-- List View -->
          <div
            class="
              col-lg-12 col-md-6 col-sm-12 col-12
              desk-list
              mb-2
              px-0
              card
              shadow-sm
            "
            v-for="product in products"
            :key="'lview-' + product.id"
            v-show="listView"
          >
            <router-link
              :to="{
                name: widgetName.includes('Deals')
                  ? 'deals-detail'
                  : 'newproduct-detail',
                params: { slug: product.slug, prdtId: product.id },
              }"
              class="list-card border-0 rounded-0 deskListView"
            >
              <!-- d-none d-sm-none d-md-block d-lg-block d-xl-block -->

              <div class="row">
                <div class="col-4 col-md-5 col-lg-4 pr-1 py-0">
                  <img
                    :src="product.image_url"
                    @error="defaultImage"
                    class="img-fluid prdtImage3 p-2"
                  />
                </div>

                <div class="col-8 col-md-7 col-lg-8 pl-0">
                  <router-link
                    :to="{
                      name: widgetName.includes('Deals')
                        ? 'deals-detail'
                        : 'newproduct-detail',
                      params: { slug: product.slug, prdtId: product.id },
                    }"
                  >
                    <h6 class="prdtName2 text-truncate text-capitalize">
                      {{ product.name }}
                    </h6>
                  </router-link>

                  <star-rating
                    :rating="rating"
                    :read-only="true"
                    :increment="0.5"
                    :star-size="12"
                    :show-rating="false"
                    class="rating"
                  ></star-rating>

                  <div class="row prdtPricing">
                    <div class="col-8 pl-3 pr-0">
                      <p
                        v-if="product.item_discount"
                        class="font-15 text-muted mb-0 rrp3 text-uppercase"
                      >
                        {{ $t("rrp") }}:
                        <span>
                          <strike>{{
                            product.price | currency("XFA ")
                          }}</strike>
                        </span>
                      </p>
                      <p
                        class="color-font priceTag mb-0 mt-1"
                        v-if="product.item_discount"
                      >
                        {{
                          product.item_discount.unit_price | currency("XFA ")
                        }}
                      </p>
                      <p
                        class="color-font priceTag mb-0 mt-1"
                        v-if="!product.item_discount"
                      >
                        {{ product.price | currency("XFA ") }}
                      </p>
                    </div>
                    <div class="col-4 pr-0 mr-0 align-bottom">
                      <div class="prdtDiscount3" v-if="product.item_discount">
                        <strong class="my-auto">{{
                          product.item_discount.percentage_decrease | percent
                        }}</strong>
                        <p class="mb-0 mt-n1 text-center">{{ $t("OFF") }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>

            <!-- Mobile List View -->
            <router-link
              :to="{
                name: widgetName.includes('Deals')
                  ? 'deals-detail'
                  : 'newproduct-detail',
                params: { slug: product.slug, prdtId: product.id },
              }"
              class="mobiListView"
            >
              <!-- d-sm-block d-md-none d-lg-none d-xl-none d-block -->
              <div class="row px-4">
                <div class="col-4 p-2">
                  <img
                    :src="product.image_url"
                    @error="defaultImage"
                    class="img-fluid d-block mx-auto"
                  />
                </div>

                <div class="col-8 prdtInfo pr-2">
                  <router-link
                    :to="{
                      name: widgetName.includes('Deals')
                        ? 'deals-detail'
                        : 'newproduct-detail',
                      params: { slug: product.slug, prdtId: product.id },
                    }"
                  >
                    <h6 class="prdtName2 text-capitalize text-truncate">
                      {{ product.name }}
                    </h6>
                  </router-link>

                  <star-rating
                    :rating="rating"
                    :read-only="true"
                    :increment="0.5"
                    :star-size="12"
                    :show-rating="false"
                    class="rating"
                  ></star-rating>

                  <div
                    class="
                      price-div
                      d-flex
                      justify-content-between
                      align-items-center
                    "
                    v-if="product.item_discount"
                  >
                    <div class="price-info">
                      <p class="text-muted mb-0 rrp text-uppercase">
                        {{ $t("rrp") }}:
                        <span>
                          <strike>{{
                            product.price | currency("XFA ")
                          }}</strike>
                        </span>
                      </p>
                      <p
                        class="color-font priceTag mb-0 mt-1"
                        v-if="product.item_discount"
                      >
                        {{
                          product.item_discount.unit_price | currency("XFA ")
                        }}
                      </p>

                      <div class="mt-2" v-else>
                        <p class="color-font priceTag mb-0 mt-1">
                          {{ product.price | currency("XFA ") }}
                        </p>
                      </div>
                    </div>

                    <div class="prdtDiscount2" v-if="product.item_discount">
                      <strong class="my-auto">{{
                        product.item_discount.percentage_decrease | percent
                      }}</strong>
                      <p class="mb-0 mt-n1 text-center">
                        {{ $t("percentOff") }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>

      <!-- Empty Products -->
      <div
        class="row justify-content-center"
        v-if="products.length == 0 || !products"
      >
        <div class="col-md-12 mt-8">
          <div class="text-center">
            <img src="@/assets/empty-search.png" alt="empty search" />
            <h5 class="text-muted mt-5">
              {{ $t("noProductFound") }}
            </h5>

            <p class="text-center font-weight-bold mt-2">
              {{ $t("possibleReasons") }}
            </p>

            <p class="font-15 mt-4 mb-1">
              {{ $t("whatYouSearched") }}
            </p>

            <p class="text-center mb-2">{{ $t("or") }}</p>
            <p class="font-15">{{ $t("resultSetIsEmpty") }}!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";

import PriceRangeSlider from "@/components/widgets/PriceRangeSlider";
import MobileFilter from "@/components/prdts-display/MobilePrdtsFilter";

import "@/assets/css/prdts-display/display.css";
import "@/assets/css/prdts-display/product.css";

export default {
  props: {
    widgetName: {
      type: String,
      required: false,
    },
    widgetImage: {
      type: String,
      required: false,
      default: "",
    },
    widgetInfo: {
      type: Object,
      required: false,
    },
    banner: {
      type: Boolean,
      required: true,
      default: false,
    },
    colors: {
      type: Object,
      required: false,
    },
    categories: {
      type: Array,
      required: false,
    },
    products: {
      type: Array,
      required: true,
    },
    otherFilters: {
      type: Array,
      required: false,
    },
    numSlots: {
      type: Number,
      required: false,
    },
    filterRequest: {
      type: Function,
    },
  },
  components: {
    PriceRangeSlider,
    MobileFilter,
    StarRating,
  },

  data() {
    return {
      listView: false,
      gridView: true,
      brightness: 0,
      rating: 2,

      imgThreshold: 110,
    };
  },

  computed: {
    min() {
      return this.widgetInfo.minPrice;
    },

    max() {
      return this.widgetInfo.maxPrice;
    },
  },

  watch: {
    fValues(val) {
      console.log("fValues selected:", this.fValues);
      this.$root.$emit("fvalues", val);
    },
  },

  methods: {
    computeBrightness(image) {
      this.getImageBrightness(image, (brightness) => {
        console.log(" Image brightness 2: ", brightness);

        this.brightness = brightness;
      });
    },
  },

  created() {
    this.minimumDiscount(this.products);
    console.log(
      "General products from " + this.widgetName + ": ",
      this.products
    );

    // this.$root.$on("resetMinMax", (reset) => {
    //   console.log("in reset ", reset);
    //   if (reset) {
    //     this.min = this.min;
    //     this.min = this.max;
    //     console.log(
    //       "reset min: ",
    //       this.minAngle,
    //       " reset max: ",
    //       this.maxAngle
    //     );
    //   }
    // });
  },

  mounted() {},
};
</script>
