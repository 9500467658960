import apiService from "../main";
import TokenService from "./storage";

class EndPointError extends Error {
  constructor(errorCode, message) {
    super(message);
    this.name = this.constructor.name;
    this.message = message;
    this.errorCode = errorCode;
  }
}

const UserService = {
  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns access_token
   * @throws EndPointError
   **/
  login: async function (payload) {
    const requestData = {
      method: "post",
      url: "/login",
      data: {
        identifier: payload.identifier,
        password: payload.password,
        remember_me: payload.remember_me,
      },
    };

    try {
      const response = await apiService.customRequest(requestData);

      TokenService.saveToken(response.data.data.access_token);
      // TokenService.saveRefreshToken(response.data.refresh_token)

      console.log("Login Token: ", response.data.data);

      return response.data;
    } catch (error) {
      console.log("login error response; ", error.response);
      return error.response;
    }
  },

  /**
   * Register user.
   **/
  register: async function (user) {
    const request = {
      method: "post",
      url: "/register",
      data: {
        name: user.name,
        email: user.email,
        password: user.password,
        has_agree: user.has_agree,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      // TokenService.saveToken(response.data.token);
      // TokenService.saveRefreshToken(response.data.refresh_token)

      console.log("Register userjs response: ", response.data);

      return response.data;
    } catch (error) {
      console.log(error.response);
      return error.response;
      // throw new EndPointError(error.response.status, error.response.data.msg);
    }
  },

  /**
   * Social Auth.
   **/
  social: async function (type) {
    const request = {
      method: "get",
      url: "/auth/" + type,
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("social authjs response: ", response.data);

      return response.data;
    } catch (error) {
      console.log(error.response);
      return error.response;
    }
  },

  /**
   * Recreate user password
   **/
  forgotPassword: async function (payload) {
    const request = {
      method: "post",
      url: "/forgot-password/sent/email",
      data: payload,
    };

    console.log("Payload: ", payload);

    try {
      const response = await apiService.customRequest(request);

      console.log("Reset Password response code: ", response.data.code);

      return response;
    } catch (error) {
      console.log("forgot password error: ", error);
      return error;
    }
  },

  /**
   * Reset user password.
   **/
  setNewPassword: async function (payload) {
    const request = {
      method: "post",
      url: "/reset-password",
      data: payload,
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Reset Password response code: ", response.data.code);

      return response.data;
    } catch (error) {
      console.log("set New Password error: ", error.response);
      return error.response;
    }
  },

  /**
   * Get user info.
   */
  getUserInfo: async function (userId = Math.random(2000)) {
    const requestData = {
      method: "get",
      url: "/users/" + userId,
    };

    try {
      const response = await apiService.customRequest(requestData);

      return response.data;
    } catch (error) {
      console.log("Get user info error: ", error.response);
      return error.response;
    }
  },

  /**
   * Get Country States data info
   */
  getStates: async function (countryId) {
    const requestData = {
      method: "get",
      url: "/cities/" + countryId,
    };

    try {
      const response = await apiService.customRequest(requestData);

      console.log("Get States: ", response.data.data);

      return response.data.data;
    } catch (error) {
      console.log("Get states error: ", error.response);
      return error.response;
    }
  },

  /**
   * Update user info.
   * country_id, state_id, city_id
   **/
  update: async function (user) {
    const request = {
      method: "post",
      url: "/users/" + user.id + "/update",
      data: {
        name: user.name,
        email: user.email,
        username: user.username,
        phone: user.phone,
        country_id: user.country_id,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("user Update: ", response.data);

      return response.data;
    } catch (error) {
      console.log("user Update error: ", error.response);
      return error.response;
    }
  },

  /**
   * Subscribe to Newsletter.
   **/
  subscribeNewsletter: async function (payload) {
    const request = {
      method: "post",
      url: "/newsletter/subscribe",
      data: {
        email: payload.email,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Newsletter updates: ", response);

      return response.data;
    } catch (error) {
      console.log("newletter error: ", error.response);
      return error.response;
    }
  },

  /**
   * Add address to AddessBook.
   **/
  addNewAddress: async function (payload) {
    const request = {
      method: "post",
      url: "/users/" + payload.id + "/add/address",
      data: {
        phone: payload.phone,
        country_id: payload.country_id,
        city_id: payload.city_id,
        quarter: payload.quarter,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Address Book: ", response);

      return response.data;
    } catch (error) {
      console.log("Add address Book error: ", error.response);
      return error.response;
    }
  },

  /**
   * Update address.
   **/
  updateAddress: async function (payload) {
    const request = {
      method: "post",
      url: "/users/" + payload.id + "/update/address",
      data: {
        phone: payload.phone,
        country_id: payload.country_id,
        city_id: payload.city_id,
        quarter: payload.quarter,
        default: payload.default,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Address Book: ", response);

      return response.data;
    } catch (error) {
      console.log("Update address Book error: ", error.response);
      return error.response;
    }
  },

  /**
   * Delete address.
   **/
  deleteAddress: async function (payload) {
    const request = {
      method: "post",
      url: "/users/" + payload.id + "/delete/address",
    };

    try {
      const response = await apiService.customRequest(request);

      //

      console.log("Delete Address: ", response);

      return response.data;
    } catch (error) {
      throw new EndPointError();
    }
  },

  /**
   * Add user Comment.
   **/
  addComment: async function (payload) {
    const request = {
      method: "post",
      url: "/items/" + payload.id + "/add/comment",
    };

    try {
      const response = await apiService.customRequest(request);

      //

      console.log("Add Comment: ", response);

      return response.data;
    } catch (error) {
      throw new EndPointError();
    }
  },

  /**
   * Delete user Comment.
   **/
  deleteComment: async function (payload) {
    const request = {
      method: "post",
      url: "/items/" + payload.id + "/delete/comment",
    };

    try {
      const response = await apiService.customRequest(request);

      //

      console.log("Delete Comment: ", response);

      return response.data;
    } catch (error) {
      throw new EndPointError();
    }
  },

  /**
   * Add item rating.
   **/
  addItemRating: async function (payload) {
    const request = {
      method: "post",
      url: "/items/" + payload.id + "/add/star",
    };

    try {
      const response = await apiService.customRequest(request);

      //

      console.log("Add Rating: ", response);

      return response.data;
    } catch (error) {
      throw new EndPointError();
    }
  },

  /**
   * Get Addresses
   **/
  getAddresses: async function (payload) {
    const request = {
      method: "get",
      url: "/users/" + payload.id + "/addresses",
    };

    try {
      const response = await apiService.customRequest(request);

      //

      console.log("Get Addresses userjs: ", response);

      return response.data;
    } catch (error) {
      throw new EndPointError();
    }
  },

  /**
   * Get Coupons
   **/
  getCoupons: async function (payload) {
    const request = {
      method: "get",
      url: "/users/" + payload.id + "/coupons",
    };

    try {
      const response = await apiService.customRequest(request);

      //

      console.log("Get Coupons userjs: ", response);

      return response.data;
    } catch (error) {
      return error.reponse;
    }
  },

  /**
   * Apply Coupon code
   **/
  applyCoupon: async function (payload) {
    const request = {
      method: "post",
      url: "/orders/" + payload.uuid + "/use/coupon",
      data: {
        code: payload.vouchCode,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      //

      console.log("Apply Coupon userjs: ", response);

      return response.data;
    } catch (error) {
      throw new EndPointError(
        error.response.data.code,
        error.response.data.message
      );
    }
  },

  /**
   * checkout orders
   **/
  checkoutOrder: async function (payload) {
    const request = {
      method: "get",
      url: "/orders/" + payload.uuid + "/checkout",
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Checkout Order userjs: ", response);

      return response.data;
    } catch (error) {
      console.log("Checkout Order userjs error: ", error.response);

      return error.response;
    }
  },

  /**
   * validate orders
   **/
  validateOrder: async function (payload) {
    const request = {
      method: "post",
      url: "/orders/" + payload.uuid + "/validate",
      data: {
        payment: payload.when,
        meth: payload.method,
        phone: payload.phone,
      },
    };

    try {
      const response = await apiService.customRequest(request);

      console.log("Validate Order userjs: ", response);

      return response.data;
    } catch (error) {
      console.log("Validate Order userjs error: ", error.response);

      return error.response;
    }
  },

  /**
   * Get Comments
   **/
  getComments: async function (payload) {
    const request = {
      method: "get",
      url: "/users/" + payload.id + "/comments",
    };

    try {
      const response = await apiService.customRequest(request);

      //

      console.log("Get comments userjs: ", response);

      return response.data;
    } catch (error) {
      throw new EndPointError();
    }
  },

  /**
   * Get Statistics
   **/
  getStatistics: async function (payload) {
    const request = {
      method: "get",
      url: "/users/" + payload.id + "/overview",
    };

    try {
      const response = await apiService.customRequest(request);

      //

      console.log("Get Statistics userjs: ", response);

      return response.data;
    } catch (error) {
      throw new EndPointError();
    }
  },

  /**
   * Logout the current user by removing the token from storage.
   *
   * Will also remove `Authorization Bearer <token>` header from future requests.
   **/
  logout() {
    // Remove the token and remove Authorization header from Api Service as well
    TokenService.removeToken();
    // apiService.removeHeader();
  },
};

export default UserService;

export { UserService, EndPointError };
