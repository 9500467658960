<template>
  <div class="container">
    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
      color="var(--afprimary)"
      :height="height"
      :width="width"
      :transition="transition"
      background-color="#000"
    ></loading>
    <listing-widget
      :widgetName="$t('newPrdts')"
      :widgetImage="require('@/assets/images/sample-banner.jpg')"
      :widgetInfo="info"
      :banner="true"
      :colors="colorFeatures"
      :categories="categoriesList"
      :products="newProducts_s"
      :otherFilters="featuresNonColor"
      :filterRequest="filterRequest"
    >
      <template #categoryLoader>
        <div class="row text-center">
          <div class="col-12 pb-1" v-show="categoryLoading">
            {{ $t("Loading") }}
          </div>
          <div class="col-12 mb-4 pt-0">
            <v-progress-linear
              color="orange accent-3"
              :indeterminate="categoryLoading"
              :active="categoryLoading"
              rounded
              height="6"
            ></v-progress-linear>
          </div>
        </div>
      </template>
    </listing-widget>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ListingWidget from "@/components/widgets/GenericListing.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    ListingWidget,
    Loading,
  },

  data() {
    return {
      range: 0,

      categoriesList: [],
      values: [],
      keys: [],

      colorfilters: [],
      categoryfilters: [],
      featurefilters: [],
      currentMin: null,
      currentMax: null,
      newProducts: [],
      newProducts_s: [],

      colorFeatures: null,
      featuresNonColor: [],

      loading: false,
      loading2: false,
      height: 80,
      width: 100,
      transition: "fade",
    };
  },

  methods: {
    ...mapActions([
      "getGuestData",
      "filterCategories",
      "searchItemByRange",
      "searchItemByFeatures",
    ]),

    filterRequest() {
      this.loading = true;
      // this.loading2 = true;
      this.newProducts_s = [];
      let colorResp = [];
      let featureResp = [];
      let categoryResp = [];

      if (this.currentMin || this.currentMax) {
        this.searchItemByRange({
          minPrice: this.currentMin ? this.currentMin : this.minPrice,
          maxPrice: this.currentMax ? this.currentMax : this.maxPrice,
        }).then((response) => {
          this.newProducts_s = [...this.newProducts_s, ...response.items.data];
          this.loading = false;
          this.newProducts_s = this.removeDuplicates(this.newProducts_s);

          console.log("min filter response", this.newProducts_s);
        });
      } else if (this.colorfilters.length > 0) {
        this.colorfilters.forEach((color) => {
          this.searchItemByFeatures({
            values: color,
            keys: "color",
          }).then((response) => {
            this.loading = false;
            colorResp = [...new Set([...colorResp, ...response.items.data])];
            console.log("color: ", color, " response: ", response);
            this.newProducts_s = [...this.newProducts_s, ...colorResp];
            this.newProducts_s = this.removeDuplicates(this.newProducts_s);

            console.log("filter colorResp: ", this.newProducts_s);
          });
        });
      } else if (this.featurefilters.length > 0) {
        this.featurefilters.forEach((feature) => {
          this.searchItemByFeatures({
            values: feature.value,
            keys: feature.key,
          }).then((response) => {
            this.loading = false;
            featureResp = [
              ...new Set([...featureResp, ...response.items.data]),
            ];
            console.log("prev Searchprod", this.newProducts_s);
            this.newProducts_s = [...this.newProducts_s, ...featureResp];
            this.newProducts_s = this.removeDuplicates(this.newProducts_s);
            console.log("featureResp: ", featureResp, " response: ", response);
            console.log("filterProducts: ", this.newProducts_s);
          });
        });
      }

      // filter categories
      else if (this.categoryfilters.length > 0) {
        this.categoryfilters.forEach((category) => {
          this.filterCategories({
            category_id: category.id,
          }).then((response) => {
            this.loading = false;
            categoryResp = [
              ...new Set([...categoryResp, ...response.items.data]),
            ];
            this.newProducts_s = [...this.newProducts_s, ...categoryResp];
            this.newProducts_s = this.removeDuplicates(this.newProducts_s);
            console.log(
              "categoryResp: ",
              categoryResp,
              " response: ",
              response
            );
            console.log("filterCategories: ", this.newProducts_s);
          });
        });
      } else {
        this.loading = false;
        this.newProducts_s = this.newProducts;
      }
    },
  },

  computed: {
    ...mapGetters(["getNewProductsObj"]),

    info() {
      return {
        title: this.$t("newPrdts"),
        description: this.$t("latestProductCollections"),
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
      };
    },
  },

  mounted() {
    this.$root.$on("filterValues", (filters) => {
      console.log("filter request colors", filters.colors);
      console.log("filter request categories", filters.categories);
      console.log("filter request features", filters.features);
      this.colorfilters = filters.colors;
      this.categoryfilters = filters.categories;
      this.featurefilters = filters.features;
    });

    this.$root.$on("currentMin", (min) => {
      console.log("current Min: ", min);
      this.currentMin = min;
    });
    this.$root.$on("currentMax", (max) => {
      console.log("current Max: ", max);
      this.currentMax = max;
    });

    console.log("New Products__ !!!: ", this.newProducts);
    this.featuresNonColor = this.loadFeatures_(this.newProducts);
    this.colorFeatures = this.loadColorFeatures_();
    this.categoriesList = this.loadCategories_(this.newProducts);

    this.loadPrdtPrices_(this.newProducts);
  },
  created() {
    this.newProducts = this.newProducts_s = this.newProducts_
      ? this.newProducts_.data
      : this.getNewProductsObj.data;

    console.log("getting NewProductsObj", this.getNewProductsObj);
    this.$root.$on("refreshFilter", (refresh) => {
      if (refresh) {
        console.log("refreshing filter");
        this.filterRequest();
      }
    });

    this.$root.$on("emptyFilters", (empty) => {
      if (empty) {
        this.newProducts_s = this.newProducts;
      }
    });
  },
};
</script>

<style scoped></style>
