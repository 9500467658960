var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"head d-flex align-items-baseline py-2"},[_vm._m(0),_c('h6',{staticClass:"ml-2 my-auto"},[_vm._v(_vm._s(_vm.$t("AllCategories")))])]),_c('div',{attrs:{"id":"categories"}},[_c('ul',{staticClass:"list-group px-0"},_vm._l((_vm.categories),function(category,index){return _c('a',{key:index,staticClass:"text-decoration-none",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();}}},[_c('li',{class:[
            'list-group-item',
            'list-group-item-action',
            'px-3',
            'py-2',
            'border-0',
            'd-flex',
            'align-items-center' ],on:{"mouseover":function($event){_vm.setCategory(category.child);
            _vm.setActive();},"mouseleave":_vm.deactivate}},[_c('i',{class:['mdi', category.icon, 'text-muted', 'pr-2']}),_vm._v(" "+_vm._s(category.name)+" ")])])}),0)]),_c('SubCategories',{attrs:{"active":_vm.activeCategoryState}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('strong',{staticClass:"my-auto"},[_c('i',{staticClass:"mdi mdi-menu"})])}]

export { render, staticRenderFns }