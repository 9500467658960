<template>
  <div :class="[!responsive ? 'timer' : 'timer ml-2']">
    <span
      :class="[!responsive ? 'timerItem' : 'timerItem2']"
      v-if="currentTime"
    >
      <b :class="[!responsive ? 'timerCount' : 'timerCount2']">{{
        `${("0" + currentTime.hours).slice(-2)}`
      }}</b>
      <span class="timerLabel" v-if="!responsive">H</span>
    </span>
    <span :class="[!responsive ? 'timerItem' : 'timerItem2']">
      <b
        :class="[!responsive ? 'timerCount' : 'timerCount2']"
        v-if="currentTime"
      >
        {{ `${("0" + currentTime.minutes).slice(-2)}` }}
      </b>
      <span class="timerLabel" v-if="!responsive">MIN</span>
    </span>
    <span
      :class="[!responsive ? 'timerItem' : 'timerItem2']"
      v-if="currentTime"
    >
      <b :class="[!responsive ? 'timerCount' : 'timerCount2']">{{
        `${("0" + currentTime.seconds).slice(-2)}`
      }}</b>
      <span class="timerLabel" v-if="!responsive">SEC</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    deadline: {
      type: String,
      required: true,
    },
    speed: {
      type: Number,
      default: 1000,
    },
    responsive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTime: null,
    };
  },
  mounted() {
    if (this.deadline) {
      setTimeout(this.countdown, 1);
    }
  },
  methods: {
    countdown() {
      let t = Math.abs(new Date() - new Date(this.deadline));
      let seconds = Math.floor((t / 1000) % 60);
      let minutes = Math.floor((t / 1000 / 60) % 60);
      let hours = Math.floor(t / (1000 * 60 * 60));
      let days = Math.floor(t / (1000 * 60 * 60 * 24));
      if (t > 0) {
        this.currentTime = {
          total: t,
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
        setTimeout(this.countdown, this.speed);
      } else {
        this.currentTime = null;
      }
    },
  },
};
</script>

<style scoped>
.timer {
  position: relative;
  z-index: 2;
  margin-top: 45px;
  font-size: 0;
  color: #fff;
  text-align: center;
}

.timerItem {
  margin: 0 5px;
  display: inline-block;
  vertical-align: top;
  min-width: 40px;
}

.timerItem2 {
  margin: 0 5px;
  display: inline-block;
  vertical-align: top;
  min-width: 33px;
}

.timerCount {
  display: block;
  height: 32px;
  line-height: 32px;
  background-color: #3f3f3f;
  font-size: 28px;
  border-radius: 6px 6px 0 0;
  font-weight: 700;
}

.timerCount2 {
  display: block;
  height: 25px;
  line-height: 26px;
  background-color: #ff8100;
  font-size: 15px;
  border-radius: 5px;
  font-weight: 700;
}

.timerLabel {
  display: block;
  margin-top: 1px;
  height: 20px;
  line-height: 20px;
  background-color: #101010;
  font-size: 12px;
  border-radius: 0 0 6px 6px;
}

@media (max-width: 991px) {
  .timerCount {
    font-size: 4vmin;
  }
}

@media only screen and (max-width: 600px) {
  .timerCount2 {
    font-size: 13px;
    height: 26px;
    width: 29px;
  }

  .timerItem2 {
    margin: 0 4px;
    display: inline-block;
    vertical-align: top;
    min-width: 23px;
  }
}
</style>
