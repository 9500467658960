import { UserService } from "@/services/user";
import router from "@/router/index";

const state = {};

const mutations = {
  newsletterSuccess(state, payload) {
    console.log("newsletter index mutation: ", payload);
    router.push({ name: "account-index" });
  },
};

const getters = {};

const actions = {
  async subscribeNewsletter({ commit }, payload) {
    try {
      const response = await UserService.subscribeNewsletter(payload);

      console.log("Newsletter Subscription: ", response);
      commit("newsletterSuccess", response);

      return response;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
