import { UserService, EndPointError } from "@/services/user";
import { OrderService } from "@/services/order";

const state = {
  ordersList: null,
  cart_no: 0,
  EndPointError: "",
};

const mutations = {
  SET_ORDERS(state, payload) {
    state.ordersList = payload;
    localStorage.setItem("orders", JSON.stringify(payload));
    console.log("Indexjs Orders: ", payload);
  },

  completeOrderError(state, { errorCode, errorMessage }) {
    state.completionError = { code: errorCode, msg: errorMessage };
    console.log("checkout order error", state.completionError);
  },

  setNoCartItems(state) {
    let i = 0;
    if (state.ordersList) {
      console.log("ordersList: ", state.ordersList);
      if (state.ordersList.data.length > 0) {
        state.ordersList.data.forEach((order) => {
          console.log("SET_NO_CART: ", order.order);
          if (order.status === "cart") {
            i += order.order_item.length;
          }
        });

        state.cart_no = i;
        console.log("Cart Items no: ", state.cart_no);
      } else {
        state.cart_no = 0;
        console.log("Cart Items no 1: ", state.cart_no);
      }
    } else {
      state.cart_no = 0;
    }
  },
};

const getters = {
  getCachedOrders: (state) => state.ordersList,

  EndPointError: (state) => state.EndPointError,

  noCartItems(state) {
    console.log("noCartItems", state.cart_no);
    return state.cart_no;
  },
};

const actions = {
  async getOrders({ commit }) {
    try {
      const response = await OrderService.getOrders();

      if (response.code == "ALL_ORDERS") {
        commit("SET_ORDERS", response.data.orders);
      }

      return true;
    } catch (e) {
      console.log("IndexJs getOrders error: ", e);
      return e;
    }
  },

  async getOrderDetail({ payload }) {
    try {
      const response = await OrderService.getOrderDetail(payload);

      console.log("response order detail: ", response);
      return response;
    } catch (e) {
      console.log("IndexJs getOrderDetail error: ", e);
      return e;
    }
  },

  async checkoutOrder({}, payload) {
    try {
      const response = await UserService.checkoutOrder(payload);

      console.log("Checkout order index: ", response);

      return response;
    } catch (e) {
      console.error("checkout order error", e);

      return e;
    }
  },

  async validateOrder({ commit }, payload) {
    try {
      const response = await UserService.validateOrder(payload);

      // console.log("Apply Coupon index: ", response);

      return response;
    } catch (e) {
      if (e instanceof EndPointError) {
        console.error("complete order error", e);
        commit("completeOrderError", {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }
      return false;
    }
  },

  async markOrderComplete({ commit }, payload) {
    try {
      const response = await OrderService.markOrderComplete(payload);

      // console.log("Apply Coupon index: ", response);

      return response;
    } catch (e) {
      console.error("complete order error", e);

      return e;
    }
  },

  async getPaymentStatus({ commit }, payload) {
    try {
      const response = await OrderService.getPaymentStatus(payload);

      // console.log("Apply Coupon index: ", response);

      return response;
    } catch (e) {
      console.error("payment status index error", e);

      return e;
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
