<template>
  <v-app>
    <Nav v-show="!($route.name == 'signup')"></Nav>
    <v-main id="main" class="mx-0 pt-0">
      <vue-page-transition name="fade">
        <router-view :key="$route.fullPath"> </router-view>
      </vue-page-transition>
    </v-main>

    <Footer v-show="!($route.name == 'signup')" />

    <chatbot></chatbot>
    <go-top
      :size="50"
      :max-width="500"
      :right="30"
      weight="bold"
      bg-color="#ff4500"
      :src="require('@/assets/icons/up-arrow.png')"
    ></go-top>
  </v-app>
</template>

<script>
import Nav from "@/components/nav/Nav";
import Footer from "@/components/footer/Footer";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import GoTop from "@inotom/vue-go-top";
import Chatbot from "@/components/Chatbot";

import "@/assets/css/app.css";
export default {
  name: "App",
  components: {
    Nav,
    Footer,
    GoTop,
    Chatbot,
  },
  data() {
    return {
      user: {},
    };
  },

  methods: {
    ...mapActions(["getGuestData", "getOrders", "getIntlCountries"]),
    ...mapMutations(["setNoCartItems"]),
  },

  computed: {
    ...mapGetters(["loggedIn", "getUserDetails", "getGuestDataObj"]),

    ...mapState({ allOrders: "ordersList", cart: "cartItems" }),
  },

  mounted() {
    this.getIntlCountries();
    console.log("Actions: ", this.$store._actions);
    console.log("Modules: ", this.$store._modules);
  },

  created() {
    if (this.loggedIn) {
      this.getOrders().then((response) => {
        console.log("App orders loaded", response);
        this.setNoCartItems();
      });
    }

    this.$root.$on("navDrawer", (props) => {
      document.getElementById("main").style.marginLeft = props.ml;
    });

    this.$root.$on("closeDrawer", (props) => {
      document.getElementById("main").style.marginLeft = props.ml;
      console.log("main close entered");
    });
  },
};
</script>
<style></style>
