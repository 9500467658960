import { render, staticRenderFns } from "./ShopRegister.vue?vue&type=template&id=02c52ff6&scoped=true&"
import script from "./ShopRegister.vue?vue&type=script&lang=js&"
export * from "./ShopRegister.vue?vue&type=script&lang=js&"
import style0 from "./ShopRegister.vue?vue&type=style&index=0&id=02c52ff6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02c52ff6",
  null
  
)

export default component.exports