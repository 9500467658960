<template>
  <div>
    <div class="container">
      <Slider :sliderData="sliderData" />

      <AboutPosters />

      <SuperDeals
        :currentDeals="superDeals_ ? superDeals_.current_deals.data : []"
      />

      <NewProducts :newProducts="newProducts_ ? newProducts_.data : []" />

      <Flash2Cards />

      <HotCategories :categoryItems="hotCategories" />

      <Brands :brandItems="brands ? guestData_.brands : brands" />

      <RandomProductBanner />

      <Recommended />
    </div>
  </div>
</template>

<script>
import Brands from "@/components/home/Brands";
import SuperDeals from "@/components/home/SuperDeals";
import Slider from "@/components/home/Slider";
import NewProducts from "@/components/home/NewProducts";
import HotCategories from "@/components/home/HotCategories";
import Recommended from "@/components/home/Recommended";
import AboutPosters from "@/components/home/AboutPosters";
import RandomProductBanner from "@/components/home/RandomProductBanner";
import Flash2Cards from "@/components/home/Flash2Cards";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "BaseContent",
  components: {
    Slider,
    Brands,
    SuperDeals,
    NewProducts,
    HotCategories,
    Recommended,
    AboutPosters,
    RandomProductBanner,
    Flash2Cards,
  },
  data() {
    return {
      brands: [],
      hotCategories: [],
      currentDeals: [],
      events: [],
      allCategories: [],
      sliderData: {},
    };
  },

  computed: {
    ...mapGetters([
      "loggedIn",
      "getUserDetails",
      "getGuestDataObj",
      "getSuperDealsObj",
      "getNewProductsObj",
      "getRecommendedObj",
    ]),
  },
  methods: {
    ...mapActions([
      "getGuestData",
      "getSuperDeals",
      "getNewProducts",
      "getRecommendedProducts",
      "getUser",
      "getStatesAction",
    ]),
  },
  beforeMount() {
    this.getGuestData().then(() => {
      console.log("In BaseContent: Guest Data: ", this.getGuestDataObj);
      this.brands = this.guestData_.brands;
      this.hotCategories = this.guestData_.hotCategories;
      this.events = this.guestData_.events;
      this.allCategories = this.guestData_.allCategories;

      this.sliderData = {
        events: this.events,
        allCategories: this.allCategories,
      };
    });

    this.getSuperDeals().then(() => {
      console.log("In BaseContent: SuperD: ", this.getSuperDealsObj);
    });

    this.getNewProducts().then(() => {
      console.log("In BaseContent: NewP: ", this.getNewProductsObj);
    });

    this.getRecommendedProducts().then(() => {
      console.log("In BaseContent: Recommd: ", this.getRecommendedObj);
    });

    if (this.loggedIn) {
      this.getStatesAction({
        id: this.user_.country.id,
      })
        .then(() => {
          console.log("GEO DATA index: ", this.geoData);
          console.log("GEO DATA user: ", this.user_);
        })
        .catch((err) => {
          console.log("GEO DATA error: ", err);
        });
    }
  },

  mounted() {},
};
</script>

<style scoped></style>
