var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[!_vm.showAsAside ? 'shadow-sm toggled' : ''],attrs:{"id":"sideDash"}},[_c('ul',{staticClass:"list-group px-0"},[_c('router-link',{attrs:{"to":{ name: 'account-index' }}},[_c('li',{class:[
          'list-group-item',
          'py-4',
          'list-group-item-action',
          this.$route.name === 'account-index' ? 'active' : '',
          'd-flex',
          'align-center',
          'border-0' ],on:{"click":_vm.close}},[_c('i',{staticClass:"mdi mdi-account-outline h5 mb-0"}),_c('span',{staticClass:"ml-3 text-capitalize"},[_vm._v(_vm._s(_vm.$t("MyAfeshopAccount")))])])]),_c('router-link',{attrs:{"to":{ name: 'statistics' }}},[_c('li',{class:[
          'list-group-item',
          'py-4',
          'list-group-item-action',
          this.$route.name === 'statistics' ? 'active' : '',
          'd-flex',
          'align-center',
          'border-0' ],on:{"click":_vm.close}},[_c('i',{staticClass:"mdi mdi-graph h5 mb-0"}),_c('span',{staticClass:"ml-3 text-capitalize"},[_vm._v(_vm._s(_vm.$t("Statistics")))])])]),_c('router-link',{attrs:{"to":{ name: 'purchases' }}},[_c('li',{class:[
          'list-group-item',
          'py-4',
          'list-group-item-action',
          this.$route.path.includes('/customer/purchases') ? 'active' : '',
          'd-flex',
          'align-center',
          'border-0' ],on:{"click":_vm.close}},[_c('i',{staticClass:"mdi mdi-arrow-down h5 mb-0"}),_c('span',{staticClass:"ml-3 text-capitalize"},[_vm._v(_vm._s(_vm.$t("Orders")))])])]),_c('router-link',{attrs:{"to":{ name: 'cart' }}},[_c('li',{class:[
          'list-group-item',
          'py-4',
          'list-group-item-action',
          this.$route.name === 'cart' ? 'active' : '',
          'd-flex',
          'align-center',
          'border-0' ],on:{"click":_vm.close}},[_c('i',{staticClass:"mdi mdi-cart-outline h5 mb-0"}),_c('span',{staticClass:"ml-3 text-capitalize"},[_vm._v(_vm._s(_vm.$t("MyShoppingCart")))])])]),_c('router-link',{attrs:{"to":{ name: 'comments' }}},[_c('li',{class:[
          'list-group-item',
          'py-4',
          'list-group-item-action',
          this.$route.name === 'comments' ? 'active' : '',
          'd-flex',
          'align-center',
          'border-top-0',
          'border-left-0',
          'border-right-0' ],on:{"click":_vm.close}},[_c('i',{staticClass:"mdi mdi-comment-multiple-outline h5 mb-0"}),_c('span',{staticClass:"ml-3 text-capitalize"},[_vm._v(_vm._s(_vm.$t("Comments")))])])]),_c('router-link',{attrs:{"to":{ name: 'voucher' }}},[_c('li',{class:[
          'list-group-item',
          'py-4',
          'list-group-item-action',
          this.$route.name === 'voucher' ? 'active' : '',
          'd-flex',
          'align-center',
          'border-top-0',
          'border-left-0',
          'border-right-0' ],on:{"click":_vm.close}},[_c('i',{staticClass:"mdi mdi-ticket-percent-outline h5 mb-0"}),_c('span',{staticClass:"ml-3 text-capitalize"},[_vm._v(_vm._s(_vm.$t("Vouchers")))])])]),_c('router-link',{attrs:{"to":{ name: 'account-edit' }}},[_c('li',{class:[
          'list-group-item',
          'py-4',
          'list-group-item-action',
          this.$route.name === 'account-edit' ? 'active' : '',
          'd-flex',
          'align-center',
          'border-0' ],on:{"click":_vm.close}},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("Details")))])])]),_c('router-link',{attrs:{"to":{ name: 'addressbook' }}},[_c('li',{class:[
          'list-group-item',
          'py-4',
          'list-group-item-action',
          this.$route.name === 'addressbook' ? 'active' : '',
          'd-flex',
          'align-center',
          'border-0' ],on:{"click":_vm.close}},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("AddressBook")))])])]),_c('router-link',{attrs:{"to":{ name: 'newsletter' }}},[_c('li',{class:[
          'list-group-item',
          'py-4',
          'list-group-item-action',
          this.$route.name === 'newsletter' ? 'active' : '',
          'd-flex',
          'align-center',
          'border-top-0',
          'border-left-0',
          'border-right-0' ],on:{"click":_vm.close}},[_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t("NewsletterPreferences")))])])]),_c('li',{staticClass:"\n        py-2\n        list-group-item\n        border-left-0 border-right-0 border-bottom-0\n        text-center\n        list-unstyled\n      "},[_c('button',{staticClass:"btn btn-font-trans color-font",on:{"click":function($event){$event.stopPropagation();_vm.logout();
          _vm.close();}}},[_vm._v(" "+_vm._s(_vm.$t("LOGOUT"))+" ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }