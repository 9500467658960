<template>
  <li
    class="py-2 nav-item dropdown vertical_menu d-inline d-md-none d-lg-none"
    v-if="categories"
  >
    <a
      class="dropdown-toggle"
      href="#"
      id="megaCategories"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      ><i class="mdi mdi-view-grid"></i
      ><span class="ml-2">{{ $t("categories") }}</span
      >&nbsp;
      <span class="float-right"><i class="fas fa-angle-down"></i> </span>
    </a>
    <ul
      id="megaMenu"
      class="dropdown-menu bg-light pl-2 pr-0"
      aria-labelledby="megaCategories"
    >
      <li
        :class="
          category.child.length > 0 ? 'dropdown-submenu' : 'dropdown-item'
        "
        v-for="category in categories"
        :key="category.id"
      >
        <a
          class="dropdown-item font-weight-bolder text-uppercase"
          :class="
            category.child.length > 0 ? ' dropdown-toggle menu_header' : ''
          "
          href="#"
          >{{ category.name | truncate(20, "...") }}
          <span class="float-right"><i class="fas fa-angle-down"></i> </span
        ></a>
        <ul
          class="dropdown-menu dnone bg-transparent"
          v-if="category.child.length > 0"
        >
          <li v-for="element in category.child" :key="element.id" class="pl-3">
            <router-link
              :to="{
                name: 'category',
                params: { id: element.id, parent: category, child: element },
              }"
              class="dropdown-item ml-n7"
              href="#"
              >{{ element.name }}</router-link
            >
          </li>
        </ul>
      </li>
    </ul>
  </li>
</template>

<script>
import $ from "jquery";

export default {
  props: {
    categories: {
      type: Array,
      required: false,
    },
  },

  created() {
    console.log("categories menu: ", this.categories);
  },

  mounted() {
    $(".dropdown-submenu a.menu_header").on("click", function (e) {
      $(this).next("ul").toggle();

      $(this).next("ul.dropdown-menu").toggleClass("dnone");

      e.stopPropagation();
      e.preventDefault();
    });

    $();
  },
};
</script>
