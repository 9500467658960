<template>
  <div class="row">
    <div class="col-md-12 py-0">
      <div class="card pane border-0 shadow-sm bg-white py-4 px-5">
        <!-- Header -->
        <div
          class="
            card-header
            border-0
            bg-transparent
            pl-2
            pb-2
            d-flex
            align-items-center
          "
        >
          <button @click="$router.go(-1)" class="btn btn-lg pl-0 text-dark">
            <i class="mdi mdi-arrow-left h5"></i>
          </button>
          <h4>{{ $t("NewsletterPreferences") }}</h4>
        </div>

        <!-- Content -->
        <div class="row px-2">
          <div class="col-md-6 position-absolute">
            <div class="card newsbox border">
              <h6 class="card-header bg-transparent">
                {{ $t("SUBSCRIBETO") }}
              </h6>

              <div class="card-body">
                <form action>
                  <v-switch color="#ffa300" v-model="switchMe">
                    <template v-slot:label>
                      {{ $t("newsletterNotifications") }}
                      <v-progress-circular
                        :indeterminate="switchMe"
                        :value="0"
                        size="18"
                        class="ml-5"
                        v-if="showLoader"
                      ></v-progress-circular>
                    </template>
                  </v-switch>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="row px-2">
          <div class="col-md-12">
            <button
              class="btn btn-block py-3 mb-3 shadow btn-orange"
              @click.prevent="submitForm"
            >
              {{ $t("SAVE") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      newsletter: false,
      user_id: null,
      user: null,
      switchMe: false,
      showLoader: false,
    };
  },

  methods: {
    ...mapActions(["subscribeNewsletter"]),

    submitForm() {
      this.showLoader = true;
      this.subscribeNewsletter({
        email: this.user.email,
      }).then((response) => {
        console.log("newsletter response: ", response);
      });
    },
  },

  computed: {
    ...mapGetters(["getUserDetails"]),
  },

  mounted() {
    this.user = this.getUserDetails;
    this.user_id = this.getUserDetails.id;

    console.log("newsletter user: ", this.user.email);
  },
};
</script>

<style scoped>
a.btn > i {
  font-size: 1.3rem;
}
.btn.rnd {
  border-radius: 50%;
}
.btn.rnd:hover {
  background: #db9e3557;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #cf8c15db;
  background-color: #cf8c15db;
}

.card.newsbox {
  height: auto !important;
}

@media (max-width: 768px) {
  .card.pane {
    height: 30em !important;
  }
}
</style>
